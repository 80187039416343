<template>
    <div class="w-5/6"
    v-if="this.$store.state.schedule.jobs"
    >
        <div class="content">
            <div class="col-span-12 mt-5 md:col-span-6 lg:col-span-4">
                <div class="flex justify-between">
                    <p class="cursor-pointer ml-2 font-bold text-4xl" @click="goBack()">
                        &#171;
                    </p>
                    <div class="flex">
                        <button
                              :class="{'bg-gray-500' : qsIngesionClicked}"
                              :disabled="qsIngesionClicked"
                              class="btn shadow-md text-white mr-6 nymus-orange"
                              @click="qsIngestion"
                        >
                            Ingestion
                        </button>
                        <p
                              v-if="this.$store.state.currentUser.group !== 'NymusViewer'"
                              :class="{ hidden: isLegalEntityComputed }"
                              class="cursor-pointer ml-2 font-bold text-3xl"
                              @click="view(selectedTabName)"
                        >
                            <font-awesome-icon icon="pencil"/>
                        </p>
                    </div>
                </div>
                <h1 class="font-bold text-2xl text-center">{{ supplierGroup.name }}</h1>
                <div>
                    <tabs v-model="selectedTab">
                        <tab
                              class="tab"
                              v-for="(tab, i) in tabs"
                              :key="`t${i}`"
                              :val="tab"
                              :label="tab"
                              :indicator="true"
                        />
                    </tabs>
                    <tab-panels v-model="selectedTab" :animate="false">
                        <tab-panel val="General">
                            <ViewGeneral :languages="languages" :typeData="typeData"/>
                        </tab-panel>
                        <tab-panel val="Branding">
                            <ViewBranding/>
                        </tab-panel>
                        <tab-panel val="Peppol Documents">
                            <ViewPeppolDocuments isFor="supplierGroup"/>
                        </tab-panel>
						<tab-panel val="Ingestion">
							<tabs
								v-model="selectedSubTabIngestion"
								class="justify-start ml-20"
							>
								<tab
									class="tab"
									v-for="(tab, i) in tabsIngestion"
									:key="`t${i}`"
									:val="tab"
									:label="tab"
									:indicator="true"
								/>
							</tabs>
							<tab-panels v-model="selectedSubTabIngestion" :animate="false">
								<tab-panel val="System Ingestion">
									<ViewIngestion
										:ingestion="ingestion"
										:style="{'display': selectedSubTabIngestion === 'Extraction' ? 'none': 'block'}"
									/>
								</tab-panel>
								<tab-panel val="Extraction">
									<ViewExtraction
										:fieldTypeData="fieldTypeData"
										:extractionDataType="extractionDataType"
									/>
								</tab-panel>
							</tab-panels>
						</tab-panel>
                        <tab-panel val="Settings">
                            <tabs
                                  v-model="selectedSubTabSettings"
                                  class="justify-start ml-60"
                            >
                                <tab
                                      class="tab"
                                      v-for="(tab, i) in tabsSettings"
                                      :key="`t${i}`"
                                      :val="tab"
                                      :label="tab"
                                      :indicator="true"
                                />
                            </tabs>
                            <tab-panels v-model="selectedSubTabSettings" :animate="false">
                                <tab-panel val="System">
                                    <ViewSettings/>
                                </tab-panel>
                                <tab-panel val="Reporting">
                                    <ViewReporting isFor="supplierGroup"/>
                                </tab-panel>
                            </tab-panels>
                        </tab-panel>
                        <tab-panel val="Channels">
                            <tabs
                                  v-model="selectedSubTabChannels"
                                  class="justify-start ml-72"
                            >
                                <tab
                                      class="tab"
                                      v-for="(tab, i) in tabsChannel"
                                      :key="`t${i}`"
                                      :val="tab"
                                      :label="tab"
                                      :indicator="true"
                                />
                            </tabs>
                            <tab-panels v-model="selectedSubTabChannels" :animate="false">
                                <tab-panel val="Mail Channel">
                                    <ViewMailChannel/>
                                </tab-panel>
                                <tab-panel val="EDI">
                                    <ViewEdi/>
                                </tab-panel>
                                <tab-panel val="Print">
                                    <ViewPrint
                                          :printColorData="printColorData"
                                          :printProviderData="printProviderData"
                                          :printEnvelopeTypeData="printEnvelopeTypeData"
                                          :languages="countryCodes"
                                    />
                                </tab-panel>
                            </tab-panels>
                        </tab-panel>
                        <tab-panel val="Channel maintenance">
                            <ViewChannelMaintenance/>
                        </tab-panel>
                        <tab-panel val="Payments">
                            <ViewPayments/>
                        </tab-panel>
                        <tab-panel val="Contact Info and Help">
                            <ViewContact/>
                        </tab-panel>
                        <tab-panel val="Templates">
                            <tabs v-model="selectedSubTabTemplates" class="justify-start marginTemplates">
                                <tab
                                      class="tab"
                                      v-for="(tab, i) in tabsTemplate"
                                      :key="`t${i}`"
                                      :val="tab"
                                      :label="tab"
                                      :indicator="true"
                                />
                            </tabs>
                            <tab-panels v-model="selectedSubTabTemplates" :animate="false">
                                <tab-panel val="Mail">
                                    <ViewMailTemplate
                                          isFor="supplierGroup"
                                          :languages="languages"
                                    />
                                </tab-panel>
                                <tab-panel val="QR">
                                    <ViewQrTemplate
                                          isFor="supplierGroup"
                                          :qrOverlayData="qrOverlayData"
                                    />
                                </tab-panel>
                                <tab-panel val="Overlay">
                                    <ViewOverlayTemplate
                                          isFor="supplierGroup"
                                          :overlayOverlayData="overlayOverlayData"
                                    />
                                </tab-panel>
                                <tab-panel val="Terms and Conditions">
                                    <ViewTac isFor="supplierGroup"/>
                                </tab-panel>
                                <tab-panel val="Pre-Ingestion">
                                    <ViewPreIngestion isFor="supplierGroup"/>
                                </tab-panel>
                                <tab-panel val="Resend">
                                    <ViewResendTemplate isFor="supplierGroup"/>
                                </tab-panel>
                                <tab-panel val="Inserts">
                                  <ViewInserts isFor="supplierGroup"/>
                                </tab-panel>
                            </tab-panels>
                        </tab-panel>

                        <tab-panel val="Legal Entities">
                            <ViewLegalEntities/>
                        </tab-panel>
                        <tab-panel val="Actions">
                            <ViewActions/>
                        </tab-panel>

                    </tab-panels>
                </div>
            </div>
        </div>
    </div>
    <EditGeneral :languages="languages" :typeData="typeData"/>
    <EditChannelMaintenance/>
    <EditPayments></EditPayments>
    <EditBranding :languages="languages"/>
    <EditPeppolDocuments isFor="supplierGroup"/>
    <EditIngestion :ingestionData="ingestionData"/>
    <EditExtraction :extractionDataType="extractionDataType" :fieldTypeData="fieldTypeData"/>
    <AddExtractionTemplate :extractionDataType="extractionDataType" :fieldTypeData="fieldTypeData"/>
    <EditPass :ingestionData="ingestionData"/>
    <EditSettings/>
    <EditReporting :languages="languages" isFor="supplierGroup"/>
    <EditContactInfoAndHelp/>
    <EditEDI/>
    <EDitMailChannel/>
    <EditPrint :printColorData="printColorData"
               :printProviderData="printProviderData"
               :printEnvelopeTypeData="printEnvelopeTypeData"
               :languages="countryCodes"/>
    <EditMailTemplate :langCodes="languages" isFor="supplierGroup"/>
    <EditQrTemplate
          :qrOverlayData="qrOverlayData"
          :langCode="languages"
          isFor="supplierGroup"
    />
    <EditOverlayTemplate
          :langCodes="languages"
          :overlayOverlayData="overlayOverlayData"
          isFor="supplierGroup"
    />
    <EditTacTemplate :langCodes="languages" isFor="supplierGroup"/>
    <EditInserts :langCodes="languages" isFor="supplierGroup"/>
    <EditPreIngestion isFor="supplierGroup"></EditPreIngestion>
    <EditResendTemplate isFor="supplierGroup"></EditResendTemplate>
</template>
<script>
import {reactive, toRefs} from "@vue/reactivity";
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import langCodes from "../langCodes";
import countryCode from "../countryCodes"
import {Tabs, Tab, TabPanels, TabPanel} from "vue3-tabs";

import ViewGeneral from "../components/ViewDetails/ViewGeneral.vue";
import ViewPayments from "../components/ViewDetails/ViewPayments.vue";
import ViewBranding from "../components/ViewDetails/ViewBranding.vue";
import ViewPeppolDocuments from "../components/ViewDetails/ViewPeppolDocuments.vue";
import ViewSettings from "../components/ViewDetails/ViewSettings.vue";
import ViewReporting from "../components/ViewDetails/ViewReporting.vue"
import ViewIngestion from "../components/ViewDetails/ViewIngestion.vue";
import ViewExtraction from "../components/ViewDetails/ViewExtraction.vue";
import ViewMailChannel from "../components/ViewDetails/ViewMailChannel.vue";
import ViewEdi from "../components/ViewDetails/ViewEdi.vue";
import ViewContact from "../components/ViewDetails/ViewContact.vue";
import ViewMailTemplate from "../components/templates/mail/ViewMailTemplate.vue";
import ViewQrTemplate from "../components/templates/qr/ViewQrTemplate.vue";
import ViewOverlayTemplate from "../components/templates/overlay/ViewOverlayTemplate.vue";
import ViewTac from "../components/templates/t&c/ViewTac.vue";
import ViewInserts from "../components/templates/inserts/ViewInserts.vue";
import ViewPreIngestion from "../components/templates/preIngestion/ViewPreIngestion.vue";
import ViewResendTemplate from "../components/templates/resend/ViewResendTemplate.vue";

import ViewLegalEntities from "../components/ViewDetails/ViewLegalEntities.vue";
import ViewPrint from "../components/ViewDetails/ViewPrint.vue";
import ViewActions from "../components/ViewDetails/ViewActions.vue"
import ViewChannelMaintenance from "../components/ViewDetails/ViewChannelMaintenance"
import EditGeneral from "../components/EditSupplierGroup/EditGeneral.vue";
import EditChannelMaintenance from "../components/EditSupplierGroup/EditChannelMaintenance.vue";
import EditPayments from "../components/EditSupplierGroup/EditPayments.vue";

import EditBranding from "../components/EditSupplierGroup/EditBranding.vue";
import EditPeppolDocuments from "../components/EditSupplierGroup/EditPeppolDocuments.vue";
import EditIngestion from "../components/EditSupplierGroup/EditIngestion.vue";
import EditPass from "../components/EditSupplierGroup/EditPass.vue";
import EditExtraction from "../components/EditSupplierGroup/EditExtraction.vue"
import AddExtractionTemplate from "../components/AddSupplierGroup/AddExtractionTemplate.vue"
import EditSettings from "../components/EditSupplierGroup/EditSettings.vue";
import EditReporting from "../components/EditSupplierGroup/EditReporting.vue"
import EditContactInfoAndHelp from "../components/EditSupplierGroup/EditContactInfoAndHelp.vue";
import EditEDI from "../components/EditSupplierGroup/EditEDI.vue";
import EDitMailChannel from "../components/EditSupplierGroup/EditMailChannel.vue";
import EditPrint from "../components/EditSupplierGroup/EditPrint.vue"
import EditMailTemplate from "../components/templates/mail/EditMail.vue";
import EditQrTemplate from "../components/templates/qr/EditQR.vue";
import EditOverlayTemplate from "../components/templates/overlay/EditOverlay.vue";
import EditTacTemplate from "../components/templates/t&c/EditTac.vue";
import EditInserts from "../components/templates/inserts/EditInserts.vue";
import EditPreIngestion from "../components/templates/preIngestion/EditPreIngestion.vue";
import EditResendTemplate from "../components/templates/resend/EditResendTemplate.vue";

export default {
    components: {
        Tabs,
        Tab,
        TabPanels,
        TabPanel,
        ViewGeneral,
        ViewPayments,
        ViewBranding,
        ViewPeppolDocuments,
        ViewIngestion,
        ViewExtraction,
        ViewSettings,
        ViewReporting,
        ViewMailChannel,
        ViewEdi,
        ViewContact,
        ViewMailTemplate,
        ViewQrTemplate,
        ViewOverlayTemplate,
        ViewTac,
        ViewInserts,
        ViewPreIngestion,
        ViewResendTemplate,
        ViewLegalEntities,
        ViewPrint,
        ViewActions,
        ViewChannelMaintenance,
        EditGeneral,
        EditChannelMaintenance,
        EditPayments,
        EditBranding,
        EditPeppolDocuments,
        EditIngestion,
        EditExtraction,
        EditSettings,
        EditReporting,
        EditContactInfoAndHelp,
        EditEDI,
        EDitMailChannel,
        EditPrint,
        EditMailTemplate,
        EditQrTemplate,
        EditOverlayTemplate,
        EditTacTemplate,
        EditInserts,
        EditPreIngestion,
        EditResendTemplate,
        AddExtractionTemplate,
        EditPass
    },
    computed: {
        isLegalEntityComputed() {
            if (this.selectedTabName === "Legal Entities") {
                return true;
            }
            return false;
        },
    },
    watch: {
        "$store.state.supplierGroups": {
            handler(data) {
                this.supplierGroup = data.find(
                      (sg) => sg.supplierGroupId === this.$route.params.id
                );
                this.getIngestionValue();
                this.getUniqueFiles();
            },
            deep: true,
        },
        "$store.state.activeSG": {
            handler(data) {
                this.getIngestionValue();
                this.getUniqueFiles();
            },
            deep: true,
        },
        selectedTab(newVal) {
            this.updateSelectedTabName(newVal);
            if (newVal === "Channels") {
                this.updateSelectedTabName(this.selectedSubTabChannels);
            }
            if (newVal === "Settings") {
                this.updateSelectedTabName(this.selectedSubTabSettings);
            }
            if (newVal === "Templates") {
                this.updateSelectedTabName(this.selectedSubTabTemplates);
            }
            if (newVal === "Ingestion") {
                this.updateSelectedTabName(this.selectedSubTabIngestion);
            }
        },
        selectedSubTabChannels(newVal) {
            this.updateSelectedTabName(newVal);
        },
        selectedSubTabSettings(newVal) {
            this.updateSelectedTabName(newVal);
        },
        selectedSubTabTemplates(newVal) {
            this.updateSelectedTabName(newVal);
        },
        selectedSubTabIngestion(newVal) {
            this.updateSelectedTabName(newVal)
        }
    },
    methods: {
        async qsIngestion() {
            await this.$store.dispatch("schedule/startQuickActionIngestion", this.$store.state.activeSG.supplierGroupId)
            if (this.$store.state.responseStatus === 200) {
                this.$store.commit("toggleAlert", {
                    title: this.$store.state.activeSG.name,
                    text: "Started QA Ingestion ",
                });
            } else {
                this.$store.commit("toggleAlert", {
                    title: this.$store.state.activeSG.name,
                    text: "ERROR",
                });
            }
            this.qsIngesionClicked = true
            setTimeout(() => {
                this.qsIngesionClicked = false;
            }, 2000);
        },
        updateSelectedTabName(data) {
            this.$store.state.activeSGTab = data
            this.selectedTabName = data
        },
        goBack() {
            this.$store.state.activeSGTab = "General"

            this.$router.push('/en');
          //  this.$router.back();
        },
        view(string) {
            switch (string) {
                case "General":
                    this.$store.state.displayEditGeneral = true;
                    break;
                case "Payments":
                    this.$store.state.displayEditPayments = true;
                    break;
                case "Branding":
                    this.$store.state.displayEditBranding = true;
                    break;
                case "Peppol Documents":
                    this.$store.state.displayEditPeppolDocuments = true;
                    break;
                case "System Ingestion":
                    this.$store.state.displayEditIngestion = true;
                    break;
                case "Extraction":
                    this.$store.state.displayEditExtraction = true;
                    break;
                case "System":
                    this.$store.state.displayEditSettings = true;
                    break;
                case "Reporting":
                    this.$store.state.displayEditReporting = true;
                    break;
                case "Contact Info and Help":
                    this.$store.state.displayEditContactInfoAndHelp = true;
                    break;
                case "Mail Channel":
                    this.$store.state.displayEditMailChannel = true;
                    break;
                case "EDI":
                    this.$store.state.displayEditEDI = true;
                    break;
                case "Print":
                    this.$store.state.displayEditPrint = true;
                    break;
                case "Mail":
                    this.$store.state.displayEditMailTemplate = true;
                    break;
                case "QR":
                    this.$store.state.displayEditQRTemplate = true;
                    break;
                case "Overlay":
                    this.$store.state.displayEditOverlayTemplate = true;
                    break;
                case "Terms and Conditions":
                    this.$store.state.displayEditTacTemplate = true;
                    break;
                case "Inserts":
                  this.$store.state.displayEditInsertsTemplate = true;
                  break;
                case "Pre-Ingestion":
                    this.$store.state.displayEditPreIngestion = true;
                    break;
                case "Resend":
                    this.$store.state.displayEditResendTemplate = true;
                    break;
                case "Channel maintenance":
                    this.$store.state.displayEditChannelMaintenance = true;
                    break;
                default:
                    break;
            }
        },
    },
    setup() {
        const route = useRoute();
        const store = useStore();
        store.dispatch("fetchSupplierGroups");
        const supplierGroup = ref(
              store.state.supplierGroups.find(
                    (sg) => sg.supplierGroupId == route.params.id
              )
        );
        store.state.activeSG = supplierGroup.value;
        const getUniqueFiles = () => {
            store.state.xsdFiles = [];
            store.state.ediFiles = {};
            store.state.receiverMail = {};
            store.state.tacFiles = [];
            store.state.printJobs = [];
            let xsdFiles = supplierGroup.value.xmlConfig
                  ? supplierGroup.value.xmlConfig.xsdFiles
                  : [];
            for (let item in xsdFiles) {
                store.state.xsdFiles[item] = xsdFiles[item];
            }
            store.state.ediFiles = supplierGroup.value.channelsConfig.edi ? JSON.parse(JSON.stringify(supplierGroup.value.channelsConfig.edi)) : null
            let tacFiles = supplierGroup.value.templates
                  ? supplierGroup.value.templates.terms
                        ? supplierGroup.value.templates.terms
                        : []
                  : [];
            store.state.tacFiles = [...tacFiles];
            store.state.receiverMail = supplierGroup.value.reportConfig ? supplierGroup.value.reportConfig.receiverEmails ? JSON.parse(JSON.stringify(supplierGroup.value.reportConfig.receiverEmails)) : {} : {}
            let printJobs = supplierGroup.value.channelsConfig.print
                  ? supplierGroup.value.channelsConfig.print.printJobs ?
                        supplierGroup.value.channelsConfig.print.printJobs : []
                  : [];
            store.state.printJobs = [...printJobs];
        };
        const selectedTabName = ref(store.state.activeSGTab);
        const languages = langCodes;
        const countryCodes = countryCode;
        const tabs = [
            "General",
            "Branding",
            "Ingestion",
            "Settings",
            "Channels",
            "Channel maintenance",
            "Payments",
            "Peppol Documents",
            "Contact Info and Help",
            "Templates",
            "Legal Entities",
            "Actions",

        ];
        const tabsChannel = ["Mail Channel", "EDI", "Print"];
        const tabsIngestion = ["System Ingestion", "Extraction"];
        const tabsSettings = ["System", "Reporting"];
        const tabsTemplate = ["Mail", "QR", "Overlay", "Terms and Conditions", "Pre-Ingestion", "Resend", "Inserts"];
        const state = reactive({
            selectedSubTabTemplates: tabsTemplate[0],
            selectedSubTabIngestion: tabsIngestion[0],
            selectedSubTabSettings: tabsSettings[0],
            selectedSubTabChannels: tabsChannel[0],
            selectedTab: store.state.activeSGTab,
        });
        const qsIngesionClicked = ref(false)
        const ingestion = ref("");
        const ingestionData = [
            {id: 0, name: "Every 15 Minutes"},
            {id: 1, name: "Every 30 Minutes"},
            {id: 2, name: "Every Hour"},
            {id: 3, name: "Daily"},
        ];
        const getIngestionValue = () => {
            switch (supplierGroup.value.storageConfig.ingestionInterval) {
                case "15":
                    ingestion.value = ingestionData[0].name;
                    break;
                case "30":
                    ingestion.value = ingestionData[1].name;
                    break;
                case "00":
                    ingestion.value = ingestionData[2].name;
                    break;
                case "DAY":
                    ingestion.value = ingestionData[3].name;
                    break;

                default:
                    break;
            }
        };
        const typeData = [
            {id: 0, name: "BILLING"},
            {id: 1, name: "GENERAL"},
            {id: 2, name: "SALARY"},
        ];
        const qrOverlayData = store.state.overlayData;
        const overlayOverlayData = store.state.overlayMode;

        const extractionDataType = [
            {id: 4, name: "SELECT_TYPE"},
            {id: 0, name: "STRING_TYPE"},
            {id: 1, name: "NUMBER_TYPE"},
            {id: 2, name: "DATE_TYPE"},
            {id: 3, name: "CURRENCY_TYPE"},
        ]
        const fieldTypeData = [
			{id: 0, name: "PAGE_NUMBER"},
			{id: 1, name: "DOCUMENT_TYPE"},
			{id: 2, name: "TOTAL_AMOUNT"},
			{id: 3, name: "VAT_RATE"},
			{id: 4, name: "NET_AMOUNT"},
			{id: 5, name: "TAX_AMOUNT"},
			{id: 6, name: "CLIENT_ID"},
			{id: 7, name: "DOCUMENT_DATE"},
			{id: 8, name: "DUE_DATE"},
			{id: 9, name: "DOCUMENT_NUMBER"},
			{id: 10, name: "RECEIVER_ADDRESS_UNSTRUCTURED"},
			{id: 11, name: "RECEIVER_VAT"},
			{id: 12, name: "STRUCTURED_MESSAGE"},
			{id: 13, name: "CURRENCY"},
			{id: 14, name: "PO_NUMBER"},
            {id: 15, name: "RECEIVER_EMAIL"},
            {id: 16, name: "SENDER_IBAN"},
            {id: 17, name: "SENDER_VAT"},
        ]
        const printProviderData = [
            {id: 0, name: "SPEOS"},
			{id: 1, name: "EASYPOST"}
        ];
        const printEnvelopeTypeData = [
            {id: 3, name: "No envelop type"},
            {id: 0, name: "SINGLE_WINDOW"},
            {id: 1, name: "DOUBLE_WINDOW"},
        ];
        const printColorData = [{id: 0, name: "FC"}];
        onMounted(() => {
            getIngestionValue();
        });
        return {
            selectedTabName,
            supplierGroup,
            tabs,
            languages,
            countryCodes,
            typeData,
            tabsChannel,
            tabsTemplate,
            ingestion,
            ingestionData,
            qrOverlayData,
            overlayOverlayData,
            getIngestionValue,
            printProviderData,
            printEnvelopeTypeData,
            fieldTypeData,
            extractionDataType,
            printColorData,
            qsIngesionClicked,
            tabsIngestion,
            tabsSettings,
            getUniqueFiles,
            ...toRefs(state),
        };
    },
};
</script>
<style scoped>
.hide {
    opacity: 0;
}

.tab {
    cursor: pointer;
}

.active {
    background-color: #00adea;
    color: white;
    border: none;
}

.box {
    box-shadow: none;
}

.marginTemplates {
    margin-left: 32rem;
}
</style>
