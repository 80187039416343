<template>
	<div
		:class="{ 'brightness-95': this.$store.state.displayEditEDI }"
		class="box shadow-none col-span-6"
	>
		<div class="text-left lg:text-left  p-5 border-t border-gray-200 dark:border-dark-5 ">
			<div class="flex-nowrap">
				<div class="mr-12">
					<!--EDI Channel-->
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">EDI Channel:</label>
						<ToggleSlide
							class="w-min"
							:disabled="true"
							:key="ediComputed"
							:bool="ediComputed"
						/>
					</div>
					<!--NEW-->
					<div v-if="ediComputed">
						<div v-if="formData.ediChannelProviders">
							<Accordion
								class="mb-5 border p-1 box border border-gray-300"
								:key="index + item"
								v-for="(item, index) in Object.keys(formData.ediChannelProviders)"
							>
								<template v-slot:title>
									<span class="font-semibold text-lg">{{ item }}</span>
								</template>
								<template v-slot:content>
									<div class="edi-item">
										<div>
											<Field
												:disabled="true"
												:id="'viewEdiProvider' + item"
												title="Sending provider:"
												type="text"
												:modelValue="formData.ediChannelProviders[item].provider"
											/>
										</div>
										<div>
											<div class="mb-3 flex">
												<label class="font-bold form-label mb-0 mr-2">Resend allowed by supplier:</label>
												<ToggleSlide
													:disabled="true"
													class="w-min"
													:id="'viewEdiResendAllowed' + item"
													:key="formData.ediChannelProviders[item].resendAllowedBySupplier"
													:bool="formData.ediChannelProviders[item].resendAllowedBySupplier"
												/>
											</div>
											<div class="mb-3 flex">
												<label
													class="font-bold form-label mb-0 mr-2">
													Fallback to BW:
												</label>
												<ToggleSlide
													:disabled="true"
													class="w-min"
													:key="formData.ediChannelProviders[item].fallbackToBW"
													:bool="formData.ediChannelProviders[item].fallbackToBW"
												/>
											</div>

											<div class="mb-3 flex">
												<label
													class="font-bold form-label mb-0 mr-2">
													Embed to BW:
												</label>
												<ToggleSlide
													:disabled="true"
													class="w-min"
													:key="formData.ediChannelProviders[item].embedToBW"
													:bool="formData.ediChannelProviders[item].embedToBW"
												/>
											</div>
											<div class="mb-3 flex">
												<label
													class="font-bold form-label mb-0 mr-2">
													Sender Registration number check:
												</label>
												<ToggleSlide
													:disabled="true"
													class="w-min"
													:key="formData.ediChannelProviders[item].receiverRegNrCheck"
													:bool="formData.ediChannelProviders[item].receiverRegNrCheck"
												/>
											</div>
										</div>
									</div>
								</template>
							</Accordion>
						</div>
						<p v-else>no new channel providers</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ToggleSlide from "../ToggleSlide.vue";
import Accordion from "../Accordion.vue";
import Field from "../Field.vue";

export default {
	name: "ViewEDI",
	components: {
		ToggleSlide,
		Accordion,
		Field,
	},
	watch: {
		"$store.state.activeSG": {
			handler() {
				this.initData()
			},
			deep: true,
		},
	},
	data() {
		return {
			formData: {
				ediChannelProviders: this.$store.state.activeSG.channelsConfig.edi
					? this.$store.state.activeSG.channelsConfig.edi.ediChannelProviders
						? this.$store.state.activeSG.channelsConfig.edi.ediChannelProviders
						: null
					: null,
			},
		}
	},
	methods: {
		initData() {
			this.formData.ediChannelProviders = this.$store.state.activeSG.channelsConfig.edi
				? this.$store.state.activeSG.channelsConfig.edi.ediChannelProviders
				: null
		},
	},
	computed: {
		ediComputed() {
			return !!this.$store.state.activeSG.channelsConfig.edi;
		},
	},
};
</script>

<style scoped>
.edi-item {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto;
	grid-column-gap: 20px;
}
</style>