<template>
    <div  class="box col-span-6">
        <div
              class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5 min-height-800">
                <edit-billing-info></edit-billing-info>

            <div v-if="this.$store.state.activeLE.legalEntityBillingInfo">
                <div class="form-container">
                    <div class="form-container-item">

                        <div>
                            <p class="font-bold form-label mb-0 mr-2">Address type:</p>
                            <Dropdown
                                  :disabled="true"
                                  :selected="this.$store.state.activeLE.legalEntityBillingInfo.addresses[0].type"
                                  :data="addressTypeData"/>
                        </div>

                        <Field
                              :disabled="true"
                              title="To attention: "
                              type="text"
                              v-model="this.$store.state.activeLE.legalEntityBillingInfo.addresses[0].toAttention"
                        />
                        <Field

                              title="VAT Number*: "
                              type="text"
                              v-model="this.$store.state.activeLE.legalEntityBillingInfo.vatNumber"
                        />

                    </div>
                    <div class="form-container-item">

                        <div>
                            <p class="font-bold form-label mb-0 mr-2">Country:</p>

                            <Dropdown
                                  :disabled="true"

                                  :selected="this.$store.state.activeLE.legalEntityBillingInfo.addresses[0].countryCode"
                                  :data="langCodes"/>
                        </div>
                        <Field
                              :disabled="true"
                              title="City: "
                              type="text"
                              v-model="this.$store.state.activeLE.legalEntityBillingInfo.addresses[0].city"
                        />


                        <Field
                              :disabled="true"
                              title="Zip code: "
                              type="text"
                              v-model="this.$store.state.activeLE.legalEntityBillingInfo.addresses[0].zip"
                        />
                        <Field
                              :disabled="true"
                              title="Street: "
                              type="text"
                              v-model="this.$store.state.activeLE.legalEntityBillingInfo.addresses[0].street"
                        />
                        <Field
                              :disabled="true"
                              title="Number: "
                              type="text"
                              v-model="this.$store.state.activeLE.legalEntityBillingInfo.addresses[0].number"
                        />
                    </div>


                </div>

            </div>
        <div v-if="!this.$store.state.activeLE.legalEntityBillingInfo">
            No billing info yet
         </div>
        </div>
    </div>


</template>

<script>
import EditBillingInfo from "../EditLegalEntity/EditBillingInfo";
import langCodes from "../../langCodes";
import Dropdown from "../Dropdown.vue";
import Field from "../Field.vue";
export default {
    name: "ViewBillingInfo",
    components: {
        EditBillingInfo,
        Dropdown,
        Field
    },
    data(){
        return{

            addressTypeData: this.$store.state.addressesTypeData,
            langCodes: [{name: "SELECT CODE"}, ...langCodes],
        }
    }
}
</script>

<style scoped>
.min-height-800{
    min-height: 800px;
}
.form-container {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 50% 50%;
}

.form-container-item {
    padding: 0 10px;
}
</style>
