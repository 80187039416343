<template>
	<pop-up-window
		:show="show"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit Ingestion</template>
		<template #content>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-row">
					<label class="font-bold text-lg form-label mb-0 mr-2">Negative creditnote: </label>
					<ToggleSlide
						class="w-min"
						:bool="creditNoteNegative"
						@update-value="creditNoteNegative = !creditNoteNegative"
					/>
				</div>
			</div>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-row">
					<label class="font-bold text-lg form-label mb-0 mr-2">Add zero tax segments: </label>
					<ToggleSlide
						class="w-min"
						:bool="addZeroTaxSegments"
						@update-value="addZeroTaxSegments = !addZeroTaxSegments"
					/>
				</div>
			</div>
			<div class="mb-3 flex">
				<p class="font-bold text-lg form-label mb-0 mr-2">API configuration:</p>
				<ToggleSlide
					class="w-min"
					@update-value="apiConfigBool = !apiConfigBool"
					:key="apiConfigBool"
					:bool="apiConfigBool"
				/>
			</div>
			<div class="ml-6 w-1/2" v-if="apiConfigBool">
				<label class="font-bold" for="editApiKey">API key: *</label>
				<div
					:class="{ 'border border-primary-3': apiKeyInvalid }"
					class="input-group mb-3"
				>
					<input
						id="editApiKey"
						type="text"
						@input="validate"
						class="form-control"
						v-model="apiKey"
					/>
					<div
						@click="generateKey"
						class="font-bold cursor-pointer input-group-text"
					>
						<font-awesome-icon icon="gears"/>
					</div>
				</div>
				<span v-if="apiKeyInvalid" class="text-primary-3">You selected API configuration, please select a correct API key</span>
			</div>

			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="cancel">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>
<script>

import ToggleSlide from "@/components/ToggleSlide.vue"
import PopUpWindow from "../PopUp.vue"
import {v4 as uuidv4} from "uuid";
export default {
	name: "ViewIngestion",
	components: {ToggleSlide, PopUpWindow},
	props: ["show"],
	emits: ["close"],
	data() {
		return {
			creditNoteNegative: this.$store.state.activeLE.ingestionConfig?.creditNoteNegative ? this.$store.state.activeLE.ingestionConfig.creditNoteNegative : false,
			addZeroTaxSegments: this.$store.state.activeLE.ingestionConfig?.addZeroTaxSegments ? this.$store.state.activeLE.ingestionConfig.addZeroTaxSegments : false,
			apiConfigBool:  !!this.$store.state.activeLE.apiConfig,
			apiKey: this.$store.state.activeLE.apiConfig?.apiKey ? this.$store.state.activeLE.apiConfig.apiKey : null,
			apiKeyInvalid: false
		}
	},
	methods: {
		generateKey() {
			this.apiKey = uuidv4()
		},
		validate() {
			this.apiKeyInvalid = false
			let hasErrors = false
			if (this.apiConfigBool === true && this.apiKey === null || this.apiKey === "") {
				hasErrors = true
				this.apiKeyInvalid = true
			}

			return hasErrors
		},
		update(){
			if (this.apiConfigBool) {
				let hasErrors = this.validate()
				if (hasErrors) return
				else {
					const payload = {
						supplierGroupId: this.$store.state.activeSG.supplierGroupId,
						legalEntityId:  this.$store.state.activeLE.entityNumber,
						apiConfig: {
							apiKey: this.apiKey,
						}
					}
					this.$store.dispatch('EditLegalEntityApiConfig', payload)
				}
			} else {
				this.apiKey = null
				const payload = {
					supplierGroupId: this.$store.state.activeSG.supplierGroupId,
					legalEntityId:  this.$store.state.activeLE.entityNumber,
					apiConfig: {}
				}
				this.$store.dispatch('EditLegalEntityApiConfig', payload)
			}

			const payload = {
				supplierGroupId: this.$store.state.activeSG.supplierGroupId,
				legalEntityId:  this.$store.state.activeLE.entityNumber,
				ingestionSettings: {
					creditNoteNegative: this.creditNoteNegative,
					addZeroTaxSegments: this.addZeroTaxSegments
				}
			}

			this.$store.dispatch('EditLegalEntityIngestion', payload)
			this.$emit("close")
		},
		cancel() {
			this.creditNoteNegative = this.$store.state.activeLE.ingestionConfig?.creditNoteNegative ? this.$store.state.activeLE.ingestionConfig.creditNoteNegative : false
			this.apiConfigBool = !!this.$store.state.activeLE.apiConfig
			this.apiKey = this.$store.state.activeLE.apiConfig?.apiKey ? this.$store.state.activeLE.apiConfig.apiKey : null
			this.$emit("close")
		}
	}
}
</script>
