<template>
    <pop-up-window
          :show="this.$store.state.displayEditBillingInfo"
          :showClose="false"
          @close="close"
          class="doc-statuses-popup"
          fixed
    >
        <template #header>Edit Billing info</template>
        <template #content>
            <div v-if="formDataIsReady">
                <p class="error" v-if="errors">Please, fill in all fields</p>
                <div class="form-container">
                    <div class="form-container-item">

                        <div @click="setErrors('errors', false)">
                            <p class="font-bold form-label mb-0 mr-2">Address type*:</p>
                            <div @click="setErrors('errors', false)">
                                <Dropdown

                                      @update-value="updateSelectedAddressType"
                                      :selected="formData.addresses[0].type"
                                      :data="addressTypeData"/>
                            </div>

                        </div>

                        <Field
                              @click="setErrors('errors', false)"
                              title="To attention*: "
                              type="text"
                              v-model="formData.addresses[0].toAttention"
                        />
                        <Field
                              @click="setErrors('errors', false)"
                              title="VAT Number*: "
                              type="text"
                              v-model="formData.vatNumber"
                        />

                    </div>
                    <div class="form-container-item">

                        <div>
                            <p class="font-bold form-label mb-0 mr-2">Country*:</p>
                            <div @click="setErrors('errors', false)">
                                <Dropdown

                                      @update-value="updateSelectedCountry"
                                      :selected="formData.addresses[0].countryCode"
                                      :data="langCodes"/>
                            </div>

                        </div>
                        <Field
                              @click="setErrors('errors', false)"
                              title="City*: "
                              type="text"
                              v-model="formData.addresses[0].city"
                        />


                        <Field
                              @click="setErrors('errors', false)"
                              title="Zip code*: "
                              type="text"
                              v-model="formData.addresses[0].zip"
                        />
                        <Field
                              @click="setErrors('errors', false)"
                              title="Street*: "
                              type="text"
                              v-model="formData.addresses[0].street"
                        />
                        <Field
                              @click="setErrors('errors', false)"
                              title="Number*: "
                              type="text"
                              v-model="formData.addresses[0].number"
                        />
                    </div>


                </div>

                <div class="grid grid-rows-1 justify-items-end">
                    <div>
                        <button class="btn shadow-md mr-2" @click="update">
                            Update
                        </button>
                        <button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
                    </div>
                </div>

            </div>
        </template>
    </pop-up-window>

</template>

<script>
import PopUpWindow from "../PopUp.vue";
import langCodes from "../../langCodes";
import Dropdown from "../Dropdown.vue";
import Field from "../Field.vue";

export default {
    name: "EditBillingInfo",
    components: {
        PopUpWindow,
        Dropdown,
        Field
    },
    data() {
        return {
            errors: false,
            formDataIsReady: false,
            formData: {},
            addressTypeData: this.$store.state.addressesTypeData,
            langCodes: [{name: "SELECT CODE"}, ...langCodes],
            activeLegalEntity: this.$store.state.activeLE

        }
    },
    mounted() {

        this.setFromData();
    },
    watch: {
        activeLE(newVal) {
            this.activeLegalEntity = newVal;
            this.setFromData();
        }
    },
    computed: {
        activeLE() {
            return this.$store.state.activeLE
        }

    },


    methods: {
        updateSelectedCountry(lang) {
            this.formData.addresses[0].countryCode = this.langCodes[lang].name;
        },
        updateSelectedAddressType(type) {
            this.formData.addresses[0].type = this.addressTypeData[type].name;
        },


        setFromData() {
            if (this.$store.state.activeLE.legalEntityBillingInfo) {
                this.formData = JSON.parse(JSON.stringify(this.$store.state.activeLE.legalEntityBillingInfo))
            } else {
                this.formData = {
                    vatNumber: '',
                    addresses: [
                        {
                            city: '',
                            countryCode: "SELECT CODE",
                            number: '',
                            street: '',
                            toAttention: "",
                            type: this.addressTypeData[0].name,
                            zip: ""

                        }
                    ]
                }
            }
            this.formDataIsReady = true;

        },
        update() {
            // let formData = {
            //
            //     vatNumber: "VAT Number",
            //     addresses: [
            //         {
            //             toAttention: "To attention",
            //             street: "Street",
            //             number: "Number_34",
            //             zip: "ZIP",
            //             city: "City",
            //             countryCode: "BE",
            //             type: "DELIVERY_ADDRESS"
            //         }
            //     ]
            // }



            if (this.formData.vatNumber === ''
                  || this.formData.addresses[0].toAttention === ''
                  || this.formData.addresses[0].street === ''
                  || this.formData.addresses[0].number === ''
                  || this.formData.addresses[0].zip === ''
                  || this.formData.addresses[0].city === ''
                  || this.formData.addresses[0].countryCode === 'SELECT CODE'
                  || this.formData.addresses[0].type === 'SELECT TYPE'
            ) {
                this.setErrors('errors', true);
                return
            }


            // let zip = String(this.formData.addresses[0].zip)
            // let number = String(this.formData.addresses[0].number)
            // this.formData.addresses[0].number = number
            // this.formData.addresses[0].zip = zip



            this.$store.dispatch('editBillingInfo', {
                      supplierGroupId: this.$store.state.activeSG.supplierGroupId,
                      legalEntityNumber: this.$store.state.activeLE.entityNumber,
                      formData: this.formData
                  }
            )
            this.close();
        },
        setErrors(field, value) {
            this[field] = value;
        },
        close() {
            this.setErrors('errors', false);
            this.setFromData();
            this.$store.state.displayEditBillingInfo = false;
        },
    }
}
</script>

<style scoped>
.form-container {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 50% 50%;
}

.form-container-item {
    padding: 0 10px;
}

</style>
