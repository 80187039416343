<template>
	<pop-up-window
		:show="this.$store.state.displayEditTacTemplate"
		:showClose="false"
		:blur="showConfirm"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit Terms &amp; Conditions Template</template>
		<template #content>
			<div :class="{ 'pointer-events-none': showConfirm }" class="flex flex-row flex-nowrap">
				<div v-if="formData.terms" class="flex flex-col w-full">
					<Accordion
						:key="item + index"
						v-for="(item, index) in formData.terms"
						class="mb-5 border border-gray-300 p-1 box"
					>
						<template v-slot:title>
							<span class="font-semibold text-xl">{{ item.name }}</span>
						</template>
						<template v-slot:content>
							<div class="p-3 grid grid-cols-2">
								<div class="mr-2">
									<Field
										id="name"
										type="text"
										title="Name:"
										v-model="item.name"
										@blur="changeName(item, item.name)"
									/>
									<span v-if="nameInvalid[index]" class="text-primary-3">Name is required</span>
									<div>
										<div class="mb-2">
											<label for="resendDocType" class="font-bold">DocType:*</label>
										</div>
										<DropdownSelectAll
											v-if="renderComponent"
											id="resendDocType"
											@update-value="updateDocTypes($event, item.name)"
											:data="docTypes"
											:selected=item.docTypes
										/>
										<span v-if="docTypeInvalid[index]" class="text-primary-3">	Please, select at least one docType</span>
									</div>

									<div class="mb-3 flex">
										<label class="font-bold form-label mr-3 mb-0">Display:</label>
										<ToggleSlide
											@update-value="updateBoolDisplay($event, item)"
											class="w-min"
											:bool="boolComputed(item.display)"
										/>
									</div>
									<div class="mb-3 flex">
										<label class="font-bold form-label mr-3 mb-0">Merge to document:</label>
										<ToggleSlide
											@update-value="updateBoolMerge($event, item)"
											class="w-min"
											:bool="boolComputed(item.mergeToBillingDocument)"
										/>
									</div>
								</div>
								<div class="ml-2">
									<div class="flex mb-1 mt-2">
										<p class="font-bold form-label my-0">T&amp;C file(s):*</p>
										<button
											@click="displayAddTacFile(item.name)"
											class="btn bg-theme-1 py-0 text-white shadow-md ml-2">
											Upload
										</button>
									</div>

									<div
										:key="item + item.location[itm] + index"
										v-for="(itm, index) in Object.keys(item.location)"
										class="flex flex-row mb-1">
										<div class="flex flex-col w-2/3">
											<FileUpload
												:isLegalEntity="isLegalEntity"
												@update-value="changePdfLocation($event, item, itm)"
												@delete-value="deleteFile($event, item, itm)"
												accept=".pdf"
												:prefix="true"
												:prefixData="itm"
												:delSuffix="true"
												:data="item.location[itm].fileName"
												:id="  'templatesEdit' +  item.name + item.location[itm].fileName"
											/>
										</div>
										<div class="flex flex-col w-1/3">
											<div class="input-group mb-1 w-full">
												<input
													type="text"
													class="form-control"
													v-model="item.location[itm].description"
												/>
											</div>
										</div>
									</div>
									<span v-if="tacError[item.name]" class="text-primary-3">
										At least 1 file is required
									</span>
								</div>
							</div>
							<div class="flex flex-row justify-end">
								<button
									@click="showConfirmDialog(item)"
									class="btn bg-theme-1 text-white shadow-md mr-2"
								>
									Delete template
								</button>
							</div>
						</template>
					</Accordion>
				</div>
			</div>
			<div :class="{ 'pointer-events-none': showConfirm }" class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
	<ConfirmDialog @close="deleteTemplate" :show="showConfirm"/>
	<add-file-to-template
		title-dialog="Add new t&c file"
		:show-dialog="displayAddFile"
		:show-description="true"
		:isLegalEntity="this.isLegalEntity"
		@add-file="addFile"
		@cancel-add-file="this.displayAddFile = false"
		:langCode="langCodes"
	></add-file-to-template>
</template>

<script>
import PopUpWindow from "../../PopUp.vue"
import Field from "../../Field.vue"
import FileUpload from "../../FileUpload.vue"
import Accordion from "../../Accordion.vue"
import ToggleSlide from "../../ToggleSlide.vue"
import ConfirmDialog from "../../ConfirmDialog.vue"
import DropdownSelectAll from "../../DropdownSelectAll.vue"
import AddFileToTemplate from "@/components/templates/AddFileToTemplate.vue";
import langCodes from "@/langCodes";
import updateTemplate from "@/mixins/updateTemplates";

export default {
	name: "EditTac",
	components: {
		AddFileToTemplate,
		PopUpWindow,
		Field,
		FileUpload,
		Accordion,
		ConfirmDialog,
		ToggleSlide,
		DropdownSelectAll
	},
	props: {
		isFor: String,
	},
	emits: ['update-template'],
	data() {
		return {
			isLegalEntity: this.isFor === "legalEntity",
			currentDelItem: {},
			showConfirm: false,
			supplierGroup: this.$store.state.activeSG,
			legalEntity: this.$store.state.activeLE,
			activeItem: {},
			formData: {
				terms: [],
			},
			selectedTac: "",
			disablePayment: false,
			disablePrivacy: false,
			disableMerge: false,
			tacError: {},
			renderComponent: true,
			docTypes: this.$store.state.docTypes,
			displayAddFile: false,
			langCodes: langCodes,
			nameInvalid: [],
			docTypeInvalid: []
		}
	},
	mounted() {
		this.initData()
		this.formData.terms.forEach(term => {
			this.nameInvalid.push(false)
			this.docTypeInvalid.push(false)
		})
	},
	computed: {
		getAddedFileResponse() {
			return this.$store.state.addedEditTacFile
		},
		getTerms() {
			let value
			if (this.isFor === "legalEntity") {
				value = this.$store.state.tacFilesLE
			}
			if (this.isFor === "supplierGroup") {
				value = this.$store.state.tacFiles
			}
			return value
		},
		activeSG() {
			return this.$store.state.activeSG
		},
		activeLE() {
			return this.$store.state.activeLE
		}
	},
	watch: {
		activeSG() {
			this.close()
		},
		activeLE() {
			this.close()
		},
		getAddedFileResponse(data) {
			this.addFile(data)
		},
		getTerms(data) {
			this.formData.terms = data
		},
		"$store.state.tacFiles": {
			handler() {
				this.initData()
				this.setBoolDisable()
			},
			deep: true,
		},
		"$store.state.tacFilesLE": {
			handler() {
				this.initData()
				this.setBoolDisable()
			},
			deep: true,
		},
	},
	methods: {
		updateDocTypes(docTypes, templateName) {
			let foundArr = []
			for (let index of docTypes) {
				let found = this.docTypes[index].name
				foundArr.push(found)
				this.docTypeInvalid[index] = false
			}

			this.formData.terms.forEach(template => {
				if (template.name === templateName) template.docTypes = foundArr
			})
		},
		boolComputed(bool) {
			if (bool) return bool
			else return false
		},
		initData() {
			this.isLegalEntity = this.isFor === "legalEntity"
			if (this.isFor === "supplierGroup") {
				this.activeItem = this.$store.state.activeSG
				this.formData.terms = JSON.parse(JSON.stringify(this.$store.state.tacFiles))
			}
			if (this.isFor === "legalEntity") {
				this.activeItem = this.$store.state.activeLE
				if (this.$store.state.activeLE?.templates?.terms) {
					this.formData.terms = JSON.parse(JSON.stringify(this.$store.state.activeLE.templates.terms))
				}
			}
			this.tacError = {}
			if (this.formData.terms) this.setBoolDisable()
		},
		changeName(item, value) {
			Object.values(this.formData.terms).find((el, index) => {
				this.nameInvalid[index] = false
				return el === item
			}).name = value
		},
		setBoolDisable() {
			this.disableMerge = this.formData.terms.find((item) => item.mergeToBillingDocument === true)
		},
		updateBoolDisplay(data, item) {
			Object.values(this.formData.terms).find((el) => el === item).display = data
		},
		updateBoolMerge(data, item) {
			Object.values(this.formData.terms).find((el) => el === item).mergeToBillingDocument = data
			this.setBoolDisable()
		},
		displayAddTacFile(data) {
			this.selectedTac = data
			this.$store.state.parentTacFile = "Edit"
			this.displayAddFile = true
		},
		changePdfLocation(event, item, itm) {
			Object.values(this.formData.terms).find((el) => el === item).location[itm].fileName = event.files[0].name
			this.validate()
		},
		addFile(data) {
			Object.values(this.formData.terms).find((el) => el.name === this.selectedTac).location = {
				...this.formData.terms.find((el) => el.name === this.selectedTac).location,
				...data,
			}
			this.displayAddFile = false
		},
		deleteFile(event, data, itm) {
			let index = Object.values(this.formData.terms).findIndex((el) => el.name === data.name)
			delete this.formData.terms[index].location[itm]
		},
		addTemplate(data) {
			if (!this.formData.terms) this.formData.terms = []
			this.formData.terms.push(data)
			this.setBoolDisable()
			this.update()
		},
		showConfirmDialog(item) {
			this.currentDelItem = item
			this.showConfirm = true
		},
		deleteTemplate(data) {
			if (data === "Yes") {
				let index = this.formData.terms.indexOf(this.currentDelItem)
				if (index > -1) this.formData.terms.splice(index, 1)
			}
			this.showConfirm = false
		},
		close() {
			if (this.isFor === "supplierGroup") {
				let tacFiles = this.$store.state.activeSG.templates?.terms
					? this.$store.state.activeSG.templates.terms : []
				this.$store.state.tacFiles = [...tacFiles]
				if (!tacFiles && this.formData.terms) this.$store.state.tacFiles = []
			}
			if (this.isFor === "legalEntity") {
				let tacFilesLE = this.$store.state.activeLE.templates?.terms
						? this.$store.state.activeLE.templates.terms : []
				this.$store.state.tacFilesLE = [...tacFilesLE]
				if (!tacFilesLE && this.formData.terms) this.$store.state.tacFilesLE = []
			}
			this.$store.state.displayEditTacTemplate = false
		},
		async update() {
			let hasErrors = this.validate()
			if (!hasErrors) {
				if (this.formData.terms === undefined || this.formData.terms.length === 0) {
					this.formData = {terms: null};
				}
				//add dummy version field for each term
				for (let item in this.formData?.terms) {
					this.formData.terms[item].version = "v1";
				}

				await updateTemplate({terms: this.formData.terms}, this.isFor, 'terms-template', 'Terms and condition')
				this.close()
			}
		},
		validate() {
			this.tacError = {}
			let hasErrors = false
			let hasFound = Object.values(this.formData.terms).find(el => Object.values(el.location).length === 0)
			if (hasFound) {
				hasErrors = true
				this.tacError[hasFound.name] = true
			}
			this.formData.terms.forEach((term,index) => {
				if (term.name === undefined || term.name.trim() === ''){
					this.nameInvalid[index] = true
					hasErrors = true
				}
				if (term.docTypes === undefined || term.docTypes.length === 0){
					this.docTypeInvalid[index] = true
					hasErrors = true
				}
			})

			return hasErrors
		}
	}
}
</script>