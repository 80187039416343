<template>
	<pop-up-window
		:show="this.$store.state.displayAddPrintJob"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Add Printjob</template>
		<template #content>
			<div class="flex flex-col flex-nowrap">
				<div class="flex flex-row">
					<div class="flex flex-col w-1/2 mr-12">
						<Field
							id="addPrintName"
							title="Name: *"
							type="text"
							:hasError="nameInvalid"
							@input="nameInvalid = false"
							v-model="formData.name"
						/>
						<span v-if="nameInvalid" class="text-primary-3">
							Name is required
						</span>
						<Field
							id="addPrintDisplayName"
							title="Display name:"
							type="text"
							v-model="formData.additional.displayName"
						/>
						<Field
							v-if="provider === 'EASYPOST'"
							type="text"
							title="Work flow ID:*"
							@input="workfowIdInvalid = false"
							:has-error="workfowIdInvalid"
							v-model="formData.workFlowId"
						/>
						<span v-if="workfowIdInvalid" class="text-primary-3">
							Work flow ID is required
						</span>
						<div >
							<div class="mb-3">
								<p class="font-bold py-2">Legal entities:</p>
								<DropdownSelectAll
									@update-value="updateLegalEntities"
									:data="supplierLegalEntities"
									v-model="formData.additional.legalEntityIds"
								/>
								<Field
									v-if="providerIsSpeos"
									id="addPrintEnvelopeTemplate"
									maxLength="50"
									title="Envelope template:"
									type="text"
									v-model="formData.additional.envelopeTemplate"
								/>
							</div>

							<div class="mb-3 flex" v-if="providerIsSpeos">
								<label class="font-bold form-label mr-3 mb-0">
									Print correlation:
								</label>
							</div>
							<div class="ml-6" v-if="providerIsSpeos">
								<Field
									id="addPrintCorrelationId"
									type="text"
									:hasError="idInvalid"
									@input="validate"
									maxLength="10"
									title="ID:*"
									v-model="formData.additional.printCorrelation.id"
								/>

								<span v-if="idInvalid" class="text-primary-3">
									ID is required
								</span>
								<Field
									id="addPrintCorrelationName"
									type="text"
									:hasError="correlationNameInvalid"
									@input="validate"
									maxLength="42"
									title="Name:*"
									v-model="formData.additional.printCorrelation.name"
								/>
								<span v-if="correlationNameInvalid" class="text-primary-3">Name is required</span>
							</div>
						</div>
					</div>
					<div class="flex flex-col w-1/2">
						<div v-if="providerIsSpeos" class="mb-3 flex">
							<label class="font-bold form-label mr-3 mb-0">Prior mail:</label>
							<ToggleSlide
								@update-value="changeBoolValue($event, 'priorMail')"
								class="w-min"
								:key="formData.priorMail"
								:bool="formData.priorMail"
							/>
						</div>
						<div v-if="providerIsSpeos" class="mb-3 flex">
							<label class="font-bold form-label mr-3 mb-0">Registered mail:</label>
							<ToggleSlide
								@update-value="changeBoolValue($event, 'registeredMail')"
								class="w-min"
								:key="formData.registeredMail"
								:bool="formData.registeredMail"
							/>
						</div>
						<div>
							<div class="mb-3 flex"  v-if="providerIsSpeos">
								<label class="font-bold form-label mr-3 mb-0">
									Print Billing Document Recto Verso:
								</label>
								<ToggleSlide
									@update-value="changeBoolValue($event, 'billingDocumentRectoVerso')"
									class="w-min"
									:key="formData.billingDocumentRectoVerso"
									:bool="formData.billingDocumentRectoVerso"
								/>
							</div>
							<div v-if="providerIsSpeos && formData.registeredMail" class="mb-3 ml-6 flex">
								<label class="font-bold form-label mr-3 mb-0">Acknowledgement of receipt:</label>
								<ToggleSlide
									@update-value="changeBoolValue($event, 'acknowledgementOfReceipt')"
									class="w-min"
									:key="formData.additional.acknowledgementOfReceipt"
									:bool="formData.additional.acknowledgementOfReceipt"
								/>
							</div>
							<div  v-if="providerIsSpeos" class="mb-3 flex">
								<label class="font-bold form-label mr-3 mb-0">
									Insert address page:
								</label>
								<ToggleSlide
									@update-value="changeBoolValue($event, 'insertAddressPage')"
									class="w-min"
									:key="formData.additional.insertAddressPage"
									:bool="formData.additional.insertAddressPage"
								/>
							</div>
							<div  v-if="providerIsSpeos || provider === 'EASYPOST'" class="mb-3 flex">
								<label class="font-bold form-label mr-3 mb-0">Insert blank page after first
									page:</label>
								<ToggleSlide
									:class="{'pointer-events-none opacity-50': formData.additional.insertTermsAndConditionsAfterFirstPage}"
									@update-value="changeBoolValue($event, 'insertBlankPageAfterFirstPage')"
									class="w-min"
									:key="formData.additional.insertBlankPageAfterFirstPage"
									:bool="formData.additional.insertBlankPageAfterFirstPage"
								/>
							</div>
							<div class="mb-3 flex" v-if="providerIsSpeos || provider === 'EASYPOST'">
								<label class="font-bold form-label mr-3 mb-0">Concatenate T&amp;C template:</label>
								<ToggleSlide
									@update-value="changeBoolValue($event,'concatenateTermsAndConditionsTemplate')"
									class="w-min"
									:key="formData.additional.concatenateTermsAndConditionsTemplate"
									:bool="formData.additional.concatenateTermsAndConditionsTemplate"
								/>
							</div>
							<div v-if="(providerIsSpeos || provider === 'EASYPOST') && formData.additional.concatenateTermsAndConditionsTemplate" class="ml-6 mb-3 flex">
								<label class="font-bold form-label mr-3 mb-0">Insert T&amp;C after first page:</label>
								<ToggleSlide
									:class="{'pointer-events-none opacity-50': formData.additional.insertBlankPageAfterFirstPage}"
									@update-value="changeBoolValue($event,'insertTermsAndConditionsAfterFirstPage')"
									class="w-min"
									:key="formData.additional.insertTermsAndConditionsAfterFirstPage"
									:bool="formData.additional.insertTermsAndConditionsAfterFirstPage"
								/>
							</div>
							<div v-if="providerIsSpeos" class="mt-12 mb-3 flex">
								<label class="font-bold form-label mr-3 mb-0">Test mode:</label>
								<ToggleSlide
									@update-value="changeBoolValue($event, 'testMode')"
									class="w-min"
									:key="formData.additional.testMode"
									:bool="formData.additional.testMode"
								/>
							</div>
						</div>
					</div>
				</div>
				<div v-if="providerIsSpeos" class="flex flex-col">
					<div class="mb-3 flex">
						<label class="font-bold form-label mr-3 mb-0">Return Address:</label>
						<ToggleSlide
							@update-value="changeBoolValue($event, 'returnAddress')"
							class="w-min"
							:key="formData.additional.returnAddress"
							:bool="!!formData.additional.returnAddress"
						/>
					</div>
					<div v-if="formData.additional.returnAddress" class="flex flex-row">
						<div class="flex flex-col w-1/2 mr-12 ml-6">
							<Field
								id="addPrintCompanyName"
								type="text"
								maxLength="50"
								title="Company name:"
								v-model="formData.additional.returnAddress.companyName"
							/>
						</div>
						<div class="flex flex-col w-1/2"></div>
					</div>
					<div v-if="formData.additional.returnAddress" class="flex flex-row">
						<div class="flex flex-col w-1/2 mr-12 ml-6">
							<Field
								id="addPrintAddressStreet"
								type="text"
								title="Street:"
								@input="validate"
								:hasError="streetHouseInvalid"
								v-model="formData.additional.returnAddress.streetName"
							/>
							<span v-if="streetHouseInvalid" class="text-primary-3">
								Street and house number combined may not exceed a length of 50 characters
							</span>
							<Field
								id="addPrintZip"
								type="text"
								maxLength="12"
								title="Zip:"
								v-model="formData.additional.returnAddress.zip"
							/>
							<div class="mb-3">
								<label for="addPrintCountryCode" class="font-bold form-label">Country code:</label>
								<Dropdown
									id="addPrintCountryCode"
									@update-value="changeAddressCountryCode"
									:data="languages"
									:key="formData.additional.returnAddress.countryCode"
									:selected="formData.additional.returnAddress.countryCode"
								/>
							</div>
						</div>
						<div class="flex flex-col w-1/2">
							<Field
								id="addPrintHouseNumber"
								type="text"
								@input="validate"
								title="House number:"
								v-model="formData.additional.returnAddress.houseNumber"
							/>
							<Field
								id="addPrintCity"
								type="text"
								maxLength="30"
								title="City:"
								v-model="formData.additional.returnAddress.city"
							/>
							<Field
								id="addPrintCountryName"
								type="text"
								maxLength="42"
								title="Country name:"
								v-model="formData.additional.returnAddress.countryName"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="add">Add</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../PopUp.vue"
import ToggleSlide from "../ToggleSlide.vue"
import Field from "../Field.vue"
import Dropdown from "../Dropdown.vue"
import DropdownSelectAll from "../DropdownSelectAll.vue"

export default {
	name: "AddPrintJob",
	components: {
		PopUpWindow,
		ToggleSlide,
		Field,
		Dropdown,
		DropdownSelectAll
	},
	props: {
		provider: String,
		printProviderData: Array,
		printEnvelopeTypeData: Array,
		printColorData: Array,
		languages: Array,
	},
	data() {
		return {
			streetHouseInvalid: false,
			nameInvalid: false,
			idInvalid: false,
			workfowIdInvalid: false,
			correlationNameInvalid: false,
			correlationIdInvalid: false,
			formData: {
				name: "",
				workFlowId: '',
				color: "",
				foldingName: "",
				billingDocumentPaperType: "",
				attachmentsPaperType: "",
				envelopeType: "SINGLE_WINDOW",
				priorMail: false,
				registeredMail: false,
				billingDocumentRectoVerso: true,
				attachmentsRectoVerso: false,
				additional: {
					displayName: '',
					envelopeTemplate: "",
					printCorrelation: {id: "", name: ""},
					returnAddress: null,
					acknowledgementOfReceipt: false,
					insertAddressPage: false,
					insertBlankPageAfterFirstPage: false,
					concatenateTermsAndConditionsTemplate: false,
					insertTermsAndConditionsAfterFirstPage: false,
					testMode: false,
					legalEntityIds: []
				}
			}
		}
	},
	emits: ["add-printjob"],
	computed: {
		supplierLegalEntities() {
			let leList = []
			for (const le in this.$store.state.activeSG.supplierLegalEntities) {
				let tempItem = {
					name: this.$store.state.activeSG.supplierLegalEntities[le].name,
					value: le
				}
				leList.push(tempItem)
			}
			return leList
		},
		providerIsSpeos(){
			return this.provider === 'SPEOS'
		},
	},
	methods: {
		updateLegalEntities(data) {
			let leArray = []
			data.forEach((selectedLe) => {
				leArray.push(this.supplierLegalEntities[selectedLe].value)
			})
			this.formData.additional.legalEntityIds = leArray
		},
		/*PRINT JOB */
		changePrintColor(data) {
			if (data === "\u00a0" || data[0] === -1) this.formData.color = ""
			else this.formData.color = this.printColorData[data].name
		},
		changeEnvelopeType(data) {
			this.formData.envelopeType = this.printEnvelopeTypeData[data].name
		},
		changeAddressCountryCode(data) {
			this.formData.additional.returnAddress.countryCode = this.languages[data].name
		},
		/*PRINT JOB BOOLS */
		changeBoolValue(data, isFor) {
			switch (isFor) {
				case "printCorrelation":
					if (data) {
						this.formData.additional.printCorrelation = {
							id: "",
							name: "",
						}
					} else {
						this.formData.additional.printCorrelation = {
							id: "",
							name: "",
						}
					}
					break
				case "returnAddress":
					if (data) {
						this.formData.additional.returnAddress = {
							companyName: "",
							streetName: "",
							zip: "",
							countryCode: "AD",
							houseNumber: "",
							city: "",
							countryName: "",
						}
					} else this.formData.additional.returnAddress = null
					break
				case "priorMail":
					this.formData.priorMail = data
					break
				case "registeredMail":
					this.formData.registeredMail = data
					if (this.provider === "SPEOS" && !data) {
						this.formData.additional.acknowledgementOfReceipt = false
					}
					break
				case "billingDocumentRectoVerso":
					this.formData.billingDocumentRectoVerso = data
					break
				case "attachmentsRectoVerso":
					this.formData.attachmentsRectoVerso = data
					break
				case "acknowledgementOfReceipt":
					this.formData.additional.acknowledgementOfReceipt = data
					break
				case "insertAddressPage":
					this.formData.additional.insertAddressPage = data
					break
				case "insertBlankPageAfterFirstPage":
					this.formData.additional.insertBlankPageAfterFirstPage = data
					break
				case "concatenateTermsAndConditionsTemplate":
					this.formData.additional.concatenateTermsAndConditionsTemplate = data
					if (this.provider === "SPEOS" && !data) {
						this.formData.additional.insertTermsAndConditionsAfterFirstPage = false
					}
					break
				case "insertTermsAndConditionsAfterFirstPage":
					this.formData.additional.insertTermsAndConditionsAfterFirstPage = data
					break
				case "testMode":
					this.formData.additional.testMode = data
					break
			}
		},
		initData() {
			this.streetHouseInvalid = false
			this.nameInvalid = false
			this.correlationIdInvalid = false
			this.correlationNameInvalid = false
			this.idInvalid = false
			this.workfowIdInvalid = false
			this.formData = JSON.parse(JSON.stringify({
				name: "",
				workFlowId: '',
				color: "",
				foldingName: "",
				billingDocumentPaperType: "",
				attachmentsPaperType: "",
				envelopeType: "SINGLE_WINDOW",
				priorMail: false,
				registeredMail: false,
				billingDocumentRectoVerso: true,
				attachmentsRectoVerso: false,
				additional: {
					envelopeTemplate: "",
					printCorrelation: {id: "", name: ""},
					returnAddress: null,
					acknowledgementOfReceipt: false,
					insertAddressPage: false,
					insertBlankPageAfterFirstPage: false,
					concatenateTermsAndConditionsTemplate: false,
					insertTermsAndConditionsAfterFirstPage: false,
					testMode: false,
				},
			}))
		},
		close() {
			this.initData()
			this.$store.state.displayAddPrintJob = false
		},
		add() {
			if (this.provider === "SPEOS") {
				delete this.formData.attachmentsPaperType
				delete this.formData.attachmentsRectoVerso
				delete this.formData.billingDocumentPaperType

				delete this.formData.color
				delete this.formData.foldingName

				if (this.formData.additional.legalEntityIds && this.formData.additional.legalEntityIds.length === 0) {
					delete this.formData.additional.legalEntityIds
				}
			}

			let hasErrors = this.validate()

			if (!hasErrors) {
				this.$emit('add-printjob', this.formData)
				this.close()
			}
		},
		validate() {
			let hasErrors = false
			this.correlationIdInvalid = false
			this.nameInvalid = false
			this.streetHouseInvalid = false
			this.idInvalid = false
			this.correlationNameInvalid = false
			if (this.formData.name.trim() === "") {
				hasErrors = true
				this.nameInvalid = true
			}
console.log(this.provider)
			if (this.provider === "SPEOS") {
				if (this.formData.additional.printCorrelation) {
					if (this.formData.additional.printCorrelation.name.trim() === "") {
						hasErrors = true
						this.correlationNameInvalid = true
					} else {
						this.correlationNameInvalid = false
					}
				}
				if (this.formData.additional.printCorrelation) {
					if (this.formData.additional.printCorrelation.id.trim() === "") {
						hasErrors = true
						this.idInvalid = true
					} else if (this.formData.additional.printCorrelation.id.length > 10) {
						this.correlationIdInvalid = true
						hasErrors = true
					}
				} else this.idInvalid = false

				if (this.formData.additional.returnAddress) {
					let lengthStreet = this.formData.additional.returnAddress.streetName ? this.formData.additional.returnAddress.streetName.length : 0
					let lengthHouseNumber = this.formData.additional.returnAddress.houseNumber ? this.formData.additional.returnAddress.houseNumber.length : 0
					let totalLengthStreetNumber = lengthStreet + lengthHouseNumber + 1
					if (totalLengthStreetNumber > 50) {
						hasErrors = true
						this.streetHouseInvalid = true
					}
				}
			}
			else if (this.provider === "EASYPOST") {
				if (this.formData.workFlowId === ''){
					this.workfowIdInvalid = true
					hasErrors = true
				}
			}
			return hasErrors
		}
	}
}
</script>