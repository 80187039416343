<template>
  <pop-up-window
    :show="this.$store.state.displayAddExternalPortalLink"
    :showClose="false"
    @close="close"
    class="doc-statuses-popup"
    fixed
  >
    <template #header>Add external portal link</template>
    <template #content>
      <div class="flex flex-row">
        <div class="flex flex-col mb-1 w-1/2">
          <div class="mb-3 mr-3">
            <label for="externalPortalLanguage" class="font-bold form-label"
              >Language: *</label
            >
            <Dropdown
              id="externalPortalLanguage"
              @update-value="updateSelectedLanguage"
              :data="languages"
              :selected="languages[0].name"
            />
          </div>
          <Field
            id="externalPortalLink"
            title="Link: *"
            :hasError="linkInvalid"
            placeholder="https://example.com"
            @input="validate"
            type="text"
            v-model="formData.link"
          />
          <span v-if="linkInvalid" class="text-primary-3">Link must be a valid URL</span>
          <Field
            id="externalPortalText"
            title="Text: *"
            :hasError="textInvalid"
            placeholder="example.com"
            @input="validate"
            type="text"
            v-model="formData.text"
          />
          <span v-if="textInvalid" class="text-primary-3">Text is required</span>
        </div>
      </div>

      <div class="grid grid-rows-1 justify-items-end">
        <div>
          <button class="btn shadow-md mr-2" @click="add">Add</button>
          <button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
        </div>
      </div>
    </template>
  </pop-up-window>
</template>

<script>
import PopUpWindow from "../PopUp.vue";
import Field from "../Field.vue";
import Dropdown from "../Dropdown.vue";
export default {
  name: "AddExternalPortalLink",
  components: {
    PopUpWindow,
    Field,
    Dropdown,
  },
  props: {
    languages: Array,
  },
  data() {
    return {
      linkInvalid: false,
      textInvalid: false,
      formData: {
        language: "AA",
        link: "",
        text: "",
      },
    };
  },
  emits: ["add-externalPortalLink"],
  methods: {
    updateSelectedLanguage(data) {
      if (data) {
        this.formData.language = this.languages[data].name;
      }
    },
    close() {
      this.formData.language = "AA"
      this.formData.text = ""
      this.formData.link = ""
      this.linkInvalid = false
      this.textInvalid = false

      this.$store.state.displayAddExternalPortalLink = false;
    },
    add() {
      let hasErrors = this.validate();
      if (!hasErrors) {
        this.$emit("add-externalPortalLink", this.formData);
        this.close();
      }
    },
    validate() {
      let hasErrors = false;
      this.linkInvalid = false
      this.textInvalid = false
      if (this.formData.text === "") {
        this.textInvalid = true;
        hasErrors = true;
      }
      if (!this.$store.state.urlRegex.test(this.formData.link)) {
        hasErrors = true;
        this.linkInvalid = true;
      }
      return hasErrors;
    },
  },
};
</script>

<style scoped>
</style>
