<template>
    <div class="intro-y box mt-5" v-if="Headers">
        <div id="small-table" class="p-5">
            <div class="preview">
                <div class="overflow-x-auto">
                    <table class="table table--sm">
                        <thead>
                        <tr>
                            <th
                                  v-for="header in Headers"
                                  :key="header"
                                  class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                            >
                                {{ header }}
                            </th>
                            <th
                                  v-if="canEdit"
                                  class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                            ></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :key="index" v-for="(item, index) in getBody">
                            <td
                                  :key="idx"
                                  v-for="(itm, idx) in getBody[index]"
                                  class="border-b dark:border-dark-5"
                            >
                                <input
                                      v-if="canEdit"
                                      type="text"
                                      class="form-control w-full"
                                      :value="itm"
                                      @input="update(index, idx, $event.target.value)"
                                />
                                <div v-else class="flex">
                                    <p
                                          :class="{ 'text-theme-1': copyData === itm }"
                                          class="cursor-pointer"
                                          @click="copy(itm)"
                                    >
                                        {{ itm }}
                                    </p>
                                    <!-- <font-awesome-icon icon="copy" /> -->
                                </div>
                            </td>
                            <td v-if="canEdit" class="border-b dark:border-dark-5">
                                <button
                                      class="btn bg-theme-1 text-white shadow-md py-1"
                                      @click="deleteBankAccount(item)"
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "BankAccountsTable",
    props: {
        Headers: Array,
        data: Object,
        canEdit: Boolean,
    },
    data() {
        return {
            copyData: "",
        };
    },
    computed: {
        getBody() {
            let bodyArr = [];
            let bodyFull = [];

            for (const key of Object.values(this.data)) {
                bodyArr = [];
                for (const itm of Object.values(key)) {
                    bodyArr.push(itm);
                }
                bodyFull.push(bodyArr);
            }
            return bodyFull;
        },
    },
    emits: ["update-value", "delete-value"],
    methods: {
        copy(data) {
            navigator.clipboard.writeText(data);
            this.copyData = data;
            setTimeout(() => {
                this.copyData = "";
            }, 750);
        },
        update(bankIdx, valueIdx, value) {
            this.$emit("update-value", bankIdx, valueIdx, value);
        },
        deleteBankAccount(item) {
            this.$emit("delete-value", item);
        },
    },
};
</script>
<style scoped>
.paddingNone {
    padding-left: none;
    padding-right: none;
}
</style>
