<template>
	<pop-up-window
		:show="this.$store.state.displayEditGeneral"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit General</template>
		<template #content>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-1/2 mr-12">
					<Field
						id="supplierGroupId"
						title="Supplier Group ID:"
						:modelValue="supplierGroup.supplierGroupId"
						:disabled="true"
						type="text"
					/>
					<Field
						id="brandingId"
						title="Branding ID: *"
						v-model="formData.brandingId"
						:hasError="brandingIdInvalid"
						@focusout="brandingIdInvalid = false"
						type="text"
					/>
					<span v-if="brandingIdInvalid" class="text-primary-3">Branding id is required</span>
					<div class="mb-3">
						<label for="editType" class="font-bold form-label">Type: *</label>
						<Dropdown
							id="editType"
							@update-value="updateSelectedType"
							:data="typeData"
							:selected="formData.sgType"
							:disabled="false"
						/>
					</div>
					<Field
						:hasError="nameInvalid"
						id="name"
						title="Name: *"
						@focusout="nameInvalid = false"
						v-model="formData.name"
						type="text"
					/>
					<span v-if="nameInvalid" class="text-primary-3">Name is Required</span>
				</div>

				<div class="flex flex-col w-1/2">
					<Field
						:hasError="portalLinkInvalid"
						id="portalLink"
						title="Portal Link: *"
						placeholder="https://example.com"
						v-model="formData.portalLink"
						@focusout="portalLinkInvalid = false"
						type="text"
					/>
					<span v-if="portalLinkInvalid" class="text-primary-3">Portal link must be a valid URL</span>
					<Field
						id="tacEmail"
						title="Terms and Conditions Email:"
						v-model="formData.termsOfServiceEmail"
						type="text"
					/>
					<div class="mb-3">
						<label for="editLanguages" class="font-bold form-label">Languages: *</label>
						<DropdownSelectAll
							id="editLanguages"
							:class="{ 'border border-primary-3': languageInvalid }"
							@update-value="updateSelectedLanguages"
							:data="languages"
							:selected="formData.languages"
							:disabled="false"
							:noSelectAll="true"
							@focusout="languageInvalid = false"
						/>

						<span v-if="languageInvalid" class="text-primary-3">Select at least 1 language</span>
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>

	<pop-up-window
		:show="this.didBrandingIdChange"
		:showClose="false"
		@close="closeWarningRealm('branding')"
		class="doc-statuses-popup"
		width20
		top10
	>
		<template #header>Changed Realm</template>
		<template #content>
			<div style="text-align: center">
				<p>You changed your Branding ID</p>
				<p>Don't forget to change the realm!</p>
				<button class="btn shadow-md mr-2 nymus-orange color-white" style="margin-top: 10px"
						@click="closeWarningRealm('branding')">Okay
				</button>
			</div>
		</template>

	</pop-up-window>
	<pop-up-window
		:show="this.didPortalLinkChange"
		:showClose="false"
		@close="closeWarningRealm('portalLink')"
		class="doc-statuses-popup"
		width20
		top10
	>
		<template #header>Changed Realm</template>
		<template #content>
			<div style="text-align: center">
				<p>You changed your Portal Link</p>
				<p>Don't forget to change the webhook in Postmark!</p>
				<button class="btn shadow-md mr-2 nymus-orange color-white" style="margin-top: 10px"
						@click="closeWarningRealm('portalLink')">Okay
				</button>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../PopUp.vue"
import Field from "../Field.vue"
import Dropdown from "../Dropdown.vue"
import DropdownSelectAll from "../DropdownSelectAll.vue"

export default {
	name: "EditGeneral",
	components: {
		PopUpWindow,
		Field,
		Dropdown,
		DropdownSelectAll,
	},
	props: {
		languages: Array,
		typeData: Array,
	},
	data() {
		return {
			supplierGroup: this.$store.state.activeSG,
			originalBrandingId: this.$store.state.activeSG.brandingId,
			originalPortalLink: this.$store.state.activeSG.channelsConfig.docImage.configPortal.portalLink,
			formData: {
				brandingId: this.$store.state.activeSG.brandingId,
				sgType: this.$store.state.activeSG.sgType,
				name: this.$store.state.activeSG.name,
				portalLink: this.$store.state.activeSG.channelsConfig.docImage.configPortal.portalLink,
				termsOfServiceEmail: this.$store.state.activeSG.channelsConfig.docImage.configPortal.termsOfServiceEmail,
				languages: this.$store.state.activeSG.languages !== undefined ? this.$store.state.activeSG.languages : [],
			},
			languageInvalid: false,
			portalLinkInvalid: false,
			nameInvalid: false,
			brandingIdInvalid: false,
			didBrandingIdChange: false,
			didPortalLinkChange: false
		}
	},
	computed: {
		activeSG() {
			return this.$store.state.activeSG
		},
	},
	watch: {
		activeSG() {
			this.initData()
		}
	},
	methods: {
		initData() {
			this.formData.brandingId = this.$store.state.activeSG.brandingId
			this.formData.sgType = this.$store.state.activeSG.sgType
			this.formData.name = this.$store.state.activeSG.name
			this.formData.portalLink = this.$store.state.activeSG.channelsConfig.docImage.configPortal.portalLink
			this.formData.termsOfServiceEmail = this.$store.state.activeSG.channelsConfig.docImage.configPortal.termsOfServiceEmail
			this.formData.languages = this.$store.state.activeSG.languages !== undefined ? this.$store.state.activeSG.languages : []
			this.nameInvalid = false
			this.portalLinkInvalid = false
			this.languageInvalid = false
			this.brandingIdInvalid = false
		},
		updateSelectedType(data) {
			if (this.typeData[data]) {
				this.formData.sgType = this.typeData[data].name
			}
		},
		updateSelectedLanguages(data) {
			this.formData.languages = []
			for (let item of data) {
				this.formData.languages.push(this.languages[item].name)
			}
		},
		close() {
			this.initData()
			this.$store.state.displayEditGeneral = false
		},
		closeWarningRealm(type) {
			if (type === "branding") this.didBrandingIdChange = false
			else if (type === "portalLink") this.didPortalLinkChange = false
		},
		async update() {
			let hasErrors = this.validate()
			if (!hasErrors) {
				if (this.formData.termsOfServiceEmail === "") {
					delete this.formData.termsOfServiceEmail
				}

				this.didBrandingIdChange = this.originalBrandingId !== this.formData.brandingId
				this.didPortalLinkChange = this.originalPortalLink !== this.formData.portalLink

				let payload = {
					sgId: this.supplierGroup.supplierGroupId,
					edit: "info",
					body: this.formData,
				}
				await this.$store.dispatch("EditSupplierGroup", payload)
				if (this.$store.state.responseStatus === 200) {
					this.$store.commit("toggleAlert", {
						title: "Edit SupplierGroup",
						text: "General successfully edited",
					})
				}
				this.close()
			}
		},
		validate() {
			this.nameInvalid = false
			this.portalLinkInvalid = false
			this.languageInvalid = false
			this.brandingIdInvalid = false
			let hasError = false
			if (this.formData.name === "") {
				this.nameInvalid = true
				hasError = true
			}
			if (this.formData.brandingId === "") {
				this.brandingIdInvalid = true
				hasError = true
			}
			if (this.formData.portalLink === "") {
				this.portalLinkInvalid = true
				hasError = true
			}
			if (!this.$store.state.urlRegex.test(this.formData.portalLink)) {
				this.portalLinkInvalid = true
				hasError = true
			}
			if (this.formData.languages.length === 0) {
				this.languageInvalid = true
				hasError = true
			}
			return hasError
		}
	}
}
</script>