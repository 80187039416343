<template>
    <pop-up-window
          :show="this.$store.state.displayEditLeBankAccounts"
          :showClose="false"
          @close="close"
          class="doc-statuses-popup"
          fixed
    >
        <template #header>Edit Bank Accounts</template>
        <template #content>
            <div v-if="formData.bankAccounts" class="flex flex-col flex-nowrap">
                <BankAccountsTable
                      @update-value="changeBankAccountDetails"
                      @delete-value="deleteBankAccount"
                      :Headers="bankAccountHeaders"
                      :data="formData.bankAccounts"
                      :canEdit="true"
                />

                <span v-if="indexInvalid" class="text-primary-3">Index is required and must be unique</span>
                <span v-if="ibanInvalid" class="text-primary-3">IBAN should be a valid IBAN</span>
            </div>
            <div class="grid grid-rows-1 mt-2 justify-items-end">
                <div>
                    <button class="btn shadow-md mr-2" @click="displayAddBankAccount">
                        Add
                    </button>
                    <button class="btn shadow-md mr-2" @click="update">Update</button>
                    <button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </pop-up-window>
    <AddBankAccount @add-bankAccount="addBankAccount"/>
</template>
<script>
import BankAccountsTable from "../BankAccountsTable.vue";
import AddBankAccount from "../AddLegalEntity/AddBankAccount.vue";
import PopUpWindow from "../PopUp.vue";

export default {
    name: "EdiitBankAccounts",
    components: {
        PopUpWindow,
        BankAccountsTable,
        AddBankAccount,
    },
    props: {
        bankAccountHeaders: Array,
    },
    data() {
        return {
            indexInvalid: false,
            ibanInvalid: false,
            legalEntity: this.$store.state.activeLE,
            formData: {
                bankAccounts: JSON.parse(JSON.stringify(this.$store.state.bankAccounts)),
            },
        };
    },

    mounted() {
        this.initData()
    },
    methods: {
        addBankAccount(data) {
            this.formData.bankAccounts.push(data);
        },
        deleteBankAccount(data) {
            let found = this.formData.bankAccounts.find(
                  (item) => item.iban === data[2]
            );
            let index = this.formData.bankAccounts.indexOf(found);
            if (index > -1) {
                this.formData.bankAccounts.splice(index, 1);
            }
        },
        initData() {
            this.indexInvalid = false
            this.ibanInvalid = false
            let bankAccounts = this.$store.state.activeLE.bankAccounts
                  ? this.$store.state.activeLE.bankAccounts
                  : [];
            this.$store.state.bankAccounts = [...bankAccounts];
            this.formData.bankAccounts = JSON.parse(JSON.stringify(this.$store.state.bankAccounts));

            this.formData.bankAccounts.sort(function (a, b) {
                return a.indexNumber - b.indexNumber
            })
        },
        displayAddBankAccount() {
            this.$store.state.displayAddLeBankAccount = true;
        },
        changeBankAccountDetails(bankIdx, valueIdx, value) {
            let attr = Object.keys(this.formData.bankAccounts[bankIdx])[valueIdx];
            this.formData.bankAccounts[bankIdx][attr] = value;
        },
        close() {
            this.initData();
            this.$store.state.displayEditLeBankAccounts = false;
        },
        async update() {
            let hasErrors = this.validate();
            if (!hasErrors) {
                if (this.formData.bankAccounts.length === 0) {
                    this.formData.bankAccounts = null
                }
                let payload = {
                    sgId: this.$store.state.activeSG.supplierGroupId,
                    leId: this.$store.state.activeLE.entityNumber,
                    edit: "bank-accounts",
                    body: this.formData,
                };
                await this.$store.dispatch("EditLegalEntity", payload);
                if (this.$store.state.responseStatus === 200) {
                    this.$store.commit("toggleAlert", {
                        title: "Edit Legal entity",
                        text: "Bankaccounts successfully edited",
                    });
                }
                setTimeout(()=>{
                    this.close();
                }, 400)

            }
        },
        toFindDuplicates(arr) {
            return arr.filter((item, index) => arr.indexOf(item) !== index)
        },
        validate() {
            let hasErrors = false;
            this.indexInvalid = false;
            this.ibanInvalid = false;
            let indexHelp = []
            for (let item of this.formData.bankAccounts) {
                item.indexNumber = parseInt(item.indexNumber);
                if (isNaN(item.indexNumber)) {
                    hasErrors = true;
                    this.indexInvalid = true;
                }
                indexHelp.push(item.indexNumber)
                if ((this.toFindDuplicates(indexHelp)).length > 0) {
                    hasErrors = true
                    this.indexInvalid = true;
                }
                if (item.iban === "") {
                    hasErrors = true;
                    this.ibanInvalid = true;
                }
                if (!this.$store.state.ibanRegex.test(item.iban)) {
                    hasErrors = true;
                    this.ibanInvalid = true;
                }
            }
            return hasErrors;
        },
    },
};
</script>
<style scoped>
</style>
