<template>
	<div
		v-if="renderComponent"
		:class="{ 'brightness-95': this.$store.state.displayEditPrint}"
		class="box shadow-none col-span-6"
	>
		<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
			<div class="flex flex-col flex-nowrap">
				<div class="mb-3 flex">
					<label class="font-bold text-xl form-label mr-3 mb-0">Print:</label>
					<ToggleSlide
						class="w-min pointer-events-none"
						:key="printComputed"
						:bool="printComputed"
						:disabled="true"
					/>
				</div>
				<div v-if="printComputed">
					<Accordion class="mb-5 border border-gray-300 p-1 box">
						<template v-slot:title>
							<span class="font-semibold text-lg">Configurations</span>
						</template>
						<template v-slot:content>
							<div class="flex flex-row">
								<div class="flex flex-col w-1/2 mr-12">
									<div class="mb-3">
										<label class="font-bold form-label">Print Provider:</label>
										<Dropdown
											:data="printProviderData"
											:key="formData.printProvider"
											:selected="formData.printProvider"
											:disabled="true"
										/>
									</div>
								</div>
							</div>
						</template>
					</Accordion>
					<p class="font-semibold text-lg">Jobs:</p>
					<Accordion
						:key="item + index"
						v-for="(item, index) in formData.printJobs"
						class="mt-3 mb-2 border border-gray-300 p-1 box"
					>
						<template v-slot:title>
							<span class="font-semibold text-lg">{{ item.name }}</span>
						</template>
						<template v-slot:content>
							<div class="flex flex-row">
								<div class="flex flex-col w-1/2 mr-12">
									<Field
										class="mt-4"
										v-if="item.additional"
										type="text"
										:disabled=true
										title="Display name:"
										v-model="item.additional.displayName"
									/>
									<Field
										class="mt-4"
										v-if="formData.printProvider === 'EASYPOST'"
										type="text"
										:disabled=true
										title="Work flow ID:*"
										v-model="item.workFlowId"
									/>
									<div>
										<p class="font-bold py-2" >Legal entities:</p>
										<DropdownSelectAll
											:selected=setSelectedLe(item.name)
											:data="supplierLegalEntities"
											:disabled=true
										/>
										<Field
											v-if="providerIsSpeos"
											:disabled="true"
											title="Envelope template:"
											type="text"
											:modelValue="item.additional ? item.additional.envelopeTemplate : ''"
											:isCopied="copyEnvelopeTemplate"
											:copySuffix="true"
											@copy="copy(item,'envelopeTemplate')"
										/>
										<div class="mb-3 flex" v-if="providerIsSpeos">
											<label class="font-bold form-label mr-3 mb-0">Print correlation:</label>
										</div>
										<div v-if="!!item.additional?.printCorrelation && providerIsSpeos" class="ml-6">
											<Field
												type="text"
												title="ID:"
												:modelValue="item.additional.printCorrelation.id"
												:disabled="true"
												:isCopied="copyCorrelationId"
												:copySuffix="true"
												@copy="copy(item,'correlationId')"
											/>
											<Field
												type="text"
												title="Name:"
												:modelValue="item.additional.printCorrelation.name"
												:disabled="true"
												:isCopied="copyCorrelationName"
												:copySuffix="true"
												@copy="copy(item,'correlationName')"
											/>
										</div>
									</div>
								</div>
								<div class="flex flex-col w-1/2">
									<div v-if="providerIsSpeos" class="mb-3 flex">
										<label class="font-bold form-label mr-3 mb-0">Prior mail:</label>
										<ToggleSlide
											class="w-min pointer-events-none"
											:key="item.priorMail"
											:bool="item.priorMail"
											:disabled="true"
										/>
									</div>
									<div v-if="providerIsSpeos" class="mb-3 flex">
										<label class="font-bold form-label mr-3 mb-0">Registered mail:</label>
										<ToggleSlide
											class="w-min pointer-events-none"
											:key="item.registeredMail"
											:bool="item.registeredMail"
											:disabled="true"
										/>
									</div>
									<!--SPEOS and/or EASYPOST-->
									<div>
										<div v-if="providerIsSpeos" class="mb-3 flex">
											<label class="font-bold form-label mr-3 mb-0">
												Print Billing Document Recto Verso:
											</label>

											<ToggleSlide
												class="w-min pointer-events-none"
												id="viewPrintBillingRectoVerso"
												:key="item.billingDocumentRectoVerso"
												:bool="item.billingDocumentRectoVerso"
												:disabled="true"
											/>
										</div>
										<div v-if="item.registeredMail || providerIsSpeos" class="mb-3 ml-6 flex">
											<label class="font-bold form-label mr-3 mb-0">
												Acknowledgement of receipt:
											</label>
											<ToggleSlide
												class="w-min pointer-events-none"
												:key="!!item.additional?.acknowledgementOfReceipt"
												:bool="!!item.additional?.acknowledgementOfReceipt"
												:disabled="true"
											/>
										</div>
										<div v-if="providerIsSpeos" class="mb-3 flex">
											<label class="font-bold form-label mr-3 mb-0">
												Insert address page:
											</label>
											<ToggleSlide
												class="w-min pointer-events-none"
												:key="item.additional? item.additional.insertAddressPage: false"
												:bool="item.additional? item.additional.insertAddressPage: false"
												:disabled="true"
											/>
										</div>
										<div v-if="providerIsSpeos || formData.printProvider === 'EASYPOST'" class="mb-3 flex">
											<label class="font-bold form-label mr-3 mb-0">
												Insert blank page after first page:
											</label>
											<ToggleSlide
												class="w-min pointer-events-none"
												:key="item.additional? item.additional.insertBlankPageAfterFirstPage: false"
												:bool="item.additional? item.additional.insertBlankPageAfterFirstPage: false"
												:disabled="true"
											/>
										</div>
										<div v-if="providerIsSpeos || formData.printProvider === 'EASYPOST'" class="mb-3 flex">
											<label class="font-bold form-label mr-3 mb-0">Concatenate T&amp;C
												template:</label>
											<ToggleSlide
												class="w-min pointer-events-none"
												:key="item.additional? item.additional.concatenateTermsAndConditionsTemplate: false"
												:bool="item.additional? item.additional.concatenateTermsAndConditionsTemplate: false"
												:disabled="true"
											/>
										</div>
										<div
											v-if="(providerIsSpeos || formData.printProvider === 'EASYPOST') && item.additional? item.additional.concatenateTermsAndConditionsTemplate: false"
											class="ml-6 mb-3 flex"
										>
											<label class="font-bold form-label mr-3 mb-0">
												Insert T&amp;C after first page:
											</label>
											<ToggleSlide
												class="w-min pointer-events-none"
												:key="item.additional ? item.additional.insertTermsAndConditionsAfterFirstPage: false"
												:bool="item.additional? item.additional.insertTermsAndConditionsAfterFirstPage: false"
												:disabled="true"
											/>
										</div>
										<div v-if="providerIsSpeos" class="mt-12 mb-3 flex">
											<label class="font-bold form-label mr-3 mb-0">Test mode:</label>
											<ToggleSlide
												class="w-min pointer-events-none"
												:key="item.additional ? item.additional.testMode : false"
												:bool="item.additional ? item.additional.testMode : false"
												:disabled="true"
											/>
										</div>
									</div>
								</div>
							</div>
							<!--SPEOS-->
							<div v-if="providerIsSpeos" class="flex flex-col">
								<div class="mb-3 flex">
									<label class="font-bold form-label mr-3 mb-0">Return Address:</label>
									<ToggleSlide
										class="w-min pointer-events-none"
										:key="!!item.additional?.returnAddress"
										:bool="!!item.additional?.returnAddress"
										:disabled="true"
									/>
								</div>
								<div
									v-if="!!item.additional?.returnAddress"
									class="flex flex-row"
								>
									<div class="flex flex-col w-1/2 mr-12 ml-6">
										<Field
											id="viewPrintCompanyName"
											type="text"
											title="Company name:"
											:modelValue="item.additional.returnAddress.companyName"
											:disabled="true"
											:isCopied="copyCompanyName"
											:copySuffix="true"
											@copy="copy(item,'companyName')"
										/>
									</div>
									<div class="flex flex-col w-1/2"></div>
								</div>
								<div v-if="!!item.additional?.returnAddress" class="flex flex-row">
									<div class="flex flex-col w-1/2 mr-12 ml-6">
										<Field
											id="viewPrintAddressStreet"
											type="text"
											title="Street:"
											:modelValue="item.additional.returnAddress.streetName"
											:disabled="true"
											:isCopied="copyStreet"
											:copySuffix="true"
											@copy="copy(item,'street')"
										/>
										<Field
											id="viewPrintZip"
											type="text"
											title="Zip:"
											:modelValue="item.additional.returnAddress.zip"
											:disabled="true"
											:isCopied="copyZip"
											:copySuffix="true"
											@copy="copy(item,'zip')"
										/>
										<div class="mb-3">
											<label class="font-bold form-label">Country code:</label>
											<Dropdown
												id="viewPrintCountryCode"
												:data="languages"
												:key="item.additional.returnAddress.countryCode"
												:selected="item.additional.returnAddress.countryCode"
												:disabled="true"
											/>
										</div>
									</div>
									<div class="flex flex-col w-1/2">
										<Field
											id="viewPrintHouseNumber"
											type="text"
											title="House number:"
											:modelValue="item.additional.returnAddress.houseNumber"
											:disabled="true"
											:isCopied="copyHouseNumber"
											:copySuffix="true"
											@copy="copy(item,'houseNumber')"
										/>
										<Field
											id="viewPrintCity"
											type="text"
											title="City:"
											:modelValue="item.additional.returnAddress.city"
											:disabled="true"
											:isCopied="copyCity"
											:copySuffix="true"
											@copy="copy(item,'city')"
										/>
										<Field
											id="viewPrintCountryName"
											type="text"
											title="Country name:"
											:modelValue="item.additional.returnAddress.countryName"
											:disabled="true"
										/>
									</div>
								</div>
							</div>
						</template>
					</Accordion>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Accordion from "../Accordion.vue"
import ToggleSlide from "../ToggleSlide.vue"
import Field from "../Field.vue"
import Dropdown from "../Dropdown.vue"
import DropdownSelectAll from "../DropdownSelectAll.vue"

export default {
	name: "ViewPrint",
	components: {
		Accordion,
		ToggleSlide,
		Field,
		Dropdown,
		DropdownSelectAll
	},
	props: {
		printProviderData: Array,
		printEnvelopeTypeData: Array,
		printColorData: Array,
		languages: Array,
	},
	data() {
		return {
			copyEnvelopeTemplate: false,
			copyCorrelationId: false,
			copyCorrelationName: false,
			copyCompanyName: false,
			copyStreet: false,
			copyHouseNumber: false,
			copyCity: false,
			copyZip: false,

			days: [],
			supplierGroup: this.$store.state.activeSG,
			formData: {
				printProvider:  "",
				printerName: "",
				printLocation: "",
				printCredentials: {
					username: "",
					password: ""
				},
				printCalendar: {
					printDates: [],
					cron: ""
				},
				printJobs: []
			},
			cronDay: "1",
			cronHour: "10",
			cronMinute: "0",
			cronSecond: "0",
			renderComponent: true,
		}
	},
	watch: {
		"$store.state.activeSG": {
			handler() {
				this.initData()
			},
			deep: true,
		},
		activeSG() {
			this.renderComponent = false
			setTimeout(() => {
				this.renderComponent = true
			}, 200)
		}
	},
	computed: {
		activeSG() {
			return this.$store.state.activeSG
		},
		providerIsSpeos(){
			return this.formData.printProvider === 'SPEOS'
		},
		supplierLegalEntities() {
			let leList = []

			for (const le in this.$store.state.activeSG.supplierLegalEntities) {
				let tempItem = {
						name: this.$store.state.activeSG.supplierLegalEntities[le].internalName ? this.$store.state.activeSG.supplierLegalEntities[le].internalName : this.$store.state.activeSG.supplierLegalEntities[le].name,
						value: le
					}
				leList.push(tempItem)
			}
			return leList
		},
		getSelectedOptionCron() {
			if (this.formData.printCalendar.cron.includes("MON-FRI")) {
				return "workDay"
			}
			return "day"
		},
		dates() {
			return this.days.map((day) => day.date)
		},
		attributes() {
			return this.dates.map((date) => ({
				highlight: true,
				dates: date,
			}))
		},
		printComputed() {
			return !!this.$store.state.activeSG.channelsConfig.print
		},
	},
	mounted() {
		this.initData()
	},
	methods: {
		setSelectedLe(printJob) {
			let printJobLe = []
			let foundPrintJob = this.formData.printJobs.find(job => job.name === printJob)

			if (foundPrintJob.additional && foundPrintJob.additional.legalEntityIds) {
				foundPrintJob.additional.legalEntityIds.forEach(id => {
					if (this.$store.state.activeSG.supplierLegalEntities[id].internalName){
						printJobLe.push(this.$store.state.activeSG.supplierLegalEntities[id].internalName)
					} else printJobLe.push(this.$store.state.activeSG.supplierLegalEntities[id].name)
				})
			}
			return printJobLe
		},
		copy(item, isFor) {
			switch (isFor) {
				case "envelopeTemplate":
					navigator.clipboard.writeText(item.additional.envelopeTemplate)
					this.copyEnvelopeTemplate = true
					setTimeout(() => {
						this.copyEnvelopeTemplate = false
					}, 750)
					break
				case "correlationId":
					navigator.clipboard.writeText(item.additional.printCorrelation.id)
					this.copyCorrelationId = true
					setTimeout(() => {
						this.copyCorrelationId = false
					}, 750)
					break
				case "correlationName":
					navigator.clipboard.writeText(item.additional.printCorrelation.name)
					this.copyCorrelationName = true
					setTimeout(() => {
						this.copyCorrelationName = false
					}, 750)
					break
				case "companyName":
					navigator.clipboard.writeText(item.additional.returnAddress.companyName)
					this.copyCompanyName = true
					setTimeout(() => {
						this.copyCompanyName = false
					}, 750)
					break
				case "street":
					navigator.clipboard.writeText(item.additional.returnAddress.streetName)
					this.copyStreet = true
					setTimeout(() => {
						this.copyStreet = false
					}, 750)
					break
				case "city":
					navigator.clipboard.writeText(item.additional.returnAddress.city)
					this.copyCity = true
					setTimeout(() => {
						this.copyCity = false
					}, 750)
					break
				case "zip":
					navigator.clipboard.writeText(item.additional.returnAddress.zip)
					this.copyZip = true
					setTimeout(() => {
						this.copyZip = false
					}, 750)
					break
				case "houseNumber":
					navigator.clipboard.writeText(item.additional.returnAddress.houseNumber)
					this.copyHouseNumber = true
					setTimeout(() => {
						this.copyHouseNumber = false
					}, 750)
					break
			}
		},
		getArr(sum, startAt1 = false) {
			let arr = []
			if (startAt1) {
				for (let index = 1; index < sum + 1; index++) {
					arr.push({id: index, name: index.toString()})
				}
				return arr
			}
			for (let index = 0; index < sum; index++) {
				arr.push({id: index, name: index.toString()})
			}
			return arr
		},
		initData() {
			this.formData = {
				printProvider: this.$store.state.activeSG.channelsConfig.print
					? this.$store.state.activeSG.channelsConfig.print.printProvider : "",
				printerName: this.$store.state.activeSG.channelsConfig.print
					? this.$store.state.activeSG.channelsConfig.print.printerName : "",
				printLocation: this.$store.state.activeSG.channelsConfig.print
					? this.$store.state.activeSG.channelsConfig.print.printLocation : "",
				printCredentials: {
					username: this.$store.state.activeSG.channelsConfig.print?.printCredentials
						? this.$store.state.activeSG.channelsConfig.print.printCredentials.username : "",
					password: this.$store.state.activeSG.channelsConfig.print?.printCredentials
						? this.$store.state.activeSG.channelsConfig.print.printCredentials.password.replace(/./g, "*")
						: ""
				},
				printCalendar: {
					printDates: this.$store.state.activeSG.channelsConfig.print?.printCalendar
						? this.$store.state.activeSG.channelsConfig.print.printCalendar.printDates : [],
					cron: this.$store.state.activeSG.channelsConfig.print?.printCalendar
						? this.$store.state.activeSG.channelsConfig.print.printCalendar.cron : ""
				},
				printJobs: this.$store.state.activeSG.channelsConfig.print
					? this.$store.state.activeSG.channelsConfig.print.printJobs : [],
			}
			let cronArray = this.formData.printCalendar.cron.split(" ")
			if (this.formData.printCalendar.cron && !this.formData.printCalendar.cron.includes("MON-FRI")) {
				this.cronDay = cronArray[3].replace("1/", "")
			}
			this.cronHour = cronArray[2]
			this.cronMinute = cronArray[1]
			this.cronSecond = cronArray[0]
			for (let date in this.formData.printCalendar.printDates) {
				this.days.push({
					id: parseInt(this.formData.printCalendar.printDates[date].slice(-2)),
					date: this.formData.printCalendar.printDates[date],
				})
			}
		}
	}
}
</script>