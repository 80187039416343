<template>
    <div id="multiple-select">
        <div class="preview flex flex-row">
            <TomSelect
                  class="w-full mb-2"
                  v-model="selectMultiple"
                  :useRemove="true"
                  :disabled="disabled"
                  multiple
                  @update-value="updateValue"
            >
                <option v-if="!noSelectAll" @change="selectAll()" value="selectAll">Select All</option>
                <option :key="index" v-for="(item,index) in data" :value="index">{{ item.name }}</option>
            </TomSelect>
            <button v-if="!disabled" :disabled="disabled" class="btn h-10 bg-theme-1 text-white w-full sm:w-16 sm:mt-0 nymus-blue"
                    @click="onResetSelected()">
                Reset
            </button>
        </div>
    </div>
</template>
<script>
import {defineComponent, onMounted, ref, watch} from 'vue'
import TomSelect from '../global-components/tom-select/Main.vue'

export default defineComponent({
    components: {
        TomSelect
    },
	data() {
		return {
			selectMultiple: [],
			selectedItem:{}
		}
	},
    computed: {
        selectAllToggle() {
            return this.$store.state.selectAllbool
        }
    },
    watch: {
        selectAllToggle() {
            this.selectAll()
        },
    },
	setup(props){
		watch(props.selected, (newVal, oldValue) => {
			this.selectedItem = newVal
			this.initSelected()
		})
	},
    props: {
        data: Array,
        selected: Array,
        disabled: Boolean,
        noSelectAll: Boolean,
    },

	emits: ['update-value'],
	mounted() {
		this.selectedItem = this.selected
		this.initSelected();
	},
	methods: {
        updateValue(data) {
            this.$emit('update-value', data)
        },
        selectAll() {
            let index = 0
            this.selectMultiple = []
            for (let item of this.data) {
                this.selectMultiple.push(index)
                index++
            }
        },
        onResetSelected() {
			this.selectMultiple = []
		},
		initSelected() {
			if (this.selectedItem) {
				let arrFound = [];
				for (let lang of this.selectedItem) {
					let found = this.data.indexOf(
						this.data.find((el) => el.name === lang));
					arrFound.push(found);
				}
				this.selectMultiple = arrFound;
			}
		}
    },
})
</script>
