<template>
    <div class="error-alert"
    v-if="this.$store.state.errorAlert.display"
    >
        <div class="top"
             :class="{'error' : error, 'warning' : !error}"
        >

        </div>
       <h2>
           {{this.$store.state.errorAlert.text}}
       </h2>
        <button class="btn shadow-md bg-theme-1 text-white mr-6"
                @click="closeError"
        >
            Close
        </button>

    </div>

</template>

<script>
export default {
    name: "ErrorAlert",

    computed:{
      error(){
          if (this.$store.state.errorAlert.text === "Session is expired"){
              return false
          }
          return true
      }
    },

    methods: {
        closeError(){
            if (this.$store.state.errorAlert.text === "Session is expired"){
                this.$root.keycloak.logout()
            }
            this.$store.commit('displayErrorAlert', false);
            this.$store.commit('setErrorAlertText', 'Sorry, something went wrong. Try again later.')
        }
    }
}
</script>

<style scoped>
.error-alert{
    position: fixed;
    width: 300px;
    height: 240px;
    top: 30%;
    left: calc(50vw - 150px);
    z-index: 200;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    background-color: white;
    justify-items: center;
    align-items: center;

    border-radius: 10px;
    border: 2px solid rgb(226, 232, 240);
}
h2{
    font-size: 16px;
    text-align: center;
    padding: 20px;
}
button{
    margin: 0;
}
.top{
    width: 100%;
    height: 60px;

    border-radius: 10px 10px 0 0;
    align-self: start;
}

.error{
    background-color: #c41212;
}
.warning{
    background-color: #eec83d;
}
</style>
