<template>
	<pop-up-window
		:show="this.$store.state.displayEditLePayment"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit Payment</template>
		<template #content>
			<div class="flex flex-row flex-nowrap">
				<div v-if="canEnable" class="flex flex-col w-1/2 mr-12">
					<div class="mb-3 flex">
						<label for="enableLePayment" class="font-bold form-label mb-0 mr-2"
						>Enable payment:</label
						>
						<ToggleSlide
							class="w-min"
							id="editEnableLePayment"
							@update-value="changePaymentBool"
							:key="paymentBool"
							:bool="paymentBool"
						/>
					</div>
					<div v-if="leShowPayment">
						<Field
							id="paymentApiKey"
							title="Api key: *"
							@input="validate"
							type="text"
							:hasError="apiKeyInvalid"
							v-model="formData.apiKey"
						/>
						<span v-if="apiKeyInvalid" class="text-primary-3"
						>Api key is required</span
						>
						<Field
							id="paymentTemplateId"
							title="Template id:"
							@keypress="validateOnNumbers($event)"
							type="number"
							v-model.number="formData.templateId"
						/>
						<Field
							id="paymentMandateIdPrefix"
							title="Mandate id prefix:"
							type="text"
							v-model="formData.mandateIdPrefix"
						/>

						<div v-if="displayPaymentMethods">
							<Field
								id="editMandateIdTemlateId"
								title="Mandate template id:"
								type="number"
								@keypress="validateOnNumbers($event)"
								v-model.number="formData.paymentMethods[0].templateId"
							/>
						</div>


					</div>


				</div>

				<p v-else>
					Payment cannot be enabled. Enable payment on suppliergroup level to
					get access
				</p>

			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button v-if="canEnable" class="btn shadow-md mr-2" @click="update">
						Update
					</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>
<script>
import Field from "../Field.vue";
import ToggleSlide from "../ToggleSlide.vue";
import PopUpWindow from "../PopUp.vue";

export default {
	name: "EditPayment",
	components: {
		PopUpWindow,
		Field,
		ToggleSlide,
	},
	props: {
		paymentProvider: String,
	},
	computed: {
		activeLE() {
			return this.$store.state.activeLE
		},
		displayPaymentMethods() {

			if (!this.formData.paymentMethods) {
				return false
			}
			return true
		},
		leShowPayment() {
			if (this.paymentProvider === "TWIKEY" && this.paymentBool === true) {
				return true;
			}
			return false;
		},
	},
	watch: {
		activeLE() {
			this.initData()
		}
	},
	data() {
		return {
			canEnable: this.$store.state.activeSG.channelsConfig
				? this.$store.state.activeSG.channelsConfig.payment
					? true
					: false
				: false,
			apiKeyInvalid: false,
			legalEntity: this.$store.state.activeLE,
			paymentBool: this.$store.state.activeLE.legalEntityConfig
				? this.$store.state.activeLE.legalEntityConfig.payment
					? true
					: false
				: false,
			formData: {
				apiKey: this.$store.state.activeLE.legalEntityConfig
					? this.$store.state.activeLE.legalEntityConfig.payment
						? this.$store.state.activeLE.legalEntityConfig.payment.apiKey
						: ""
					: "",
				templateId: this.$store.state.activeLE.legalEntityConfig
					? this.$store.state.activeLE.legalEntityConfig.payment
						? this.$store.state.activeLE.legalEntityConfig.payment.templateId
						: 0
					: 0,
				mandateIdPrefix: this.$store.state.activeLE.legalEntityConfig
					? this.$store.state.activeLE.legalEntityConfig.payment
						? this.$store.state.activeLE.legalEntityConfig.payment.mandateIdPrefix
						: ""
					: "",
				paymentMethods: this.$store.state.activeLE.legalEntityConfig
					? this.$store.state.activeLE.legalEntityConfig.payment
						? this.$store.state.activeLE.legalEntityConfig.payment.paymentMethods
							? this.$store.state.activeLE.legalEntityConfig.payment.paymentMethods
							: [{
								id: "mandate",
								templateId: '',
								methodType: "MANDATE"
							}]
						: [{
							id: "mandate",
							templateId: '',
							methodType: "MANDATE"
						}]
					: [{
						id: "mandate",
						templateId: '',
						methodType: "MANDATE"
					}]


			},
		};
	},
	methods: {
		changePaymentBool(data) {
			this.paymentBool = data;
		},
		initData() {
			if (!this.formData) {
				this.formData = {};
			}
			this.canEnable = this.$store.state.activeSG.channelsConfig
				? this.$store.state.activeSG.channelsConfig.payment
					? true
					: false
				: false;
			this.apiKeyInvalid = false;
			this.paymentBool = this.$store.state.activeLE.legalEntityConfig
				? this.$store.state.activeLE.legalEntityConfig.payment
					? true
					: false
				: false;
			this.formData.apiKey = this.$store.state.activeLE.legalEntityConfig
				? this.$store.state.activeLE.legalEntityConfig.payment
					? this.$store.state.activeLE.legalEntityConfig.payment.apiKey
					: ""
				: "";
			this.formData.templateId = this.$store.state.activeLE.legalEntityConfig
				? this.$store.state.activeLE.legalEntityConfig.payment
					? this.$store.state.activeLE.legalEntityConfig.payment.templateId
					: 0
				: 0;
			this.formData.mandateIdPrefix = this.$store.state.activeLE.legalEntityConfig
				? this.$store.state.activeLE.legalEntityConfig.payment
					? this.$store.state.activeLE.legalEntityConfig.payment.mandateIdPrefix
					: ""
				: "";

			this.formData.paymentMethods = this.$store.state.activeLE.legalEntityConfig
				? this.$store.state.activeLE.legalEntityConfig.payment
					? this.$store.state.activeLE.legalEntityConfig.payment.paymentMethods
						? this.$store.state.activeLE.legalEntityConfig.payment.paymentMethods
						: [{
							id: "mandate",
							templateId: '',
							methodType: "MANDATE"
						}]
					: [{
						id: "mandate",
						templateId: '',
						methodType: "MANDATE"
					}]
				: [{
					id: "mandate",
					templateId: '',
					methodType: "MANDATE"
				}]

			if (this.$store.state.activeLE
				&& this.$store.state.activeLE.legalEntityConfig
				&& this.$store.state.activeLE.legalEntityConfig.payment
				&& this.$store.state.activeLE.legalEntityConfig.payment.paymentMethods
				&& this.$store.state.activeLE.legalEntityConfig.payment.paymentMethods[0]
				&& this.$store.state.activeLE.legalEntityConfig.payment.paymentMethods[0].templateId
			) {
				this.formData.paymentMethods[0].templateId = this.$store.state.activeLE.legalEntityConfig.payment.paymentMethods[0].templateId;
			}
		},
		close() {
			this.initData();
			this.$store.state.displayEditLePayment = false;
		},
		async update() {
			let hasErrors = this.validate();
			if (!hasErrors) {

				if (!this.paymentBool) {
					this.formData = null;

				}
				if (this.paymentBool) {
					if (this.formData.templateId === 0 || this.formData.templateId === "") {
						delete this.formData.templateId;
					}
					if (this.formData.mandateIdPrefix === "") {
						delete this.formData.mandateIdPrefix;
					}


					if (this.formData.paymentMethods[0].templateId === '') {
						delete this.formData.paymentMethods;
					} else {
						let templateId = this.formData.paymentMethods[0].templateId;
						this.formData.paymentMethods[0].templateId = parseInt(this.formData.paymentMethods[0].templateId);
					}

				}

				let payload = {
					sgId: this.$store.state.activeSG.supplierGroupId,
					leId: this.$store.state.activeLE.entityNumber,
					edit: "payment-settings",
					body: {payment: this.formData},
				};
				await this.$store.dispatch("EditLegalEntity", payload);
				if (this.$store.state.responseStatus === 200) {
					this.$store.commit("toggleAlert", {
						title: "Edit Legal entity",
						text: "Payment successfully edited",
					});
				}
				setTimeout(() => {
					// this.close();
				}, 300)


			}
		},
		validate() {
			let hasErrors = false;
			this.apiKeyInvalid = false;

			if (this.leShowPayment) {
				if (this.formData.apiKey === "") {
					hasErrors = true;
					this.apiKeyInvalid = true;
				}
			}
			return hasErrors;
		},
		validateOnNumbers(e) {
			if (
				(e.which != 8 && e.which != 0 && e.which < 48) ||
				e.which > 57
			) {
				e.preventDefault();
			}
		},
	},
};
</script>
<style scoped>
</style>
