
if (window.location.host.includes("localhost")) {
    fetch('https://staging-admin.nymus.eu/environment').then(res => res.json()).then(config => {

        window.environment = config;
        require("./app")
})
} else {
    fetch('/environment').then(res => res.json()).then(config => {
        window.environment = config;
        require("./app")
    })
}








