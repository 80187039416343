<template>
	<pop-up-window
		:show="this.$store.state.displayEditMailChannel"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit Mail Channel</template>
		<template #content>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-1/2 mr-12">
					<!--Mail Server Name-->
					<Field
						id="mailServerName"
						type="text"
						:hasError="mailNameInvalid"
						@input="validate"
						title="Mail Server Name: *"
						v-model="formData.mailServerName"
					/>
					<span v-if="mailNameInvalid" class="text-primary-3">Mail server name is Required</span>
					<!--Mail Server Token-->
					<Field
						id="mailServerToken"
						type="text"
						:hasError="mailTokenInvalid"
						@input="validate"
						title="Mail Server Token: *"
						v-model="formData.mailServerToken"
					/>
					<span v-if="mailTokenInvalid" class="text-primary-3">Mail server token is required</span>

					<label class="font-bold form-label mb-0 mr-2">Invitation Template:</label>
					<Dropdown
						@update-value="updateInvitation"
						:data="optionsInvitationTemplate"
						:selected="formData.invitationTemplate"
						:disabled="false"
						:select-none="true"
						text-select-none="No invitation template"
						style="margin-bottom: 10px"
					/>
					<div class="flex flex-row flex-nowrap">
						<div class="flex flex-col w-1/2">
							<!--Link Channel-->
							<div class="mb-3 flex">
								<label class="font-bold form-label mb-0 mr-2">Link Channel:</label>
								<ToggleSlide
									@update-value="updateLcBool"
									class="w-min"
									:bool="lcBool"
								/>
							</div>
							<div v-if="lcBool">
								<!--Link Send Invitation Mail-->
								<div class="mb-3 ml-6 flex">
									<label class="font-bold form-label mb-0 mr-2">Send Invitation Mail:</label>
									<ToggleSlide
										@update-value="updateLcSendInvBool"
										class="w-min"
										:bool="formData.linkEmail.sendInvitationMail"
									/>
								</div>
								<!--Link Create Receiver-->
								<div class="mb-3 ml-6 flex">
									<label class="font-bold form-label mb-0 mr-2">Create Receiver:</label>
									<ToggleSlide
										@update-value="updateLcCreateReceiverBool"
										class="w-min"
										:bool="formData.linkEmail.createReceiverUser"
									/>
								</div>

								<div>
									<div class="mb-3  ml-6 " v-if="formData.linkEmail.createReceiverUser">
										<label class="font-bold form-label">User type:</label>
										<Dropdown
											id="editUserTypeLink"
											@update-value="updateUserType($event, 'linkEmail')"
											class="pr-2"
											:data="userTypeOptions"
											:selected="formData.linkEmail.userType"
										/>
										<p v-if="errors.linkEmail" class="text-primary-3">Please, select user type</p>
									</div>
								</div>
							</div>
						</div>
						<div class="flex flex-col w-1/2">
							<!--Attach Channel-->
							<div class="mb-3 flex">
								<label class="font-bold form-label mb-0 mr-2">Attach Channel:</label>
								<ToggleSlide
									@update-value="updateAcBool"
									class="w-min"
									:bool="acBool"
								/>
							</div>
							<div v-if="acBool">
								<!--Attach Send Invitation Mail-->
								<div class="mb-3 ml-6 flex">
									<label class="font-bold form-label mb-0 mr-2">Send Invitation Mail:</label>
									<ToggleSlide
										@update-value="updateAcSendInvBool"
										class="w-min"
										:bool="formData.attachEmail.sendInvitationMail"
									/>
								</div>
								<!--Attach Include Ubl-->
								<div class="mb-3 ml-6 flex">
									<label class="font-bold form-label mb-0 mr-2">Include UBL:</label>
									<ToggleSlide
										@update-value="updateAcIncludeUblBool"
										class="w-min"
										:bool="formData.attachEmail.includeUbl"
									/>
								</div>
								<!--Attach Create Receiver-->
								<div class="mb-3 ml-6 flex">
									<label class="font-bold form-label mb-0 mr-2">Create Receiver:</label>
									<ToggleSlide
										@update-value="updateAcCreateReceiverBool"
										class="w-min"
										:bool="formData.attachEmail.createReceiverUser"
									/>
								</div>

								<div class="mb-3  ml-6 " v-if="formData.attachEmail.createReceiverUser">
									<label class="font-bold form-label">User type:</label>
									<Dropdown
										id="editUsertypeAttach"
										@update-value="updateUserType($event, 'attachEmail')"
										class="pr-2"
										:data="userTypeOptions"
										:selected="formData.attachEmail.userType"
									/>
									<p v-if="errors.attachEmail" class="text-primary-3">Please, select user type</p>
								</div>
							</div>
						</div>
					</div>
					<Field
						id="supportEmailAddress"
						type="text"
						title="Support Email Address:"
						:hasError="supportEmailInvalid"
						@input="validate"
						v-model="formData.supportEmail"
					/>
					<span v-if="supportEmailInvalid" class="text-primary-3">Support email should be a valid email format!</span>
					<Field
						id="editDefaultReceiverEmail"
						type="text"
						title="Default Receiver Email Address:"
						v-model="formData.defaultReceiverEmail"
					/>
					<span v-if="defaultReceiverEmailInvalid" class="text-primary-3">Please, enter the valid email</span>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>

	<new-alert
		v-if="channelChanged"
		title="Le channels are configured"
		:is-visible="channelChanged"
		text="There is a le with separate configurations. Check if still correct"
		style="margin-top: 30px"
	/>
</template>

<script>
import PopUpWindow from "../PopUp.vue"
import Field from "../Field.vue"
import ToggleSlide from "../ToggleSlide.vue"
import Dropdown from "../Dropdown.vue"
import NewAlert from "@/components/NewAlert.vue";

export default {
	name: "EditMailChannel",
	components: {
		NewAlert,
		PopUpWindow,
		Field,
		ToggleSlide,
		Dropdown
	},
	data() {
		return {
			defaultReceiverEmailInvalid: false,
			supplierGroup: this.$store.state.activeSG,
			formData: {
				mailServerName: this.$store.state.activeSG.mailServerName,
				mailServerToken: this.$store.state.activeSG.mailServerToken,
				invitationTemplate: this.$store.state.activeSG.templates?.invitationMailTemplate === undefined
					? "" : this.$store.state.activeSG.templates.invitationMailTemplate,
				linkEmail: {
					createReceiverUser: this.$store.state.activeSG.channelsConfig.linkEmail
						? this.$store.state.activeSG.channelsConfig.linkEmail.createReceiverUser
						: false,
					sendInvitationMail: this.$store.state.activeSG.channelsConfig.linkEmail
						? this.$store.state.activeSG.channelsConfig.linkEmail.sendInvitationMail
						: false,
					userType: this.$store.state.activeSG.channelsConfig.linkEmail?.userType
						? this.$store.state.activeSG.channelsConfig.linkEmail.userType
						: "No user type",
				},
				attachEmail: {
					createReceiverUser: this.$store.state.activeSG.channelsConfig.attachEmail
						? this.$store.state.activeSG.channelsConfig.attachEmail.createReceiverUser
						: false,
					includeUbl: this.$store.state.activeSG.channelsConfig.attachEmail
						? this.$store.state.activeSG.channelsConfig.attachEmail.includeUbl
						: false,
					sendInvitationMail: this.$store.state.activeSG.channelsConfig.attachEmail
						? this.$store.state.activeSG.channelsConfig.attachEmail.sendInvitationMail
						: false,
					userType: this.$store.state.activeSG.channelsConfig.attachEmail?.userType
						? this.$store.state.activeSG.channelsConfig.attachEmail.userType
						: "No user type",
				},
				supportEmail: this.$store.state.activeSG.channelsConfig.docImage.configPortal.supportEmail,

				defaultReceiverEmail: this.$store.state.activeSG.defaultReceiverEmail
					? this.$store.state.activeSG.defaultReceiverEmail
					: "",
			},
			lcBool: !!this.$store.state.activeSG.channelsConfig.linkEmail,
			acBool: !!this.$store.state.activeSG.channelsConfig.attachEmail,
			mailNameInvalid: false,
			mailTokenInvalid: false,
			supportEmailInvalid: false,

			userTypeOptions: [
				{id: 0, name: "No user type"},
				{id: 1, name: "ReceiverAdmin"},
				{id: 2, name: "ReceiverUser"},
			],

			errors: {
				linkEmail: false,
				attachEmail: false,
			},
			channelChanged: false
		}
	},

	computed: {
		activeSG() {
			return this.$store.state.activeSG
		},
		optionsInvitationTemplate(){
			let options = []
			if (this.supplierGroup.templates?.mails !== undefined) {
				this.supplierGroup.templates.mails.forEach(mail => options.push({name: mail.name}))
			}
			return options
		}
	},
	watch: {
		activeSG() {
			this.initData()
		}
	},
	methods: {
		setError(field, value) {
			this.errors[field] = value
		},
		updateUserType(data, field) {
			if (typeof data === "object") data = data[0]
			this.setError(field, false)
			this.formData[field].userType = this.userTypeOptions.find((item) => (item.id.toString() === data.toString())).name
		},
		updateInvitation(newValue){
			if(Array.isArray(newValue)) newValue = newValue[0]
			// -1 -> No invitation
			// 0 -> template1
			// 1 -> template2
			// that's why whe have to do -1 in order to get the correct value
			if (newValue === "-1" || newValue === -1  )this.formData.invitationTemplate = "No invitation Template"
			else this.formData.invitationTemplate = this.optionsInvitationTemplate[newValue].name
		},
		initData() {
			this.defaultReceiverEmailInvalid = false
			this.mailNameInvalid = false
			this.mailTokenInvalid = false
			this.supportEmailInvalid = false
			this.lcBool = !!this.$store.state.activeSG.channelsConfig.linkEmail
			this.acBool = !!this.$store.state.activeSG.channelsConfig.attachEmail
			this.formData.mailServerName = this.$store.state.activeSG.mailServerName
			this.formData.invitationTemplate= this.$store.state.activeSG.templates?.invitationMailTemplate === undefined
				? "" : this.$store.state.activeSG.templates.invitationMailTemplate
			this.formData.mailServerToken =
				this.$store.state.activeSG.mailServerToken
			this.formData.linkEmail = {
				createReceiverUser: this.$store.state.activeSG.channelsConfig.linkEmail
					? this.$store.state.activeSG.channelsConfig.linkEmail.createReceiverUser
					: false,
				sendInvitationMail: this.$store.state.activeSG.channelsConfig.linkEmail
					? this.$store.state.activeSG.channelsConfig.linkEmail.sendInvitationMail
					: false,
				userType: this.$store.state.activeSG.channelsConfig.linkEmail?.userType
					? this.$store.state.activeSG.channelsConfig.linkEmail.userType
					: "No user type"
			}
			this.formData.attachEmail = {
				createReceiverUser: this.$store.state.activeSG.channelsConfig.attachEmail
					? this.$store.state.activeSG.channelsConfig.attachEmail.createReceiverUser
					: false,
				includeUbl: this.$store.state.activeSG.channelsConfig.attachEmail
					? this.$store.state.activeSG.channelsConfig.attachEmail.includeUbl
					: false,
				sendInvitationMail: this.$store.state.activeSG.channelsConfig
					.attachEmail
					? this.$store.state.activeSG.channelsConfig.attachEmail.sendInvitationMail
					: false,
				userType: this.$store.state.activeSG.channelsConfig.attachEmail?.userType
						? this.$store.state.activeSG.channelsConfig.attachEmail.userType
					: "No user type"
			}
			this.formData.supportEmail = this.$store.state.activeSG.channelsConfig.docImage.configPortal.supportEmail
			this.formData.defaultReceiverEmail =
				this.$store.state.activeSG.defaultReceiverEmail
					? this.$store.state.activeSG.defaultReceiverEmail
					: ''
		},
		updateLcBool(data) {
			this.lcBool = data
		},
		updateAcBool(data) {
			this.acBool = data
		},
		updateLcSendInvBool(data) {
			if (!this.formData.linkEmail) this.formData.linkEmail = {}
			this.formData.linkEmail.sendInvitationMail = data
		},
		updateLcCreateReceiverBool(data) {
			if (!this.formData.linkEmail) this.formData.linkEmail = {}
			this.formData.linkEmail.createReceiverUser = data
		},
		updateAcSendInvBool(data) {
			if (!this.formData.attachEmail) this.formData.attachEmail = {}
			this.formData.attachEmail.sendInvitationMail = data
		},
		updateAcCreateReceiverBool(data) {
			if (!this.formData.attachEmail) this.formData.attachEmail = {}
			this.formData.attachEmail.createReceiverUser = data
		},
		updateAcIncludeUblBool(data) {
			if (!this.formData.attachEmail) this.formData.attachEmail = {}
			this.formData.attachEmail.includeUbl = data
		},
		close() {
			if (!this.lcBool) this.formData.linkEmail = {}
			if (!this.acBool) this.formData.attachEmail = {}
			this.initData()
			this.$store.state.displayEditMailChannel = false
		},
		async update() {

			let hasErrors = this.validate()
			if (!hasErrors) {
				let leSettingsOn = false
				Object.values(this.$store.state.activeSG.supplierLegalEntities).forEach(element => {
					if (element.channelsConfig !== undefined && Object.keys(element.channelsConfig).length > 0) leSettingsOn = true
				})
				if(leSettingsOn && (this.lcBool !== this.$store.state.activeSG.channelsConfig.linkEmail || this.acBool !== this.$store.state.activeSG.channelsConfig.attachEmail)){
					this.channelChanged = true
					setTimeout(() => {
						this.channelChanged = false
					}, 5000)
				}
				if (!this.lcBool) {
					delete this.formData.linkEmail
				} else {
					if (this.formData.linkEmail.createReceiverUser) {
						if (this.formData.linkEmail.userType === "No user type" || !this.formData.linkEmail.userType) {
							this.setError('linkEmail', true)
							return
						}
					} else delete this.formData.linkEmail.userType
				}
				if (!this.acBool) delete this.formData.attachEmail
				else {
					if (this.formData.attachEmail.createReceiverUser) {
						if (this.formData.attachEmail.userType === "No user type" || !this.formData.attachEmail.userType) {
							this.setError('attachEmail', true)
							return
						}
					} else delete this.formData.attachEmail.userType
				}
				if (this.formData.supportEmail === "") {
					delete this.formData.supportEmail
				}
				if (this.formData.defaultReceiverEmail === "") {
					delete this.formData.defaultReceiverEmail
				} else {
					if (!this.$store.state.emailRegex.test(this.formData.defaultReceiverEmail)) {
						this.defaultReceiverEmailInvalid = true
						return
					}
				}
				if (this.formData.invitationTemplate?.name  === "No invitation Template"){
					delete this.formData.invitationTemplate
				}
				else {
					this.formData.invitationMailTemplate = this.formData.invitationTemplate
					delete this.formData.invitationTemplate
				}

				let payload = {
					sgId: this.supplierGroup.supplierGroupId,
					edit: "mail-settings",
					body: this.formData,
				}
				await this.$store.dispatch("EditSupplierGroup", payload)
				this.close()
			}
		},
		validate() {
			this.mailNameInvalid = false
			this.mailTokenInvalid = false
			this.supportEmailInvalid = false
			let hasErrors = false
			if (this.formData.mailServerName === "") {
				this.mailNameInvalid = true
				hasErrors = true
			}
			if (this.formData.mailServerToken === "") {
				this.mailTokenInvalid = true
				hasErrors = true
			}
			if (this.formData.supportEmail !== "") {
				if (!this.$store.state.emailRegex.test(this.formData.supportEmail)) {
					hasErrors = true
					this.supportEmailInvalid = true
				}
			}
			return hasErrors
		}
	}
}
</script>