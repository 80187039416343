<template>
	<pop-up-window
		:show="this.$store.state.displayAddSupplierGroup"
		:showClose="false"
		@close="close"
		fixed
	>
		<template #header>Create supplier group</template>
		<template #content>
			<div class="flex flex-row">
				<div class="flex flex-col mr-12 w-1/2">
					<Field
						id="createSGname"
						title="Suppliergroup name:"
						type="text"
						@input="validate"
						v-model="formData.name"
						:hasError="nameInvalid"
					/>
					<span v-if="nameInvalid" class="text-primary-3">Name is required</span>
					<Field
						id="createSGsenderId"
						title="Suppliergroup ID:"
						type="text"
						maxLength="32"
						@input="validate"
						v-model="formData.sender.senderId"
						:hasError="senderIdInvalid"
					/>
					<span v-if="senderIdInvalid" class="text-primary-3">Sender ID is required and must be unique</span>
					<div class="flex">
						<label class="font-bold" for="createSGftpPassword">FTP password:</label>
						<span v-if="copiedBool" class="ml-2 font-bold trans text-theme-1">
							Copied
						</span>
					</div>
					<div class="input-group mb-3">
						<input
							id="createSGftpPassword"
							type="text"
							class="form-control"
							@input="validate"
							:class="{ 'border border-primary-3': ftpPasswordInvalid }"
							v-model="formData.sender.sFTP.ftp_password"
						/>
						<div
							@click="copyFtp"
							class="font-bold cursor-pointer input-group-text"
						>
							<font-awesome-icon icon="copy"/>
						</div>
						<div
							@click="generateFtp"
							class="font-bold cursor-pointer input-group-text"
						>
							<font-awesome-icon icon="gears"/>
						</div>
					</div>
					<span v-if="ftpPasswordInvalid" class="text-primary-3">FTP password cannot be empty</span>
				</div>
				<div class="flex flex-col w-1/2">
					<h1 class="font-bold form-label">Process</h1>
					<button :class="{'bg-primary-4': !isDisabledRealm, 'bg-theme-1': isDisabledRealm}" @click="createSG"
							id="btnCreateSG"
							:disabled="isDisabledSG" class="btn text-white shadow-md mb-2 py-1"> Create SupplierGroup
					</button>
					<span v-if="errors.createSG" class="text-primary-3" style="margin-top: -7px; margin-bottom: 7px">Could not create suppliergroup. Please try again.</span>

					<button :class="{'bg-primary-4': !isDisabledClient, 'bg-theme-1': isDisabledClient}"
							id="btnCreateRealm"
							@click="createRealm" :disabled="isDisabledRealm" class="btn text-white shadow-md mb-2 py-1">
						Create Keycloak Realm
					</button>
					<span v-if="errors.createRealm" class="text-primary-3" style="margin-top: -7px; margin-bottom: 7px">Could not create keycloak realm. Please try again.</span>

					<button :class="{'bg-primary-4': !isDisabledBuckets, 'bg-theme-1': isDisabledBuckets}"
							@click="createClient" :disabled="isDisabledClient" id="btnCreateClient"
							class="btn text-white shadow-md mb-2 py-1"> Create Keycloak Realm Client
					</button>
					<span v-if="errors.createClient" class="text-primary-3"
						style="margin-top: -7px; margin-bottom: 7px">Could not create keycloak realm clients. Please try again.</span>

					<button :class="{'bg-primary-4': !isDisabledFTP, 'bg-theme-1': isDisabledFTP}"
							@click="createBuckets" :disabled="isDisabledBuckets" id="btnCreateBuckets"
							class="btn text-white shadow-md mb-2 py-1"> Create Google Buckets
					</button>
					<span v-if="errors.createBucket" class="text-primary-3"
						style="margin-top: -7px; margin-bottom: 7px">Could not create the buckets. Please try again.</span>

					<button @click="createFTP" :disabled="isDisabledFTP" id="btnCreateFTP"
							class="btn shadow-md mb-2 py-1">
						Mount FTPs
					</button>
					<span v-if="errors.createFTP" class="text-primary-3" style="margin-top: -7px; margin-bottom: 7px">Could not create the ftp. Please try again.</span>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../PopUp.vue"
import Field from "../Field.vue"

export default {
	name: "AddSupplierGroup",
	components: {
		PopUpWindow,
		Field,
	},
	data() {
		return {
			copiedBool: false,
			ftpPasswordInvalid: false,
			nameInvalid: false,
			senderIdInvalid: false,
			formData: {
				name: "",
				sender: {
					senderId: "",
					sFTP: {
						ftp_password: "",
					},
				},
				brandingId: ""
			},
			allCapsAlpha: [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"],
			allLowerAlpha: [..."abcdefghijklmnopqrstuvwxyz"],
			allUniqueChars: [..."@%~!#$()_+-[]{}:?"],
			allNumbers: [..."0123456789"],
			isDisabledSG: true,
			isDisabledRealm: true,
			isDisabledClient: true,
			isDisabledBuckets: true,
			errors: {
				createSG: false,
				createRealm: false,
				createClient: false,
				createBucket: false,
				createFTP: false
			},
			isDisabledFTP: true,
		}
	},
	emits: ['trigger'],
	computed: {
		statusCreateSG() {
			return this.$store.state.statusCreateSG
		},
		statusCreateRealm() {
			return this.$store.state.statusCreateRealm
		},
		statusCreateClient() {
			return this.$store.state.statusCreateClient
		},
		statusCreateBuckets() {
			return this.$store.state.statusCreateBuckets
		},
		statusCreateFTP() {
			return this.$store.state.statusCreateFTP
		}
	},
	watch: {
		statusCreateSG(newVal) {
			if (newVal === 200) {
				this.isDisabledRealm = false
			}
		},

		statusCreateRealm(newVal) {
			if (newVal === 200) {
				this.isDisabledClient = false
			}
		},
		statusCreateClient(newVal) {
			if (newVal === 200) {
				this.isDisabledBuckets = false
			}
		},
		statusCreateBuckets(newVal) {
			if (newVal === 200) {
				this.isDisabledFTP = false
			}
		},
		statusCreateFTP(newVal) {
			if (newVal === 200) {
				this.close()
			}
		}
	},

	methods: {
		async createSG() {
			this.errors.createSG = false
			this.formData.brandingId = this.formData.sender.senderId
			let payload = {
				body: this.formData
			}
			await this.$store.dispatch('CreateSupplierGroup', payload)
			if (this.$store.state.statusCreateSG === 200) {
				this.isDisabledRealm = false
			} else {
				this.errors.createSG = true
				this.isDisabledRealm = true
				document.getElementById("btnCreateSG").style.backgroundColor = "#d32929"
			}
		},
		async createRealm() {
			this.errors.createRealm = false
			let payload = {
				sgId: this.formData.sender.senderId
			}
			await this.$store.dispatch('CreateKeycloakRealm', payload)
			if (this.$store.state.statusCreateRealm === 200) {
				this.isDisabledClient = false
			} else {
				this.errors.createRealm = true
				this.isDisabledClient = true
				document.getElementById("btnCreateRealm").style.backgroundColor = "#d32929"
			}
		},
		async createClient() {
			this.errors.createClient = false
			let payload = {
				sgId: this.formData.sender.senderId
			}
			await this.$store.dispatch('CreateKeycloakClient', payload)
			if (this.$store.state.statusCreateClient === 200) {
				this.isDisabledBuckets = false
			} else {
				this.errors.createClient = true
				this.isDisabledBuckets = true
				document.getElementById("btnCreateBuckets").style.backgroundColor = "#d32929"
			}
		},
		async createBuckets() {
			this.errors.createBucket = false
			let payload = {
				sgId: this.formData.sender.senderId
			}
			await this.$store.dispatch('CreateGoogleCloudBuckets', payload)
			if (this.$store.state.statusCreateBuckets === 200) {
				this.isDisabledFTP = false
			} else {
				this.errors.createBucket = true
				this.isDisabledFTP = false
				document.getElementById("btnCreateBuckets").style.backgroundColor = "#d32929"
			}
		},
		async createFTP() {
			this.errors.createFTP = false
			let payload = {
				sgId: this.formData.sender.senderId
			}
			await this.$store.dispatch('CreateFTP', payload)
			if (this.$store.state.statusCreateFTP === 200) {
				this.close()
			} else {
				this.errors.createFTP = true
				document.getElementById("btnCreateFTP").style.backgroundColor = "#d32929"
			}
		},

		copyFtp() {
			navigator.clipboard.writeText(this.formData.sender.sFTP.ftp_password)
			this.copiedBool = true
			setTimeout(() => {
				this.copiedBool = false
			}, 750)
		},
		generateFtp() {
			const allBase = [
				...this.allCapsAlpha,
				...this.allLowerAlpha,
				...this.allUniqueChars,
				...this.allNumbers,
			]
			this.formData.sender.sFTP.ftp_password = [...Array(12)]
				.map((i) => allBase[(Math.random() * allBase.length) | 0])
				.join("")
			setTimeout(() => {
				this.validate()
				navigator.clipboard.writeText(this.formData.sender.sFTP.ftp_password)
			}, 500)
		},
		close() {
			this.initData()
			this.$store.state.statusCreateSG = 0
			this.$store.state.statusCreateRealm = 0
			this.$store.state.statusCreateClient = 0
			this.$store.state.statusCreateBuckets = 0
			this.$store.state.statusCreateFTP = 0
			this.$emit('trigger')
			this.$store.state.displayAddSupplierGroup = false

			this.errors = {
				createSG: false,
				createRealm: false,
				createClient: false,
				createBucket: false,
				createFTP: false
			}
		},
		initData() {
			this.isDisabledSG = true
			this.isDisabledRealm = true
			this.isDisabledClient = true
			this.isDisabledBuckets = true
			this.isDisabledFTP = true
			this.ftpPasswordInvalid = false
			this.nameInvalid = false
			this.senderIdInvalid = false
			this.formData = {
				name: "",
				sender: {
					senderId: "",
					sFTP: {
						ftp_password: "",
					},
				},
			}
		},
		validate() {
			this.ftpPasswordInvalid = false
			this.nameInvalid = false
			this.senderIdInvalid = false
			let hasErrors = false
			if (this.formData.name === "") {
				hasErrors = true
				this.nameInvalid = true
			}
			for (let item of this.$store.state.supplierGroups) {
				if (item.supplierGroupId === this.formData.sender.senderId) {
					hasErrors = true
					this.senderIdInvalid = true
				}
			}

			if (this.formData.sender.senderId === "") {
				hasErrors = true
				this.senderIdInvalid = true
			}
			if (this.formData.sender.sFTP.ftp_password === "") {
				hasErrors = true
				this.ftpPasswordInvalid = true
			}
			if (!hasErrors) {
				this.isDisabledSG = false
			}
			return hasErrors
		}
	}
}
</script>