<template>
	<div
		:class="{ 'brightness-95': this.$store.state.displayEditLePayment }"
		class="box col-span-6"
		v-if="legalEntity"
	>
		<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
			<div class="flex flex-row flex-nowrap">
				<div v-if="canEnable" class="flex flex-col w-1/2 mr-12">
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">
							Enable payment:
						</label>
						<ToggleSlide
							class="w-min pointer-events-none"
							:key="lePaymentComputed"
							:bool="lePaymentComputed"
						/>
					</div>
					<div v-if="leShowPayment">
						<Field
							id="paymentApiKey"
							title="Api key:"
							type="text"
							:modelValue="lePaymentApiKeyComputed"
							:disabled="true"
							:isCopied="copyApiKey"
							:copySuffix="true"
							@copy="copy('apiKey')"
						/>
						<Field
							id="paymentTemplateId"
							title="Template id:"
							type="text"
							:modelValue="lePaymentTemplateIdComputed"
							:disabled="true"
							:isCopied="copyTemplateId"
							:copySuffix="true"
							@copy="copy('templateId')"
						/>
						<Field
							id="paymentMandateIdPrefix"
							title="Mandate id prefix:"
							type="text"
							:modelValue="lePaymentMandateIdPrefixComputed"
							:disabled="true"
							:isCopied="copyMandateIdPrefix"
							:copySuffix="true"
							@copy="copy('mandateIdPrefix')"
						/>
						<Field
							id="mandateTemplateId"
							title="Mandate template id:"
							type="text"
							:modelValue="leMandateTemplateIdComputed"
							:disabled="true"
							:isCopied="copyMandateTemplateId"
							:copySuffix="true"
							@copy="copy('mandateTemplateId')"
						/>
						<p class="font-bold form-label mb-0 mr-2">Trigger feed polling:</p>

						<div class="flex mt-4">
							<div class="mb-3 ml-3 flex flex-col">
								<p class="font-bold form-label mb-0 mr-2">Mandate</p>
								<button
									@click="triggerFeedPolling('mandatePolling')"
									class="btn-bl btn btn-sm w-24 mr-1 mb-2 text-white"
									:class="{
										'bg-theme-1': !bools.mandatePolling,
										'bg-gray-500': bools.mandatePolling,
										'pointer-events-none bg-gray-500':lePaymentTemplateIdComputed === '' || !lePaymentTemplateIdComputed, }"
								>
									Start
								</button>
							</div>
							<div class="mb-3 ml-3 flex flex-col">
								<p class="font-bold form-label mb-0 mr-2">Invoice</p>
								<button
									@click="triggerFeedPolling('invoicePolling')"
									class="btn-bl btn btn-sm w-24 mr-1 mb-2 text-white"
									:class="{
										'bg-theme-1': !bools.invoicePolling,
										'bg-gray-500': bools.invoicePolling,
										'pointer-events-none bg-gray-500':lePaymentTemplateIdComputed === '' || !lePaymentTemplateIdComputed}"
								>
									Start
								</button>
							</div>
							<div class="mb-3 ml-3 flex flex-col">
								<p class="font-bold form-label mb-0 mr-2">Transaction</p>
								<button
									@click="triggerFeedPolling('transactionPolling')"
									class="btn-bl btn btn-sm w-24 mr-1 mb-2 text-white"
									:class="{
										'bg-theme-1': !bools.transactionPolling,
										'bg-gray-500': bools.transactionPolling,
										'pointer-events-none bg-gray-500':lePaymentTemplateIdComputed === '' || !lePaymentTemplateIdComputed}"
								>
									Start
								</button>
							</div>
						</div>
					</div>
				</div>
				<p v-else>Payment cannot be enabled. Enable payment on suppliergroup level to get access</p>
			</div>
		</div>
	</div>
</template>
<script>
import Field from "../Field.vue"
import ToggleSlide from "../ToggleSlide.vue"

export default {
	name: "ViewPayment",
	components: {
		Field,
		ToggleSlide,
	},
	props: {
		paymentProvider: String,
	},
	data() {
		return {
			bools: [],
			legalEntity: this.$store.state.activeLE,
			copyApiKey: false,
			copyTemplateId: false,
			copyMandateTemplateId: false,
			copyMandateIdPrefix: false,
		}
	},
	methods: {
		triggerFeedPolling(id) {
			this.clickEvent(id)
			let payload = {
				apiKey: this.lePaymentApiKeyComputed,
				supplierGroupId: this.$store.state.activeSG.supplierGroupId,
				templateIdLegalEntities: {}
			}
			payload.templateIdLegalEntities[this.lePaymentTemplateIdComputed] = this.$store.state.activeLE.entityNumber
			switch (id) {
				case "mandatePolling":
					this.$store.dispatch("globalActions/triggerMandatePolling", payload)
					this.getAlert("Mandate feed polling", "Triggered")
					break
				case "invoicePolling":
					this.$store.dispatch("globalActions/triggerInvoicePolling", payload)
					this.getAlert("Invoice feed polling", "Triggered")
					break
				case "transactionPolling":
					this.$store.dispatch("globalActions/triggerTransactionPolling", payload)
					this.getAlert("Transaction feed polling", "Triggered")
					break
			}
		},
		copy(isFor) {
			switch (isFor) {
				case "apiKey":
					navigator.clipboard.writeText(this.lePaymentApiKeyComputed)
					this.copyApiKey = true
					setTimeout(() => {
						this.copyApiKey = false
					}, 750)
					break
				case "templateId":
					navigator.clipboard.writeText(this.lePaymentTemplateIdComputed)
					this.copyTemplateId = true
					setTimeout(() => {
						this.copyTemplateId = false
					}, 750)
					break
				case "mandateTemplateId":
					navigator.clipboard.writeText(this.leMandateTemplateIdComputed)
					this.copyMandateTemplateId = true
					setTimeout(() => {
						this.copyMandateTemplateId = false
					}, 750)
					break
				case "mandateIdPrefix":
					navigator.clipboard.writeText(this.lePaymentMandateIdPrefixComputed)
					this.copyMandateIdPrefix = true
					setTimeout(() => {
						this.copyMandateIdPrefix = false
					}, 750)
					break
			}
		},
		clickEvent(id) {
			if (!this.bools[id]) this.bools[id] = false
			this.bools[id] = true
			setTimeout(() => {
				this.bools[id] = false
			}, 2000)
		},
		getAlert(title, text) {
			if (this.$store.state.responseStatus === 200) {
				this.$store.commit("toggleAlert", {
					title: title,
					text: text,
				})
			} else {
				this.$store.commit("toggleAlert", {
					title: title,
					text: "ERROR",
				})
			}
		},
	},
	watch: {
		"$store.state.activeLE": {
			handler(data) {
				this.legalEntity = data
			},
			deep: true,
		},
	},
	computed: {
		leShowPayment() {
			return this.paymentProvider === "TWIKEY" && this.lePaymentComputed === true
		},
		canEnable() {
			return !!this.$store.state.activeSG.channelsConfig?.payment
		},
		lePaymentComputed() {
			if (this.legalEntity.legalEntityConfig) {
				return !!this.legalEntity.legalEntityConfig.payment
			}
			return false
		},
		lePaymentApiKeyComputed() {
			if (this.legalEntity.legalEntityConfig?.payment) {
				return this.legalEntity.legalEntityConfig.payment.apiKey
			}
			return ""
		},
		lePaymentTemplateIdComputed() {
			if (this.legalEntity.legalEntityConfig?.payment) {
				return this.legalEntity.legalEntityConfig.payment.templateId
			}
			return ""
		},
		lePaymentMandateIdPrefixComputed() {
			if (this.legalEntity.legalEntityConfig?.payment) {
				return this.legalEntity.legalEntityConfig.payment.mandateIdPrefix
			}
			return ""
		},
		leMandateTemplateIdComputed() {
			if (this.legalEntity.legalEntityConfig && this.legalEntity.legalEntityConfig.payment && this.legalEntity.legalEntityConfig.payment.paymentMethods) {
				return this.legalEntity.legalEntityConfig.payment.paymentMethods[0].templateId
			}
			return ''
		}
	}
}
</script>