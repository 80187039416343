<template>
	<div :class="{'brightness-95': this.$store.state.displayEditMailChannel}" class="box shadow-none col-span-6">
		<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-1/2 mr-12">
					<!--Mail Server Name-->
					<Field
						id="mailServerName"
						title="Mail Server Name:"
						type="text"
						:modelValue="supplierGroup.mailServerName"
						:disabled="true"
						:copySuffix="true"
						:isCopied="copyMailServerName"
						@copy="copy('mailServerName')"
					/>
					<!--Mail Server Token-->
					<Field
						id="mailServerToken"
						title="Mail Server Token:"
						type="text"
						:modelValue="pwdMailToken"
						:disabled="true"
					/>
					<label class="font-bold form-label mb-0 mr-2">Invitation Template:</label>
					<Dropdown
						:data="optionsInvitationTemplate"
						:selected="selectedInvitationTemplate"
						:disabled="true"
						:select-none="true"
						text-select-none="No invitation template"
						style="margin-bottom: 10px"
					/>

					<div class="flex flex-row flex-nowrap">
						<div class="flex flex-col w-1/2">
							<!--Link Channel-->
							<div class="mb-3 flex">
								<label class="font-bold form-label mb-0 mr-2">Link Channel:</label>
								<ToggleSlide
									class="w-min pointer-events-none"
									:key="linkChannelComputed"
									:bool="linkChannelComputed"
									:disabled="true"
								/>
							</div>
							<div v-if="linkChannelComputed">
								<!--Link Send Invitation Mail-->
								<div class="mb-3 ml-6 flex">
									<label class="font-bold form-label mb-0 mr-2">Send Invitation Mail:</label>
									<ToggleSlide
										class="w-min pointer-events-none"
										:key="linkSendInvComputed"
										:bool="linkSendInvComputed"
										:disabled="true"
									/>
								</div>
								<!--Link Create Receiver-->
								<div class="mb-3 ml-6 flex">
									<label class="font-bold form-label mb-0 mr-2">Create Receiver:</label>
									<ToggleSlide
										class="w-min pointer-events-none"
										:key="linkCreateUserComputed"
										:bool="linkCreateUserComputed"
										:disabled="true"
									/>
								</div>

								<div class="mb-3  ml-6 " v-if="linkCreateUserComputed">
									<label class="font-bold form-label">User type:</label>
									<Dropdown
										id="viewUserTypeLink"
										class="pr-2"
										:data="userTypeOptions"
										:selected="userTypeLink"
										:disabled=true
									/>
								</div>
							</div>
						</div>
						<div class="flex flex-col w-1/2">
							<!--Attach Channel-->
							<div class="mb-3 flex">
								<label class="font-bold form-label mb-0 mr-2">Attach Channel:</label>
								<ToggleSlide
									class="w-min pointer-events-none"
									:key="attachChannelComputed"
									:bool="attachChannelComputed"
									:disabled="true"
								/>
							</div>
							<div v-if="attachChannelComputed">
								<!--Attach Send Invitation Mail-->
								<div class="mb-3 ml-6 flex">
									<label class="font-bold form-label mb-0 mr-2">Send Invitation Mail:</label>
									<ToggleSlide
										class="w-min pointer-events-none"
										:key="attachSendInvComputed"
										:bool="attachSendInvComputed"
										:disabled="true"
									/>
								</div>
								<div class="mb-3 ml-6 flex">
									<label class="font-bold form-label mb-0 mr-2">Include UBL:</label>
									<ToggleSlide
										class="w-min pointer-events-none"
										:key="attachIncludeUblComputed"
										:bool="attachIncludeUblComputed"
										:disabled="true"
									/>
								</div>
								<!--Attach Create Receiver-->
								<div class="mb-3 ml-6 flex">
									<label class="font-bold form-label mb-0 mr-2">Create Receiver:</label>
									<ToggleSlide
										class="w-min pointer-events-none"
										:key="attachCreateUserComputed"
										:bool="attachCreateUserComputed"
										:disabled="true"
									/>
								</div>
								<!--Attach Include Ubl-->
								<div class="mb-3  ml-6 " v-if="attachCreateUserComputed">
									<label class="font-bold form-label">User type:</label>
									<Dropdown
										id="viewUserTypeAttach"
										class="pr-2"
										:data="userTypeOptions"
										:selected="userTypeAttach"
										:disabled=true
									/>
								</div>
							</div>
						</div>
					</div>
					<Field
						id="supportEmailAddress"
						title="Support Email Address:"
						type="text"
						:modelValue="supplierGroup.channelsConfig.docImage.configPortal.supportEmail"
						:disabled="true"
						:copySuffix="true"
						:isCopied="copySupportEmail"
						@copy="copy('supportEmail')"
					/>
					<Field
						id="defaultReceiverEmail"
						title="Default Receiver Email Address:"
						type="text"
						:modelValue="supplierGroup.defaultReceiverEmail"
						:disabled="true"
						:copySuffix="true"
						:isCopied="copyDefaultReceiverEmail"
						@copy="copy('defaultReceiverEmail')"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Field from "../Field.vue";
import ToggleSlide from "../ToggleSlide.vue";
import Dropdown from "../Dropdown.vue"

export default {
	name: "ViewMailChannel",
	components: {
		Field,
		ToggleSlide,
		Dropdown
	},
	watch: {
		"$store.state.activeSG": {
			handler(data) {
				this.supplierGroup = data;
			},
			deep: true,
		},
	},
	data() {
		return {
			supplierGroup: this.$store.state.activeSG,
			copyMailServerName: false,
			copySupportEmail: false,
			copyDefaultReceiverEmail: false,
			userTypeOptions: [
				{id: 0, name: "No user type"},
				{id: 1, name: "ReceiverAdmin"},
				{id: 2, name: "ReceiverUser"},
			]
		}
	},
	methods: {
		copy(isFor) {
			switch (isFor) {
				case "mailServerName":
					navigator.clipboard.writeText(this.supplierGroup.mailServerName)
					this.copyMailServerName = true
					setTimeout(() => {
						this.copyMailServerName = false
					}, 750)
					break
				case "supportEmail":
					navigator.clipboard.writeText(this.supplierGroup.channelsConfig.docImage.configPortal.supportEmail)
					this.copySupportEmail = true
					setTimeout(() => {
						this.copySupportEmail = false
					}, 750)
					break
				case "defaultReceiverEmail":
					navigator.clipboard.writeText(this.supplierGroup.defaultReceiverEmail)
					this.copyDefaultReceiverEmail = true
					setTimeout(() => {
						this.copyDefaultReceiverEmail = false
					}, 750)
					break
			}
		}
	},
	computed: {
		pwdMailToken() {
			return this.supplierGroup.mailServerToken.replace(/./g, "*")
		},
		linkChannelComputed() {
			return !!this.supplierGroup.channelsConfig.linkEmail
		},
		optionsInvitationTemplate(){
			let options = []
			if (this.supplierGroup.templates?.mails !== undefined) this.supplierGroup.templates.mails.forEach(mail => options.push({name: mail.name}))
			return options
		},
		selectedInvitationTemplate(){
			return this.supplierGroup.templates?.invitationMailTemplate === undefined ? "No invitation template": this.supplierGroup.templates.invitationMailTemplate
		},
		linkSendInvComputed() {
			if (this.supplierGroup.channelsConfig.linkEmail) {
				return this.supplierGroup.channelsConfig.linkEmail.sendInvitationMail
			}
			return false
		},
		linkCreateUserComputed() {
			if (this.supplierGroup.channelsConfig.linkEmail) {
				return this.supplierGroup.channelsConfig.linkEmail.createReceiverUser
			}
			return false
		},
		userTypeLink() {
			let userType = "No user type";

			if (this.supplierGroup.channelsConfig.linkEmail?.userType) {
				userType = this.supplierGroup.channelsConfig.linkEmail.userType
			}
			return userType
		},

		userTypeAttach() {
			let userType = "No user type";
			if (this.supplierGroup.channelsConfig.attachEmail?.userType) {
				userType = this.supplierGroup.channelsConfig.attachEmail.userType
			}
			return userType
		},
		attachChannelComputed() {
			return !!this.supplierGroup.channelsConfig.attachEmail
		},
		attachSendInvComputed() {
			if (this.supplierGroup.channelsConfig.attachEmail) {
				return this.supplierGroup.channelsConfig.attachEmail.sendInvitationMail
			}
			return false
		},
		attachCreateUserComputed() {
			if (this.supplierGroup.channelsConfig.attachEmail) {
				return this.supplierGroup.channelsConfig.attachEmail.createReceiverUser
			}
			return false
		},
		attachIncludeUblComputed() {
			if (this.supplierGroup.channelsConfig.attachEmail) {
				return this.supplierGroup.channelsConfig.attachEmail.includeUbl
			}
			return false
		}
	}
}
</script>