<template>
  <pop-up-window
    :show="this.$store.state.displayAddLinkFontFile"
    :showClose="false"
    @close="close"
    class="doc-statuses-popup"
    fixed
  >
    <template #header>Add Overlay text</template>
    <template #content>
      <div class="flex flex-row">
        <div class="flex flex-col mb-1 w-1/2">
          <div class="mb-3 mr-3">
            <label for="qrLanguage" class="font-bold form-label"
              >Language:</label
            >
            <Dropdown id="qrLanguage" @update-value="updateSelectedLanguage" :selected="selectedLang" :data="langCode" />
          </div>
          <FileUpload :isLegalEntity="isLegalEntity"
                      @update-value="changeFileValue"
                      accept=".pdf"
                      id="addOverlayFileUpload" title="Choose File: *" :invalid="fileInvalid"
                      :data="selectedFile"/>
          <span v-if="fileInvalid" class="text-primary-3">Select an Overlay file</span>
        </div>
      </div>

      <div class="grid grid-rows-1 justify-items-end">
        <div>
          <button
            class="btn  shadow-md mr-2 "
            @click="add"
          >
            Add
          </button>
          <button
            class="btn  shadow-md mr-2 nymus-orange color-white"
            @click="close"
          >
            Cancel
          </button>
        </div>
      </div>
    </template>
  </pop-up-window>
</template>

<script>
import PopUpWindow from "../../PopUp.vue";
import FileUpload from "../../FileUpload.vue"
import Dropdown from "../../Dropdown.vue";
export default {
  name: "AddLinkFontFile",
  components: {
    PopUpWindow,
    FileUpload,
    Dropdown,
  },
  props: {
    langCode: Array,
    isLegalEntity: Boolean,
  },
  data() {
    return {
      selectedFile: '',
      file: "",
      selectedLang: "AA",
      fileUrl: "",
      fileInvalid: false
    };
  },
  computed: {
    getFileResponse() {
      return this.$store.state.tempFile;
    },
  },
  watch: {
    getFileResponse(data) {
      this.fileUrl = data;
    },
  },
  emits:['add-overlayFile'],
  methods: {
    changeFileValue(event){
      this.selectedFile = event.value
      this.file = event.files[0]
      this.validate()
    },
    updateSelectedLanguage(data) {
      if (data) {
        this.selectedLang = this.langCode[data].name;
      }
    },
    close() {
      this.fileInvalid = false
      this.file = ''
      this.selectedFile = ''
      this.selectedLang = "AA"
      this.$store.state.displayAddLinkFontFile = false;
    },
    async add() {
      let hasErrors = this.validate()
      if(!hasErrors){
      if (this.$store.state.responseStatus === 200) {
        let data = {
          file: this.selectedFile,
          supplierGroup: this.$store.state.activeSG.supplierGroupId,
        };
        await this.$store.dispatch("file/getProxyFile", data);
      }
      let data = {};
      if(this.fileUrl !== ''){
        data[this.selectedLang] = {
        fileName: this.selectedFile,
        url: this.fileUrl,
      };
      }
      this.$emit("add-linkFontFile", data);
      this.close()
      }
      
    },
    validate(){
      let hasErrors = false
      this.fileInvalid = false
      if(!this.file){
        this.fileInvalid = true
        hasErrors = true
      }
      return hasErrors
    }
  },
};
</script>

<style scoped>
</style>
