import store from "@/store";
export default async function updateTemplate(formData, isFor, templateName, templateNameAlert){
	if (isFor === "supplierGroup") {
		let payload = {
			sgId: store.state.activeSG.supplierGroupId,
			edit: templateName,
			body: formData,
		}
		await store.dispatch("EditSupplierGroup", payload);
		if (store.state.responseStatus === 200) {
			await store.commit("toggleAlert", {
				title: "Edit Supplier group",
				text: templateNameAlert + " template successfully edited",
			})
		}
	}

	if (isFor === "legalEntity") {
		let payload = {
			sgId: store.state.activeSG.supplierGroupId,
			leId: store.state.activeLE.entityNumber,
			edit: templateName,
			body: formData,
		};
		await store.dispatch("EditLegalEntity", payload)
		if (store.state.responseStatus === 200) {
			await store.commit("toggleAlert", {
				title: "Edit Legal entity",
				text: templateNameAlert + " template successfully edited",
			})
		}
	}
}