<template>
	<pop-up-window
		:show="this.$store.state.displayEditMailTemplate"
		:showClose="false"
		:blur="showConfirm"
		@close="close"
		fixed
	>
		<template #header>Edit Mail Template</template>
		<template #content>
			<div
				:class="{ 'pointer-events-none': showConfirm }"
				v-if="displayTemplates"
				class="flex flex-col flex-nowrap"
			>
				<div class="flex flex-row flex-wrap">
					<div class="w-1/2">
						<div >
							<p class="font-bold form-label">Select mail template:</p>
							<Dropdown
								@update-value="updateSelectedName"
								class="pr-8"
								:data="mailTemplateOptions"
								:selected="this.$store.state.selectedMailTemplateName"
							/>

						</div>
						<FileUpload
							class="pr-8 mt-3"
							:isLegalEntity="isLegalEntity"
							@update-value="changeBannerValue"
							accept="image/png, image/jpeg"
							id="bannerMailTemplate"
							title="Banner:  *"
							:invalid="bannerInvalid"
							:data="activeTemplate.banner"
						/>
						<span v-if="bannerInvalid">Select a banner</span>
					</div>
					<div class="mb-3 w-1/2 pl-8">
						<label for="editAddressInformation" class="font-bold form-label">
							Address information: *
						</label>
						<textarea
							id="editAddressInformation"
							ref="addressInfo"
							rows="8"
							:class="{ 'border border-primary-3': addressInvalid }"
							@input="validate"
							class="form-control w-full"
							v-model="activeTemplate.address"
						/>
						<span v-if="addressInvalid" class="text-primary-3">
							Address information is required
						</span>
					</div>
				</div>

				<div class="flex flex-row flex-wrap">
					<div class="w-1/2 pr-8">
						<p class="font-bold form-label">Mail doc types:*</p>
						<DropdownSelectAll
							v-if="renderComponent"
							id="editMailTemplateDocTypes"
							@update-value="updateSelectedDocTypes($event, 'docType')"
							:data="docTypes"
							:selected="activeTemplate.docTypes"
							:noSelectAll="true"
						/>
						<span v-if="docTypesInvalid" class="text-primary-3">
							Please, select at least one docType
						</span>
					</div>

					<div class="w-1/2 pl-8">
						<Field
							id="disclaimer"
							type="text"
							title="Disclaimer email:"
							@input="validate"
							v-model="activeTemplate.disclaimerEmail"
							:hasError="disclaimerEmailInvalid"
						/>
						<span v-if="disclaimerEmailInvalid" class="text-primary-3">
							Invalid disclaimer email format
						</span>
					</div>
					<div class="w-1/2 pr-8">
						<Field
							id="senderEmail"
							type="text"
							title="Sender email: *"
							v-model="activeTemplate.senderEmail"
							@input="validate"
							:hasError="senderEmailInvalid"
						/>
						<span v-if="senderEmailInvalid" class="text-primary-3">
							Invalid sender email format
						</span>
					</div>
					<div class="mb-3 w-1/2 pl-8">
						<label
							for="editMailTemplateLanguages"
							class="font-bold form-label">
							Languages:
						</label>
						<DropdownSelectAll
							v-if="renderComponent"
							@update-value="updateSelectedLanguages($event, 'lang')"
							:data="langCodes"
							:selected="activeTemplate.emailLanguages"
							:disabled="false"
							:noSelectAll="true"
						/>
					</div>
					<div class="w-1/2 pr-8">
						<Field
							id="fromName"
							title="From name:"
							type="text"
							v-model="activeTemplate.fromName"
							:disabled="false"
						/>
					</div>
					<div class="mb-3 w-1/2 pl-8">
						<label for="editMailTemplateLanguages" class="font-bold form-label">
							Default Languages:
						</label>
						<Dropdown
							@update-value="updateSelectedDefaultLanguage"
							:data="defaultLanguageOptions"
							:selected="activeTemplate.defaultEmailLanguage"
						/>
					</div>
					<div class="w-1/2 pr-8">
						<Field
							id="replyEmail"
							title="Reply-to-email:"
							type="email"
							@input="replyEmailInvalid = false"
							v-model="activeTemplate.replyEmail"
							:disabled="false"
							:has-error="replyEmailInvalid"
						/>
						<span v-if="replyEmailInvalid" class="text-primary-3">
								Invalid reply to email, please enter a correct email address
							</span>
					</div>

				</div>
			</div>
			<p v-if="!displayTemplates"> No template yet </p>
			<div
				:class="{ 'pointer-events-none': showConfirm }"
				class="grid grid-rows-1 justify-items-end"
			>
				<div>
					<button
						v-if="displayTemplates"
						class="btn shadow-md mr-2"
						@click="update"
					>
						Update
					</button>
					<button
						v-if="displayTemplates"
						class="btn shadow-md bg-theme-1 text-white mr-2"
						@click="deleteTemplate"
					>
						Delete
					</button>
					<button
						class="btn shadow-md mr-2 nymus-orange color-white"
						@click="close"
					>
						Cancel
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
	<ConfirmDialog @close="delTemp" :show="showConfirm"/>
</template>

<script>
import PopUpWindow from "../../PopUp.vue";
import Field from "../../Field.vue";
import FileUpload from "../../FileUpload.vue";
import DropdownSelectAll from "../../DropdownSelectAll.vue";
import ConfirmDialog from "../../ConfirmDialog.vue";
import Dropdown from "../../Dropdown.vue"
import langCodes from "@/langCodes";
import languages from "@/langCodes";

export default {
	name: "EditMailTemplate",
	components: {
		PopUpWindow,
		Field,
		FileUpload,
		DropdownSelectAll,
		ConfirmDialog,
		Dropdown
	},
	props: {
		langCodes: Array,
		isFor: String,
	},
	data() {
		return {
			isLegalEntity: this.isFor === "legalEntity",
			showConfirm: false,
			supplierGroup: this.$store.state.activeSG,
			formData: [],
			activeItem: {},
			mailTemplateOptions: [],
			activeTemplate: {},
			senderEmailInvalid: false,
			addressInvalid: false,
			bannerInvalid: false,
			replyEmailInvalid: false,
			docTypesInvalid: false,
			disclaimerEmailInvalid: false,
			docTypes: this.$store.state.docTypes,
			displayTemplates: false,
			renderComponent: true,
		};
	},
	mounted() {
		this.initData();
	},

	computed: {
		activeSG() {
			return this.$store.state.activeSG
		},

		activeLE() {
			return this.$store.state.activeLE
		},
		defaultLanguageOptions() {
			let defaultLanguages = [...languages]
			if (defaultLanguages[0].name !== 'No default language') {
				defaultLanguages.unshift({name: 'No default language'})
			}
			return defaultLanguages
		},
		templateExist() {
			if (this.isFor === "legalEntity") {
				return this.$store.state.activeLE.templates ? !!this.$store.state.activeLE.templates.mail : false
			} else return this.$store.state.activeSG.templates ? !!this.$store.state.activeSG.templates.mail : false
		},
	},
	watch: {
		activeSG() {
			this.initData()
		},
		activeLE() {
			this.initData()
		}
	},
	methods: {
		forceRerender() {
			// Removing my-component from the DOM
			this.renderComponent = false;

			this.$nextTick(() => {
				// Adding the component back in
				this.renderComponent = true;
			});
		},
		updateSelectedName(data) {
			let foundName
			if (typeof data === 'string') {
				foundName = this.mailTemplateOptions.find((item) => (item.id.toString() === data)).name;
			} else {
				foundName = this.mailTemplateOptions.find((item) => (item.id.toString() === data[0].toString())).name;
			}
			this.setActiveTemplate(foundName);
			this.forceRerender();
		},

		async deleteTemplate() {

			const foundIndex = this.formData.findIndex((template) => template.name === this.activeTemplate.name);
			if (foundIndex !== -1) {
				this.formData.splice(foundIndex, 1);
			}

			const payload = {
				sgId: this.supplierGroup.supplierGroupId,
				edit: 'mail-template',
				body: {mail: this.formData},
			};

			if (this.isFor === 'legalEntity') {
				payload.leId = this.$store.state.activeLE.entityNumber
			}
			await this.$store.dispatch('EditSupplierGroup', payload);

			if (this.$store.state.responseStatus === 200) {
				this.showConfirm = true
				this.$store.commit('toggleAlert', {
					title: 'Edit Suppliergroup',
					text: 'Mail template successfully deleted',
				});
				this.close();
			}
		},

		initData() {
			this.isLegalEntity = this.isFor === "legalEntity",
				this.showConfirm = false;
			if (this.isFor === "supplierGroup") {
				this.activeItem = this.$store.state.activeSG;
			}
			if (this.isFor === "legalEntity") {
				this.activeItem = this.$store.state.activeLE;
			}
			if (this.activeItem.templates && this.activeItem.templates.mail) {
				this.formData = [JSON.parse(JSON.stringify(this.activeItem.templates.mail))]
			}

			if (this.activeItem.templates && this.activeItem.templates.mails) {
				this.formData = JSON.parse(JSON.stringify(this.activeItem.templates.mails));
			}

			this.setEmailTemplateList(this.formData);

			this.setActiveTemplate(this.$store.state.selectedMailTemplateName);

			this.displayTemplates = this.formData.length !== 0;
			this.disclaimerEmailInvalid = false;
			this.bannerInvalid = false;
			this.senderEmailInvalid = false;
			this.addressInvalid = false;

		},
		setEmailTemplateList(mailTemplates) {
			this.mailTemplateOptions = [];
			if (mailTemplates.length !== 0) {
				mailTemplates.forEach((template, index) => {
					this.mailTemplateOptions.push({
						id: index,
						name: template.name
					})
				});
			}
		},

		setActiveTemplate(templateName) {
			this.formData.forEach(template => {
				if (template.name === templateName) {
					this.activeTemplate = JSON.parse(JSON.stringify(template));
				}
			})

			if (!this.activeTemplate.senderEmail) {
				this.activeTemplate.senderEmail = ''
			}
			if (!this.activeTemplate.disclaimerEmail) {
				this.activeTemplate.disclaimerEmail = ''
			}
			if (!this.activeTemplate.address) {
				this.activeTemplate.address = ''
			}
			if (!this.activeTemplate.emailLanguages) {
				this.activeTemplate.emailLanguages = []
			}
			if (!this.activeTemplate.defaultEmailLanguage) {
				this.activeTemplate.defaultEmailLanguage = 'No default language'
			}
			if (!this.activeTemplate.docTypes) {
				this.activeTemplate.docTypes = []
			}
			if (!this.activeTemplate.banner) {
				this.activeTemplate.banner = ''
			}
			if (!this.activeTemplate.replyEmail) this.activeTemplate.replyEmail = ''
			if (!this.activeTemplate.fromName) this.activeTemplate.fromName = ''
		},
		changeBannerValue(event) {
			this.activeTemplate.banner = event.value;
			this.validate()
		},
		updateSelectedLanguages(data, field) {
			if (field === 'lang') {

				this.activeTemplate.emailLanguages = [];
				for (let item of data) {
					this.activeTemplate.emailLanguages.push(this.langCodes[item].name);
				}
			}
		},
		updateSelectedDefaultLanguage(data) {
			if (typeof data === 'string') {
				this.activeTemplate.defaultEmailLanguage = this.defaultLanguageOptions[data].name
			} else this.activeTemplate.defaultEmailLanguage = this.defaultLanguageOptions[data[0]].name
		},
		updateSelectedDocTypes(data, field) {
			if (field === 'docType') {
				this.docTypesInvalid = false
				this.activeTemplate.docTypes = [];
				for (let item of data) {
					this.activeTemplate.docTypes.push(this.docTypes[item].name);
				}
			}
		},
		close() {
			this.initData();
			this.$store.state.displayEditMailTemplate = false;
		},
		async update() {
			let hasErrors = this.validate();
			if (!hasErrors) {
				if (this.activeTemplate.emailLanguages && this.activeTemplate.emailLanguages.length === 0) {
					delete this.activeTemplate.emailLanguages
				}
				if (this.activeTemplate.disclaimerEmail === "") {
					delete this.activeTemplate.disclaimerEmail
				}

				if (this.activeTemplate.defaultEmailLanguage === 'No default language') {
					delete this.activeTemplate.defaultEmailLanguage
				}
				if (this.activeTemplate.replyEmail.trim() === '') {
					delete this.activeTemplate.replyEmail
				}
				if (this.activeTemplate.fromName.trim() === '') {
					delete this.activeTemplate.fromName
				}
				let foundIndex = -1;

				this.formData.forEach((template, index) => {
					if (template.name === this.activeTemplate.name) {
						foundIndex = index;
					}
				})

				this.formData[foundIndex] = this.activeTemplate;

				if (this.isFor === "supplierGroup") {
					let payload = {
						sgId: this.supplierGroup.supplierGroupId,
						edit: "mail-template",
						body: {mail: this.formData},
					};
					await this.$store.dispatch("EditSupplierGroup", payload);
					if (this.$store.state.responseStatus === 200) {
						this.$store.commit("toggleAlert", {
							title: "Edit Suppliergroup",
							text: "Mail template successfully edited",
						});
					}
				}
				if (this.isFor === "legalEntity") {
					let payload = {
						sgId: this.$store.state.activeSG.supplierGroupId,
						leId: this.$store.state.activeLE.entityNumber,
						edit: "mail-template",
						body: {mail: this.formData},
					};
					await this.$store.dispatch("EditLegalEntity", payload);
					if (this.$store.state.responseStatus === 200) {
						this.$store.commit("toggleAlert", {
							title: "Edit Legal entity",
							text: "Mail template successfully edited",
						});
					}
				}
				//    this.close();
			}
		},
		async delTemp(data) {
			if (data === "Yes") {
				let foundIndex = -1;
				this.formData.forEach((template, index) => {
					if (template.name === this.activeTemplate.name) {
						foundIndex = index
					}
				})
				this.formData.splice(foundIndex, 1);
				let requestData = {}
				if (this.formData.length === 0) {
					requestData = {mail: null}
				} else {
					requestData = {mail: this.formData}
				}

				if (this.isFor === "supplierGroup") {
					let payload = {
						sgId: this.supplierGroup.supplierGroupId,
						edit: "mail-template",
						body: requestData,
					};
					await this.$store.dispatch("EditSupplierGroup", payload);
				}
				if (this.isFor === "legalEntity") {

					let payload = {
						sgId: this.$store.state.activeSG.supplierGroupId,
						leId: this.$store.state.activeLE.entityNumber,
						edit: "mail-template",
						body: requestData,
					};
					await this.$store.dispatch("EditLegalEntity", payload);
				}
				this.close();
			}
			this.showConfirm = false;
		},
		validate() {
			this.bannerInvalid = false;
			this.senderEmailInvalid = false;
			this.addressInvalid = false;
			this.disclaimerEmailInvalid = false;
			let hasErrors = false;
			if (this.activeTemplate.banner === "") {
				this.bannerInvalid = true;
				hasErrors = true;
			}
			if (this.activeTemplate.docTypes.length === 0) {
				this.docTypesInvalid = true
				hasErrors = true
			}
			if (!this.$store.state.emailRegex.test(this.activeTemplate.senderEmail)) {
				this.senderEmailInvalid = true;
				hasErrors = true;
			}
			if (this.activeTemplate.replyEmail.trim() !== '' && !this.$store.state.emailRegex.test(this.activeTemplate.replyEmail)) {
				this.replyEmailInvalid = true
				hasErrors = true
			}
			if (this.activeTemplate.senderEmail === "") {
				this.senderEmailInvalid = true;
				hasErrors = true;
			}
			if (this.activeTemplate.disclaimerEmail !== "") {
				if (
					!this.$store.state.emailRegex.test(this.activeTemplate.disclaimerEmail)
				) {
					this.disclaimerEmailInvalid = true;
					hasErrors = true;
				}
			}
			if (this.activeTemplate.address === "") {
				this.addressInvalid = true;
				hasErrors = true;
			}
			return hasErrors;
		},
	},
};
</script>
