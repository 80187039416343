<template>
	<pop-up-window
		:show="showDialog"
		:showClose=false
		class="confirmation"
		:positionFixed=true
		:top0=true
		fixed
		width20
	>
		<template #header>
			<h1 class="text-2xl ">{{ title }} </h1>
		</template>
		<template #content>
			<h1 class="text-center">
				<i class="icon-notification info-color pr-2"></i>{{ description }}
			</h1>
			<div class="grid grid-rows-1 justify-items-center mt-3">
				<div>
					<button class="btn shadow-md mr-2" value="Yes" @click="approveAction">Yes</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" value="No" @click="cancelAction">No</button>
				</div>
			</div>
		</template>
	</pop-up-window>

</template>

<script>

import PopUpWindow from "./PopUp.vue"

export default {
	name: "ConfirmationWithProps",
	components: {PopUpWindow},
	emits: ['approveAction', 'cancelAction'],
	props: {
		showDialog: {
			type: Boolean,
			required: true
		},
		description: {
			type: String,
			required: true
		},
		title: {
			type: String,
			required: false,
			default: "Confirm"
		}
	},

	methods: {
		approveAction() {
			this.$emit('approveAction')
		},
		cancelAction() {
			this.$emit("cancelAction")
		},
	}
}
</script>

<style scoped>
.confirmation {
	z-index: 300;
}

</style>
