<template>
    <pop-up-window
          :show="this.$store.state.displayAddEmail"
          :showClose="false"
          @close="close"
          class="doc-statuses-popup"
          fixed
    >
        <template #header>Add email</template>
        <template #content>
            <div class="flex flex-row">
                <div class="flex flex-col mb-1 w-1/2">
                    <div class="mb-3 mr-3">
                        <label for="receiverMailLanguage" class="font-bold form-label"
                        >Language: *</label
                        >
                        <Dropdown
                              id="receiverMailLanguage"
                              @update-value="updateSelectedLanguage"
                              :data="languages"
                              :selected="languages[0].name"
                        />
                    </div>
                    <Field
                          id="receiverMailEmail"
                          title="Email: *"
                          :hasError="emailInvalid"
                          @input="validate"
                          type="text"
                          v-model="formData.email"
                    />
                    <span v-if="emailInvalid" class="text-primary-3">Email must be a valid email</span>
                </div>
            </div>

            <div class="grid grid-rows-1 justify-items-end">
                <div>
                    <button class="btn shadow-md mr-2" @click="add">Add</button>
                    <button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </pop-up-window>
</template>

<script>
import PopUpWindow from "../PopUp.vue";
import Field from "../Field.vue";
import Dropdown from "../Dropdown.vue";
export default {
    name: "AddEmail",
    components: {
        PopUpWindow,
        Field,
        Dropdown,
    },
    props: {
        languages: Array,
        emailAdded : Boolean,
    },
    data() {
        return {
            emailInvalid: false,
            formData: {
                language: "AA",
                email: "",
            },
        };
    },
    emits: ["add-receiverMail"],
    watch:{
        emailAdded(newVal){
            if (newVal) {
                this.close();
            }
        }
    },
    methods: {
        updateSelectedLanguage(data) {
            if (data) {
                this.formData.language = this.languages[data].name;
            }
        },
        close() {
            this.formData.language = "AA"
            this.formData.email = ""
            this.emailInvalid = false;
            this.$store.state.displayAddEmail = false;
        },
        add() {

            let hasErrors = this.validate();
            if (!hasErrors) {
                this.$emit("add-receiverMail", this.formData);
               // this.close();
            }
        },
        validate() {
            let hasErrors = false;
            this.emailInvalid = false;
            if (this.formData.email === "") {
                this.emailInvalid = true;
                hasErrors = true;
            }
            if (!this.$store.state.emailRegex.test(this.formData.email)) {
                hasErrors = true;
                this.emailInvalid = true;
            }
            return hasErrors;
        },
    },
};
</script>

<style scoped>
</style>
