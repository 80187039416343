<template>
	<pop-up-window
		:show="this.$store.state.displayAddPreingestionTemplate"
		:showClose="false"
		:top10="true"
		:positionFixed="true"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Add pre-ingestion template</template>
		<template #content>
			<div class="form-container">
				<div>
					<Field
						title="Pre-Ingestion template name:*"
						v-model="formData.name"
						type="text"
						@click="cleanError('name')"
					/>
					<div>
						<span v-if="errors.name" class="text-primary-3">
							Please, enter the template name
						</span>
					</div>
					<Field
						title="Pre-Ingestion template version:"
						v-model="formData.version"
						type="text"
					/>
					<label for="addPreingestionDocType">DocType:*</label>
					<DropdownSelectAll
						id="addPreingestionDocType"
						@update-value="updateDocTypes"
						:data="docTypes"
					/>
					<div>
						<span v-if="errors.docTypes" class="text-primary-3">
							Please, select at least one doc type
						</span>
					</div>
				</div>
				<div>
					<div class="flex mb-1">
						<p class="font-bold form-label my-0">Base file:*</p>
						<button
							@click="showDialogAddFile = true"
							class="btn bg-theme-1 py-0 text-white shadow-md ml-2"
						>
							Add
						</button>
						<add-file-to-template
							title-dialog="Add pre-ingestion base file"
							:isLegalEntity="isLegalEntity"
							@add-file="addFile($event, 'baseFile')"
							@cancel-add-file="showDialogAddFile = false"
							:langCode="langCodes"
							:show-dialog="showDialogAddFile"
						/>
					</div>
					<div>
						<span v-if="errors.baseFile" class="text-primary-3">
							Please, select at least one base file
						</span>
					</div>
					<div
						:key="index + item"
						v-for="(item, index) in formData.baseFile"
						class="flex flex-col"
					>
						<div class="flex flex-col">
							<FileUpload
								:isLegalEntity="isLegalEntity"
								:class=index
								@update-value="changeFileLocation($event, item, index, 'baseFile')"
								@delete-value="deleteFile($event, item, index, 'baseFile')"
								accept=".pdf"
								:prefix="true"
								:prefixData="index"
								:delSuffix="true"
								:data="item.fileName"
								:id="  'templatesEdit' + item.fileName"
							/>
						</div>
					</div>
					<div class="flex mb-1">
						<p class="font-bold form-label my-0">Template JSON:*</p>
						<button
							@click="showDialogAddJsonFile = true"
							class="btn bg-theme-1 py-0 text-white shadow-md ml-2"
						>
							Add
						</button>
						<add-file-to-template
							title-dialog="Add template JSON"
							:isLegalEntity="isLegalEntity"
							@add-file="addFile($event, 'templateJson')"
							@cancel-add-file="showDialogAddJsonFile = false"
							:langCode="langCodes"
							:show-dialog="showDialogAddJsonFile"
							file-type=".json"
						/>
					</div>
					<div>
                         <span v-if="errors.templateJson" class="text-primary-3">
                             Please, select at least one template JSON
                         </span>
					</div>
					<div
						:key="index + item"
						v-for="(item, index) in formData.templateJson"
						class="flex flex-col"
					>
						<div class="flex flex-col">
							<FileUpload
								:isLegalEntity="isLegalEntity"
								:class=index
								@update-value="changeFileLocation($event, item, index, 'templateJson')"
								@delete-value="deleteFile($event, item, index, 'templateJson')"
								accept=".json"
								:prefix="true"
								:prefixData="index"
								:delSuffix="true"
								:data="item.fileName"
								:id="  'templatesEdit' + item.fileName"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md margin-right" @click="create">Create</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../../PopUp.vue";
import Field from "../../Field.vue";
import DropdownSelectAll from "../../DropdownSelectAll.vue";
import langCodes from '../../../langCodes.js';
import FileUpload from "../../FileUpload.vue";
import AddFileToTemplate from "@/components/templates/AddFileToTemplate.vue";

export default {
	name: "AddPreingestionTemplate",
	components: {
		AddFileToTemplate,
		PopUpWindow,
		Field,
		DropdownSelectAll,
		FileUpload
	},
	props: {
		isFor: String,
	},
	mounted() {
		this.initData();
	},
	data() {
		return {
			langCodes: langCodes,
			isLegalEntity: this.isFor === "legalEntity",
			docTypes: this.$store.state.docTypes,
			formData: {
				name: '',
				version: '',
				docTypes: [],
				baseFile: {},
				templateJson: {}
			},
			activeItem: {},
			errors: {
				name: false,
				docTypes: false,
				baseFile: false,
				templateJson: false,
			},

			showDialogAddFile: false,
			showDialogAddJsonFile: false
		}
	},
	computed: {
		activeSG() {
			return this.$store.state.activeSG;
		},
		activeLE() {
			return this.$store.state.activeLE;
		},
	},
	methods: {
		cleanError(field) {
			this.errors[field] = false;
		},
		initData() {
			if (this.isFor === "supplierGroup") this.activeItem = this.$store.state.activeSG;
			if (this.isFor === "legalEntity") this.activeItem = this.$store.state.activeLE;

			this.formData = {
				name: '',
				version: '',
				docTypes: [],
				baseFile: {},
				templateJson: {}
			};
			this.errors = {
				docTypes: false,
				baseFile: false,
				templateJson: false,
			}
		},
		async create() {
			if (this.formData.name === '') {
				this.errors.name = true;
				return
			}
			if (this.formData.docTypes.length === 0) {
				this.errors.docTypes = true;
				return
			}
			if (Object.keys(this.formData.baseFile).length === 0) {
				this.errors.baseFile = true;
				return
			}
			if (Object.keys(this.formData.templateJson).length === 0) {
				this.errors.templateJson = true;
				return
			}

			let formData = []
			if (this.isFor === "legalEntity" && this.activeItem.templates && this.activeItem.templates.textOverlayTemplates) {
				formData = this.$store.state.activeLE.templates.textOverlayTemplates
			}
			if (this.isFor === "supplierGroup" && this.activeItem.templates && this.activeItem.templates.textOverlayTemplates) {
				formData = this.$store.state.activeSG.templates.textOverlayTemplates
			}

			formData.push(this.formData);

			let requestData = {textOverlay: formData};
			let payload = {
				isFor: this.isFor,
				sgId: this.activeItem.supplierGroupId,
				formData: requestData
			}

			if (this.isFor === "legalEntity") {
				payload.legalEntityNumber = this.activeItem.entityNumber
				payload.sgId = this.$store.state.activeSG.supplierGroupId
			}

			await this.$store.dispatch("editPreIngestionTemplate", payload)
			this.close()
		},
		close() {
			this.$store.state.displayAddPreingestionTemplate = false
			this.initData()
		},
		deleteFile(event, item, index, field) {
			delete this.formData[field][index]
		},
		changeFileLocation(event, item, index, field) {
			setTimeout(() => {

				this.formData[field][index].url = this.$store.state.uploadedFileUrl
			}, 400)
			this.formData[field][index].fileName = event.files[0].name
		},
		addFile(data, field) {
			this.cleanError(field)
			this.formData[field] = {
				...this.formData[field],
				...data,
			};
			this.showDialogAddFile = false
			this.showDialogAddJsonFile = false
		},
		updateDocTypes(docTypes) {
			this.cleanError('docTypes')

			this.errors.docTypes = false

			let foundArr = []
			for (let index of docTypes) {
				let found = this.docTypes[index].name
				foundArr.push(found)
			}
			this.formData.docTypes = foundArr
		},
	}
}
</script>

<style scoped>
.form-container {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	margin-top: 20px;
}

.form-container > div:first-child {
	margin-right: 20px;
}

.form-container > div:last-child {
	margin-left: 20px;
}

label {
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.margin-right {
	margin-right: 10px;
}
</style>