<template>
	<pop-up-window
		:show="this.$store.state.displayAddInsertsTemplate && !this.$store.state.displayAddInsertsFile"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Add inserts template</template>
		<template #content>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-full">
					<div class="p-3 grid grid-cols-2 ">
						<div class="mr-2">
							<div class="mb-3">
								<label for="addInsertsName" class="font-bold form-label">Name: *</label>
								<input
									id="addInsertsName"
									v-model="name"
									:class="{'border border-primary-3': nameInvalid}"
									type="text"
									class="form-control w-full"
								/>
								<span v-if="nameInvalid" class="text-primary-3">Name is required</span>
							</div>

							<div>
								<div class="mb-2">
									<label for="resendDocType" class="font-bold">DocType:*</label>
								</div>

								<DropdownSelectAll
									v-if="renderComponent"
									id="resendDocType"
									@update-value="updateDocTypes"
									:data="docTypeOptions"
									:selected="docTypes"
									:disabled="false"
									:noSelectAll="true"
								/>
								<div>
                            <span v-if="docTypesError" class="text-primary-3">
                                Please, select at least one docType
                            </span>
								</div>
							</div>
						</div>

						<div class="ml-2">
							<div class="flex mb-1">
								<p class="font-bold form-label my-0">Insert file(s):*</p>
								<button
									@click="displayAddInsertsFile"
									class="btn bg-theme-1 py-0 text-white shadow-md  ml-2"
								>
									Upload
								</button>
							</div>
							<div
								:key="index"
								v-for="(item, index) in Object.keys(locations)"
								class="flex flex-row mb-1"
							>
								<div class="flex flex-col w-2/3">
									<FileUpload
										:isLegalEntity="isLegalEntity"
										@update-value="changePdfLocation($event, item)"
										@delete-value="deleteFile($event, item)"
										accept=".pdf"
										:prefix="true"
										:prefixData="item"
										:delSuffix="true"
										:data="locations[item].fileName"
										:id="'templatesAdd' + item + locations[item].fileName"
									/>
								</div>
								<div class="flex flex-col w-1/3">
									<div class="input-group mb-1 w-full">
										<input
											type="text"
											class="form-control"
											:value="locations[item].description"
										/>
									</div>
								</div>
							</div>
							<span v-if="locationsInvalid" class="text-primary-3">At least 1 file is required</span>
						</div>
					</div>
				</div>
			</div>
			<toggle-options-insert-template
				@toggle-slides-changed="toggleSlidesChanged"
				:incoming-archive-config="archiveConfig"
				:incoming-merge="comingFromView ? false: merge"
				:incoming-add-to-print="comingFromView ? false : addToPrint"
			></toggle-options-insert-template>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button
						class="btn  shadow-md mr-2 "
						@click="add() && updateTemplate()"
					>
						Add Template
					</button>
					<button
						class="btn  shadow-md mr-2 nymus-orange color-white"
						@click="close"
					>
						Cancel
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
	<add-file-to-template
		title-dialog="Add inserts template"
		:show-dialog="displayAddFile"
		:show-description="true"
		:isLegalEntity="this.isLegalEntity"
		@add-file="addFile"
		@cancel-add-file="locationsInvalid = false; this.displayAddFile = false"
		:langCode="langCode"
	></add-file-to-template>
</template>
<script>
import PopUpWindow from "../../PopUp.vue"
import FileUpload from "../../FileUpload.vue"
import DropdownSelectAll from "../../DropdownSelectAll.vue"
import ToggleOptionsInsertTemplate from "@/components/templates/inserts/toggleOptionsInsertTemplate.vue"
import AddFileToTemplate from "@/components/templates/AddFileToTemplate.vue"

export default {
	name: "AddInsertsTemplate",
	components: {
		AddFileToTemplate,
		ToggleOptionsInsertTemplate,
		PopUpWindow,
		FileUpload,
		DropdownSelectAll
	},
	props: {
		langCode: Array,
		supplierGroup: Object,
		isLegalEntity: Boolean,
	},
	mounted() {
		this.comingFromView = false
	},
	data() {
		return {
			name: '',
			docTypes: [],
			locations: [],
			selectedLang: "",
			fileUrl: "",
			locationsInvalid: false,
			nameInvalid: false,
			docTypesError: false,
			docTypeOptions: this.$store.state.docTypes,
			renderComponent: true,
			addToPrint: false,
			archiveConfig: false,
			merge: false,
			comingFromView: true,
			displayAddFile: false
		}
	},
	computed: {
		getFileResponse() {
			return this.$store.state.tempFile
		},
		getAddedFileResponse() {
			return this.$store.state.addedTemplateInsertsFile
		}
	},
	watch: {
		getFileResponse(data) {
			this.fileUrl = data
		},
		getAddedFileResponse(data) {
			this.addFile(data)
		},
	},
	emits: ['add-template'],
	inject: ['updateTemplate'],
	methods: {
		initData() {
			this.name = ''
			this.docTypes = []
			this.locations = []
			this.selectedLang = ""
			this.fileUrl = ""
			this.locationsInvalid = false
			this.nameInvalid = false
			this.docTypesError = false
			this.merge = false
			this.addToPrint = false
		},
		addFile(data) {
			this.locations = {
				...this.locations,
				...data,
			}
			this.locationsInvalid = false
			this.displayAddFile = false
		},
		displayAddInsertsFile() {
			this.$store.state.parentInsertsFile = "Template"
			this.displayAddFile = true
		},
		async add() {
			let archivedDocumentOptions
			if (!this.archiveConfig) archivedDocumentOptions = null
			else {
				archivedDocumentOptions = {
					merge: this.merge
				}
			}
			let hasErrors = this.validate()
			if (!hasErrors) {
				let data = {
					name: this.name,
					location: this.locations,
					docTypes: this.docTypes,
					addToPrint: this.addToPrint,
					archivedDocumentOptions: archivedDocumentOptions
				}
				this.$emit("add-template", data)
				this.close()
			}
		},
		updateDocTypes(docTypes) {
			let foundArr = []
			for (let index of docTypes) {
				let found = this.docTypeOptions[index].name
				foundArr.push(found)
			}
			this.docTypes = foundArr
			this.docTypesError = false
		},
		toggleSlidesChanged(data) {
			if (data.addToPrint === undefined) this.addToPrint = false
			else this.addToPrint = data.addToPrint

			if (data.archiveConfig === undefined) this.archiveConfig = false
			else this.archiveConfig = data.archiveConfig

			if (data.archiveConfig && data.merge) {
				this.merge = data.merge
			}
		},
		deleteFile(event, item) {
			delete this.locations[item]
		},
		async changePdfLocation(event, item) {
			await this.addToBucket(event.files[0])
			this.locations[item].fileName = event.value
			this.locations[item].url = this.fileUrl
		},
		close() {
			this.initData()
			this.nameInvalid = false
			this.locationsInvalid = false
			this.$store.state.displayAddInsertsTemplate = false
			this.archiveConfig = false
			this.merge = false
			this.addToPrint = false
		},
		validate() {
			this.locationsInvalid = false
			this.nameInvalid = false
			let hasErrors = false
			if (Object.values(this.locations).length === 0) {
				this.locationsInvalid = true
				hasErrors = true
			}
			if (this.name === '') {
				hasErrors = true
				this.nameInvalid = true
			}
			if (this.docTypes.length === 0) {
				hasErrors = true
				this.docTypesError = true
			}
			return hasErrors
		}
	}
}
</script>