<template>
	<pop-up-window
		:show="this.$store.state.displayEditIngestion && !this.$store.state.displayAddXsd"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup "
		fixed
	>
		<template #header>Edit Ingestion</template>
		<template #content>
			<div class="flex flex-row flex-nowrap">
				<!--SFTP-->
				<div class="flex flex-col w-1/2 mr-12">
					<p class="text-lg font-bold mt-3 mb-2">SFTP</p>
					<Field
						id="ftpUsername"
						type="text"
						title="FTP username:"
						:modelValue="supplierGroup.sender.sFTP.ftp_username"
						:disabled="true"
					/>

					<div class="mb-3">
						<label class="font-bold form-label">ingestion interval:</label>
						<Dropdown
							id="ingestionInterval"
							@update-value="updateSelectedIngestion"
							:data="ingestionData"
							:selected="selectedIngestion"
						/>
					</div>
					<Field
						id="locateFileTimeout"
						type="number"
						title="Number of tries to process attachments:"
						v-model.number="formData.locateFileTimeout"
					/>
					<div>
					<!--XSD Files-->
					<div class="flex flex-col">
						<div class="flex flex-row">
							<p class="text-lg font-bold mr-2">XSD Files *</p>
							<button @click="displayAddXsd" class="btn bg-theme-1 py-0 text-white shadow-md mr-2">
								Add
							</button>
						</div>
						<div
							:key="item"
							v-for="(item, index) in formData.xmlConfig.xsdFiles"
							class="flex flex-row flex-nowrap"
						>
							<div class="mb-3 mr-3 w-3/12">
								<label class="font-bold form-label">Type:</label>
								<Dropdown
									id="editXsdType"
									@update-value="updateSelectedXsdType($event, item)"
									:data="xsdData"
									:selected="item.xsdType"
								/>
							</div>
							<div class="mb-3 w-9/12">
								<FileUpload
									@update-value="changeXSD($event, item)"
									@delete-value="deleteXSD($event, item.xsdType)"
									accept=".xsd"
									:id="index + item.xsdType + item.xsdLocation"
									title="File:"
									:data="item.xsdLocation"
									:delSuffix="true"
								/>
							</div>
						</div>
						<span v-if="xsdInvalid" class="text-primary-3">Requires atleast 1 XSD file</span>
					</div>
				</div>
				</div>

				<!--API Configuration-->
				<div class="flex flex-col w-1/2">
					<div class="mb-3 flex">
						<label class="font-bold text-lg form-label mb-0 mr-2">Dynamic attachments:</label>
						<ToggleSlide
							class="w-min"
							@update-value="changeDynamicAttachmentsBool"
							:key=" dynamicAttachments"
							:bool="dynamicAttachments"
						/>
					</div>
					<div class="mb-3 flex">
						<label class="font-bold text-lg form-label mb-0 mr-2">Negative creditnote:</label>
						<ToggleSlide
							class="w-min"
							@update-value="this.formData.creditNoteNegative = !this.formData.creditNoteNegative"
							:key="formData.creditNoteNegative "
							:bool="formData.creditNoteNegative"
						/>
					</div>
					<div class="mb-3 flex">
						<label class="font-bold text-lg form-label mb-0 mr-2">Add zero tax segments:</label>
						<ToggleSlide
							class="w-min"
							@update-value="this.formData.addZeroTaxSegments = !this.formData.addZeroTaxSegments"
							:key="formData.addZeroTaxSegments "
							:bool="formData.addZeroTaxSegments"
						/>
					</div>
					<div class="mb-3 flex">
						<label class="font-bold text-lg form-label mb-0 mr-2">Hold release:</label>
						<ToggleSlide
							class="w-min"
							@update-value="changeHoldReleaseBool"
							:key="formData.holdRelease"
							:bool="formData.holdRelease"
						/>
					</div>
					<div class="mb-3 flex">
						<label class="font-bold text-lg form-label mb-0 mr-2">API configuration:</label>
						<ToggleSlide
							class="w-min"
							@update-value="changeApiConfigBool"
							:key="apiConfigBool"
							:bool="apiConfigBool"
						/>
					</div>
					<div v-if="apiConfigBool">
						<div class="ml-6">
							<label class="font-bold" for="editApiKey">API key: *</label>
							<div
								:class="{ 'border border-primary-3': apiKeyInvalid }"
								class="input-group mb-3"
							>
								<input
									id="editApiKey"
									type="text"
									@input="validate"
									class="form-control"
									v-model="formData.apiConfig.apiKey"
								/>
								<div
									@click="generateKey"
									class="font-bold cursor-pointer input-group-text"
								>
									<font-awesome-icon icon="gears"/>
								</div>
							</div>
							<span v-if="apiKeyInvalid" class="text-primary-3">API key is required</span>
						</div>
						<div class="mb-3 ml-6 flex">
							<label class="font-bold form-label mb-0 mr-2">Create LE:</label>
							<ToggleSlide
								@update-value="updateCreateLeBool"
								class="w-min"
								:bool="formData.apiConfig.manageLegalEntities"
							/>
						</div>
						<div class="mb-3 ml-6 flex">
							<label for="editIngestionValidateUbl" class="font-bold form-label mb-0 mr-2">
								Validate UBL:
							</label>
							<ToggleSlide
								@update-value="updateValidateUbl"
								class="w-min"
								:bool="formData.apiConfig.validateUbl"
							/>
						</div>
						<div class="mb-3 ml-6 flex">
							<label class="font-bold form-label mb-0 mr-2">Public link:</label>
							<ToggleSlide
								@update-value="updatePublicLinkBool"
								class="w-min"
								:bool="formData.apiConfig.publicLinks"
							/>
						</div>
						<div class="mb-3 ml-6 flex">
							<label class="font-bold form-label mb-0 mr-2">Ingestion:</label>
							<ToggleSlide
								@update-value="updateIngestionBool"
								class="w-min"
								:bool="formData.apiConfig.ingestion"
							/>
						</div>
						<div class="mb-3 ml-6 flex">
							<label class="font-bold form-label mb-0 mr-2">Manage payments:</label>
							<ToggleSlide
								@update-value="updateManagePaymentsBool"
								class="w-min"
								:bool="formData.apiConfig.managePayments"
							/>
						</div>
						<div class="mb-3 ml-6 flex">
							<label class="font-bold form-label mb-0 mr-2">Manage users:</label>
							<ToggleSlide
								@update-value="updateManageUsersBool"
								class="w-min"
								:bool="formData.apiConfig.manageUsers"
							/>
						</div>
						<div class="mb-3 ml-6 flex">
							<label class="font-bold text-lg form-label mb-0 mr-2">Webhook config:</label>
							<ToggleSlide
								class="w-min"
								@update-value="changeWebhookBool"
								:key="webhookConfigBool"
								:bool="webhookConfigBool"
							/>
						</div>
						<div v-if="webhookConfigBool" class="ml-12">
							<Field
								id="webhookUrl"
								:hasError="webhookUrlInvalid"
								@input="validate"
								type="text"
								placeholder="https://example.com"
								title="URL: *"
								v-model="formData.apiConfig.webhookConfig.url"
							/>
							<span v-if="webhookUrlInvalid" class="text-primary-3">Webhook URL must be a valid URL</span>
							<div class="mb-3 flex">
								<label class="font-bold form-label mb-0 mr-2">Errors:</label>
								<ToggleSlide
									@update-value="updatewebhookErrorBool"
									class="w-min"
									:bool="formData.apiConfig.webhookConfig.errors"
								/>
							</div>
							<div class="mb-3 flex">
								<label class="font-bold form-label mb-0 mr-2">Notifications:</label>
								<ToggleSlide
									@update-value="updatewebhookNotificationBool"
									class="w-min"
									:bool="formData.apiConfig.webhookConfig.notifications"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
	<AddXsdFiles
		@add-xsd="addXsd"
		:supplierGroup="supplierGroup"
		:xsdData="xsdData"
	/>
</template>

<script>
import PopUpWindow from "../PopUp.vue"
import Field from "../Field.vue"
import FileUpload from "../FileUpload.vue"
import Dropdown from "../Dropdown.vue"
import ToggleSlide from "../ToggleSlide.vue"
import AddXsdFiles from "../AddSupplierGroup/AddXsdFiles.vue"
import {v4 as uuidv4} from 'uuid'

export default {
	name: "EditIngestion",
	components: {
		PopUpWindow,
		Field,
		FileUpload,
		ToggleSlide,
		Dropdown,
		AddXsdFiles,
	},
	props: {
		ingestionData: Array,
	},
	computed: {
		activeSG() {
			this.setApiConfigBool()
			return this.$store.state.activeSG
		},
		getXsdFiles() {
			return this.$store.state.xsdFiles
		},
	},
	watch: {
		activeSG() {
			this.setApiConfigBool()
			this.initData()
		},
		getXsdFiles(data) {
			this.formData.xmlConfig.xsdFiles = data
		},
	},

	data() {
		return {
			supplierGroup: this.$store.state.activeSG,
			apiConfigBool: true,

			dynamicAttachments: this.$store.state.activeSG.storageConfig?.dynamicAttachments
					? this.$store.state.activeSG.storageConfig.dynamicAttachments : false,
			webhookConfigBool: !!this.$store.state.activeSG.apiConfig?.webhookConfig,
			formData: {
				ingestionInterval: this.$store.state.activeSG.storageConfig.ingestionInterval,
				locateFileTimeout: this.$store.state.activeSG.storageConfig.locateFileTimeout,
				holdRelease: this.$store.state.activeSG.storageConfig.holdRelease ? this.$store.state.activeSG.storageConfig.holdRelease : false,
				creditNoteNegative: this.$store.state.activeSG.storageConfig.creditNoteNegative ?  this.$store.state.activeSG.storageConfig.creditNoteNegative : false,
				addZeroTaxSegments: this.$store.state.activeSG.storageConfig.addZeroTaxSegments ?  this.$store.state.activeSG.storageConfig.addZeroTaxSegments : false,
				apiConfig: {
					apiKey: this.$store.state.activeSG.apiConfig
						? this.$store.state.activeSG.apiConfig.apiKey
						: "",
					manageLegalEntities: !!this.$store.state.activeSG.apiConfig?.manageLegalEntities,
					ingestion: this.$store.state.activeSG.apiConfig ? this.$store.state.activeSG.apiConfig.ingestion : false,
					managePayments: this.$store.state.activeSG.apiConfig ? this.$store.state.activeSG.apiConfig.managePayments : false,
					validateUbl: this.$store.state.activeSG.apiConfig ? this.$store.state.activeSG.apiConfig.validateUbl : false,
					manageUsers: this.$store.state.activeSG.apiConfig ? this.$store.state.activeSG.apiConfig.manageUsers : false,
					publicLinks: this.$store.state.activeSG.apiConfig ? this.$store.state.activeSG.apiConfig.publicLinks : false,
					webhookConfig: {
						url: this.$store.state.activeSG.apiConfig?.webhookConfig
							? this.$store.state.activeSG.apiConfig.webhookConfig.url
							: "",
						errors: this.$store.state.activeSG.apiConfig?.webhookConfig
							? this.$store.state.activeSG.apiConfig.webhookConfig.errors
							: false,
						notifications: this.$store.state.activeSG.apiConfig?.webhookConfig
							? this.$store.state.activeSG.apiConfig?.webhookConfig.notifications : false,
					},
				},
				xmlConfig: {
					xsdFiles: this.$store.state.xsdFiles,
				},
			},
			ftpPassword: this.$store.state.activeSG.sender.sFTP.ftp_password,
			selectedIngestion: "",
			xsdInvalid: false,
			apiKeyInvalid: false,
			ftpPasswordInvalid: false,
			webhookUrlInvalid: false,
			xsdData: [
				{id: 0, name: "BILLING"},
				{id: 1, name: "GENERAL"},
				{id: 2, name: "SALARY"},
			],
			allCapsAlpha: [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"],
			allLowerAlpha: [..."abcdefghijklmnopqrstuvwxyz"],
			allUniqueChars: [..."@%~!#$()_+-[]{}:?"],
			allNumbers: [..."0123456789"],
		}
	},
	mounted() {
		this.initIngestionInterval(this.formData.ingestionInterval)
	},
	methods: {
		setApiConfigBool() {
			if (this.$store.state.activeSG.apiConfig) {
				this.apiConfigBool = this.$store.state.activeSG.apiConfig.apiKey !== ''
			} else {
				this.apiConfigBool = false
			}
		},
		initData() {
			this.ftpPassword = this.$store.state.activeSG.sender.sFTP.ftp_password
			this.xsdInvalid = false
			this.ftpPasswordInvalid = false
			this.apiKeyInvalid = false
			this.webhookUrlInvalid = false
			this.webhookConfigBool = !!this.$store.state.activeSG.apiConfig?.webhookConfig
			this.formData.ingestionInterval = this.$store.state.activeSG.storageConfig.ingestionInterval
			this.formData.locateFileTimeout = this.$store.state.activeSG.storageConfig.locateFileTimeout
			this.formData.apiConfig = {
				apiKey: this.$store.state.activeSG.apiConfig ? this.$store.state.activeSG.apiConfig.apiKey : "",
				manageLegalEntities: !!this.$store.state.activeSG.apiConfig?.manageLegalEntities,
				ingestion: this.$store.state.activeSG.apiConfig ? this.$store.state.activeSG.apiConfig.ingestion : false,
				holdRelease: this.$store.state.activeSG.storageConfig.holdRelease ? this.$store.state.activeSG.storageConfig.holdRelease : false,
				creditNoteNegative: this.$store.state.activeSG.storageConfig.creditNoteNegative ?  this.$store.state.activeSG.storageConfig.creditNoteNegative : false,
				addZeroTaxSegments: this.$store.state.activeSG.storageConfig.addZeroTaxSegments ?  this.$store.state.activeSG.storageConfig.addZeroTaxSegments : false,
				managePayments: this.$store.state.activeSG.apiConfig ? this.$store.state.activeSG.apiConfig.managePayments : false,
				validateUbl: this.$store.state.activeSG.apiConfig ? this.$store.state.activeSG.apiConfig.validateUbl : false,
				manageUsers: this.$store.state.activeSG.apiConfig ? this.$store.state.activeSG.apiConfig.manageUsers : false,
				publicLinks: this.$store.state.activeSG.apiConfig ? this.$store.state.activeSG.apiConfig.publicLinks : false,
				webhookConfig: {
					url: this.$store.state.activeSG.apiConfig?.webhookConfig ? this.$store.state.activeSG.apiConfig.webhookConfig.url : "",
					errors: this.$store.state.activeSG.apiConfig?.webhookConfig ? this.$store.state.activeSG.apiConfig.webhookConfig.errors : false,
					notifications: this.$store.state.activeSG.apiConfig?.webhookConfig ? this.$store.state.activeSG.apiConfig.webhookConfig.notifications : false,
				},
			}
			this.formData.xmlConfig = {xsdFiles: this.$store.state.xsdFiles}
			this.initIngestionInterval(this.formData.ingestionInterval)
		},
		changeWebhookBool(data) {
			this.webhookConfigBool = data
		},
		changeApiConfigBool(data) {
			this.apiConfigBool = data
			this.webhookConfigBool = false
		},
		changeDynamicAttachmentsBool(data) {
			this.dynamicAttachments = data
		},
		changeHoldReleaseBool(data) {
			this.formData.holdRelease = data
		},
		changeXSD(event, prevItem) {
			Object.values(this.formData.xmlConfig.xsdFiles).find(
				(item) =>
					item.xsdType === prevItem.xsdType &&
					item.xsdLocation === prevItem.xsdLocation
			).xsdLocation = event.files[0].name
			this.validate()
		},
		displayAddXsd() {
			this.$store.state.displayAddXsd = true
		},
		addXsd(data) {
			let xsdFile = {
				version: "",
				active: true,
				xsdName: "",
				xsdType: data.xsdType,
				xsdLocation: data.xsdLocation,
			}
			this.formData.xmlConfig.xsdFiles.push(xsdFile)
		},
		updateSelectedXsdType(data, item) {
			let index = this.formData.xmlConfig.xsdFiles.indexOf(item)
			if (this.xsdData[data]) this.formData.xmlConfig.xsdFiles[index].xsdType = this.xsdData[data].name
		},
		deleteXSD(event, type) {
			let found = this.formData.xmlConfig.xsdFiles.find(item => item.xsdType === type && item.xsdLocation === event)
			let index = this.formData.xmlConfig.xsdFiles.indexOf(found)
			if (index > -1) this.formData.xmlConfig.xsdFiles.splice(index, 1)
		},
		initIngestionInterval(data) {
			switch (data) {
				case "15":
					this.selectedIngestion = "Every 15 Minutes"
					break
				case "30":
					this.selectedIngestion = "Every 30 Minutes"
					break
				case "00":
					this.selectedIngestion = "Every Hour"
					break
				case "DAY":
					this.selectedIngestion = "Daily"
					break
			}
		},
		updateSelectedIngestion(data) {
			switch (this.ingestionData[data].name) {
				case "Every 15 Minutes":
					this.formData.ingestionInterval = "15"
					break
				case "Every 30 Minutes":
					this.formData.ingestionInterval = "30"
					break
				case "Every Hour":
					this.formData.ingestionInterval = "00"
					break
				case "Daily":
					this.formData.ingestionInterval = "DAY"
					break
			}
		},
		updateIngestionBool(data) {
			this.formData.apiConfig.ingestion = data
		},
		updateManagePaymentsBool(data) {
			this.formData.apiConfig.managePayments = data
		},
		updateManageUsersBool(data) {
			this.formData.apiConfig.manageUsers = data
		},
		updateCreateLeBool(data) {
			this.formData.apiConfig.manageLegalEntities = data
		},
		updateValidateUbl(data) {
			this.formData.apiConfig.validateUbl = data
		},
		updatePublicLinkBool(data) {
			this.formData.apiConfig.publicLinks = data
		},
		updatewebhookNotificationBool(data) {
			this.formData.apiConfig.webhookConfig.notifications = data
		},
		updatewebhookErrorBool(data) {
			this.formData.apiConfig.webhookConfig.errors = data
		},
		generateKey() {
			this.formData.apiConfig.apiKey = uuidv4()
		},
		generateFtp() {
			const allBase = [
				...this.allCapsAlpha,
				...this.allLowerAlpha,
				...this.allUniqueChars,
				...this.allNumbers,
			]
			this.ftpPassword = [...Array(12)]
				.map((i) => allBase[(Math.random() * allBase.length) | 0])
				.join("")
		},
		close() {
			let xsdFiles = this.supplierGroup.xmlConfig ? this.supplierGroup.xmlConfig.xsdFiles : []
			for (let item in xsdFiles) {
				this.$store.state.xsdFiles[item] = xsdFiles[item]
			}
			if (this.apiConfigBool) this.formData.apiConfig = {}
			if (this.webhookConfigBool) this.formData.apiConfig.webhookConfig = {}

			this.initData()
			this.$store.state.displayEditIngestion = false
			this.$store.state.displayAddXsd = false
		},
		async update() {
			let hasErrors = this.validate()
			if (!hasErrors) {
				if (!this.apiConfigBool) delete this.formData.apiConfig
				if (!this.webhookConfigBool) {
					if (this.formData.apiConfig) delete this.formData.apiConfig.webhookConfig
				}
				this.formData.dynamicAttachments = this.dynamicAttachments

				let payload = {
					sgId: this.supplierGroup.supplierGroupId,
					edit: "ingestion-settings",
					body: this.formData,
				}

				await this.$store.dispatch("EditSupplierGroup", payload)
				if (this.$store.state.responseStatus === 200) {
					this.$store.commit("toggleAlert", {
						title: "Edit SupplierGroup",
						text: "Ingestion successfully edited",
					})
				}
				this.close()
			}
		},
		validate() {
			this.xsdInvalid = false
			this.apiKeyInvalid = false
			this.webhookUrlInvalid = false
			this.ftpPasswordInvalid = false

			let hasErrors = false
			if (Object.keys(this.formData.xmlConfig.xsdFiles).length === 0) {
				this.xsdInvalid = true
				hasErrors = true
			}
			if (this.ftpPassword === "") {
				this.ftpPasswordInvalid = true
				hasErrors = true
			}
			if (this.apiConfigBool === true && this.formData.apiConfig.apiKey === "") {
				hasErrors = true
				this.apiKeyInvalid = true
			}
			if (this.apiConfigBool === true && this.webhookConfigBool === true) {
				if (this.formData.apiConfig.webhookConfig.url === "") {
					hasErrors = true
					this.webhookUrlInvalid = true
				}
				if (!this.$store.state.urlRegex.test(this.formData.apiConfig.webhookConfig.url)) {
					this.webhookUrlInvalid = true
					hasErrors = true
				}
			}
			return hasErrors
		}
	}
}
</script>