<template>
    <div :class="{'brightness-95': this.$store.state.displayEditLeBankAccounts}" class="box col-span-6"
         v-if="legalEntity">
        <div
              class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5"
        >
            <div class="flex flex-row flex-nowrap">
                <BankAccountsTable v-if="bankAccountComputed" :canEdit="false" :Headers="bankAccountHeaders"
                                   :data="bankAccountComputed"/>
                <p v-else>No bank accounts yet</p>
            </div>
        </div>
    </div>
</template>
<script>
import BankAccountsTable from "../BankAccountsTable.vue";

export default {
    name: "ViewBankAccounts",
    components: {
        BankAccountsTable,
    },
    props: {
        bankAccountHeaders: Array,
    },
    data() {
        return {
            legalEntity: this.$store.state.activeLE
        }
    },
    watch: {
        "$store.state.activeLE": {
            handler(data) {
                this.legalEntity = data
            },
            deep: true,
        },
    },
    methods: {
        sort(data) {
            return data.sort(function (a, b) {
                return a.indexNumber - b.indexNumber
            })
        }
    },
    computed: {
        bankAccountComputed() {
            if (this.legalEntity.bankAccounts) {
                let data = this.sort(this.legalEntity.bankAccounts)
                return data
            }
            return null
        }
    }
};
</script>
