<template>
	<div
		:class="{ 'brightness-95': this.$store.state.displayEditReporting }"
		class="box shadow-none col-span-6"
	>
		<div class=" text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
			<div class="">
				<div class=" mr-12">
					<div class="mb-3 flex">
						<label class="font-bold text-lg form-label mb-0 mr-2">
							Reporting:
						</label>
						<ToggleSlide
							class="w-min"
							:disabled="true"
							:key="ftpBool"
							:bool="ftpBool"
						/>
					</div>
					<div class="ml-6" v-if="ftpBool">
						<Accordion
							:key="item"
							v-for="item in types"
							class="mb-5 border p-1 box  border-gray-300"
						>
							<template v-slot:title>
								<span class="font-semibold text-lg">{{ tabName(item) }}</span>
							</template>
							<template v-slot:content>
								<div class="m-3 reporting-item">
									<div>
										<Field
											v-if="formData.reportingConfig.ftpFolders"
											:id="'viewReportingFtpFolder' + item"
											title="FTP folder: "
											type="text"
											placeholder="/path/to"
											:disabled="true"
											:modelValue="formData.reportingConfig.ftpFolders[item]"
											:isCopied="copyFtpFolder[item]"
											@copy="copy('ftpFolder', item)"
											:copySuffix="true"
										/>
										<div class="mb-3">
											<p class="font-bold form-label mr-2">Receiver mails:</p>

											<div v-if="formData.reportingConfig.receiverEmails">
												<div v-if="formData.reportingConfig.receiverEmails[item]">
													<div
														:key="'edit' + item + itm + index"
														v-for="(itm, index) of Object.keys(formData.reportingConfig.receiverEmails[item])"
														class="flex flex-row input-group w-full mb-2"
													>
														<div class="input-group-text">
															{{ formData.reportingConfig.receiverEmails[item][itm] }}
														</div>
														<input
															type="text"
															disabled
															class="form-control"
															:value="itm"
														/>
													</div>
												</div>
												<p v-else>no receiver emails</p>
											</div>
											<p v-else>no receiver emails</p>
										</div>
									</div>
									<div>
										<button
											v-if="isFor === 'supplierGroup' && item === 'MAIL_ERRORS'"
											@click="triggerReport(item)"
											class="btn-bl btn btn-sm bg-theme-1 text-white w-24 mb-2"
										>
											Send Report
										</button>
										<div class="mb-3 flex" v-if="isFor === 'supplierGroup'">
											<label
												class="font-bold form-label mb-0 mr-2">
												Send as Xlsx:
											</label>
											<ToggleSlide
												class="w-min"
												:key="formData.reportingConfig.generateXlsx[item]"
												:bool="formData.reportingConfig.generateXlsx[item]"
												:disabled="true"
											/>
										</div>
									</div>
								</div>
							</template>
						</Accordion>
					</div>
					<div class="mb-3 flex">
						<p class="font-bold text-lg form-label mb-0 mr-2">
							Admin e-mails:
						</p>
						<ToggleSlide
							class="w-min"
							id="editAdminEmails"
							:key="adminEmailsBool"
							:bool="adminEmailsBool"
							:disabled="true"
						/>
					</div>
					<div v-if="adminEmailsBool" class="ml-2 w-2/4">
						<div
							v-for="(item, index) of Object.keys(this.formData.reportingConfig.adminEmails)"
							:key="'editAdminEmail' + index"
							class="flex flex-row input-group w-full mb-2"
						>
							<div class="input-group-text">
								{{ this.formData.reportingConfig.adminEmails[item] }}
							</div>
							<input
								type="text"
								:name="item"
								class="form-control"
								:disabled=true
								:value="item"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Field from "../Field.vue";
import Accordion from "../Accordion.vue";
import ToggleSlide from "../ToggleSlide.vue";

export default {
	name: "ViewReporting",
	components: {
		Field,
		ToggleSlide,
		Accordion,
	},
	props: {
		isFor: String,
	},
	mounted() {
		this.setFormData();
		this.initData();
	},

	watch: {
		"$store.state.activeSG": {
			handler() {
				if (this.isFor === "supplierGroup") {
					this.initData();
				}
			},
			deep: true,
		},
		"$store.state.activeLE": {
			handler() {
				if (this.isFor === "legalEntity") {
					this.initData();
				}
			},
			deep: true,
		},
	},

	data() {
		return {
			types: [
				"MAIL_ERRORS",
				"CHANNEL_DETAIL",
				"CHANNEL_ARCHIVED_DETAIL",
				"DOCUMENTS_HELD",
				"TRANSACTIONS",
				"MANDATES_OVERVIEW",
				"MANDATES_CHOICES",
				"CHANNEL_CHOICE",
				"POST_DROP",
				"SG_SPECIFIC_ERRORS",
				"UBL_STATUS_REPORT"
			],
			formData: {
				reportingConfig: {
					ftpFolders: {},
					receiverEmails: {},
					generateXlsx: {},
					adminEmails: {}
				},
			},
			copyFtpFolder: {},
			jobs: this.$store.state.schedule.jobs.filter(
				(job) => job.supplierGroupId === "default"
			),
			isChannelDetailRunning: false,
			ftpBool: false,
			adminEmailsBool: false,
		};
	},

	methods: {
		tabName(item) {
			let name = item;
			if (item === 'SG_SPECIFIC_ERRORS') {
				name = "GENERAL_REPORT"
			}
			return name
		},
		setFormData() {
			this.types.forEach(type => {
				this.formData.reportingConfig.ftpFolders[type] = "";
				this.formData.reportingConfig.receiverEmails[type] = null;
				this.formData.reportingConfig.generateXlsx[type] = false;
			})
		},
		initData() {
			this.isChannelDetailRunning = this.jobs.find((job) => job.jobType === "CHANNEL_DETAIL_REPORT");

			if (this.isChannelDetailRunning === undefined || this.isChannelDetailRunning.length === 0) {
				this.isChannelDetailRunning = false
			} else this.isChannelDetailRunning = this.isChannelDetailRunning.started

			if (this.isFor === "supplierGroup") {

				this.ftpBool = !!(this.$store.state.activeSG.reportingConfig
					&& this.$store.state.activeSG.reportingConfig.receiverEmails);
				this.adminEmailsBool = !!(this.$store.state.activeSG.reportingConfig
					&& this.$store.state.activeSG.reportingConfig.adminEmails);

				this.formData.reportingConfig.adminEmails = {};

				if (this.$store.state.activeSG.reportingConfig && this.$store.state.activeSG.reportingConfig.adminEmails) {
					this.formData.reportingConfig.adminEmails = this.$store.state.activeSG.reportingConfig.adminEmails
				}

				if (!this.formData.reportingConfig.ftpFolders) {
					this.formData.reportingConfig.ftpFolders = {}
				}
				if (!this.formData.reportingConfig.receiverEmails) {
					this.formData.reportingConfig.receiverEmails = {}
				}
				if (!this.formData.reportingConfig.generateXlsx) {
					this.formData.reportingConfig.generateXlsx = {}
				}

				this.types.forEach(type => {
					this.formData.reportingConfig.ftpFolders[type] = (this.$store.state.activeSG.reportingConfig ?
						(this.$store.state.activeSG.reportingConfig.ftpFolders ? this.$store.state.activeSG.reportingConfig.ftpFolders[type] : "") : "");

					this.formData.reportingConfig.receiverEmails[type] = (this.$store.state.activeSG.reportingConfig ?
						(this.$store.state.activeSG.reportingConfig.receiverEmails ? this.$store.state.activeSG.reportingConfig.receiverEmails[type] : null) : null);

					this.formData.reportingConfig.generateXlsx[type] = (this.$store.state.activeSG.reportingConfig ?
						(this.$store.state.activeSG.reportingConfig.generateXlsx ? this.$store.state.activeSG.reportingConfig.generateXlsx[type] : false) : false);
				})
			}
			if (this.isFor === "legalEntity") {
				this.ftpBool = !!(this.$store.state.activeLE.legalEntityConfig
					&& this.$store.state.activeLE.legalEntityConfig.reportingConfig
					&& this.$store.state.activeLE.legalEntityConfig.reportingConfig.receiverEmails);

				this.adminEmailsBool = !!(this.$store.state.activeLE.legalEntityConfig
					&& this.$store.state.activeLE.legalEntityConfig.reportingConfig
					&& this.$store.state.activeLE.legalEntityConfig.reportingConfig.adminEmails);

				if (this.formData.reportingConfig) {
					if (this.$store.state.activeLE.legalEntityConfig
						&& this.$store.state.activeLE.legalEntityConfig.reportingConfig
						&& this.$store.state.activeLE.legalEntityConfig.reportingConfig.adminEmails) {
						this.formData.reportingConfig.adminEmails = this.$store.state.activeLE.legalEntityConfig.reportingConfig.adminEmails
					} else this.formData.reportingConfig.adminEmails = {}
				} else this.formData.reportingConfig.adminEmails = {}

				this.types.forEach(type => {
					this.formData.reportingConfig.ftpFolders[type] = (this.$store.state.activeLE.legalEntityConfig ?
						(this.$store.state.activeLE.legalEntityConfig.reportingConfig ?
							(this.$store.state.activeLE.legalEntityConfig.reportingConfig.ftpFolders ? this.$store.state.activeLE.legalEntityConfig.reportingConfig.ftpFolders[type] : "")
							: "")
						: "");
					this.formData.reportingConfig.receiverEmails[type] = (this.$store.state.activeLE.legalEntityConfig ?
						(this.$store.state.activeLE.legalEntityConfig.reportingConfig ?
							(this.$store.state.activeLE.legalEntityConfig.reportingConfig.receiverEmails ? this.$store.state.activeLE.legalEntityConfig.reportingConfig.receiverEmails[type] : null)
							: null)
						: null);
					this.formData.reportingConfig.generateXlsx[type] = (this.$store.state.activeLE.legalEntityConfig ?
						(this.$store.state.activeLE.legalEntityConfig.reportingConfig ?
							(this.$store.state.activeLE.legalEntityConfig.reportingConfig.generateXlsx ? this.$store.state.activeLE.legalEntityConfig.reportingConfig.generateXlsx[type] : false) : false)
						: false);
				})
			}
		},
		copy(isFor, type) {
			this.copyFtpFolder = {};
			switch (isFor) {
				case "ftpFolder":
					navigator.clipboard.writeText(
						this.formData.reportingConfig.ftpFolders[type]
					);
					this.copyFtpFolder[type] = true;
					setTimeout(() => {
						this.copyFtpFolder[type] = false;
					}, 750);
					break;

				default:
					break;
			}
		},
		triggerReport(type) {
			let payload = {
				sgId: this.$store.state.activeSG.supplierGroupId,
				type: type,
			};
			this.$store.dispatch("schedule/triggerReport", payload);
		},
	},
};
</script>
<style scoped>
.reporting-item {
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: 50% 50%;
	grid-column-gap: 20px;
}
</style>