import qs from "qs"
import axios from "axios"

const env = window.environment
export default {
	getSmpParticipants(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + context.rootState.API.smpParticipants
			return axios.post(url,
				payload,
				{
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then((res) => {
				context.commit('setSmpParticipants', res.data.data.information, {root: true})
			})
				.catch(() => {
					context.commit('displayErrorAlert', true, {root: true})
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},

	addPeppolIdentifier(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken,
			body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + context.rootState.API.addPeppolIdentifier.replace(':supplierGroupId', payload.sgId).replace(':legalEntityNumber', payload.leId);
			return axios.post(url, payload.peppolParticipants, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
						'Content-Type': "application/json; charset=utf8"
					}
				}
			).then((response) => {
				let pl = {
					data: response.data.data,
					sgId: payload.sgId
				}
				context.commit('setUpdatedLegalEntity', pl, {root: true})

				context.commit("toggleAlert", {
					title: "Update Peppol Identifiers",
					text: "New Peppol Identifiers successfully updated",
				}, {root: true})
				context.commit('peppolItemChanged', true, {root: true})
			})
				.catch(() => {
					context.commit('displayErrorAlert', true, {root: true})
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},

	createPeppolParticipant(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + context.rootState.API.createPeppolParticipant;
			return axios.post(url, payload, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then((res) => {
				if (res.data.data.success) {
					context.commit('peppolCreateDocumentDisplay', true, {root: true})
					context.commit("toggleAlert", {
						title: "Create new Peppol participant",
						text: "New participant successfully created",
					}, {root: true})
				} else {
					context.commit('displayErrorAlertText', "Error: " + res.data.data.message, {root: true})
					context.commit('displayErrorAlert', true, {root: true})
				}
			})
				.catch(() => {
					context.commit('peppolCreateDocumentDisplay', false, {root: true})
					context.commit('displayErrorAlert', true, {root: true})
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	deletePeppolParticipant(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + context.rootState.API.deletePeppolParticipant
			return axios.delete(url,
				{
					data: {
						identifier: payload.identifier,
						smpProvider: payload.smpProvider,
					},
					headers: {
						'Content-Type': "application/json",
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then(() => {
				context.commit("toggleAlert", {
					title: "Delete Peppol document",
					text: "Document successfully Deleted",
				}, {root: true})
				context.commit('peppolItemChanged', true, {root: true})
			})
				.catch(() => {
					context.commit('displayErrorAlert', true, {root: true})
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},

	createPeppolDocument(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + context.rootState.API.createPeppolDocument;
			return axios.post(url, payload, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then((res) => {
				if (res.data.data.success) {
					context.commit("toggleAlert", {
						title: "Create new Peppol document",
						text: "New document successfully created",
					}, {root: true})
					context.commit("peppolDocumentCreated", true, {root: true})
					context.commit('peppolItemChanged', true, {root: true})
				} else {
					context.commit('displayErrorAlertText', "Error: " + res.data.data.message, {root: true})
					context.commit('displayErrorAlert', true, {root: true})
				}
			})
				.catch(() => {
					context.commit('displayErrorAlert', true, {root: true})
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})

	},
	deletePeppolDocument(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + context.rootState.API.deletePeppolDocument
			return axios.delete(url, {
					data: {
						identifier: payload.identifier,
						smpProvider: payload.smpProvider,
						documentTypeIdentifier: payload.documentTypeIdentifier

					},
					headers: {
						'Content-Type': "application/json",
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then((res) => {
				if (res.data.data.success) {
					context.commit("toggleAlert", {
						title: "Delete Peppol document",
						text: "Document successfully Deleted",
					}, {root: true})
					context.commit('peppolItemChanged', true, {root: true})
					context.commit("peppolDocumentDeleted", true, {root: true})

				} else {
					context.commit('displayErrorAlertText', "Error: " + res.data.data.message, {root: true})
					context.commit('displayErrorAlert', true, {root: true})
				}
			})
				.catch(() => {
					context.commit('displayErrorAlert', true, {root: true})
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},

	retrievePeppolInfo(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})
			context.commit('setLoader', true, {root: true})
			let url = context.rootState.baseUrl + context.rootState.API.retrievePeppolInfo.replace(':identifier', payload);

			return axios.get(url, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then(res => {
				context.commit('setPeppolRetrievedData', {
					information: res.data.data.information,
					identifier: payload
				}, {root: true})
				context.commit('peppolIdentifiersDisplay', true, {root: true})
				context.commit('peppolIdentifiersResults', {field: "participantFound", value: true}, {root: true})
				context.commit('setLoader', false, {root: true})
			})
				.catch(() => {
					context.commit('peppolIdentifiersDisplay', true, {root: true})
					context.commit('peppolIdentifiersResults', {field: "participantNotFound", value: true}, {root: true})
					context.commit('setLoader', false, {root: true})
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	async getHermesMigrationKey(context, payload) {
		const url = context.rootState.baseUrl + context.rootState.API.getHermesMigrationKey.replace(":identifier", payload)
		return await axios.get(url, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).then(res => {
			return res.data.data.keyOrError
		})
	},
	migrateHermesToPeppolPeppol(context, payload) {
		const url = context.rootState.baseUrl + context.rootState.API.migrateHermesToPeppol
		axios.post(url, payload, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		})
	},
	triggerPeppolIngestion(context) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + context.rootState.API.triggerPeppolIngestion
			return axios.get(url, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then(() => {
				context.commit("toggleAlert", {
					title: "Peppol ingestion",
					text: "Peppol ingestion successfully triggered",
				}, {root: true})
			})
				.catch(() => {
					context.commit('displayErrorAlert', true, {root: true})
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},

	peppolConfig(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url;
			if (payload.legalEntityNumber) {
				url = context.rootState.baseUrl + context.rootState.API.peppolConfigLe.replace(":supplierGroupId", payload.supplierGroupId).replace(':legalEntityNumber', payload.legalEntityNumber)
			} else {
				url = context.rootState.baseUrl + context.rootState.API.peppolConfigSg.replace(":supplierGroupId", payload.supplierGroupId)
			}

			return axios.post(url,
				payload.requestData,
				{
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				})
				.then(res => {
					context.dispatch("fetchSupplierGroups", null, {root: true});

					if (payload.legalEntityNumber) context.commit('setActiveLe', res.data.data, {root: true})
					else context.commit('setActiveSg', res.data.data, {root: true})
				})
				.catch(() => {
					context.commit('displayErrorAlert', true, {root: true})
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	}
}