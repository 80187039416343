<template>
	<pop-up-window
		:show="this.$store.state.displayEditResendTemplate"
		fixed
	>
		<template #header>Edit resent template</template>
		<template #content>
			<div v-if="!displayTemplate"> No templates yet</div>
			<div v-if="displayTemplate">
				<div class="mb-3">
					<div class="flex flex-col px-2 pb-2">
						<label for="preingestionTemplateName">Select resend template:</label>
						<Dropdown
							id="preingestionTemplateName"
							@update-value="updateSelectedName"
							class="w-1/2 pr-2"
							:data="resendTemplateNames"
							:selected="this.$store.state.selectedResendTemplateName"
						/>
					</div>
					<div class="form-container">
						<div class="px-2">
							<Field
								id="templateVersion"
								title="Version*:"
								v-model="activeTemplate.version"
								type="text"
								@click="this.setError('version', false)"
							/>
							<div>
								<span v-if="errors.version" class="text-primary-3">
									Version can ot be empty
								</span>
							</div>
							<div>
								<label for="resendDocType" class="font-bold">DocType:*</label>
								<DropdownSelectAll
									v-if="renderComponent"
									id="resendDocType"
									@update-value="updateDocTypes"
									:data="docTypes"
									:selected=activeTemplate.docTypes
								/>
								<div>
                                    <span v-if="errors.docType" class="text-primary-3">
										Please, select at least one docType
									</span>
								</div>
							</div>
						</div>
						<div class="px-2">
							<div>
								<label for="overlayTemplateOverlayMode" class="font-bold form-label">
									Overlay mode:*
								</label>
								<Dropdown
									id="overlayTemplateOverlayMode"
									@update-value="updateSelectedOverlay"
									:data="overlayData"
									:selected="activeTemplate.overlayMode"
								/>
							</div>

							<div>
								<div class="flex mb-1 my-2">
									<p class="font-bold form-label my-0">PDF file:*</p>
									<button
										@click="showDialogAddFile = true"
										class="btn bg-theme-1 py-0 text-white shadow-md ml-2"
									>
										Add
									</button>
								</div>
								<div>
                                    <span v-if="errors.pdfFile" class="text-primary-3">
										Please, select at least one base file
									</span>
								</div>
								<div
									:key="index + item"
									v-for="(item, index) in activeTemplate.location"
									class="flex flex-col"
								>
									<div class="flex flex-col">
										<FileUpload
											:isLegalEntity="isLegalEntity"
											:class=index
											@update-value="changeFileLocation($event, item, index, 'location')"
											@delete-value="deleteFile($event, item, index)"
											accept=".pdf"
											:prefix="true"
											:prefixData="index"
											:delSuffix="true"
											:data="item.fileName"
											:id="  'templatesEdit' + item.fileName"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2"
							v-if="displayTemplate"
							@click="update(false)"
					>
						Update
					</button>
					<button
						class="btn shadow-md bg-theme-1 text-white mr-2"
						@click="showConfirmDialog"
						v-if="displayTemplate"
					>
						Delete
					</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
	<add-file-to-template
		title-dialog="Add resend template PDF"
		:isLegalEntity="isLegalEntity"
		@add-file="addFile"
		@cancel-add-file="showDialogAddFile = false"
		:langCode="langCodes"
		:show-dialog="showDialogAddFile"
	/>
	<ConfirmDialog @close="delTemp" :show="showConfirm"/>
</template>

<script>
import PopUpWindow from "../../PopUp.vue";
import Dropdown from "../../Dropdown.vue";
import Field from "../../Field.vue";
import DropdownSelectAll from "../../DropdownSelectAll.vue";
import FileUpload from "../../FileUpload.vue";
import langCodes from '../../../langCodes.js';
import ConfirmDialog from "../../ConfirmDialog.vue";
import AddFileToTemplate from "@/components/templates/AddFileToTemplate.vue";

export default {
	name: "EditResendTemplate",
	components: {
		AddFileToTemplate,
		PopUpWindow,
		Dropdown,
		Field,
		DropdownSelectAll,
		FileUpload,
		ConfirmDialog
	},
	props: {
		isFor: {
			type: String,
			required: true,
		}
	},
	data() {
		return {
			isLegalEntity: this.isFor === "legalEntity",
			activeItem: '',
			displayTemplate: false,
			initialTemplate: '',
			activeTemplate: {},
			resendTemplates: [],
			renderComponent: true,
			docTypes: this.$store.state.docTypes,
			overlayData: this.$store.state.overlayMode,
			langCodes: langCodes,
			errors: {
				version: false,
				pdfFile: false,
				docType: false,
			},
			showConfirm: false,
			showDialogAddFile: false
		}
	},
	mounted() {
		this.initData();
	},
	computed: {
		activeSG() {
			return this.$store.state.activeSG;
		},

		activeLE() {
			return this.$store.state.activeLE;
		},

		resendTemplateNames() {
			let names = [];
			if (this.activeItem.templates && this.activeItem.templates.resendTemplates) {

				this.activeItem.templates.resendTemplates.forEach((template, index) => {
					names.push(
						{id: index, name: template.name},
					)
				})
			}
			return names;
		},
	},
	watch: {
		activeSG() {
			this.initData()
		},
		activeLE() {
			this.initData()
		}
	},

	methods: {
		delTemp(data) {
			if (data === "Yes") {
				this.update(true);
				this.showConfirm = false;
			} else {
				this.showConfirm = false;
			}
		},
		showConfirmDialog() {
			this.showConfirm = true;
		},
		setError(error, value) {
			this.errors[error] = value
		},

		addFile(data) {
			this.setError("pdfFile", false)
			this.activeTemplate.location = {
				...this.activeTemplate.location,
				...data,
			};
			this.showDialogAddFile = false
		},
		changeFileLocation(event, item, index, field) {
			setTimeout(() => {

				this.activeTemplate[field][index].url = this.$store.state.uploadedFileUrl;
			}, 500)
			this.activeTemplate[field][index].fileName = event.files[0].name;
		},
		deleteFile(event, item, index) {
			delete this.activeTemplate.location[index];
		},
		updateSelectedOverlay(data) {
			if (this.overlayData[data]) {
				this.activeTemplate.overlayMode = this.overlayData[data].name;
			}
		},
		updateDocTypes(docTypes) {
			this.setError("docType", false);
			let foundArr = [];
			for (let index of docTypes) {
				let found = this.docTypes[index].name;
				foundArr.push(found);
			}
			this.activeTemplate.docTypes = foundArr;
			this.docTypesError = false;
		},
		forceRerender() {
			// Removing my-component from the DOM
			this.renderComponent = false;

			this.$nextTick(() => {
				// Adding the component back in
				this.renderComponent = true;
			});
		},
		setResentTemplates() {
			this.resendTemplates = this.activeItem.templates.resendTemplates;
		},
		updateSelectedName(templateId) {
			console.log(templateId)
			if (this.resendTemplateNames !== undefined) {
				if (typeof templateId === "object"){
					templateId = templateId[0]
				}
				console.log(this.resendTemplates)
				console.log(templateId)
				this.selectedTemplateName = this.resendTemplateNames.find(item => (item.id.toString() === templateId.toString())).name;

				if (this.selectedTemplateName !== "Select template") {
					this.resendTemplates.forEach(template => {
						if (template.name === this.selectedTemplateName) {
							this.activeTemplate = JSON.parse(JSON.stringify(template));
						}
					})
				} else {
					this.activeTemplate = {}
				}
				this.forceRerender();
			}
		},
		initData() {
			if (this.isFor === "supplierGroup") {
				this.activeItem = this.$store.state.activeSG;
			}
			if (this.isFor === "legalEntity") {
				this.activeItem = this.$store.state.activeLE;
			}

			if (this.activeItem.templates && this.activeItem.templates.resendTemplates && this.activeItem.templates.resendTemplates.length !== 0) {
				this.displayTemplate = true;
				this.initialTemplate = this.resendTemplateNames[0].name;
				this.setResentTemplates();

				this.resendTemplates.forEach(template => {
					if (template.name === this.initialTemplate) {
						this.activeTemplate = JSON.parse(JSON.stringify(template));
					}
				})
			} else {
				this.displayTemplate = false;
				this.initialTemplate = '';
			}
		},

		close() {
			this.$store.state.displayEditResendTemplate = false;
		},
		async update(deleteParam) {
			if (this.activeTemplate.docTypes.length === 0) {
				this.setError('docType', true);
				return
			}
			if (Object.keys(this.activeTemplate.location).length === 0) {
				this.setError('pdfFile', true);
				return
			}

			let formData = this.resendTemplates;

			let foundIndex;
			formData.forEach((item, index) => {
				if (item.name === this.activeTemplate.name) {
					foundIndex = index
				}
			})

			if (foundIndex >= 0) {
				if (!deleteParam) {
					formData[foundIndex] = this.activeTemplate;
				}
				if (deleteParam) {
					formData.splice(foundIndex, 1)
				}
			}
			let requestData = {resend: formData}

			let payload = {
				isFor: this.isFor,
				sgId: this.$store.state.activeSG.supplierGroupId,
				formData: requestData
			};

			if (this.isFor === "legalEntity") {
				payload.legalEntityNumber = this.activeItem.entityNumber;
			}

			await this.$store.dispatch("editResendTemplate", payload);
			if (this.$store.state.responseStatus === 200) {
				this.$store.commit("toggleAlert", {
					title: "Edit resend template",
					text: "Resend template successfully edited",
				});
			}
			this.close();
		},
	}
}
</script>

<style scoped>
.form-container {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 100%;
}

label {
	font-weight: bold;
	margin-bottom: 0.5rem;
}
</style>
