<template>
	<div :class="{'brightness-95': this.$store.state.displayEditContactInfoAndHelp}" class="box shadow-none col-span-6">
		<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
			<div class="flex flex-col flex-nowrap">
				<div class="flex flex-col mb-1">
					<p class="text-lg font-bold mr-3">Contact info footer</p>
					<div class="flex flex-row">
						<button class="btn bg-theme-1 text-white w-24 mr-1 mb-2" @click="copy('footer')">
							Copy
						</button>
						<button class="btn bg-theme-1 text-white w-24 mr-1 mb-2" @click="view('footer')">
							View
						</button>
					</div>
					<p class="font-bold">Preview:</p>
					<div class="icon-container" v-html="supplierGroup.channelsConfig.docImage.configPortal.footerHtml">
					</div>
				</div>
			</div>
			<div class="mb-3">
				<p class="text-lg font-bold form-label">Manuals:</p>
				<div v-if="supplierGroup.channelsConfig.docImage.configPortal.helpPdf">
					<div
						:key="index"
						v-for="(item, index) in Object.keys(supplierGroup.channelsConfig.docImage.configPortal.helpPdf)"
						class="grid-cols-3 gap-2 mb-2"
					>
						<div class="input-group">
							<div id="manuals" class="input-group-text">{{ item }}</div>
							<input
								disabled
								type="text"
								class="form-control"
								:value="supplierGroup.channelsConfig.docImage.configPortal.helpPdf[item]"
							/>
							<div
								class="input-group-text"
								@click="download(supplierGroup.channelsConfig.docImage.configPortal.helpPdf[item])"
								style="cursor:pointer"
							>
								<span class="material-icons" style="font-size: 20px">file_download</span>
							</div>
						</div>
					</div>
				</div>
				<input
					v-else
					disabled
					id="manuals"
					type="text"
					class="form-control w-full"
					value="n.a."
				/>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'ViewContact',
	data() {
		return {
			supplierGroup: this.$store.state.activeSG,
		}
	},
	watch: {
		"$store.state.activeSG": {
			handler(data) {
				this.supplierGroup = data
			},
			deep: true,
		},
	},
	methods: {
		copy(string) {
			switch (string) {
				case "footer":
					navigator.clipboard.writeText(
						this.supplierGroup.channelsConfig.docImage.configPortal.footerHtml
					);
					break;
				default:
					break;
			}
		},
		download(pdfPath) {
			window.open(pdfPath)
		},
		view(string) {
			switch (string) {
				case "footer":
					this.$store.state.viewHtmlContent =
						this.supplierGroup.channelsConfig.docImage.configPortal.footerHtml;
					this.$store.state.displayShowHtml = true;
					break;
				default:
					break;
			}
		},
	}
}
</script>

<!--All the not used css (.extra-margin and .container) are used in the dynamiccally loaded Icons-->
<!--DO NOT delete these classes-->
<style scoped>
>>> span {
	font-size: 13px;
}

>>> .extra-margin {
	margin-right: 5px;
}

>>> a {
	font-size: 13px;
}

>>> div > div > div > div > div > div {
	display: flex;
	flex-direction: row;
	align-items: center;
}

#container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-size: 13px;
}
</style>