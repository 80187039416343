import { createRouter, createWebHistory } from 'vue-router'
import routerData from "../routerData";

import SGOverview from '../views/SGOverview'
import GlobalOverview from '../views/GlobalOverview'
import Exporting from '../views/Exporting'
import SGDetails from '../views/SGDetails'
import LEDetails from '../views/LEDetails'
import Summary from '../views/Summary'
import SGSummary from '../views/SGSummary'
//import Onderzoek from '../views/Onderzoek'
import Peppol from '../views/Peppol'
import Schedule from '../views/Schedule'
import GlobalActions from "../views/GlobalActions.vue";


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: routerData.SGOverview.link,
    },
    {
      path: '/en/',
      redirect: routerData.SGOverview.link,
    },
    {
      path: '/auth',
      redirect: routerData.SGOverview.link,
    },
    {
			path: routerData.SGOverview.link,
      name: 'SGOverview',
			component: SGOverview,
		},
    {
      path: routerData.LEDetails.link,
      component: LEDetails,
      name:'LEDetails'
    },
    {
      path: routerData.SGDetails.link,
      component: SGDetails,
      name: 'SGDetails'
    },
    {
      path: routerData.GlobalOverview.link,
      name: 'GlobalOverview',
      component: GlobalOverview,
    },
    {
      path: routerData.Schedule.link,
      name: 'Schedule',
      component: Schedule,
    },
    {
      path: routerData.Exporting.link,
      name: 'Exporting',
      component: Exporting
    },
    {
      path: routerData.Summary.link,
      name: 'Summary',
      component: Summary,
    },
    {
      path: routerData.SGSummary.link,
      name: 'SGSummary',
      component: SGSummary,
    },
    // {
    //   path: routerData.Onderzoek.link,
    //   name: 'Onderzoek',
    //   component:  Onderzoek,
    // },
    {
      path: routerData.Peppol.link,
      name: 'Peppol',
      component:  Peppol,
    },
    {
      path: routerData.GlobalActions.link,
      name: 'GlobalActions',
      component: GlobalActions
    }
  ]
})

router.beforeEach((to, from, next) => {
    next()
})

export default router
