const routerData = {
	home: {
		link: '/', //root folder for project, defines at vue.config.js
	},
	SGOverview: {
		link: '/SGOverview',
	},
	SGDetails: {
		link: '/SGOverview/:id'
	},
	LEDetails:{
		link: '/SGOverview/:id/:idLe'
	},
	GlobalOverview: {
		link: '/GlobalOverview'
	},
	Schedule:{
		link: '/Schedule'
	},
	Exporting:{
		link: '/Exporting'
	},
	Summary: {
		link: '/Summary'
	},
	SGSummary:{
		link: '/Summary/:id'
	},
	// Onderzoek: {
	// 	link: '/Onderzoek'
	// },
	Peppol: {
		link: '/Peppol'
	},
	GlobalActions: {
		link: '/GlobalActions'
	},
	notFound: {
		link: '/:notFound(.*)'
	}

}
export default routerData;
