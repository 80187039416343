<template>
	<div :class="{'brightness-95': this.$store.state.displayEditLeGeneral}" class="box col-span-6" v-if="legalEntity">
		<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-1/2 mr-12">
					<Field
						id="leName"
						title="Name:"
						type="text"
						:modelValue="legalEntity.name"
						:disabled="true"
						:copySuffix="true"
						:isCopied="copyName"
						@copy="copy('name')"
					/>
					<Field
						title="Legal entity ID:"
						type="text"
						:modelValue="legalEntity.entityNumber"
						:disabled="true"
						:copySuffix="true"
						:isCopied="copyLe"
						@copy="copy('leId')"
					/>
					<Field
						title="Branding ID:"
						type="text"
						:modelValue="legalEntity.brandingId"
						:disabled="true"
						:copySuffix="true"
						:isCopied="copyBrandingId"
						@copy="copy('brandingId')"
					/>
					<Field
						id="internalName"
						title="Internal name:"
						type="text"
						:modelValue="internalName"
						:disabled="true"
						:copySuffix=false
					/>
					<Field
						id="RPR"
						title="RPR:"
						type="text"
						:modelValue="legalEntity.RPR"
						:disabled="true"
						:copySuffix=false
					/>

				</div>
				<div class="flex flex-col w-1/2 mr-12">
					<div class="mb-3">
						<Field
							title="Portal link:"
							type="text"
							:modelValue="legalEntity.legalEntityConfig?.portalLink"
							:disabled="true"
							:copySuffix=true
							:is-copied="copyPortalLink"
							@copy="copy('portalLink')"
						/>
						<div class="w-full mb-2" style="height: 38px">
							<label class="font-bold form-label">Logo:</label>
							<div class="input-group">
								<input
									disabled
									id="logo"
									type="text"
									class="form-control w-full"
									:value="leLogoComputed"

								/>
								<div
									class="font-bold cursor-pointer input-group-text"
									@click="download(leLogoComputed)"
									style="cursor:pointer; padding: 7px 9px 2px 9px"
								>
										<span class="material-icons" style="font-size: 20px">file_download</span>
								</div>
							</div>
						</div>
						<img class="w-1/4 mt-8 max-w-max" :src="leLogoComputed"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Field from "../Field.vue";

export default {
	name: "ViewGeneral",
	components: {
		Field,
	},
	data() {
		return {
			legalEntity: this.$store.state.activeLE,
			copyName: false,
			copyLe: false,
			copyBrandingId: false,
			copyPortalLink: false
		}
	},
	methods: {
		download(url){
			window.open(url)
		},
		copy(element) {
			if (element === 'name') {
				this.copyName = true
				navigator.clipboard.writeText(this.legalEntity.name);
			} else if (element === 'leId') {
				this.copyLe = true
				navigator.clipboard.writeText(this.legalEntity.entityNumber)
			} else if (element === 'brandingId') {
				this.copyBrandingId = true
				navigator.clipboard.writeText(this.legalEntity.brandingId !== undefined ? this.legalEntity.brandingId : '')
			}
			else if (element === 'portalLink'){
				this.copyPortalLink = true
				navigator.clipboard.writeText(this.legalEntity.legalEntityConfig?.portalLink !== undefined? this.legalEntity.legalEntityConfig.portalLink : '')
			}

			setTimeout(() => {
				this.copyName = false
				this.copyLe = false
				this.copyBrandingId = false
				this.copyPortalLink = false
			}, 750)
		}
	},
	watch: {
		"$store.state.activeLE": {
			handler(data) {
				this.legalEntity = data
			},
			deep: true,
		},
	},
	computed: {
		internalName() {
			return this.$store.state.activeLE?.internalName ? this.$store.state.activeLE.internalName : ''
		},
		leLogoComputed() {
			if (this.legalEntity.legalEntityConfig) {
				if (this.legalEntity.legalEntityConfig.logo) {
					return this.legalEntity.legalEntityConfig.logo;
				}
			}
			return "No logo yet";
		},
	},
};
</script>
