<template>
  <div class="w-full">
    <!-- BEGIN: Content -->
    <div class="content">
      <Tabulator :token="token" :requestUrl="SGsAPIurl"/>
    </div>
  </div>
</template>
<script>
import Tabulator from "../components/Tabulator.vue";
export default {
  components: {
    Tabulator: Tabulator,
  },
  data() {
    return {
      SGsAPIurl: this.$store.state.baseUrl + this.$store.state.supplierGroupUrl,
      token: this.$store.state.token
    }
  },
};
</script>
<style scoped>
.content{
  min-height: fit-content;
}
</style>
