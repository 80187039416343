<template>
    <div class="box shadow-none col-span-6">
        <div class=" text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5 min-height-600">
            <div class="top-container">
                    <div class="flex flex-row flex-nowrap">
                        <div class="flex flex-col w-1/2 mr-12">
                            <div class="mb-3 flex">
                                <label class="font-bold form-label mb-0 mr-2">Modify channel:</label>
                                <ToggleSlide
                                      :disabled = true
                                      class="w-min"
                                      :bool="viewModifyChannel"
                                      :renewBool=true
                                />
                            </div>
                            <div class="mb-3">
                                <label class="font-bold form-label">Default channel: *</label>
                                <Dropdown
                                      id="viewDefaultChannel"
                                      :disabled = true
                                      :data="defaultChannelOptions"
                                      :selected="viewDefaultChannel"
                                      :key="viewDefaultChannel"
                                />

                            </div>
                            <div class="mb-3 flex">
                                <label class="font-bold form-label mb-0 mr-2">Allow import:</label>
                                <ToggleSlide
                                      :disabled = true
                                      class="w-min"
                                      :bool="viewAllowImport"
                                      :renewBool=true
                                />
                            </div>
                            <div class="mb-3" v-if="viewAllowImport">
                                <label class="font-bold form-label">Import method: </label>
                                <Dropdown
                                      :disabled = true
                                      id="viewImportMethod"
                                      :data="importMethodOptions"
                                      :selected="viewImportMethod"
                                      :key="viewImportMethod"
                                />
                            </div>
                        </div>
                        <div class="flex flex-col w-1/2 mr-12">
                            <div class="mb-3 flex">
                                <label class="font-bold form-label mb-0 mr-2">Display on portal:</label>
                                <ToggleSlide
                                      :disabled = true
                                      class="w-min"
                                      :bool="viewDisplayOnPortal"
                                      :renewBool=true
                                />
                            </div>
                            <div class="mb-3 flex">
                                <label class="font-bold form-label mb-0 mr-2">Edit on portal:</label>
                                <ToggleSlide
                                      :disabled = true
                                      class="w-min"
                                      :bool="viewEditOnPortal"
                                      :renewBool=true
                                />
                            </div>
                            <div class="mb-3 flex">
                                <label class="font-bold form-label mb-0 mr-2">
									Generate onboarding access code:
								</label>
                                <ToggleSlide
                                      :disabled = true
                                      class="w-min"
                                      :bool="viewGenerateOnboardingAccessCode"
                                      :renewBool=true
                                />
                            </div>
                            <div class="mb-3 flex">
                                <label class="font-bold form-label mb-0 mr-2">QR on email:</label>
                                <ToggleSlide
                                      :disabled = true
                                      class="w-min"
                                      :bool="viewQrOnEmail"
                                      :renewBool=true
                                />
                            </div>
                            <div class="mb-3 flex">
                                <label for="viewQrOnPrint" class="font-bold form-label mb-0 mr-2">QR on print:</label>
                                <ToggleSlide
                                      :disabled = true
                                      class="w-min"
                                      :bool="viewQrOnPrint"
                                      :renewBool=true
                                />
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
import ToggleSlide from "../ToggleSlide.vue";
import Dropdown from "../Dropdown.vue";
export default {
    name: "ViewChannelMaintenance",
    components: {
        ToggleSlide,
        Dropdown
    },
    data(){
        return{
            defaultChannelOptions: [
                {id: 0, name: ""},
                {id: 1, name: "ARCHIVE"},
                {id: 2, name: "PRINT"},
            ],
            importMethodOptions: [
                {id: 0, name: ""},
                {id: 1, name: "MODIFY"},
                {id: 1, name: "OVERWRITE"},
            ],
        }
    },
    computed:{
        viewModifyChannel(){
            if (this.$store.state.activeSG.channelsConfig.docImage?.channelChoice?.modifyChannel !== undefined) {
               return this.$store.state.activeSG.channelsConfig.docImage.channelChoice.modifyChannel
            }
            return false
        },
        viewDefaultChannel(){
            if (this.$store.state.activeSG.channelsConfig.docImage?.channelChoice?.defaultChannel) {
                return this.$store.state.activeSG.channelsConfig.docImage.channelChoice.defaultChannel
            }
            return ''
        },
        viewDisplayOnPortal(){
            if (this.$store.state.activeSG.channelsConfig.docImage?.channelChoice?.displayOnPortal !== undefined) {
                return this.$store.state.activeSG.channelsConfig.docImage.channelChoice.displayOnPortal
            }
            return false
        },
        viewEditOnPortal(){
            if (this.$store.state.activeSG.channelsConfig.docImage?.channelChoice?.editOnPortal !== undefined) {
                return this.$store.state.activeSG.channelsConfig.docImage.channelChoice.editOnPortal
            }
            return false
        },
        viewGenerateOnboardingAccessCode(){
            if (this.$store.state.activeSG.channelsConfig.docImage?.channelChoice?.generateOnboardingAccessCode !== undefined) {
                return this.$store.state.activeSG.channelsConfig.docImage.channelChoice.generateOnboardingAccessCode
            }
            return false
        },
        viewQrOnEmail(){
            if (this.$store.state.activeSG.channelsConfig.docImage?.channelChoice?.qrOnEmail !== undefined) {
                return this.$store.state.activeSG.channelsConfig.docImage.channelChoice.qrOnEmail
            }
            return false
        },
        viewQrOnPrint(){
            if (this.$store.state.activeSG.channelsConfig.docImage?.channelChoice?.qrOnPrint !== undefined) {
                return this.$store.state.activeSG.channelsConfig.docImage.channelChoice.qrOnPrint
            }
            return false
        },
        viewAllowImport(){
            if (this.$store.state.activeSG.channelsConfig.docImage?.channelChoice?.allowImport !== undefined) {
                return this.$store.state.activeSG.channelsConfig.docImage.channelChoice.allowImport
            }
            return false
        },
        viewImportMethod(){
            if (this.$store.state.activeSG.channelsConfig.docImage?.channelChoice?.importMethod) {
                return this.$store.state.activeSG.channelsConfig.docImage.channelChoice.importMethod
            }
            return ''
        }
    }
}
</script>

<style scoped>
.min-height-600{
    min-height: 600px;
}
</style>
