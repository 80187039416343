<template>
	<pop-up
		:show="isPopupOpen"
		:show-close="false"
		fixed
		:style="{zIndex: 200}"
	>
		<template #header>Create environment Nymus API Express</template>
		<template #content>
			<div class="form-container-two-fields">
				<div class="width-input">
					<label for="name" class="font-bold form-label">Name:</label>
					<input type="text" class="mt-1 form-control" id="name" v-model.trim="environmentData.name"/>
				</div>
				<div class="width-input">
					<label for="entityName" class="font-bold form-label">Entity name: *</label>
					<input type="text"
						class="mt-1 form-control"
						id="entityName"
						v-model.trim="environmentData.entityName"
					/>
					<p v-if="errors.entityName" :class="errors.entityName ? 'error-message' : ''">
						Entity name can't be empty
					</p>
				</div>
			</div>

			<div class="form-container-two-fields">
				<div class="width-input">
					<label for="email" class="font-bold form-label">Email: *</label>
					<input type="email" class="mt-1 form-control" id="email" v-model.trim="environmentData.email"/>
					<p v-if="errors.email" :class="errors.email ? 'error-message': ''">Please enter a valid email</p>
				</div>
				<div class="width-input">
					<label for="phoneNumber" class="font-bold form-label">Phone number:</label>
					<input type="tel"
						class="mt-1 form-control"
						id="phoneNumber"
						v-model.trim="environmentData.phoneNumber"
					/>
					<p v-if="errors.phoneNumber" :class="errors.phoneNumber ? 'error-message' : ''">
						Please enter a valid phone number
					</p>
				</div>
			</div>
			<div class="form-container-two-fields">
				<div class="width-input">
					<label for="VatNumber" class="font-bold form-label">VAT number: </label>
					<input type="text"
						class="mt-1 form-control"
						id="vatNumber"
						v-model.trim="environmentData.vatNumber"/>
				</div>
				<div class="width-input">
					<label for="registrationNumber" class="font-bold form-label">Registration number:</label>
					<input type="text"
						class="mt-1 form-control"
						id="registrationNumber"
						v-model.trim="environmentData.registrationNumber"
					/>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end" style="margin-top: 10px">
				<div>
					<button class="btn shadow-md mr-2" @click="add">Create</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up>
</template>

<script>

import PopUp from "@/components/PopUp.vue";

export default {
	components: {PopUp},
	emits: ['closePopup', 'addedEnvironment'],
	props: {
		showPopup: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			isPopupOpen: false,
			environmentData: {},
			errors: {}
		}
	},
	mounted() {
		this.isPopupOpen = this.showPopup
		this.initData()
	},
	watch: {
		// is unused for inteliJ, but we do use this watch. !! DO NOT delete this !!
		showPopup(newVal) {
			this.isPopupOpen = newVal
		}
	},
	methods: {
		initData() {
			this.environmentData = {
				email: '',
				name: '',
				phoneNumber: '',
				entityName: '',
				vatNumber: '',
				registrationNumber: ''
			}
			this.errors = {
				email: false,
				phoneNumber: false,
			}
		},
		close() {
			this.initData()
			this.$emit('closePopup')
		},
		validate() {
			if (this.environmentData.email === '') this.errors.email = true
			else if (this.$store.state.emailRegex.test(this.environmentData.email) === false) this.errors.email = true
			if (this.environmentData.entityName === '') this.errors.entityName = true
			if (this.environmentData.phoneNumber !== '' && this.$store.state.phoneNumberRegex.test(this.environmentData.phoneNumber) === false) this.errors.phoneNumber = true
		},
		async add() {
			this.validate()
			if (this.errors.email === true ||
				this.errors.phoneNumber === true || this.errors.entityName === true) {
				return
			}
			if (this.environmentData.name === '') {
				delete this.environmentData.name
			}
			if (this.environmentData.phoneNumber === '') {
				delete this.environmentData.phoneNumber
			}
			if (this.environmentData.vatNumber === '') {
				delete this.environmentData.vatNumber
			}
			if (this.environmentData.registrationNumber === '') {
				delete this.environmentData.registrationNumber
			}

			const payload = {
				environmentData: this.environmentData,
				supplierGroupId: this.$route.params.id
			}

			await this.$store.dispatch("AddEnvironmentNymusX", payload)

			this.initData()
			this.$emit("addedEnvironment")
			this.$emit("closePopup")
		}
	}
}
</script>

<style scoped>
.form-container-two-fields {
	display: flex;
	flex-direction: row;
	justify-content: space-between
}

.width-input {
	width: 45%
}
</style>