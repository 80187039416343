<template>
	<pop-up-window
		:show="this.$store.state.displayEditBranding"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit Branding</template>
		<template #content>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-1/2 mr-12">
					<!--MainColor-->
					<div class="mb-3">
						<label for="mainColor" class="font-bold form-label"
						>Main Color: *</label
						>
						<div class="grid-cols-3 gap-2">
							<div class="input-group">
								<div
									id="mainColor"
									class="input-group-text"
									:style="{'background-color': formData.mainColor, }"
								></div>
								<input
									ref="mainColor"
									type="text"
									@input="validate"
									:class="{ 'border border-primary-3': mainColorInvalid }"
									class="form-control"
									v-model="formData.mainColor"
								/>
							</div>
							<span v-if="mainColorInvalid" class="text-primary-3">{{
									mainColorInvalidText
								}}</span>
						</div>
					</div>
					<!--Max Report Days-->
					<Field type="number" v-model.number="formData.maxReportDays" title="Maximum Report Days:" id="editMaximumReportDays"/>
					<Field
						@click="clearErrors"
						v-model="contactEmail"
						title="Contact email:"
						id="editContactEmail"/>
					<p v-if="emailError" class="error">Please, enter valid email</p>

					<div class="toggle-container mt-2 mb-2">
						<label for="hidePasswordReset" class="font-bold form-label mb-0 mr-2">
							Hide password reset:
						</label>

						<div id="hidePasswordReset">
							<ToggleSlide
								@update-value="updateToggleData($event, 'hidePasswordReset')"
								class="w-min"

								:bool="formData.hidePasswordReset"
							/>
						</div>
					</div>
					<div class="toggle-container mt-2 mb-2">
						<label for="generalPost" class="font-bold form-label mb-0 mr-2">
							NymDrop:
						</label>

						<div id="generalPost">
							<ToggleSlide
								@update-value="updateToggleData($event, 'generalPost')"
								class="w-min"

								:bool="formData.generalPost"
							/>
						</div>
					</div>

					<div class="toggle-container mt-2 mb-2">
						<label for="editBilling" class="font-bold form-label mb-0 mr-2">
							Billing:
						</label>

						<div id="editBilling">
							<ToggleSlide
								@update-value="updateToggleData($event, 'billing')"
								class="w-min"

								:bool="formData.billing"
							/>
						</div>
					</div>
					<div class="toggle-container mt-2 mb-2">
						<label for="editPeppol" class="font-bold form-label mb-0 mr-2">
							Peppol:
						</label>

						<div id="editPeppol">
							<ToggleSlide
								@update-value="updateToggleData($event, 'peppol')"
								class="w-min"

								:bool="formData.peppol"
							/>
						</div>
					</div>

					<!--External Portal links-->
					<div class="mb-3">
						<p class="font-bold form-label mr-2">External portal links:</p>
						<button
							@click="displayAddExternalPortalLink"
							class="btn bg-theme-1 py-0 text-white shadow-md ml-2">
							Add
						</button>
						<div v-if="formData.externalPortalLinks">
							<div
								:key="'edit' + item + index"
								v-for="(item, index) of Object.keys(formData.externalPortalLinks)"
								class="flex flex-row input-group w-full mb-2">
								<div class="input-group-text">
									{{ item }}
								</div>
								<input
									type="text"
									class="form-control"
									@change="updateExternalPortalLink(item, $event.target.value, 'link')"
									:value="formData.externalPortalLinks[item].link"
								/>


								<input
									type="text"
									class="form-control"
									@change="updateExternalPortalLink(item, $event.target.value, 'text')"
									:value="formData.externalPortalLinks[item].text"
								/>
								<div
									@click="deleteExternalPortalLinks(item)"
									class="font-bold cursor-pointer input-group-text"
								>
									<font-awesome-icon icon="trash-can"/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex flex-col w-1/2">
					<!--Banner-->
					<FileUpload
						@update-value="changeBannerValue"
						id="editBrandingBanner"
						title="Banner: *"
						:invalid="bannerInvalid"
						:data="formData.banner"
						accept="image/png, image/jpeg"
					/>
					<span v-if="bannerInvalid">Select a banner</span>
					<!--Logo-->
					<FileUpload
						@update-value="changeLogoValue"
						id="editBrandingLogo"
						title="Logo: *"
						:invalid="logoInvalid"
						:data="formData.logo"
						accept="image/png, image/jpeg"
					/>
					<span v-if="logoInvalid">Select a logo</span>
					<!--Favicon-->
					<FileUpload
						@update-value="changeFaviconValue"
						@delete-value="deleteFaviconValue"
						id="editBrandingFavicon"
						title="Favicon:"
						:data="formData.favicon"
						accept=".ico"
						:delSuffix="true"
					/>

					<label for="editBrandingDocTypes" class="font-bold form-label">
						Searchable doc types:*
					</label>
					<DropdownSelectAll
						v-if="renderComponent"
						id="editBrandingDocTypes"
						@update-value="updateSelectedDocTypes"
						:data="docTypes"
						:selected=formData.searchableDocTypes

					/>
					<span v-if="searchableDocTypesError" class="text-primary-3">
                        Please, select at least one doc type
                    </span>
					<label for="editBrandingDisplayableDocTypes" class="font-bold form-label">
						Displayable doc types:
					</label>
					<DropdownSelectAll
						id="editBrandingDisplayableDocTypes"
						@update-value="updateSelectedDisplayableDocTypes"
						:data="docTypes"
						:selected="formData.displayableDocTypes"
					/>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
	<AddExternalPortalLink
		@add-externalPortalLink="addExternalPortalLink"
		:languages="languages"
	/>
</template>
<script>
import PopUpWindow from "../PopUp.vue";
import FileUpload from "../FileUpload.vue";
import Field from "../Field.vue"
import AddExternalPortalLink from "../AddSupplierGroup/AddExternalPortalLink.vue";
import DropdownSelectAll from "../DropdownSelectAll.vue";
import ToggleSlide from "../ToggleSlide.vue";

export default {
	name: "EditBranding",
	components: {
		PopUpWindow,
		FileUpload,
		Field,
		AddExternalPortalLink,
		DropdownSelectAll,
		ToggleSlide
	},
	props: {
		languages: Array,
	},

	data() {
		return {
			supplierGroup: this.$store.state.activeSG,
			regex: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
			formData: {
				mainColor:
				this.$store.state.activeSG.channelsConfig.docImage.configPortal
					.mainColor,
				maxReportDays: this.$store.state.activeSG.channelsConfig.docImage.configPortal.maxReportDays,
				externalPortalLinks: this.$store.state.activeSG.channelsConfig.docImage.configPortal.externalPortalLinks ? JSON.parse(JSON.stringify(this.$store.state.activeSG.channelsConfig.docImage.configPortal.externalPortalLinks))
					: {},
				banner: this.$store.state.activeSG.channelsConfig.docImage.configPortal.banner,
				hidePasswordReset: this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.hidePasswordReset
						? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.hidePasswordReset
						: false
					: false,
				generalPost: this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost
						? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost
						: false
					: false,
				billing: this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.billing
						? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.billing
						: false
					: false,
				peppol: this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.peppol
						? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.peppol
						: false
					: false,

				logo: this.$store.state.activeSG.channelsConfig.docImage.configPortal.logo,
				favicon: this.$store.state.activeSG.channelsConfig.docImage.configPortal.favicon,
				searchableDocTypes: this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.searchableDocTypes
						? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.searchableDocTypes
						: []
					: [],
				displayableDocTypes: this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.displayableDocTypes
						? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.displayableDocTypes
						: []
					: []
			},

			contactEmail: this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
				? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.contactEmail
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.contactEmail
					: ""
				: ""
			,

			mainColorInvalid: false,
			mainColorInvalidText: "",
			bannerInvalid: false,
			logoInvalid: false,
			emailError: false,
			searchableDocTypesError: false,
			renderComponent: true,
			docTypes: this.$store.state.docTypes

		};
	},

	computed: {
		activeSG() {
			return this.$store.state.activeSG
		},

	},
	watch: {
		activeSG(){
			this.initData()
		}
	},
	methods: {
		updateToggleData(data, field) {
			this.formData[field] = data;
		},
		updateSelectedDocTypes(data) {
			this.searchableDocTypesError = false;

			let foundArr = [];
			for (let index of data) {
				let found = this.docTypes[index].name;
				foundArr.push(found);
			}
			this.formData.searchableDocTypes = foundArr;
		},

		updateSelectedDisplayableDocTypes(data) {
			let foundArr = [];
			for (let index of data) {
				let found = this.docTypes[index].name;
				foundArr.push(found);
			}
			this.formData.displayableDocTypes = foundArr;
		},
		clearErrors() {
			this.emailError = false;
		},
		initData() {
			this.formData.mainColor =
				this.$store.state.activeSG.channelsConfig.docImage.configPortal.mainColor;
			this.formData.externalPortalLinks = this.$store.state.activeSG.channelsConfig.docImage.configPortal.externalPortalLinks
				? JSON.parse(
					JSON.stringify(
						this.$store.state.activeSG.channelsConfig.docImage.configPortal.externalPortalLinks
					)
				)
				: {};
			this.formData.maxReportDays = this.$store.state.activeSG.channelsConfig.docImage.configPortal.maxReportDays
			this.formData.banner =
				this.$store.state.activeSG.channelsConfig.docImage.configPortal.banner;
			this.formData.logo =
				this.$store.state.activeSG.channelsConfig.docImage.configPortal.logo;
			this.formData.favicon =
				this.$store.state.activeSG.channelsConfig.docImage.configPortal.favicon;
			this.formData.searchableDocTypes = this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
				? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.searchableDocTypes
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.searchableDocTypes
					: []
				: []
			this.formData.hidePasswordReset = this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
				? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.hidePasswordReset
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.hidePasswordReset
					: false
				: false;
			this.formData.displayableDocTypes = this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
				? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.displayableDocTypes
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.displayableDocTypes
					: []
				: []
			this.formData.generalPost = this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
				? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost
					: false
				: false;
			this.formData.billing = this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
				? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.billing
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.billing
					: false
				: false;
			this.formData.peppol = this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
				? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.peppol
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.peppol
					: false
				: false;


			this.mainColorInvalid = false;
			this.bannerInvalid = false;
			this.logoInvalid = false;
			this.formData.contactEmail = '';
			this.contactEmail = this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
				? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.contactEmail
					? this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.contactEmail
					: ""
				: "";
			this.emailError = false;
		},
		changeBannerValue(event) {
			this.formData.banner = event.value;
			this.validate()
		},
		changeLogoValue(event) {
			this.formData.logo = event.value;
			this.validate()
		},
		changeFaviconValue(event) {
			this.formData.favicon = event.value;
		},
		deleteFaviconValue(event) {
			this.formData.favicon = null;
		},
		displayAddExternalPortalLink() {
			this.$store.state.displayAddExternalPortalLink = true;
		},
		addExternalPortalLink(event) {
			let payload = {
				link: event.link,
				text: event.text,
			};
			this.formData.externalPortalLinks[event.language] = payload;
		},
		updateExternalPortalLink(item, value, field) {
			this.formData.externalPortalLinks[item][field] = value;
		},
		deleteExternalPortalLinks(item) {
			delete this.formData.externalPortalLinks[item];
		},
		close() {
			this.initData();
			this.$store.state.displayEditBranding = false;
		},
		validateEmail(email) {
			let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return email.match(pattern);
		},
		async update() {
			let hasErrors = this.validate();
			if (!hasErrors) {
				if (!this.formData.favicon) {
					delete this.formData.favicon
				}
				if (this.formData.externalPortalLinks && Object.keys(this.formData.externalPortalLinks).length === 0) {
					delete this.formData.externalPortalLinks
				}
				if (this.formData.displayableDocTypes && this.formData.displayableDocTypes.length === 0) {
					delete this.formData.displayableDocTypes
				}

				if (this.contactEmail == '') {
					delete this.formData.contactEmail
				} else {
					if (this.validateEmail(this.contactEmail)) {
						this.formData.contactEmail = this.contactEmail
					} else {
						this.emailError = true;
						return
					}
				}

				let payload = {
					sgId: this.supplierGroup.supplierGroupId,
					edit: "branding",
					body: this.formData,
				};

				await this.$store.dispatch("EditSupplierGroup", payload);
				if (this.$store.state.responseStatus === 200) {
					this.$store.commit("toggleAlert", {
						title: "Edit SupplierGroup",
						text: "Branding successfully edited",
					});
				}
				this.close();


			}
		},
		validate() {
			this.mainColorInvalid = false;
			this.bannerInvalid = false;
			this.logoInvalid = false;
			let hasErrors = false;
			if (!this.regex.test(this.formData.mainColor)) {
				this.mainColorInvalid = true;
				this.mainColorInvalidText = "Use #fff or #ffffff format";
				if (this.formData.mainColor === "") {
					this.mainColorInvalidText = "Main color is required";
				}
				hasErrors = true;
			}
			if (this.formData.banner === "") {
				this.bannerInvalid = true;
				hasErrors = true;
			}
			if (this.formData.logo === "") {
				this.logoInvalid = true;
				hasErrors = true;
			}

			if (this.formData.searchableDocTypes.length === 0) {
				this.searchableDocTypesError = true;
				hasErrors = true;
			}
			return hasErrors;
		},
	},
};
</script>

<style scoped>
.toggle-container {
	display: grid;
	grid-template-columns: 160px auto;
	grid-template-rows: 100%;
	grid-auto-flow: row;
}
</style>
