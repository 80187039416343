<template>
	<div class="box shadow-none col-span-6 min-height-600">
		<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
			<div class="button-container">
				<button
					class="btn shadow-md bg-theme-1 text-white mr-2"
					@click="addNewOverlayTemplate">
					Add new template
				</button>
			</div>

			<div v-if="activeItemHasOverlayTemplate">
				<div class="flex flex-col w-1/2">
					<label for="overlayTemplateName" class="font-bold form-label">
						Select overlay template:
					</label>
					<Dropdown
						id="overlayTemplateName"
						@update-value="updateSelectedName"
						:class="{ 'border border-primary-3': selectedOverlayInvalid }"
						class="pr-2"
						:data="overlayTemplateNames"
						:selected="selectedTemplateName"
					/>
				</div>
				<div
					class="flex flex-row flex-nowrap">
					<div class="flex flex-col w-1/2 mr-2">
						<div class="mb-3">
							<label for="overlayTemplateOverlayModeView" class="font-bold form-label mt-2">
								Overlay mode:*
							</label>
							<Dropdown
								id="overlayTemplateOverlayModeView"
								class=""
								:data="overlayOverlayData"
								:key="activeTemplate.overlayMode"
								:selected="activeTemplate.overlayMode"
								:disabled="true"
							/>

							<label for="overlayTemplateDocTypesView" class="font-bold form-label mt-2">
								Overlay doc types:*
							</label>
							<DropdownSelectAll
								v-if="renderComponent"
								id="overlayTemplateDocTypesView"
								:data="overlayDocTypes"
								:selected=activeTemplate.docTypes
								:disabled="true"
							/>
						</div>
					</div>
					<div class="flex flex-col w-1/2 ml-2">
						<div class="mb-3">
							<label for="overlayTemplateOverlayModeView" class="font-bold form-label mt-2">
								Overlay position:
							</label>
							<Dropdown
								id="overlayPosition"
								class=""
								:data="overlayPositionData"
								:key="activeTemplate.overlayPosition"
								:selected="activeTemplate.overlayPosition ? activeTemplate.overlayPosition : 'BACKGROUND'"
								:disabled="true"
							/>
							<div v-if="activeTemplate.location">
								<p class="font-bold form-label mt-2">Overlay file:*</p>
								<div
									:key="item+index"
									v-for="(item, index) in Object.keys(activeTemplate.location)"
									class="flex flex-row"
								>
									<div class="input-group mb-1 w-full">
										<div id="manuals" class="input-group-text">
											{{ item }}
										</div>
										<input
											disabled
											type="text"
											class="form-control"
											:value="activeTemplate.location[item].fileName"
										/>

										<div class="input-group-text" @click="download(activeTemplate.location[item].url)" style="cursor: pointer">
											<span class="material-icons" style="font-size: 20px">file_download</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<p v-if="!activeItemHasOverlayTemplate">No overlay templates </p>
		</div>
		<add-overlay-template :isFor=this.isFor></add-overlay-template>
	</div>
</template>
<script>
import Dropdown from "../../Dropdown.vue"
import DropdownSelectAll from "../../DropdownSelectAll.vue"
import AddOverlayTemplate from "./AddOverlayTemplate.vue"

export default {
	name: 'ViewOverlayTemplate',
	components: {
		AddOverlayTemplate,
		Dropdown,
		DropdownSelectAll
	},
	props: {
		isFor: String,
	},
	data() {
		return {
			activeItem: {},
			selectedTemplate: 0,
			selectedTemplateName: '',
			activeTemplate: {},
			overlayDocTypes: this.$store.state.docTypes,
			overlayOverlayData: this.$store.state.overlayMode,
			renderComponent: true,
			overlayPositionData: [
				{id: 0, name: "BACKGROUND"},
				{id: 1, name: "FOREGROUND"},
			],
		}
	},
	watch: {
		activeSg(newVal, oldVal) {
			if (this.isFor === 'supplierGroup') {
				this.activeItem = newVal
				this.updateActiveTemplate()
				this.forceRerender()

				if (oldVal.templates.overlayTemplates === undefined) {
					this.setActiveTemplate();
				}
			}
		},
		activeLe(newVal, oldVal) {
			if (this.isFor === 'legalEntity') {
				this.activeItem = newVal;
				this.updateActiveTemplate();
				this.forceRerender();

				if (oldVal !== undefined && oldVal.templates !== undefined && oldVal.templates.overlayTemplates === undefined) {
					this.setActiveTemplate();
				}
			}
		},
		overlayTemplateDeleted(newVal) {
			if (newVal) {
				this.setActiveTemplate()
				this.$store.commit("overlayTemplateDeleted", false)
			}
		}
	},
	computed: {
		overlayTemplateDeleted() {
			return this.$store.state.overlayTemplateDeleted
		},
		activeLe() {
			return this.$store.state.activeLE
		},
		activeSg() {
			return this.$store.state.activeSG
		},
		activeItemHasOverlayTemplate() {
			return !!((this.activeItem && this.activeItem.templates && this.activeItem.templates.overlay && this.activeItem.templates && this.activeItem.templates.overlay.length !== 0)
				|| (this.activeItem && this.activeItem.templates && this.activeItem.templates.overlayTemplates && this.activeItem.templates && this.activeItem.templates.overlayTemplates.length !== 0));
		},
		activeTemplateExists() {
			return Object.keys(this.activeItem).length !== 0;
		},
		overlayTemplateNames() {
			let names = []
			let templates = []
			if (this.activeItem.templates && this.activeItem.templates.overlayTemplates) {
				templates = this.activeItem.templates.overlayTemplates
				templates.forEach((template, index) => {
					names.push({id: index, name: template.name})
				})
			}
			if (this.activeItem.templates && this.activeItem.templates.overlay) {
				templates = [this.activeItem.templates.overlay]
				templates.forEach((template, index) => {
					names.push({id: index, name: template.name})
				})
			}
			return names
		}
	},
	mounted() {
		this.initData()
		this.setActiveTemplate()
	},
	methods: {
		addNewOverlayTemplate() {
			this.$store.state.displayAddOverlayTemplate = true;
		},
		download(pdfPath){
			window.open(pdfPath)
		},
		updateActiveTemplate() {
			if (this.activeItem.templates && this.activeItem.templates.overlayTemplates) {
				this.activeTemplate = this.activeItem.templates.overlayTemplates.find(item => (item.name === this.selectedTemplateName));
			}
			if (this.activeItem.templates && this.activeItem.templates.overlay) {
				this.activeTemplate = this.activeItem.templates.overlay;
			}
		},
		updateSelectedName(data) {
			this.selectedTemplateName = this.overlayTemplateNames.find((item) => (item.id == data)).name;
			this.$store.commit("setSelectedOverlayTemplateName", this.selectedTemplateName);
			this.updateActiveTemplate();
			this.forceRerender();
		},
		forceRerender() {
			// Removing my-component from the DOM
			this.renderComponent = false

			this.$nextTick(() => {
				// Adding the component back in
				this.renderComponent = true
			})
		},
		setActiveTemplate() {
			if (this.overlayTemplateNames.length) {
				if (this.activeItem.templates && this.activeItem.templates.overlayTemplates) {
					this.activeTemplate = this.activeItem.templates.overlayTemplates[0]
					this.selectedTemplateName = this.overlayTemplateNames[0].name
					this.selectedTemplate = this.overlayTemplateNames[0].name
				}
				if (this.activeItem.templates && this.activeItem.templates.overlay) {
					this.activeTemplate = this.activeItem.templates.overlay
					this.selectedTemplateName = this.overlayTemplateNames[0].name
					this.selectedTemplate = this.overlayTemplateNames[0].name
				}
			} else {
				this.activeTemplate = {}
				this.selectedTemplateName = ''
			}
		},
		initData() {
			if (this.isFor === 'supplierGroup') {
				this.activeItem = this.$store.state.activeSG
			}
			if (this.isFor === 'legalEntity') {
				this.activeItem = this.$store.state.activeLE
			}
		}
	}
}
</script>
<style scoped>
.button-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	justify-items: end;
}

</style>
