<template>
  <pop-up-window
    :show="this.$store.state.displayAddManual"
    :showClose="false"
    @close="close"
    class="doc-statuses-popup"
    fixed
  >
    <template #header>Add Manuel</template>
    <template #content>
      <div class="flex flex-row">
        <div class="flex flex-col mb-1 w-1/2">
          <div class="mb-3 mr-3">
            <label for="manualLanguage" class="font-bold form-label"
              >Language:</label
            >
            <Dropdown
              id="manualLanguage"
              @update-value="updateSelectedLanguage"
              :data="languages"
              :selected="languages[0].name"
            />
          </div>
          <FileUpload @update-value="changeManualValue" accept=".pdf" :invalid="fileInvalid" title="Choose File:*" id="addManual" :data="selectedFile"/>
          <span v-if="fileInvalid" class="text-primary-3">Select a Manual file</span>
        </div>
      </div>

      <div class="grid grid-rows-1 justify-items-end">
        <div>
          <button class="btn shadow-md mr-2" @click="add">Add</button>
          <button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
        </div>
      </div>
    </template>
  </pop-up-window>
</template>

<script>
import PopUpWindow from "../PopUp.vue";
import FileUpload from "../FileUpload.vue"
import Dropdown from "../Dropdown.vue";
export default {
  name: "AddXsdFiles",
  components: {
    PopUpWindow,
    FileUpload,
    Dropdown,
  },
  props: {
    supplierGroup: Object,
  },
  data() {
    return {
      languages: [],
      selectedLang: "",
      selectedFile: "",
      file: "",
      fileInvalid: false,
    };
  },
  mounted() {
    let index = 0;
    let obj = {};
    if (this.supplierGroup.languages) {
      for (let lang of this.supplierGroup.languages) {
        obj = { id: index, name: lang };
        this.languages[index] = obj;
        index++;
      }
    }
  },
  emits: ["add-manual"],
  methods: {
    changeManualValue(event) {
      this.selectedFile = event.value
      this.file = event.files[0]
      this.validate()
    },
    updateSelectedLanguage(data) {
      if (data) {
        this.selectedLang = this.languages[data].name;
      }
    },
    close() {
      this.selectedFile = "";
      this.file = "";
      this.fileInvalid = false;
      this.$store.state.displayAddManual = false;
    },
    add() {
      let hasErrors = this.validate();
      if (!hasErrors) {
        let data = {};
        data[this.selectedLang] = this.selectedFile
        this.$emit("add-manual", data);
        this.close()
      }
    },
    validate() {
      this.fileInvalid = false;
      let hasErrors = false;
      if (!this.file) {
        this.fileInvalid = true;
        hasErrors = true;
      }
      return hasErrors
    },
  },
};
</script>

<style scoped>
</style>
