<template>
	<pop-up-window
		:show="this.$store.state.displayEditSettings"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit System</template>
		<template #content>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-1/2 mr-12">
					<!--Allow Duplicate Document Numbers-->
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">
							Allow duplicate document numbers:
						</label>
						<ToggleSlide
							@update-value="updateDupDocBool"
							class="w-min"
							:bool="formData.allowDuplicateDocumentNumbers"
						/>
					</div>
					<!--Move address location in ingestion flag-->
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">
							Move address location in ingestion flag:
						</label>
						<ToggleSlide
							@update-value="updateAddressBool"
							class="w-min"
							:bool="formData.movePdfAddressLocation"
						/>
					</div>
					<p class="font-bold mr-3">Public link</p>
					<!--Allow Login Public Page-->
					<div class="mb-3 ml-6 flex">
						<label class="font-bold form-label mb-0 mr-2">
							Allow login on public page:
						</label>
						<ToggleSlide
							@update-value="updateLoginPublicBool"
							class="w-min"
							:bool="formData.allowLogin"
						/>
					</div>
					<!--Public Link Expiration-->
					<Field
						class="ml-6"
						id="publicExpiration"
						type="number"
						title="Public Link Expiration:"
						v-model.number="formData.publicLinkExpirationDays"
					/>
					<!--enableSigning-->
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">
							Signing:
						</label>
						<ToggleSlide
							@update-value="updateSigningBool"
							class="w-min"
							:bool="formData.enableSigning"
						/>
					</div>

					<div v-if="formData.enableSigning" class="mb-3 ml-3">
						<label class="font-bold form-label mb-0 mr-2">
							Signing provider: *
						</label>
						<Dropdown
							id="editSigningProvider"
							@update-value="updateSigningProvider"
							:data="signingProviderData"
							:key="signingProvider"
							:selected="signingProvider"
						/>
						<div>
                            <span v-if="signingProviderError" class="text-primary-3">
								Signing provider can not be
							</span>
						</div>
						<label class="font-bold form-label mb-0 mr-2">
							Doc types:
						</label>
						<DropdownSelectAll
							id="editDocTypes"
							:class="{ 'border border-primary-3': selectedOverlayInvalid }"
							@update-value="updateSelectedDocTypes"
							:data="docTypes"
							:selected=signingDocTypes
							:noSelectAll="true"
						/>
						<div>
                            <span v-if="signingDocTypesError" class="text-primary-3">
								Please, select at least one doc type
							</span>
						</div>
					</div>
					<!--enableTimestamping-->
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">
							Timestamping:
						</label>
						<ToggleSlide
							@update-value="updateTimestampBool"
							class="w-min"
							:key="formData.enableTimestamping"
							:bool="formData.enableTimestamping"
						/>
					</div>
				</div>
				<div class="flex flex-col w-1/2">
					<!--Destroy billingdocument Request-->
					<Field
						id="editSettingsReportUrl"
						@input="validate"
						placeholder="https://example.com"
						:hasError="reportUrlInvalid"
						title="Google Data Studio URL:"
						type="text"
						v-model="formData.reportUrl"
					/>
					<span v-if="reportUrlInvalid" class="text-primary-3">Google Data URL must be a valid URL</span>
					<Field
						id="destroyRequest"
						type="number"
						title="Destroy billingdocument request:"
						v-model.number="formData.destroyPeriod"
					/>
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">
							Autoreprint config:
						</label>
						<ToggleSlide
							@update-value="autoReprintConfig"
							class="w-min"
							:bool="autoReprintConfigData"
						/>
					</div>
					<div class="mx-6" v-if="autoReprintConfigData">
						<div class="mb-3 flex">
							<label class="font-bold form-label mb-0 mr-2">
								Attach email reprint:
							</label>
							<ToggleSlide
								@update-value="attachEmailReprint"
								class="w-min"
								:bool="formData.autoReprintConfig.attachEmailReprint"
							/>
						</div>
						<div class="mb-3 flex">
							<label class="font-bold form-label mb-0 mr-2">
								Link email reprint:
							</label>
							<ToggleSlide
								@update-value="linkEmailReprint"
								class="w-min"
								:bool="formData.autoReprintConfig.linkEmailReprint"
							/>
						</div>
						<div class="mb-3 flex">
							<label class="font-bold form-label mb-0 mr-2">
								EDI reprint:
							</label>
							<ToggleSlide
								@update-value="ediReprint"
								class="w-min"
								:bool="formData.autoReprintConfig.ediReprint"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2 " @click="update">
						Update
					</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">
						Cancel
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../PopUp.vue";
import Field from "../Field.vue"
import ToggleSlide from "../ToggleSlide.vue";
import Dropdown from "../Dropdown.vue";
import DropdownSelectAll from "../DropdownSelectAll.vue";

export default {
	name: "EditSettings",
	components: {
		PopUpWindow,
		Field,
		ToggleSlide,
		Dropdown,
		DropdownSelectAll
	},
	computed: {
		activeSG() {
			return this.$store.state.activeSG
		},
		signingDocTypes() {
			if (this.$store.state.activeSG.channelsConfig?.signing?.docTypes) {
				return this.$store.state.activeSG.channelsConfig.signing.docTypes
			}
			return []
		},
		signingProvider() {
			if (this.$store.state.activeSG.channelsConfig?.signing?.provider) {
				return this.$store.state.activeSG.channelsConfig.signing.provider
			}
			return ''
		}
	},
	watch: {
		activeSG() {
			this.initData()
		}
	},
	data() {
		return {
			formData: {
				allowDuplicateDocumentNumbers: this.$store.state.activeSG.channelsConfig.docImage.allowDuplicateDocumentNumbers ? this.$store.state.activeSG.channelsConfig.docImage.allowDuplicateDocumentNumbers : false,
				movePdfAddressLocation: this.$store.state.activeSG.channelsConfig.docImage.movePdfAddressLocation ? this.$store.state.activeSG.channelsConfig.docImage.movePdfAddressLocation : false,
				allowLogin: this.$store.state.activeSG.channelsConfig.docImage.allowLogin ? this.$store.state.activeSG.channelsConfig.docImage.allowLogin : false,
				publicLinkExpirationDays: this.$store.state.activeSG.channelsConfig.docImage.publicLinkExpirationDays,
				enableSigning: this.$store.state.activeSG.channelsConfig.docImage.enableSigning ? this.$store.state.activeSG.channelsConfig.docImage.enableSigning : false,
				signing: {
					provider: this.$store.state.activeSG.channelsConfig.signing
						? this.$store.state.activeSG.channelsConfig.signing.provider
						: "",
					docTypes: this.$store.state.activeSG.channelsConfig.signing
						? this.$store.state.activeSG.channelsConfig.signing.docTypes
						: [],
				},

				enableTimestamping: this.$store.state.activeSG.channelsConfig.docImage.enableTimestamping
					? this.$store.state.activeSG.channelsConfig.docImage.enableTimestamping : false,

				destroyPeriod: this.$store.state.activeSG.channelsConfig.docImage.destroyPeriod,
				reportUrl: this.$store.state.activeSG.channelsConfig.docImage.configPortal.reportUrl,
				autoReprintConfig: this.$store.state.activeSG.channelsConfig.docImage.autoReprintConfig ?
					this.$store.state.activeSG.channelsConfig.docImage.autoReprintConfig :
					{
						attachEmailReprint: false,
						linkEmailReprint: false,
						ediReprint: false,
					}
			},
			signingProviderData: [
				{id: 3, name: ""},
				{id: 0, name: "OPTIPOST"},
				{id: 1, name: "NYMUS"}],
			docTypes: [
				{id: 0, name: ""},
				{id: 1, name: "INVOICE"},
				{id: 2, name: "CREDIT_NOTE"},
				{id: 3, name: "SALARY"},
				{id: 4, name: "CAR_INSURANCE"},
				{id: 5, name: "MANDATE_ONBOARDING"},
				{id: 6, name: "GENERIC"},
			],
			providerInvalid: false,
			payPageInvalid: false,
			reportUrlInvalid: false,
			signingProviderError: false,
			signingDocTypesError: false,

			autoReprintConfigData: !!this.$store.state.activeSG.channelsConfig.docImage.autoReprintConfig
		}
	},
	methods: {
		initData() {
			this.formData.allowDuplicateDocumentNumbers = this.$store.state.activeSG.channelsConfig.docImage.allowDuplicateDocumentNumbers
			this.formData.allowLogin = this.$store.state.activeSG.channelsConfig.docImage.allowLogin
			this.formData.publicLinkExpirationDays = this.$store.state.activeSG.channelsConfig.docImage.publicLinkExpirationDays
			this.formData.enableSigning = this.$store.state.activeSG.channelsConfig.docImage.enableSigning ? this.$store.state.activeSG.channelsConfig.docImage.enableSigning : false
			this.formData.signing = {
				provider: this.$store.state.activeSG.channelsConfig.signing ? this.$store.state.activeSG.channelsConfig.signing.provider : "",
				docTypes: this.$store.state.activeSG.channelsConfig.signing ? this.$store.state.activeSG.channelsConfig.signing.docTypes : [],
			}

			this.formData.enableTimestamping = this.$store.state.activeSG.channelsConfig.docImage.enableTimestamping ? this.$store.state.activeSG.channelsConfig.docImage.enableTimestamping : false

			this.formData.destroyPeriod = this.$store.state.activeSG.channelsConfig.docImage.destroyPeriod
			this.formData.reportUrl = this.$store.state.activeSG.channelsConfig.docImage.configPortal.reportUrl

			this.providerInvalid = false
			this.payPageInvalid = false
			this.reportUrlInvalid = false
			this.signingDocTypesError = false
			this.formData.autoReprintConfig = this.$store.state.activeSG.channelsConfig.docImage.configPortal.autoReprintConfig ?
				this.$store.state.activeSG.channelsConfig.docImage.configPortal.autoReprintConfig :
				{
					attachEmailReprint: false,
					linkEmailReprint: false,
					ediReprint: false,
				}
		},

		autoReprintConfig(data) {
			this.autoReprintConfigData = data
			if (!this.formData.autoReprintConfig) {
				this.formData.autoReprintConfig =
					{
						attachEmailReprint: false,
						linkEmailReprint: false,
						ediReprint: false,
					}
			}
		},
		attachEmailReprint(data) {
			this.formData.autoReprintConfig.attachEmailReprint = data
		},
		linkEmailReprint(data) {
			this.formData.autoReprintConfig.linkEmailReprint = data
		},
		ediReprint(data) {
			this.formData.autoReprintConfig.ediReprint = data
		},

		updateSelectedDocTypes(data) {
			this.signingDocTypesError = false
			this.docTypesError = false

			let foundArr = []
			for (let index of data) {
				let found = this.docTypes[index].name
				foundArr.push(found)
			}
			if (this.formData.signing === undefined) {
				this.formData.signing = {}
			}
			this.formData.signing.docTypes = foundArr
		},

		updateSigningProvider(data) {
			this.signingProviderError = false
			if (!this.formData.enableSigning) {
				this.formData.signing.provider = null
			}
			this.formData.signing.provider = this.signingProviderData[data].name
		},

		updateDupDocBool(data) {
			this.formData.allowDuplicateDocumentNumbers = data
		},
		updateAddressBool(data) {
			this.formData.movePdfAddressLocation = data
		},
		updateLoginPublicBool(data) {
			this.formData.allowLogin = data
		},
		updateSigningBool(data) {
			this.signingProviderError = false
			this.formData.enableSigning = data
		},
		updateTimestampBool(data) {
			this.formData.enableTimestamping = data
		},
		close() {
			this.initData()
			this.$store.state.displayEditSettings = false
		},
		async update() {
			let hasErrors = this.validate()
			if (!hasErrors) {
				if (!this.boolPayment) {
					delete this.formData.payment
				}
				if (this.formData.reportUrl === "") {
					delete this.formData.reportUrl
				}
				if (!this.autoReprintConfigData) {
					delete this.formData.autoReprintConfig
				}

				if (!this.formData.enableSigning) {
					delete this.formData.signing
				}

				if (this.formData.publicLinkExpirationDays === '') {
					delete this.formData.publicLinkExpirationDays
				}

				let payload = {
					sgId: this.$store.state.activeSG.supplierGroupId,
					edit: 'settings',
					body: this.formData
				}

				await this.$store.dispatch('EditSupplierGroup', payload)
				if (this.$store.state.responseStatus === 200) {
					this.$store.commit("toggleAlert", {
						title: "Edit SupplierGroup",
						text: "Settings successfully edited",
					})
				}
				this.$store.state.displayEditSettings = false
			}
		},
		validate() {
			this.providerInvalid = false
			this.payPageInvalid = false
			this.reportUrlInvalid = false
			let hasErrors = false

			if (this.formData.reportUrl && this.formData.reportUrl !== "") {
				if (!this.$store.state.urlRegex.test(this.formData.reportUrl)) {
					hasErrors = true
					this.reportUrlInvalid = true
				}
			}
			if ((this.formData.enableSigning && this.formData.signing.provider === '')
				|| (this.formData.enableSigning && this.formData.signing.provider === undefined)) {
				hasErrors = true
				this.signingProviderError = true
			}
			if ((this.formData.enableSigning && this.formData.signing.docTypes.length === 0)) {
				hasErrors = true
				this.signingDocTypesError = true
			}
			return hasErrors
		},
	}
}
</script>