<template>
	<div>
		<div
			:class="{ 'brightness-95': this.$store.state.displayEditPayments }"
			class="box shadow-none col-span-6">
			<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
				<div class="flex flex-row flex-nowrap">
					<div class="flex flex-col w-1/2 mr-12">
						<div class="mb-3 flex">
							<label class="font-bold form-label mb-0 mr-2">Payment:</label>
							<ToggleSlide
								class="w-min pointer-events-none"
								:bool="payment"
								:disabled="true"
								:renew-bool="true"
							/>

						</div>
						<div v-if="payment">
							<div class="mb-3 ml-6">
								<label class="font-bold form-label">Provider: *</label>
								<Dropdown
									:class="{ 'border border-primary-3': providerInvalid }"
									class="pointer-events-none"
									:data="paymentProviderData"
									:selected="paymentProvider"
									:key="paymentProvider"
									:disabled="true"
								/>
							</div>
							<div class="mb-3 ml-6">
								<label class="font-bold form-label">
									Paypage Provider: *
								</label>
								<Dropdown
									:class="{ 'border border-primary-3': payPageInvalid }"
									class="pointer-events-none"
									:data="paypageProviderData"
									:selected="paymentPayPage"
									:key="paymentPayPage"
								/>
							</div>
							<div class="mb-3   ml-6 flex">
								<label class="font-bold form-label mb-0 mr-2">
									Transactions via Twikey:
								</label>
								<ToggleSlide
									class="w-min pointer-events-none"
									:bool="transactionsViaTwikey"
									:renewBool=true
									:disabled="true"
								/>
							</div>
						</div>
					</div>

					<div class="flex flex-col w-1/2 mr-12">
						<div class="mb-3 flex">
							<label class="font-bold form-label mb-0 mr-2">Show Paystatus:</label>
							<ToggleSlide
								class="w-min pointer-events-none"
								id="viewPaymentStatus"
								:bool="paymentStatus"
								:renewBool=true
								:disabled="true"
							/>

						</div>
						<div class="mb-3 flex">
							<label class="font-bold form-label mb-0 mr-2">Show Mandates:</label>
							<ToggleSlide
								class="w-min pointer-events-none"
								:bool="showMandates"
								:renewBool=true
								:disabled="true"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ToggleSlide from "../ToggleSlide.vue";
import Dropdown from "../Dropdown.vue";

export default {
	name: "ViewPayments",
	components: {
		ToggleSlide,
		Dropdown
	},
	data() {
		return {
			paymentProviderData: [
				{id: 0, name: "No payment provider"},
				{id: 1, name: "TWIKEY"},
			],
			paypageProviderData: [
				{id: 0, name: "No paypage provider"},
				{id: 1, name: "TWIKEY"},
			],
			providerInvalid: false,
			payPageInvalid: false,
			payment: !!this.$store.state.activeSG.channelsConfig.payment,
			paymentProvider: this.$store.state.activeSG.channelsConfig.payment ?
				this.$store.state.activeSG.channelsConfig.payment.provider :
				"No payment provider",
			paymentPayPage: this.$store.state.activeSG.channelsConfig.payment ?
				this.$store.state.activeSG.channelsConfig.payment.payPageProvider :
				"No payment provider",
			transactionsViaTwikey: this.$store.state.activeSG.channelsConfig.payment ?
				this.$store.state.activeSG.channelsConfig.payment.transactionsViaTwikey :
				false,
			paymentStatus: this.$store.state.activeSG.channelsConfig.docImage.configPortal.showPaymentStatus ?
				this.$store.state.activeSG.channelsConfig.docImage.configPortal.showPaymentStatus :
				false,
			showMandates: this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig ?
				this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.showMandates ?
					this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.showMandates :
					false :
				false,
		}
	},
	computed: {
		paymentData() {
			return this.$store.state.activeSG.channelsConfig;
		}
	},

	watch: {
		paymentData(newVal) {
			if (newVal.payment) {
				this.payment = true;
				this.paymentProvider = newVal.payment.provider;
				this.paymentPayPage = newVal.payment.payPageProvider;
				this.transactionsViaTwikey = newVal.payment.transactionsViaTwikey
			} else {
				this.payment = false
				this.paymentProvider = "No payment provider";
				this.paymentPayPage = "No payment provider";
				this.transactionsViaTwikey = false

			}
			this.paymentStatus = newVal.docImage.configPortal.showPaymentStatus
			if (newVal.docImage.configPortal?.additionalPortalConfig?.showMandates) {
				this.showMandates = newVal.docImage.configPortal.additionalPortalConfig.showMandates
			} else {
				this.showMandates = false;
			}
		}
	}
}
</script>