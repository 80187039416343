<template>
    <pop-up-window
          :show="
      this.$store.state.displayEditContactInfoAndHelp &&
      !this.$store.state.displayAddManual
    "
          :showClose="false"
          @close="close"
          class="doc-statuses-popup"
          fixed
    >
        <template #header>Edit Contact info and help</template>
        <template #content>
            <div class="flex flex-col flex-nowrap">
                <div class="flex flex-col mb-1">
                    <!--
                    <div class="mb-3 flex flex-col">
                        <label for="helpHtml" class="text-lg font-bold form-label"
                        >Contact info and help
                        </label>
                        <textarea
                              v-model="formData.helpHtml"
                              ref="helpHtml"
                              id="helpHtml"
                              cols="60"
                              rows="10"
                        ></textarea>
                    </div>

                    -->
                    <div class="mb-3 flex flex-col">
                        <label for="footerHtml" class="text-lg font-bold form-label"
                        >Contact info footer
                        </label>
                        <textarea
                              v-model="formData.footerHtml"
                              ref="footerHtml"
                              id="footerHtml"
                              cols="60"
                              rows="10"
                              class="border p-4"
                        ></textarea>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <p class="text-lg font-bold form-label">Manuals</p>
                <button
                      @click="displayAddManual"
                      class="btn bg-theme-1 py-0 text-white shadow-md ml-2"
                >
                    Add
                </button>
                <div v-if="formData.helpPdf">
                    <div
                          :key="item + index"
                          v-for="(item, index) in Object.keys(formData.helpPdf)"
                          class="grid-cols-3 gap-2 mb-2"
                    >
                        <FileUpload
                              @update-value="updateManual($event, item)"
                              @delete-value="deleteManual($event, item)"
                              accept=".pdf"
                              :data="formData.helpPdf[item]"
                              :prefix="true"
                              :prefixData="item"
                              :delSuffix="true"
                              :id="'manual' + index + item + formData.helpPdf[item]"
                        />
                    </div>
                </div>
                <input
                      v-else
                      disabled
                      id="manuals"
                      type="text"
                      class="form-control w-full"
                      value="n.a."
                />
            </div>

            <div class="grid grid-rows-1 justify-items-end">
                <div>
                    <button class="btn shadow-md mr-2" @click="update">Update</button>
                    <button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </pop-up-window>
    <AddManual
          @add-manual="addManual"
          :supplierGroup="this.$store.state.activeSG"
    />
    <ViewHtml/>
</template>

<script>
import PopUpWindow from "../PopUp.vue";
import FileUpload from "../FileUpload.vue";
import ViewHtml from "../ViewHtml.vue";
import AddManual from "../AddSupplierGroup/AddManual.vue";

export default {
    name: "EditContactInfo",
    components: {
        PopUpWindow,
        AddManual,
        FileUpload,
        ViewHtml
    },
    data() {
        return {
            formData: {
               // helpHtml:this.$store.state.activeSG.channelsConfig.docImage.configPortal.helpHtml,
                footerHtml:
                this.$store.state.activeSG.channelsConfig.docImage.configPortal
                      .footerHtml,
                helpPdf: this.$store.state.activeSG.channelsConfig.docImage.configPortal.helpPdf ? JSON.parse(JSON.stringify(this.$store.state.activeSG.channelsConfig.docImage.configPortal.helpPdf))
                      : null,
            },
        };
    },
    computed:{
        activeSG(){
            return this.$store.state.activeSG
        },

    },
    watch:{
        activeSG(){
            this.initData()
        }
    },
    methods: {
        initData() {
           // this.formData.helpHtml = this.$store.state.activeSG.channelsConfig.docImage.configPortal.helpHtml;
            this.formData.footerHtml =
                  this.$store.state.activeSG.channelsConfig.docImage.configPortal.footerHtml;
            this.helpPdf = this.$store.state.activeSG.channelsConfig.docImage.configPortal.helpPdf ? JSON.parse(JSON.stringify(this.$store.state.activeSG.channelsConfig.docImage.configPortal.helpPdf))
                  : null
        },
        updateManual(event, data) {
            this.formData.helpPdf[data] = event.value;
        },
        deleteManual(event, data) {
            delete this.formData.helpPdf[data];
        },
        addManual(data) {
            this.formData.helpPdf = {...this.formData.helpPdf, ...data};
        },
        displayAddManual() {
            this.$store.state.displayAddManual = true;
        },
        close() {
            this.initData();
            this.$store.state.displayAddManual = false;
            this.$store.state.displayEditContactInfoAndHelp = false;
        },
        async update() {
            if (!this.formData.helpPdf || Object.keys(this.formData.helpPdf).length === 0) {
                delete this.formData.helpPdf;
            }
/*
            if (this.formData.helpHtml === "") {
                delete this.formData.helpHtml;
            }
            */
            if (this.formData.helpHtml) {
                delete this.formData.helpHtml;
            }
            if (this.formData.footerHtml === "") {
                delete this.formData.footerHtml;
            }
            let payload = {
                sgId: this.$store.state.activeSG.supplierGroupId,
                edit: "contact-help",
                body: this.formData,
            };
            await this.$store.dispatch("EditSupplierGroup", payload);
            if (this.$store.state.responseStatus === 200) {
                this.$store.commit("toggleAlert", {
                    title: "Edit SupplierGroup",
                    text: "Contact&Info successfully edited",
                });
            }
            this.close();
        },
    },
};
</script>

<style scoped>
</style>
