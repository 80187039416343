import axios from "axios"

const env = window.environment
const qs = require('qs')
export default {
	sendMassResendPostmark(context, payload) {
		axios.post(context.rootState.baseUrl + context.rootState.API.massResendPostmark, payload, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).catch(err => console.error(err))
	},

	/**
	 * Global overview
	 * ACTIONS TAB
	 * Restore SG users
	 */
	RestoreSGUsers(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			axios.request({
				url: "api/admin/supplier-groups/" + payload + "/restore-users",
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
			}).then(() => context.rootState.responseStatus = 200)
				.catch((exception) => {
					context.rootState.responseStatus = 400
					console.error("RestoreSGUsers-error: ", exception);
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	RestoreAllUsers(context) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			axios.request({
				url: "api/admin/restore-all-users",
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
			})
				.then(() => context.rootState.responseStatus = 200)
				.catch((exception) => {
					context.rootState.responseStatus = 400
					console.error("RestoreAllUsers-error: ", exception);
				})
		}).catch(() => {
			context.commit('displayErrorAlert', true, {root: true})
			context.commit('displayErrorAlertText', "Session is expired", {root: true})
		})
	},
	ClearAllOrphanedMessages(context) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			axios.request({
				url: "api/admin/clear-messages",
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
			})
				.then(() => context.rootState.responseStatus = 200)
				.catch((exception) => {
					context.rootState.responseStatus = 400
					console.error("RestoreAllUsers-error: ", exception);
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	ClearSGOrphanedMessages(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			axios.request({
				url: "api/admin/supplier-groups/" + payload + "/clear-messages",
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
			})
				.then(() => context.rootState.responseStatus = 200)
				.catch((exception) => {
					context.rootState.responseStatus = 400
					console.error("RestoreAllUsers-error: ", exception)
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	ArchiveDocuments(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			axios.request({
				url: "api/admin/supplier-groups/" + payload.sgId + "/archive/start",
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
				data: payload.body
			})
				.then(() => {
					context.rootState.responseStatus = 200
					context.commit("startArchive", true, {root: true})
				})
				.catch((exception) => {
					context.rootState.responseStatus = 400
					console.error("ArchiveDocuments-error: ", exception)
				});
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	triggerInvoicePolling(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			axios.request({
				url: "api/admin/invoices-feed/poll",
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
				data: payload
			})
				.then(() => context.rootState.responseStatus = 200)
				.catch((exception) => {
					context.rootState.responseStatus = 400
					console.error("ArchiveDocuments-error: ", exception);
				});
		}).catch(() => {
			context.commit('displayErrorAlert', true, {root: true})
			context.commit('displayErrorAlertText', "Session is expired", {root: true})
		})
	},
	triggerTransactionPolling(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			axios.request({
				url: "api/admin/transactions-feed/poll",
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
				data: payload
			})
				.then(() => context.rootState.responseStatus = 200)
				.catch((exception) => {
					context.rootState.responseStatus = 400
					console.error("ArchiveDocuments-error: ", exception);
				})
		}).catch(() => {
			context.commit('displayErrorAlert', true, {root: true})
			context.commit('displayErrorAlertText', "Session is expired", {root: true})
		})
	},
	triggerMandatePolling(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			axios.request({
				url: "api/admin/mandates-feed/poll",
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
				data: payload
			}).then(() => context.rootState.responseStatus = 200)
				.catch((exception) => {
					context.rootState.responseStatus = 400
					console.error("ArchiveDocuments-error: ", exception)
				});
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	clearCache() {
		let url = ''
		if (window.location.hostname.includes('localhost') || window.location.hostname.includes('staging')) {
			url = 'https://staging-connect.nymus.eu/v1/gateway/system/cache'
		} else {
			url = 'https://connect.nymus.eu/v1/gateway/system/cache'
		}
		axios.delete(url)
	},
	clearCash(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + "api/system/" + payload

			return axios.delete(url, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then(() => {
				context.commit("toggleAlert", {
					title: "Clear cash",
					text: payload + " cash cleared successfully",
				}, {root: true});
			})
				.catch(() => context.commit('displayErrorAlert', true, {root: true}))
		}).catch(() => {
			context.commit('displayErrorAlert', true, {root: true})
			context.commit('displayErrorAlertText', "Session is expired", {root: true})
		})
	},

	triggerPostDropIngestion(context) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + context.rootState.API.triggerPostDropIngestion
			return axios.post(url, {}, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then(() => {
				context.commit("toggleAlert", {
					title: "PostDrop ingestion",
					text: "PostDrop ingestion successfully triggered",
				}, {root: true})
			}).catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
			})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	}
}