<template>
	<pop-up-window
		:show="this.$store.state.displayEditLeGeneral"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit General</template>
		<template #content>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-1/2 mr-12">
					<Field
						title="Name: *"
						type="text"
						@input="nameInvalid = false"
						:hasError="nameInvalid"
						v-model="formData.name"
					/>
					<span v-if="nameInvalid" class="text-primary-3">Name is required</span>
					<Field
						title="Branding ID:"
						type="text"
						v-model="formData.brandingId"
					/>
					<Field
						title="Internal name: "
						type="text"
						v-model="formData.internalName"
						:copySuffix=false
					/>
					<Field
						title="RPR:"
						type="text"
						v-model="formData.RPR"
					/>
				</div>
				<div class="flex flex-col w-1/2 mr-12">
					<Field
						title="Portal link:"
						type="text"
						v-model="formData.portalLink"
						:copySuffix="false"
					/>
					<FileUpload
						id="editGeneralLogo"
						:isLegalEntity="true"
						:delSuffix="true"
						@delete-value="deleteLogoValue"
						@update-value="changeLogoValue"
						accept="image/png, image/jpeg"
						title="Logo:"
						:data="formData.logo"
					/>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>
<script>
import Field from "../Field.vue"
import FileUpload from "../FileUpload.vue"
import PopUpWindow from "../PopUp.vue"

export default {
	name: "EditGeneral",
	components: {
		PopUpWindow,
		FileUpload,
		Field,
	},
	data() {
		return {
			nameInvalid: false,
			legalEntity: this.$store.state.activeLE,
			formData: {
				name: this.$store.state.activeLE.name
					? JSON.parse(JSON.stringify(this.$store.state.activeLE.name)) : "",
				internalName: this.$store.state.activeLE.internalName
					? JSON.parse(JSON.stringify(this.$store.state.activeLE.internalName)) : "",
				logo: this.$store.state.activeLE.legalEntityConfig?.logo
					? JSON.parse(JSON.stringify(this.$store.state.activeLE.legalEntityConfig.logo)) : "",
				RPR: this.$store.state.activeLE.RPR ? JSON.parse(JSON.stringify(this.$store.state.activeLE.RPR)) : "",
				brandingId: this.$store.state.activeLE.brandingId,
				portalLink: this.$store.state.activeLE.legalEntityConfig?.portalLink ? this.$store.state.activeLE.legalEntityConfig.portalLink : ""
			}
		}
	},
	computed: {
		activeLE() {
			return this.$store.state.activeLE
		},
	},
	watch: {
		activeLE() {
			this.initData()
		}
	},
	methods: {
		changeLogoValue(event) {
			this.formData.logo = event.value;
		},
		deleteLogoValue(event) {
			delete this.formData.logo;
		},
		initData() {
			this.formData.name = this.$store.state.activeLE.name ? this.$store.state.activeLE.name : ''
			this.formData.brandingId = this.$store.state.activeLE.brandingId ? this.$store.state.activeLE.brandingId : ''
			this.formData.portalLink = this.$store.state.activeLE.legalEntityConfig?.portalLink ? this.$store.state.activeLE.legalEntityConfig.portalLink : ""

			this.formData.internalName = '';
			if (this.$store.state.activeLE.internalName) {
				this.formData.internalName = this.$store.state.activeLE.internalName ? this.$store.state.activeLE.internalName : ''
			}
			this.formData.logo = ''
			if (this.$store.state.activeLE.legalEntityConfig?.logo) {
				this.formData.logo = this.$store.state.activeLE.legalEntityConfig?.logo
			}
			this.formData.RPR = ''
			if (this.$store.state.activeLE.RPR) {
				this.formData.RPR = JSON.parse(JSON.stringify(this.$store.state.activeLE.RPR))
			}
		},
		close() {
			this.nameInvalid = false;
			this.initData();
			this.$store.state.displayEditLeGeneral = false;
		},
		async update() {
			let hasErrors = this.validate()
			if (!hasErrors) {
				if (this.formData.internalName === '') delete this.formData.internalName
				if (this.formData.RPR === '') delete this.formData.RPR
				if (this.formData.brandingId === '') delete this.formData.brandingId
				if (this.formData.portalLink === '') delete this.formData.portalLink

				let payload = {
					sgId: this.$store.state.activeSG.supplierGroupId,
					leId: this.$store.state.activeLE.entityNumber,
					edit: "info",
					body: this.formData,
				}

				await this.$store.dispatch("EditLegalEntity", payload);
				if (this.$store.state.responseStatus === 200) {
					this.$store.commit("toggleAlert", {
						title: "Edit Legal entity",
						text: "General successfully edited",
					})
				}
			}
		},
		validate() {
			let hasErrors;
			this.nameInvalid = false

			if (this.formData.name === "") {
				hasErrors = true
				this.nameInvalid = true
			}
			return hasErrors
		}
	}
}
</script>