<template>
	<div class="mb-3 flex">
		<label
			class="font-bold form-label mb-0 mr-2"
		>Archive Config:</label
		>

		<ToggleSlide
			@update-value="setArchive()"
			class="w-min"
			:bool="archiveConfig"
			:disabled="disabled"
			:value="(id + 'archiveConfig')"
		/>
	</div>
	<div class="mx-6" v-if="archiveConfig">
		<div class="mb-3 flex">
			<label
				class="font-bold form-label mb-0 mr-2"
			>Merge:</label
			>
			<ToggleSlide
				@update-value="setArchiveMerge()"
				class="w-min"
				:bool="merge"
				:disabled="disabled"
				:value="(id + 'merge')"
			/>
		</div>
	</div>

	<div class="mb-3 flex">
		<label
			class="font-bold form-label mb-0 mr-2"
		>Add to print:</label
		>
		<ToggleSlide
			@update-value="setAddToPrent()"
			class="w-min"
			:bool="addToPrint"
			:disabled="disabled"
			:value="(id + 'addToPrint')"
		/>
	</div>
</template>

<script>

import ToggleSlide from "@/components/ToggleSlide.vue";

export default {
	components: {
		ToggleSlide
	},
	emits: ['toggleSlidesChanged'],
	props: {
		incomingArchiveConfig: {
			type: Boolean,
			required: false,
			default: false
		},
		incomingMerge: {
			type: Boolean,
			required: false,
			default: false
		},
		incomingAddToPrint: {
			type: Boolean,
			required: false,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		},
		name: String

	},
	data() {
		return {
			archiveConfig: this.incomingArchiveConfig,
			merge: this.incomingMerge,
			addToPrint: this.incomingAddToPrint,
		}
	},

	methods: {
		setArchive() {
			this.archiveConfig = !this.archiveConfig
			if (this.archiveConfig === false){
				this.merge = false
			}
			this.sendDataToParent()
		},
		setArchiveMerge() {
			this.merge = !this.merge
			this.sendDataToParent()
		},
		setAddToPrent() {
			this.addToPrint = !this.addToPrint
			this.sendDataToParent()
		},
		sendDataToParent() {
			const payload = {
				archiveConfig: this.archiveConfig,
				merge: this.merge,
				addToPrint: this.addToPrint,
				name: this.name,
			}
			this.$emit('toggleSlidesChanged', payload)
		}
	}
}

</script>