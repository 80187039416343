<template>
    <div class="flex flex-col flex-nowrap" id="app" v-if="this.$store.state.supplierGroups.length">
        <Alert/>
        <error-alert></error-alert>
        <loader></loader>
        <header class="h-12 header-container">
            <div class="logo">
                <img src="./assets/images/logo_white.png">
            </div>
            <div class="staging-text">
                <Staging ></Staging>
            </div>

        </header>
        <main class="flex flex-nowrap">
            <Sidebar/>
            <router-view></router-view>
        </main>
        <footer>

        </footer>
    </div>
</template>

<script>
import Alert from './components/Alert.vue'
import Sidebar from './components/side-menu/Sidebar.vue'
import Staging from './components/topBarStaging.vue'
import feather from 'feather-icons'
import ErrorAlert from "./components/ErrorAlert";
import Loader from "./components/Loader";

export default {
    components: {
        ErrorAlert,
        Loader,
        'Sidebar': Sidebar,
        'Alert': Alert,
        'Staging': Staging,
    },
    mounted() {
        feather.replace();
        this.changeBG();
    },
    computed:{
        suppliers(){
            return this.$store.state.supplierGroups
        }
    },
    async beforeCreate() {
        await this.$store.commit('setToken', this.$root.token);
        await this.$store.commit('setRefreshToken',this.$root.keycloak.refreshToken);
        await this.$store.dispatch('getCurrentUser')
		await this.$store.dispatch('setFavIcon')
        await this.$store.dispatch('schedule/fetchSchedule');

        //must be last
        await this.$store.dispatch('fetchSupplierGroups');
    },
    methods: {
        changeBG() {
            let bodyEl = document.getElementsByTagName("body");
            let htmlEl = document.getElementsByTagName("html");
            if (window.location.hostname.includes('localhost') || window.location.hostname.includes('staging')) {
                bodyEl[0].classList.add('bgStaging');
                htmlEl[0].classList.add('bgStaging')
            } else {
                bodyEl[0].classList.add('production');
                htmlEl[0].classList.add('production')
            }
        }
    }
};
</script>

<style>
.bgStaging {
    background-color: #7D7B8C;
}
.production {
    background-color: #1B1464;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
.header-container{
    height: 70px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
}
.staging-text, .logo{
    grid-column: 1/2;
    grid-row: 1/2;
}
.staging-text{
    justify-self: center;
}
.logo{
    justify-self: start;
}
.logo img{
    height: 80px;
}
</style>
