<template>
	<pop-up-window
		:show="this.$store.state.displayEditMailTemplate"
		:width10="true"
		:showClose="false"
		@close="close"
		:top10="true"
		fixed
	>
		<template #header>Confirm Dialog</template>
		<template #content>
			<h1 class="text-center mb-2">Are you sure?</h1>
			<div class="grid grid-rows-1 justify-items-center">
				<div>
					<button class="btn shadow-md mr-2" value="Yes" @click="close($event.target.value)">Yes</button>
					<button class="btn shadow-md mr-2" value="No" @click="close($event.target.value)">No</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "./PopUp.vue"

export default {
	name: "ConfirmDialog",
	components: {
		PopUpWindow,
	},
	emits: ['close'],
	methods: {
		close(data) {
			this.$emit('close', data)
		}
	}
}
</script>