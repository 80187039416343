<template>
	<div id="basic-dropdown">
		<div class="preview">
			<TomSelect
				class="w-full getDisabledColor"
				v-model="select"
				@update-value="updateValue"
				:disabled="disabled"
				:useRemove="false"
				:placeholder="placeholder"
			>
				<option v-if="selectNone" :key="-1" :value="-1">{{ textSelectNone }}</option>
				<option
					:key=" index"
					v-for="(item, index) in data"
					:value="index">
					{{ item.name }}
				</option>
			</TomSelect>
		</div>
	</div>
</template>
<script>
import {defineComponent, onMounted, ref, watch} from "vue"
import TomSelect from "../global-components/tom-select/Main.vue"

export default defineComponent({
	components: {
		TomSelect,
	},
	props: {
		data: Array,
		selected: String,
		disabled: Boolean,
		selectNone: {
			type: Boolean,
			required: false,
			default: false
		},
		textSelectNone: {
			type: String,
			required: false,
			default: ''
		},
		placeholder: String
	},
	emits: ['update-value'],
	methods: {
		updateValue(data) {
			this.$emit("update-value", data);
		},
	},

	setup(props) {
		const select = ref([]);
		const initSelected = () => {
			if (props.selected) {
				let found
				let arrFound = [];
				if (props.data.length === undefined ){
					if (props.data.name === props.data) found = 0
				}
				else {
					found = props.data.indexOf(props.data.find((el) => el.name === props.selected))
				}
				arrFound.push(found)
				select.value = arrFound
			}
		}
		watch(() => props.selected, function () {
			initSelected()
		})
		onMounted(() => {
			initSelected()
		})
		return {
			select
		}
	}
})
</script>

