<template>
	<pop-up-window
		:show="this.$store.state.displayAddOverlayTemplate"
		:showClose="false"
		:top10="true"
		:positionFixed="true"
		class="doc-statuses-popup add-new-overlay-template"
		fixed
	>
		<template #header>Add New Overlay Template</template>
		<template #content>
			<div class="mb-3">
				<div class="container">
					<div class="">
						<Field
							id="addOverlayTemplateName"
							title="Overlay name:*"
							v-model="formData.name"
							type="text"
							@focusout="errors.name = false"
						/>
						<span v-if="errors.name" class="text-primary-3">
							Please, enter the name
						</span>

						<label for="addOverlayTemplateName" class="font-bold form-label">
							Overlay mode:*
						</label>
						<Dropdown
							id="overlayTemplateOverlayMode"
							@update-value="updateSelectedOverlay"
							:class="{ 'border border-primary-3': selectedOverlayInvalid }"
							class=""
							:data="overlayOverlayData"
							:selected=overlayOverlayData[0].name
							@click="errors.overlayMode = false"
						/>
						<span v-if="errors.overlayMode" class="text-primary-3">Please, select overlay mode</span>

						<label for="overlayTemplateDocTypes" class="font-bold form-label mt-2">Overlay
							docTypes:*</label>
						<DropdownSelectAll
							v-if="renderComponent"
							id="overlayTemplateDocTypes"
							:class="{ 'border border-primary-3': selectedOverlayInvalid }"
							@update-value="updateSelectedDocTypes"
							:data="overlayDocTypes"
						/>
						<span v-if="errors.docTypes" class="text-primary-3">Please, select at least one docType</span>
					</div>
					<div>
						<label for="overlayTemplateOverlayMode" class="font-bold form-label">
							Overlay position:
						</label>
						<Dropdown
							id="editOverlayPosition"
							@update-value="updateSelectedOverlayPosition"
							class=""
							:data="overlayPositionData"
							:selected="formData.overlayPosition"
						/>
						<div class="">
							<p class="font-bold form-label my-0  mt-2">Overlay file:*</p>
							<button
								@click="toggleDisplayAddFile"
								class="btn bg-theme-1 py-0 text-white shadow-md ml-2"
							>
								Add
							</button>
						</div>
						<span v-if="errors.location" class="text-primary-3">Please, select at least one location</span>

						<add-file-to-template
							title-dialog="Add Overlay text"
							:show-dialog="displayAddFile"
							:isLegalEntity="this.isLegalEntity"
							@add-file="addFile"
							@cancel-add-file="displayAddFile = false"
							:langCode="langCodes"
						>

						</add-file-to-template>
						<div
							:key="index + item"
							v-for="(item, index) in formData.location"
							class="flex flex-col"
						>
							<FileUpload
								:isLegalEntity="isLegalEntity"
								@update-value="updateFile($event, index)"
								@delete-value="deleteFile($event, index)"
								accept=".pdf"
								:prefix="true"
								:prefixData="index"
								:delSuffix="true"
								:data="formData.location[index].fileName"
								:id=" index + 'overlay' + item + formData.location[index].fileName"
							/>
						</div>
					</div>
				</div>

				<div class="grid grid-rows-1 justify-items-end">
					<div>
						<button class="btn shadow-md mr-2" @click="update">
							Add
						</button>

						<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../../PopUp.vue";
import Field from "../../Field.vue";
import Dropdown from "../../Dropdown.vue";
import langCodes from "../../../langCodes";
import FileUpload from "../../FileUpload.vue";
import DropdownSelectAll from "../../DropdownSelectAll.vue";
import AddFileToTemplate from "@/components/templates/AddFileToTemplate.vue";

export default {
	name: "AddOverlayTemplate",
	components: {
		AddFileToTemplate,
		PopUpWindow,
		Field,
		Dropdown,
		FileUpload,
		DropdownSelectAll
	},
	props: {
		isFor: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			errors: {
				name: false,
				overlayMode: false,
				location: false,
				docTypes: false,
			},
			formData: {
				name: "",
				version: "",
				overlayMode: "ALL_PAGES",
				location: {},
				docTypes: [],
				overlayPosition: "BACKGROUND"
			},
			overlayOverlayData: this.$store.state.overlayMode,
			overlayDocTypes: this.$store.state.docTypes,
			langCodes: langCodes,
			isLegalEntity: this.isFor === "legalEntity",

			renderComponent: true,
			overlayPositionData: [
				{id: 0, name: "BACKGROUND"},
				{id: 1, name: "FOREGROUND"},
			],

			displayAddFile: false
		}
	},
	methods: {
		validate() {
			let errors = false;

			if (this.formData.name === '') {
				errors = true;
				this.errors.name = true
			}

			if (this.formData.overlayMode === '') {
				errors = true;
				this.errors.overlayMode = true
			}

			if (this.formData.docTypes.length === 0) {
				errors = true;
				this.errors.docTypes = true;
			}
			if (Object.keys(this.formData.location).length === 0) {
				errors = true;
				this.errors.location = true
			}

			return !errors;
		},
		async update() {
			if (this.validate()) {
				let existedOverlayTemplate = []
				let requestPayload = {
					overlay: []
				}

				if (this.isFor === "supplierGroup"
					&& this.$store.state.activeSG.templates
					&& this.$store.state.activeSG.templates.overlayTemplates) {
					existedOverlayTemplate = this.$store.state.activeSG.templates.overlayTemplates

				}
				if (this.isFor === "supplierGroup"
					&& this.$store.state.activeSG.templates
					&& this.$store.state.activeSG.templates.overlay) {
					existedOverlayTemplate.push(this.$store.state.activeSG.templates.overlay);

				}
				if (this.isFor === 'legalEntity'
					&& this.$store.state.activeLE.templates
					&& this.$store.state.activeLE.templates.overlayTemplates
				) {
					existedOverlayTemplate = this.$store.state.activeLE.templates.overlayTemplates;
				}
				if (this.isFor === 'legalEntity'
					&& this.$store.state.activeLE.templates
					&& this.$store.state.activeLE.templates.overlay
				) {
					existedOverlayTemplate.push(this.$store.state.activeLE.templates.overlay);
				}
				existedOverlayTemplate.forEach(item => {
					if (item.docTypes === undefined) {
						item.docTypes = ["INVOICE"];
					}
				})

				if (existedOverlayTemplate.length !== 0) {
					requestPayload.overlay = existedOverlayTemplate;
				}

				requestPayload.overlay.push(this.formData);
				this.formData.version = 'v1';

				if (this.isFor === "supplierGroup") {
					let payload = {
						sgId: this.$store.state.activeSG.supplierGroupId,
						edit: "overlay-template",
						body: requestPayload,
					};
					await this.$store.dispatch("EditSupplierGroup", payload);
					if (this.$store.state.responseStatus === 200) {
						this.$store.commit("toggleAlert", {
							title: "Edit Supplier group",
							text: "Overlay template successfully edited",
						});
					}
				}

				if (this.isFor === "legalEntity") {
					let payload = {
						sgId: this.$store.state.activeSG.supplierGroupId,
						leId: this.$store.state.activeLE.entityNumber,
						edit: "overlay-template",
						body: requestPayload,
					};
					await this.$store.dispatch("EditLegalEntity", payload);
					if (this.$store.state.responseStatus === 200) {
						this.$store.commit("toggleAlert", {
							title: "Edit Legal entity",
							text: "Overlay template successfully edited",
						});
					}
				}
				this.close();
			}
		},
		close() {
			this.$store.state.displayAddOverlayTemplate = false;
			this.formData = {
				name: "",
				version: "",
				overlayMode: "ALL_PAGES",
				location: {},
				docTypes: [],
				overlayPosition: "BACKGROUND"
			};
			this.errors = {
				name: false,
				overlayMode: false,
				location: false,
				docTypes: false,
			}
		},
		updateSelectedDocTypes(data) {
			let foundArr = [];
			for (let index of data) {
				let found = this.overlayDocTypes[index].name;
				foundArr.push(found);
			}
			this.formData.docTypes = foundArr;
			this.errors.docTypes = false
		},
		updateFile(event, data) {
			this.formData.location[data].fileName = event.value;
			this.formData.location[data].url = this.tempFile;
		},
		deleteFile(event, data) {
			delete this.formData.location[data];
		},
		addFile(data) {
			this.formData.location = {
				...this.formData.location,
				...data,
			};
			this.displayAddFile = false
			this.errors.location = false
		},
		toggleDisplayAddFile() {
			this.displayAddFile = true
		},
		updateSelectedOverlay(data) {
			this.formData.overlayMode = this.overlayOverlayData[data].name;
		},
		updateSelectedOverlayPosition(data) {
			this.formData.overlayPosition = this.overlayPositionData[data].name;
		}
	}
}
</script>

<style scoped>
.container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 50% 50%;
	grid-auto-flow: row;
}

.container > div {
	margin: 0 10px;
}
</style>
