<template>
    <pop-up-window :show="this.$store.state.displayShowHtml"
                   :showClose = false
                   @close="close"
                   class="doc-statuses-popup"
                   fixed>
        <template #header>View HTML</template>
        <template #content>
            <div v-if="content">
                {{content}}
            </div>
            <div v-else>No Html To show</div>
            <div class="grid grid-rows-1 justify-items-end">
                <div>
                    <button class="btn  shadow-md mr-2 " @click="close">
                        Close
                    </button>
                </div>

            </div>


        </template>

    </pop-up-window>
</template>

<script>
import PopUpWindow from "./PopUp.vue";

export default {
    name: "ViewHtml",
    components: {PopUpWindow},
    data(){
        return{
            content: "",
        }
    },
    watch: {
    "$store.state.viewHtmlContent": {
      handler(data) {
        this.content = data
      },
      deep: true,
    },
  },
    methods:{
        close(){
            this.$store.state.displayShowHtml = false
        },
    }
}
</script>

<style scoped>

</style>
