import qs from "qs"
import axios from "axios"

const env = window.environment

export default {
	fetchSupplierGroups(context) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			axios.request({
				url: context.rootState.supplierGroupUrl,
				method: "get",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
			}).then((response) => {
				const res = response.data.data.supplierGroups
				context.rootState.responseStatus = 200
				context.commit('setSupplierGroups', res, {root: true})
			})
				.catch((exception) => {
					context.rootState.responseStatus = 400
					console.error("fetch-SG-error: ", exception)
				})
		}).catch(() => {
			context.commit('displayErrorAlert', true, {root: true})
			context.commit('displayErrorAlertText', "Session is expired", {root: true})
		})
	},

	async CreateSupplierGroup(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		await axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(async res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			await axios.request({
				url: "api/admin/supplier-groups",
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
				data: payload.body
			})
				.then(() => context.rootState.statusCreateSG = 200)
				.catch((exception) => {
					context.rootState.statusCreateSG = 400
					console.error("CreateSG-error: ", exception)
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	async CreateKeycloakRealm(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		await axios.post(context.rootState.API.keycloakToken,
			body,
			{
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(async res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			await axios.request({
				url: "api/admin/supplier-groups/" + payload.sgId + "/realm",
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
				data: {email: context.rootState.currentUser.email}
			})
				.then(() => context.rootState.statusCreateRealm = 200)
				.catch((exception) => {
					context.rootState.statusCreateRealm = 400
					console.error("CreateKCRealm-error: ", exception)
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	async CreateKeycloakClient(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		await axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(async res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			await axios.request({
				url: "api/admin/supplier-groups/" + payload.sgId + "/realm/client",
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
				data: {redirectUris: ["*"], webOrigins: ["*"]}
			})
				.then(() => context.rootState.statusCreateClient = 200)
				.catch((exception) => {
					context.rootState.statusCreateClient = 400
					console.error("CreateKCClient-error: ", exception)
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	async CreateGoogleCloudBuckets(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		await axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(async res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})
			await axios.request({
				url: "api/admin/supplier-groups/" + payload.sgId + "/storage",
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
			})
				.then(() => context.rootState.statusCreateBuckets = 200)
				.catch((exception) => {
					context.rootState.statusCreateBuckets = 400
					console.log("CreateBuckets-error: ", exception)
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	async CreateFTP(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		await axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(async res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			await axios.request({
				url: "api/admin/supplier-groups/" + payload.sgId + "/ftp",
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
				data: {}
			})
				.then(() => context.rootState.statusCreateFTP = 200)
				.catch((exception) => {
					context.rootState.statusCreateFTP = 400
					console.error("CreateBuckets-error: ", exception)
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},

	async EditSupplierGroup(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}

		let body = qs.stringify(dataToSend)
		await axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})
			
			axios.request({
				url: "api/admin/supplier-groups/" + payload.sgId + "/" + (payload.leId !== undefined ? "legal-entities/" + payload.leId + "/" : '') + payload.edit,
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
				data: payload.body
			})
				.then((response) => {
					context.rootState.responseStatus = 200

					if (payload.leId === undefined) {
						context.commit('setUpdatedSupplierGroup', response.data.data, {root: true})
						if (response.data.data.channelsConfig.print && response.data.data.channelsConfig.print.printJobs) {
							context.commit('updateSGPrintJobs', response.data.data.channelsConfig.print.printJobs, {root: true})
						}
					}
					else {
						context.rootState.activeLE = response.data.data
					}
					if (payload.edit === "report-settings") {
						context.rootState.displayEditReporting = false
					}
					if (payload.edit === "mail-template") {
						context.rootState.displayEditMailTemplate = false
					}
					if (payload.edit === "overlay-template") {
						context.rootState.displayEditOverlayTemplate = false
					}
					if (payload.edit === "print-settings") {
						context.rootState.displayEditPrint = false
					}
				})
				.catch((exception) => {
					context.rootState.responseStatus = 400
					context.commit('displayErrorAlert', true, {root: true})
					console.error("EditSg-error: ", exception)
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	async AddEnvironmentNymusX(context, payload) {
		const url = context.rootState.baseUrl + context.rootState.API.addEnvironment.replace(':supplierGroupId', payload.supplierGroupId)
		await axios.post(url, payload.environmentData, {
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		}).then(res => console.log(res))
			.catch(err => console.error(err))
	},
	EditFtpPassword(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			axios.request({
				url: "api/admin/supplier-groups/" + payload.sgId + "/ftp-password",
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
				data: payload.body
			})
				.then(() => context.rootState.responseStatus = 200)
				.catch((exception) => {
					context.rootState.responseStatus = 400
					console.error("EditFTP-error: ", exception)
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},

	async getExtractionTemplate(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		await axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(async res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + 'api/admin/extraction/templates/' + payload.templateId

			return await axios
				.request({
					url: url,
					method: "get",
					baseURL: context.rootState.baseUrl,
					headers: {
						Authorization: `Bearer ${context.rootState.token}`,
					},
				})
				.then((res) => {
					let found = false
					if (res.data.data.extractionTemplate !== undefined) {
						if (res.data.data.extractionTemplate?.id !== undefined) {
							context.rootState.extractionTemplates.forEach((temp, index) => {
								if (temp.id === res.data.data.extractionTemplate.id) {
									context.rootState.extractionTemplates[index] = res.data.data.extractionTemplate
									found = true
								}
							})
							if (found === false) context.rootState.extractionTemplates.push(res.data.data.extractionTemplate)
						}
					}
				}).catch(() => context.commit('displayErrorAlert', true, {root: true}))
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	createExtractionTemplate(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + 'api/admin/supplier-groups/' + payload.supplierGroupId + '/extraction-template'

			return axios.post(url, payload.requestData, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			)
				.then((res) => {
						context.rootState.extractionTemplateIds = res.data.data.channelsConfig.docImage.extractionConfig
						context.commit('updateExtractionTemplateIds', res.data.data.channelsConfig.docImage.extractionConfig, {root: true})
					}
				)
				.catch(() => {
					context.commit('displayErrorAlert', true, {root: true})
				})

		}).catch(() => {
			context.commit('displayErrorAlert', true, {root: true})
			context.commit('displayErrorAlertText', "Session is expired", {root: true})
		})
	},

	async AddLegalEntity(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		await axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(async res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			await axios.request({
				url: "api/admin/supplier-groups/" + payload.sgId + "/legal-entities",
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
				data: payload.body
			})
				.then((res) => {
					context.rootState.responseStatus = 200
					context.rootState.supplierGroups.find(sg => sg.supplierGroupId === context.rootState.activeSG.supplierGroupId).supplierLegalEntities [res.data.data.entityNumber] = res.data.data
					context.rootState.activeSG.supplierLegalEntities[res.data.data.entityNumber] = res.data.data
					context.commit("legalEntityCreated", true, {root: true})
				})
				.catch((exception) => {
					context.rootState.responseStatus = 400
					console.error("EditLe-error: ", exception)
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	async EditLegalEntity(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		await axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(async res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			await axios.request({
				url: "api/admin/supplier-groups/" + payload.sgId + "/legal-entities/" + payload.leId + "/" + payload.edit,
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
				data: payload.body
			})
				.then((response) => {
					context.rootState.responseStatus = 200
					let pl = {
						data: response.data.data,
						sgId: payload.sgId
					}
					context.commit('setUpdatedLegalEntity', pl, {root: true})
					if (payload.edit === 'report-settings') {
						context.rootState.displayEditReporting = false
					}
					if (payload.edit === 'payment-settings') {
						context.rootState.displayEditLePayment = false
					}
					if (payload.edit === "mail-template") {
						context.rootState.displayEditMailTemplate = false
					}
					if (payload.edit === "overlay-template") {
						context.rootState.displayEditOverlayTemplate = false
					}
					if (payload.edit === "info") {
						context.rootState.displayEditLeGeneral = false
					}
					if (payload.edit === "overlay-template") {
						context.rootState.displayAddOverlayFile = false
					}
				})
				.catch((exception) => {
					context.rootState.responseStatus = 400
					console.error("EditLe-error: ", exception)
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	EditLegalEntityIngestion(context, payload) {
		const url = context.rootState.baseUrl +
			context.rootState.API.updateLegalEntityIngestionSettings
				.replace(':supplierGroupId', payload.supplierGroupId)
				.replace(':legalEntityNumber', payload.legalEntityId)

		axios.post(url, payload.ingestionSettings, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).then(res => {
			context.rootState.activeLE = res.data.data
		}).catch(err => console.error(err))
	},
	EditLegalEntityApiConfig(context, payload) {
		const url = context.rootState.baseUrl +
			context.rootState.API.updateLegalEntityApiConfig
				.replace(':supplierGroupId', payload.supplierGroupId)
				.replace(':legalEntityNumber', payload.legalEntityId)
		axios.post(url, payload.apiConfig, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).then(res => context.rootState.activeLE = res.data.data)
			.catch(err => console.error(err))
	},
	EditLegalEntityChannels(context, payload){
		const url = context.rootState.baseUrl +
			context.rootState.API.updateLegalEntityChannelSetings
				.replace(':supplierGroupId', context.rootState.activeSG.supplierGroupId)
				.replace(':legalEntityNumber', context.rootState.activeLE.entityNumber)
		axios.post(url, payload, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).then(res => context.rootState.activeLE = res.data.data)
			.catch(err => console.error(err))
	},
	async resetLEChannelSettings(context) {
		const url = context.rootState.baseUrl + context.rootState.API.resetLEChannelSettings
			.replace(':supplierGroupId', context.rootState.activeSG.supplierGroupId)
			.replace(':legalEntityNumber', context.rootState.activeLE.entityNumber)

		await axios.delete(url, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		})
			.then(res => context.rootState.activeLE = res.data.data)
			.catch(err => console.error(err))
	},
	async deleteLegalEntity(context, payload){
		const url =  context.rootState.baseUrl + context.rootState.API.deleteLegalEntity
			.replace(':legalEntityNumber', payload.legalEntityNumber)
			.replace(':supplierGroupId', payload.supplierGroupId)
		return await  axios.delete(url, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).catch(err => {
			console.error(err)
			context.commit('displayErrorAlert', true, {root: true})
		})
	},

	EditChannelMaintenance(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + 'api/admin/supplier-groups/' + payload.supplierGroupId + '/channel-choice-settings'

			return axios.post(url, payload.formData, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then((res) => {
					context.commit('setUpdatedSupplierGroup', res.data.data, {root: true})
					if (res.data.data.channelsConfig.print && res.data.data.channelsConfig.print.printJobs) {
						context.commit('updateSGPrintJobs', res.data.data.channelsConfig.print.printJobs, {root: true})
					}

					context.commit("toggleAlert", {
						title: "Edit channel maintenance",
						text: "Channel maintenance successfully edited",
					}, {root: true})

				}
			).catch(() => context.commit('displayErrorAlert', true, {root: true}))
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	updatePayments(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + context.rootState.API.updatePayment.replace(':supplierGroupId', payload.supplierGroupId)

			return axios.post(url, payload.formData, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then((res) => {
					context.commit('setUpdatedSupplierGroup', res.data.data, {root: true})
					if (res.data.data.channelsConfig.print && res.data.data.channelsConfig.print.printJobs) {
						context.commit('updateSGPrintJobs', res.data.data.channelsConfig.print.printJobs, {root: true})
					}
					context.commit("toggleAlert", {
						title: "Edit Payment",
						text: "Settings successfully edited",
					}, {root: true})
				}
			).catch(() => context.commit('displayErrorAlert', true, {root: true}))
		}).catch(() => {
			context.commit('displayErrorAlert', true)
			context.commit('displayErrorAlertText', "Session is expired", {root: true})
		})
	},
	editBillingInfo(context, payload) {

		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + context.rootState.API.editBillingInfo.replace(":supplierGroupId", payload.supplierGroupId).replace(':legalEntityNumber', payload.legalEntityNumber)

			return axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			})
				.then((response) => {
					let pl = {
						data: response.data.data,
						sgId: payload.supplierGroupId
					}
					context.commit('setUpdatedLegalEntity', pl, {root: true})

					context.commit("toggleAlert", {
						title: "Billing info",
						text: "Billing info successfully edited",
					}, {root: true})
				})
				.catch(() => context.commit('displayErrorAlert', true, {root: true}))
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	deleteExtractionTemplate(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + 'api/admin/supplier-groups/' + payload.supplierGroupId + '/extraction-template/' + payload.templateId
			return axios.delete(url, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then((res) => {
					context.commit('updateExtractionTemplateIds', res.data.data.channelsConfig.docImage.extractionConfig, {root: true})
				}
			).catch(() => context.commit('displayErrorAlert', true))
		}).catch(() => {
			context.commit('displayErrorAlert', true, {root: true})
			context.commit('displayErrorAlertText', "Session is expired", {root: true})
		})
	},
	async modifyExtractionTemplate(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		await axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(async res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + 'api/admin/supplier-groups/' + payload.supplierGroupId + '/extraction-template/' + payload.requestData.id
			return await axios.put(url, payload.requestData, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then(async (res) => {
					context.commit('updateExtractionTemplateIds', res.data.data.channelsConfig.docImage.extractionConfig, {root: true})
					await context.dispatch('getExtractionTemplate', {
						templateId: payload.id,
						token: context.rootState.token
					}, {root: true})
				}
			)
				.catch(() => context.commit('displayErrorAlert', true, {root: true}))
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},

	//TODO: Probably deleted after the change of add/update template
	editPreIngestionTemplate(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = ''
			if (payload.isFor === "supplierGroup") {
				url = context.rootState.baseUrl + 'api/admin/supplier-groups/' + payload.sgId + '/text-overlay-template '
			}
			if (payload.isFor === "legalEntity") {
				url = context.rootState.baseUrl + 'api/admin/supplier-groups/' + payload.sgId + '/legal-entities/' + payload.legalEntityNumber + '/text-overlay-template '
			}
			return axios.post(url,
				payload.formData,
				{
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then((response) => {
					if (payload.isFor === "supplierGroup") {
						context.commit('setUpdatedSupplierGroup', response.data.data, {root: true})
						if (response.data.data.channelsConfig.print && response.data.data.channelsConfig.print.printJobs) {
							context.commit('updateSGPrintJobs', response.data.data.channelsConfig.print.printJobs, {root: true})
						}
					}
					if (payload.isFor === "legalEntity") {
						let data = {
							data: response.data.data,
							sgId: payload.sgId
						}
						context.commit('setUpdatedLegalEntity', data, {root: true})
					}

					context.commit("toggleAlert", {
						title: "Edit Pre Ingestion template",
						text: "QR template successfully edited",
					}, {root: true})
				}
			).catch(() => context.commit('displayErrorAlert', true, {root: true}))
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	editResendTemplate(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = ''
			if (payload.isFor === "supplierGroup") {
				url = context.rootState.baseUrl + 'api/admin/supplier-groups/' + payload.sgId + '/resend-template'
			}
			if (payload.isFor === "legalEntity") {
				url = context.rootState.baseUrl + 'api/admin/supplier-groups/' + payload.sgId + '/legal-entities/' + payload.legalEntityNumber + '/resend-template'
			}

			return axios.post(url, payload.formData, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
						"Content-Type": "application/json charset=utf8"
					}
				}
			).then((response) => {
					if (payload.isFor === "supplierGroup") {
						context.commit('setUpdatedSupplierGroup', response.data.data, {root: true})
						if (response.data.data.channelsConfig.print && response.data.data.channelsConfig.print.printJobs) {
							context.commit('updateSGPrintJobs', response.data.data.channelsConfig.print.printJobs, {root: true})
						}
					}
					if (payload.isFor === "legalEntity") {
						let data = {
							data: response.data.data,
							sgId: payload.sgId
						}
						context.commit('setUpdatedLegalEntity', data, {root: true})
					}

					context.commit("toggleAlert", {
						title: "Edit Pre Ingestion template",
						text: "QR template successfully edited",
					}, {root: true})
				}
			).catch(() => context.commit('displayErrorAlert', true, {root: true}))
		}).catch(() => {
			context.commit('displayErrorAlert', true, {root: true})
			context.commit('displayErrorAlertText', "Session is expired", {root: true})
		})
	},

	deleteFromLegalArchive(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + context.rootState.API.deleteFromLegalArchive.replace(':supplierGroupId', payload.supplierGroupId)

			return axios.post(url, payload.formData, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then(() => {
				context.commit("toggleAlert", {
					title: "Continue ingestion",
					text: "Documents:" + payload.formData.docIds + ' deleted. For ' + payload.supplierGroupId,
				}, {root: true})
			})
				.catch((error) => {
					console.error(error)
					context.commit('displayErrorAlert', true)
				})
		}).catch(() => {
			context.commit('displayErrorAlert', true)
			context.commit('displayErrorAlertText', "Session is expired")
		})
	},
	continueIngestion(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + context.rootState.API.continueIngestion.replace(':supplierGroupId', payload.supplierGroupId)
			return axios.post(url, payload.docIds, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then(() => {
				context.commit("toggleAlert", {
					title: "Continue ingestion",
					text: "Ingestion continued for docIds:" + payload.docIds.docIds + '. For ' + payload.supplierGroupId,
				}, {root: true})
			}).catch(() => context.commit('displayErrorAlert', true))
		}).catch(() => {
			context.commit('displayErrorAlert', true, {root: true})
			context.commit('displayErrorAlertText', "Session is expired", {root: true})
		})
	}
}