<template>
	<pop-up-window
		:show="
      this.$store.state.displayEditInsertsTemplate
    "
		:showClose="false"
		:blur="showConfirm"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit Inserts Template</template>
		<template #content>
			<div
				:class="{ 'pointer-events-none': showConfirm }"
				class="flex flex-row flex-nowrap"
			>
				<div v-if="formData.inserts" class="flex flex-col w-full">
					<Accordion
						:key="item + index"
						v-for="(item, index) in formData.inserts"
						class="mb-5 border border-gray-300 p-1 box"
					>
						<template v-slot:title>
							<span class="font-semibold text-xl">{{ item.name }}</span>
						</template>
						<template v-slot:content>
							<div class="p-3 grid grid-cols-2">
								<div class="mr-2">
									<Field
										id="name"
										type="text"
										title="Name:"
										v-model="item.name"
										@blur="changeName(item, item.name)"
									/>
									<div>
										<div class="mb-2">
											<label for="resendDocType" class="font-bold">DocType:*</label>
										</div>

										<DropdownSelectAll
											v-if="renderComponent"
											id="resendDocType"
											@update-value="updateDocTypes($event, item.name)"
											:data="docTypes"
											:selected=item.docTypes

										/>
										<div>
										</div>
									</div>

								</div>
								<div class="ml-2">
									<div class="flex mb-1 mt-2">
										<p class="font-bold form-label my-0">Insert file(s):*</p>
										<button
											@click="displayAddInsertFile(item.name)"
											class="btn bg-theme-1 py-0 text-white shadow-md ml-2">
											Add
										</button>

									</div>

									<div
										:key="item + item.location[itm] + index"
										v-for="(itm, index) in Object.keys(item.location)"
										class="flex flex-row mb-1">
										<div class="flex flex-col w-2/3">
											<FileUpload
												:isLegalEntity="isLegalEntity"
												@update-value="changePdfLocation($event, item, itm)"
												@delete-value="deleteFile($event, item, itm)"
												accept=".pdf"
												:prefix="true"
												:prefixData="itm"
												:delSuffix="true"
												:data="item.location[itm].fileName"
												:id="  'templatesEdit' +  item.name + item.location[itm].fileName"
											/>
										</div>
										<div class="flex flex-col w-1/3">
											<div class="input-group mb-1 w-full">
												<input
													type="text"
													class="form-control"
													v-model="item.location[itm].description"
												/>
											</div>
										</div>
									</div>
									<span v-if="insertError[item.name]" class="text-primary-3"
									>At least 1 file is required</span
									>
								</div>


							</div>
							<toggle-options-insert-template
								:incoming-add-to-print="item.addToPrint"
								:incoming-archive-config="item.archivedDocumentOptions!== undefined && item.archivedDocumentOptions !== null"
								:incoming-merge="item.archivedDocumentOptions === undefined || item.archivedDocumentOptions === null ? false : item.archivedDocumentOptions.merge"
								:name="item.name"
								@toggle-slides-changed="toggleChanged">
							</toggle-options-insert-template>
							<div class="flex flex-row justify-end">
								<button
									@click="showConfirmDialog(item)"
									class="btn bg-theme-1 text-white shadow-md mr-2"
								>
									Delete template
								</button>
							</div>
						</template>
					</Accordion>
				</div>
			</div>
			<div
				:class="{ 'pointer-events-none': showConfirm }"
				class="grid grid-rows-1 justify-items-end"
			>
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
	<ConfirmDialog @close="deleteTemplate" :show="showConfirm"/>
	<AddInsertsTemplate
		:isLegalEntity="isLegalEntity"
		@add-template="addTemplate"
		:supplierGroup="supplierGroup"
		:langCode="langCodes"
	/>
	<add-file-to-template
		title-dialog="Add inserts template"
		:show-dialog="displayAddFile"
		:show-description="true"
		:isLegalEntity="this.isLegalEntity"
		@add-file="addFile"
		@cancel-add-file=" this.displayAddFile = false"
		:langCode="langCodes"
	></add-file-to-template>
</template>

<script>
import PopUpWindow from "../../PopUp.vue"
import Field from "../../Field.vue"
import FileUpload from "../../FileUpload.vue"
import Accordion from "../../Accordion.vue"
import ConfirmDialog from "../../ConfirmDialog.vue"
import DropdownSelectAll from "../../DropdownSelectAll.vue"
import ToggleOptionsInsertTemplate from "@/components/templates/inserts/toggleOptionsInsertTemplate.vue"
import AddInsertsTemplate from "@/components/templates/inserts/AddInsertsTemplate.vue"
import AddFileToTemplate from "@/components/templates/AddFileToTemplate.vue"

export default {
	name: "EditInserts",
	components: {
		AddFileToTemplate,
		AddInsertsTemplate,
		ToggleOptionsInsertTemplate,
		PopUpWindow,
		Field,
		FileUpload,
		Accordion,
		ConfirmDialog,
		DropdownSelectAll
	},
	props: {
		langCodes: Array,
		isFor: String,
	},
	data() {
		return {
			isLegalEntity: this.isFor === "legalEntity",
			currentDelItem: {},
			showConfirm: false,
			supplierGroup: this.$store.state.activeSG,
			legalEntity: this.$store.state.activeLE,
			activeItem: {},
			formData: {
				inserts: [],
			},
			selectedInsert: "",
			insertError: {},
			renderComponent: true,
			docTypes: this.$store.state.docTypes,
			openAccordionIndex: -1,
			displayAddFile: false
		}
	},
	mounted() {
		this.initData()
	},
	computed: {
		getAddedFileResponse() {
			return this.$store.state.addedEditInsertsFile
		},
		getInserts() {
			let value
			if (this.isFor === "legalEntity") {
				value = this.$store.state.insertFilesLE
			}
			if (this.isFor === "supplierGroup") {
				value = this.$store.state.insertFiles
			}
			return value
		},
		activeSG() {
			return this.$store.state.activeSG
		},
		activeLE() {
			return this.$store.state.activeLE
		}
	},
	watch: {
		activeSG() {
			this.close()
		},
		activeLE() {
			this.close()
		},

		getAddedFileResponse(data) {
			this.addFile(data)
		},
		getInserts(data) {
			this.formData.inserts = data
		},
		"$store.state.insertFiles": {
			handler() {
				this.initData()
			},
			deep: true,
		},
		"$store.state.insertFilesLE": {
			handler() {
				this.initData()
			},
			deep: true,
		},
	},
	provide() {
		return {
			updateTemplate: this.update
		}
	},
	methods: {
		updateDocTypes(docTypes, templateName) {
			let foundArr = []
			for (let index of docTypes) {
				let found = this.docTypes[index].name
				foundArr.push(found)
			}

			this.formData.inserts.forEach(template => {
				if (template.name === templateName) template.docTypes = foundArr
			})
		},
		initData() {
			this.isLegalEntity = this.isFor === "legalEntity"
			if (this.isFor === "supplierGroup") {
				if (this.$store.state.activeSG.templates !== undefined &&
					this.$store.state.activeSG.templates.inserts !== undefined) {
					this.formData.inserts = JSON.parse(JSON.stringify(this.$store.state.activeSG.templates.inserts))
				}
			}
			if (this.isFor === "legalEntity") {
				if (this.$store.state.activeLE && this.$store.state.activeLE.templates && this.$store.state.activeLE.templates.inserts) {
					this.formData.inserts = JSON.parse(JSON.stringify(this.$store.state.activeLE.templates.inserts))
				}
			}
			this.insertError = {}
		},
		changeName(item, value) {
			Object.values(this.formData.inserts).find((el) => el === item).name = value
		},
		displayAddInsertFile(data) {
			this.selectedInsert = data
			this.$store.state.parentInsertsFile = "Edit"
			this.displayAddFile = true
		},
		changePdfLocation(event, item, itm) {
			Object.values(this.formData.inserts).find(el => el === item).location[itm].fileName = event.files[0].name
			this.validate()
		},
		addFile(data) {
			Object.values(this.formData.inserts).find(el => el.name === this.selectedInsert).location = {
				...this.formData.inserts.find(el => el.name === this.selectedInsert).location,
				...data,
			}
			this.displayAddFile = false
		},
		deleteFile(event, data, itm) {
			let index = Object.values(this.formData.inserts).findIndex(el => el.name === data.name)
			delete this.formData.inserts[index].location[itm]
		},
		addTemplate(data) {
			this.initData()
			if (!this.formData.inserts) {
				this.formData.inserts = []
			}

			this.formData.inserts.push(data)
		},
		showConfirmDialog(item) {
			this.currentDelItem = item
			this.showConfirm = true
		},
		deleteTemplate(data) {
			if (data === "Yes") {
				let index = this.formData.inserts.indexOf(this.currentDelItem)
				if (index > -1) {
					this.formData.inserts.splice(index, 1)
				}
			}
			this.showConfirm = false
		},
		close() {
			if (this.isFor === "supplierGroup") {
				let insertFiles =this.$store.state.activeSG.templates?.inserts
						? this.$store.state.activeSG.templates.inserts : []
				this.$store.state.insertFiles = [...insertFiles]
				if (!insertFiles && this.formData.inserts) this.$store.state.insertFiles = []
			}

			if (this.isFor === "legalEntity") {
				let insertFilesLE = this.$store.state.activeLE.templates?.inserts
						? this.$store.state.activeLE.templates.inserts : []
				this.$store.state.insertFilesLE = [...insertFilesLE]
				if (!insertFilesLE && this.formData.inserts) this.$store.state.insertFilesLE = []
			}

			this.$store.state.displayAddInsertFile = false
			this.$store.state.displayEditInsertsTemplate = false
		},
		toggleChanged(data) {
			let item = this.formData.inserts.find((el) => el.name === data.name)
			item.addToPrint = data.addToPrint

			if (data.archiveConfig) {
				if (data.merge !== undefined) {
					item.archivedDocumentOptions = {
						merge: data.merge
					}
				}
			} else {
				item.archivedDocumentOptions = null
			}
		},
		async update() {
			let hasErrors = this.validate()
			if (!hasErrors) {
				//add dummy version field for each insert
				for (let item in this.formData.inserts) {
					this.formData.inserts[item].version = "v1"
				}
				if (this.formData.inserts.length === 0) {
					this.formData = {inserts: null}
				}
				if (this.isFor === "supplierGroup") {
					let payload = {
						sgId: this.supplierGroup.supplierGroupId,
						edit: "inserts-template",
						body: this.formData,
					}
					await this.$store.dispatch("EditSupplierGroup", payload)
					if (this.$store.state.responseStatus === 200) {
						this.$store.commit("toggleAlert", {
							title: "Edit Supplier group",
							text: "Insert template successfully edited",
						})
					}
				}

				if (this.isFor === "legalEntity") {
					let payload = {
						sgId: this.$store.state.activeSG.supplierGroupId,
						leId: this.$store.state.activeLE.entityNumber,
						edit: "inserts-template",
						body: this.formData,
					};
					await this.$store.dispatch("EditLegalEntity", payload);
					if (this.$store.state.responseStatus === 200) {
						this.$store.commit("toggleAlert", {
							title: "Edit Legal entity",
							text: "Inserts template successfully edited",
						})
					}
				}
			}
		},
		validate() {
			this.insertError = {}
			let hasErrors = false
			let hasFound = Object.values(this.formData.inserts).find(el => Object.values(el.location).length === 0)
			if (hasFound) {
				hasErrors = true
				this.insertError[hasFound.name] = true
			}
			return hasErrors
		}
	}
}
</script>