<template>
    <div :class="{ 'brightness-95': this.$store.state.displayEditSettings }"
		class="box shadow-none col-span-6"
    >
        <div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
            <div class="flex flex-row flex-nowrap">
                <div class="flex flex-col w-1/2 mr-12">
                    <!--Allow Duplicate Document Numbers-->
                    <div class="mb-3 flex">
                        <label class="font-bold form-label mb-0 mr-2">
							Allow duplicate document numbers:
						</label>
                        <ToggleSlide
							class="w-min pointer-events-none"
                            :key="supplierGroup.channelsConfig.docImage.allowDuplicateDocumentNumbers"
                            :bool="supplierGroup.channelsConfig.docImage.allowDuplicateDocumentNumbers"
							:disabled="true"
                        />
                    </div>
					<!--Move address location in ingestion flag-->
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">
							Move address location in ingestion flag:
						</label>
						<ToggleSlide
							class="w-min pointer-events-none"
							:key="supplierGroup.channelsConfig.docImage.movePdfAddressLocation"
							:bool="supplierGroup.channelsConfig.docImage.movePdfAddressLocation"
							:disabled="true"
						/>
					</div>
                    <p class="font-bold mr-3">Public link</p>
                    <!--Allow Login Public Page-->
                    <div class="mb-3 ml-6 flex">
                        <label class="font-bold form-label mb-0 mr-2">
							Allow login on public page:
						</label>
                        <ToggleSlide
							class="w-min pointer-events-none"
                            :key="supplierGroup.channelsConfig.docImage.allowLogin"
							:bool="supplierGroup.channelsConfig.docImage.allowLogin"
							:disabled="true"
                        />
                    </div>
                    <!--Public Link Expiration-->
                    <Field
                          class="ml-6"
                          title="Public Link Expiration:"
                          type="text"
                          :modelValue="supplierGroup.channelsConfig.docImage.publicLinkExpirationDays"
                          :disabled="true"
                    />
                    <!--enableSigning-->
                    <div class="mb-3 flex">
                        <label class="font-bold form-label mb-0 mr-2">
							Signing:
						</label>
                        <ToggleSlide
							class="w-min pointer-events-none"
							:key="supplierGroup.channelsConfig.docImage.enableSigning"
							:bool="supplierGroup.channelsConfig.docImage.enableSigning"
							:disabled="true"
                        />
                    </div>
                    <div v-if="supplierGroup.channelsConfig.docImage.enableSigning" class="ml-6">
                        <Field
							id="viewSettingsSigningProvider"
							title="Signing provider: *"
							type="text"
							:disabled="true"
							:modelValue="paymentProvider"
						/>

                        <label for="editDocTypes" class="font-bold form-label mb-0 mr-2">
                            Doc types:
                        </label>
                        <DropdownSelectAll
                              id="editDocTypes"
                              :class="{ 'border border-primary-3': selectedOverlayInvalid }"
                              v-if="renderComponent"
                              :data="docTypes"
                              :selected= docTypeSigning
                              :noSelectAll="true"
                              :disabled="true"
                        />
                    </div>
                    <!--enableTimestamping-->
                    <div class="mb-3 flex">
                        <label class="font-bold form-label mb-0 mr-2">
							Timestamping:
						</label>
                        <ToggleSlide
							class="w-min pointer-events-none"
							:key="supplierGroup.channelsConfig.docImage.enableTimestamping"
							:bool="supplierGroup.channelsConfig.docImage.enableTimestamping"
							:disabled="true"
                        />
                    </div>
                </div>
                <div class="flex flex-col w-1/2">
                    <!--Destroy billingdocument Request-->
                    <Field
                          id="viewSettingsReportUrl"
                          title="Google Data Studio URL:"
                          type="text"
                          :modelValue="supplierGroup.channelsConfig.docImage.configPortal.reportUrl"
                          :disabled="true"
                          :copySuffix="true"
                          :isCopied="copyGoogleUrl"
                          @copy="copy('googleUrl')"
                    />
                    <Field
                          id="destroyRequest"
                          title="Destroy billingdocument request:"
                          type="text"
                          :modelValue="supplierGroup.channelsConfig.docImage.destroyPeriod"
                          :disabled="true"
                    />
                    <div class="mb-3 flex">
                        <label class="font-bold form-label mb-0 mr-2">Autoreprint config:</label>
                        <ToggleSlide
							class="w-min pointer-events-none"
							:bool="autoReprintConfig"
							:renewBool="true"
							:disabled="true"
                        />
                    </div>
                    <div class="mx-6" v-if="autoReprintConfig">
                        <div class="mb-3 flex">
                            <label class="font-bold form-label mb-0 mr-2">
								Attach email reprint:
							</label>
                            <ToggleSlide
								class="w-min pointer-events-none"
								:bool="supplierGroup.channelsConfig.docImage.autoReprintConfig.attachEmailReprint"
								:renewBool="true"
								:disabled="true"
                            />
                        </div>
                        <div class="mb-3 flex">
                            <label class="font-bold form-label mb-0 mr-2">
								Link email reprint:
							</label>
                            <ToggleSlide
								class="w-min pointer-events-none"
								:bool="supplierGroup.channelsConfig.docImage.autoReprintConfig.linkEmailReprint"
								:renewBool="true"
								:disabled="true"
                            />
                        </div>
                        <div class="mb-3 flex">
                            <label class="font-bold form-label mb-0 mr-2">
								EDI reprint:
							</label>
                            <ToggleSlide
								class="w-min pointer-events-none"
								:bool="supplierGroup.channelsConfig.docImage.autoReprintConfig.ediReprint"
								:renewBool="true"
								:disabled="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Field from "../Field.vue";
import ToggleSlide from "../ToggleSlide.vue";
import DropdownSelectAll from "../DropdownSelectAll.vue";

export default {
    name: "ViewSettings",
    components: {
        Field,
        ToggleSlide,
        DropdownSelectAll,
    },
    data() {
        return {
            renderComponent: true,
            supplierGroup: this.$store.state.activeSG,
            copyGoogleUrl: false,
            autoReprintConfigData: !!this.$store.state.activeSG.channelsConfig.docImage.autoReprintConfig,
            docTypes: [
                {id: 0, name: ""},
                {id: 1, name: "INVOICE"},
                {id: 2, name: "CREDIT_NOTE"},
                {id: 3, name: "SALARY"},
                {id: 4, name: "CAR_INSURANCE"},
                {id: 5, name: "MANDATE_ONBOARDING"},
                {id: 5, name: "GENERIC"},
            ]
        }
    },
    methods: {
        forceRerender() {
            // Removing my-component from the DOM
            this.renderComponent = false

            this.$nextTick(() => {
                // Adding the component back in
                this.renderComponent = true
            })
        },
        copy(isFor) {
            switch (isFor) {
                case "googleUrl":
                    navigator.clipboard.writeText(this.supplierGroup.channelsConfig.docImage.configPortal.reportUrl);
                    this.copyGoogleUrl = true;
                    setTimeout(() => {
                        this.copyGoogleUrl = false
                    }, 750)
                    break
            }
        },
    },
    watch: {
        "$store.state.activeSG": {
            handler(data) {
                this.supplierGroup = data
                this.forceRerender()
            },
            deep: true,
        },
    },
    computed: {
        paymentProvider() {
            if (this.supplierGroup.channelsConfig?.signing?.provider) {
                return this.supplierGroup.channelsConfig.signing.provider
            }
            return ""
        },
        docTypeSigning(){
            if (this.supplierGroup.channelsConfig?.signing?.docTypes) {
                return this.$store.state.activeSG.channelsConfig.signing.docTypes
            }
            return []
        },

        autoReprintConfig() {
            return !!this.supplierGroup.channelsConfig.docImage.autoReprintConfig;
        },
    }
}
</script>