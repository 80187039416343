<template>
    <div
          :class="{ 'brightness-95': this.$store.state.displayEditGeneral }"
          class="box shadow-none col-span-6"
    >
        <div
              class="
        text-left
        lg:text-left
        p-5
        border-t border-gray-200
        dark:border-dark-5
      "
        >
            <div class="flex flex-row flex-nowrap">
                <div class="flex flex-col w-1/2 mr-12">
                    <Field
                          id="supplierGroupId"
                          title="Supplier Group ID:"
                          type="text"
                          :modelValue="supplierGroup.supplierGroupId"
                          :disabled="true"
                    />
                    <Field
                          id="brandingId"
                          title="Branding ID:"
                          type="text"
                          :modelValue="supplierGroup.brandingId"
                          :disabled="true"
                          :copySuffix="true"
                          :isCopied="copyBrandingId"
                          @copy="copy('brandingId')"
                    />
                    <div class="mb-3">
                        <label for="type" class="font-bold form-label">Type*:</label>
                        <Dropdown
                              id="type"
                              :key="supplierGroup.sgType"
                              :data="typeData"
                              :selected="supplierGroup.sgType"
                              :disabled="true"
                        />
                    </div>
                    <Field
                          id="name"
                          title="Name:"
                          type="text"
                          :modelValue="supplierGroup.name"
                          :disabled="true"
                          :copySuffix="true"
                          :isCopied="copyName"
                          @copy="copy('name')"
                    />
                </div>
                <div class="flex flex-col w-1/2">
                    <Field
                          id="portalLink"
                          title="Portal Link:"
                          type="text"
                          :modelValue="
              supplierGroup.channelsConfig.docImage.configPortal.portalLink
            "
                          :disabled="true"
                          :copySuffix="true"
                          :isCopied="copyPortalLink"
                          @copy="copy('portalLink')"
                    />
                    <Field
                          id="tacEmail"
                          title="Terms and Conditions Email:"
                          type="text"
                          :modelValue="
              supplierGroup.channelsConfig.docImage.configPortal.termsOfServiceEmail
            "
                          :disabled="true"
                          :copySuffix="true"
                          :isCopied="copyTacEmail"
                          @copy="copy('termsOfServiceEmail')"
                    />
                    <div class="mb-3">
                        <label for="languages" class="font-bold form-label"
                        >Languages*:</label
                        >
                        <DropdownSelectAll
                              id="languages"
                              :data="languages"
                              :key="supplierGroup.languages"
                              :selected="supplierGroup.languages"
                              :disabled="true"
                              :noSelectAll="true"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Field from "../Field.vue";
import Dropdown from "../Dropdown.vue";
import DropdownSelectAll from "../DropdownSelectAll.vue";

export default {
    name: "ViewGeneral",
    components: {
        Field,
        Dropdown,
        DropdownSelectAll,
    },
    props: {
        typeData: Array,
        languages: Array,
    },
    watch: {
        "$store.state.activeSG": {
            handler(data) {
                this.supplierGroup = data;
            },
            deep: true,
        },
    },
    data() {
        return {
            supplierGroup: this.$store.state.activeSG,
            copyName: false,
            copyBrandingId: false,
            copyPortalLink: false,
            copyTacEmail: false,
        };
    },
    methods: {
        copy(isFor) {
            switch (isFor) {
                case "brandingId":
                    navigator.clipboard.writeText(this.supplierGroup.brandingId);
                    this.copyBrandingId = true;
                    setTimeout(() => {
                        this.copyBrandingId = false;
                    }, 750);
                    break;
                case "name":
                    navigator.clipboard.writeText(this.supplierGroup.name);
                    this.copyName = true;
                    setTimeout(() => {
                        this.copyName = false;
                    }, 750);
                    break;
                case "portalLink":
                    navigator.clipboard.writeText(this.supplierGroup.channelsConfig.docImage.configPortal.portalLink);
                    this.copyPortalLink = true;
                    setTimeout(() => {
                        this.copyPortalLink = false;
                    }, 750);
                    break;
                case "termsOfServiceEmail":
                    navigator.clipboard.writeText(this.supplierGroup.channelsConfig.docImage.configPortal.termsOfServiceEmail);
                    this.copyTacEmail = true;
                    setTimeout(() => {
                        this.copyTacEmail = false;
                    }, 750);
                    break;
                default:
                    break;
            }
        },
    },
};
</script>
