<template>
	<pop-up-window
		:show="this.$store.state.displayEditEDI && !this.$store.state.displayAddEdi"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit EDI</template>
		<template #content>
			<div class="">
				<div class="mr-12">
					<!--EDI Channel-->
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">EDI Channel:</label>
						<ToggleSlide
							class="w-min"
							@update-value="updateEdiBool"
							:key="ediBool"
							:bool="ediBool"
						/>
					</div>
					<div v-if="ediBool">
						<div>
							<button
								class="btn shadow-md bg-theme-1 text-white mr-6"
								@click="displayAddEdi"
							>
								Add EDI
							</button>
						</div>
						<!--NEW-->
						<div v-if="ediChannelProvidersComputed" class="mt-5">
							<Accordion
								class="mb-5 border p-1 box border border-gray-300"
								:key="index + item"
								v-for="(item, index) in manipulatedEdi"
							>
								<template v-slot:title>
									<span class="font-semibold text-lg">{{ item.name }}</span>
								</template>
								<template v-slot:content>
									<div class="edi-item">
										<div>
											<div class="mb-3 flex-col " id="ediReceivingProvider">
												<label
													:for="'editEdiReceivingProvider' + item"
													class="font-bold form-label mb-0 mr-2"
												>
													Change receiving provider: *
												</label>
												<div class="receiving-provider">
													<div class="provider-dropdown">
														<Dropdown
															@update-value="changeReceivingProvider($event, item)"
															:data="currentEdiReceivingProviderData.filter(provider => provider.name !== item.name)"
															:id="'editEdiReceivingProvider' + item.id"
															:selected="item.name"
															:key="item.name"
														/>
													</div>
													<div class="provider-name" :style="{ 'z-index': 1 }">
														{{ item.name }}
													</div>
												</div>
											</div>
											<div class="mb-3 flex-col">
												<label
													:for="'editEdiSendingProvider' + item"
													class="font-bold form-label mb-0 mr-2"
												>
													Sending provider: *
												</label>
												<Dropdown
													@update-value="changeSendingProvider($event, item)"
													:data="sendingProvider"
													:id="'editEdiSendingProvider' + item"
													:selected="item.provider"
													:key="item.provider"
												/>
											</div>
										</div>
										<div>
											<div class="mb-3 flex">
												<label class="font-bold form-label mb-0 mr-2">
													Resend allowed by supplier:
												</label>
												<ToggleSlide
													class="w-min"
													@update-value="changeResendBool($event, item)"
													:key="item.resendAllowedBySupplier"
													:bool="item.resendAllowedBySupplier"
												/>
											</div>
											<div class="mb-3 flex">
												<label class="font-bold form-label mb-0 mr-2">Fallback to BW:</label>
												<ToggleSlide
													class="w-min"
													@update-value="changeFallbackToBWBool($event, item)"
													:key="item.fallbackToBW"
													:bool="item.fallbackToBW"
												/>
											</div>
											<div class="mb-3 flex">
												<label class="font-bold form-label mb-0 mr-2">Embed to BW:</label>
												<ToggleSlide
													class="w-min"
													@update-value="changeEmbedToBWBool($event, item)"
													:key="item.embedToBW"
													:bool="item.embedToBW"
												/>
											</div>
											<div class="mb-3 flex">
												<label class="font-bold form-label mb-0 mr-2">
													Sender Registration number check:
												</label>
												<ToggleSlide
													class="w-min"
													@update-value="changeSenderRegNrBool($event, item)"
													:key="item.receiverRegNrCheck"
													:bool="item.receiverRegNrCheck"
												/>
											</div>
										</div>
									</div>
									
									<div class="flex flex-row justify-end">
										<button
											@click="showConfirmDialog(item)"
											class="btn bg-theme-1 text-white shadow-md mr-2"
										>
											Delete EDI
										</button>
									</div>
								</template>
							</Accordion>
						</div>
						<p v-else>no new channel providers</p>
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
	<ConfirmDialog @close="deleteEdi" :show="showConfirm"/>
	<AddEdi @add-edi="addEdi"
			:ediData="sendingProvider"
			:ediReceivingProvider="currentEdiReceivingProviderData"
	/>
	<new-alert
		v-if="channelChanged"
		title="Le channels are configured"
		:is-visible="channelChanged"
		text="There is a le with separate configurations. Check if still correct"
		style="margin-top: 30px"
	/>
</template>

<script>
import PopUpWindow from "../PopUp.vue"
import ToggleSlide from "../ToggleSlide.vue"
import Accordion from "../Accordion.vue"

import Dropdown from "../Dropdown.vue"
import ConfirmDialog from "../ConfirmDialog.vue"
import AddEdi from "../AddSupplierGroup/addEdi.vue"
import NewAlert from "@/components/NewAlert.vue";

export default {
	name: "EditEDI",
	components: {
		NewAlert,
		PopUpWindow,
		ToggleSlide,
		AddEdi,
		Accordion,
		ConfirmDialog,
		Dropdown,
	},
	mounted() {
		this.initData()
	},
	data() {
		return {
			showConfirm: false,
			curDelItem: {},
			sendingProvider: this.$store.state.sendingProvider,
			ediReceivingProviderData: this.$store.state.ediReceivingProviderData,
			manipulatedEdi: [],
			formData: {
				edi: this.$store.state.ediFiles,
			},
			ediBool:!!this.$store.state.activeSG.channelsConfig?.edi,
			currentEdiReceivingProviderData: JSON.parse(JSON.stringify(this.$store.state.ediReceivingProviderData)),
			channelChanged: false
		}
	},
	computed: {
		ediChannelProvidersComputed() {
			return this.manipulatedEdi.length > 0
		},
		getEdiFiles() {
			return this.$store.state.ediFiles
		},
	},
	watch: {
		getEdiFiles(data) {
			this.initData()
		},
	},
	methods: {
		setCurrentEdiReceivingProviderData() {
			let options = JSON.parse(JSON.stringify(this.$store.state.ediReceivingProviderData))
			let removeIndexes = []
			
			let namesToRemove = []

			if (this.manipulatedEdi.length !== 0) {
				this.manipulatedEdi.forEach(item => {
					namesToRemove.push(item.name)
				})
			}

			let uniqueNamesToRemove = [...new Set(namesToRemove)]

			uniqueNamesToRemove.forEach(name => {
				options.forEach((option, index) => {
					if (name === option.name) {
						removeIndexes.push(index)
					}
				})
			})
			let sortedIndexes = removeIndexes.sort().reverse()
			
			sortedIndexes.forEach(index => {
				options.splice(index, 1)
			})

			this.currentEdiReceivingProviderData = options
		},
		initData() {
			this.showConfirm = false
			this.curDelItem = {}
			this.ediBool = !!this.$store.state.activeSG.channelsConfig?.edi
			this.formData.edi = this.$store.state.ediFiles
			this.manipulatedEdi = []
			if (this.$store.state.ediFiles?.channelProviders) {
				for (let item in this.$store.state.ediFiles.channelProviders) {
					let obj = {
						name: item,
						provider: this.$store.state.ediFiles.channelProviders[item],
					}
					this.manipulatedEdi.push(obj)
				}
			}
			if (this.$store.state.ediFiles?.ediChannelProviders) {
				for (let item in this.$store.state.ediFiles.ediChannelProviders) {
					let obj = {
						name: item,
						provider: this.$store.state.ediFiles.ediChannelProviders[item].provider,
						resendAllowedBySupplier: this.$store.state.ediFiles.ediChannelProviders[item].resendAllowedBySupplier,
						fallbackToBW: this.$store.state.ediFiles.ediChannelProviders[item].fallbackToBW,
						embedToBW: this.$store.state.ediFiles.ediChannelProviders[item].embedToBW,
					}
					if (this.$store.state.ediFiles.ediChannelProviders[item].receiverRegNrCheck) {
						obj.receiverRegNrCheck = this.$store.state.ediFiles.ediChannelProviders[item].receiverRegNrCheck
					} else {
						obj.receiverRegNrCheck = false
					}

					this.manipulatedEdi.push(obj)
				}
			}
			this.setCurrentEdiReceivingProviderData()
		},
		updateEdiBool(data) {
			this.ediBool = data
		},

		changeReceivingProvider(data, item) {
			if (this.currentEdiReceivingProviderData[data[0]]) {
				Object.values(this.manipulatedEdi).find((el) => el === item).name = this.currentEdiReceivingProviderData[data[0]].name
				this.setCurrentEdiReceivingProviderData()
			}
		},
		changeSendingProvider(data, item) {
			Object.values(this.manipulatedEdi).find((el) => el === item).provider = this.sendingProvider[data[0]].name
		},
		changeResendBool(data, item) {
			Object.values(this.manipulatedEdi).find(
				(el) => el === item
			).resendAllowedBySupplier = data
		},
		changeFallbackToBWBool(data, item) {
			Object.values(this.manipulatedEdi).find((el) => el === item).fallbackToBW = data
		}, changeEmbedToBWBool(data, item) {
			Object.values(this.manipulatedEdi).find((el) => el === item).embedToBW = data
		},
		changeSenderRegNrBool(data, item) {
			Object.values(this.manipulatedEdi).find((el) => el === item).receiverRegNrCheck = data
		},
		displayAddEdi() {
			this.$store.state.displayAddEdi = true
		},
		addEdi(payload) {
			this.manipulatedEdi.push(payload)
			this.setCurrentEdiReceivingProviderData()
		},
		showConfirmDialog(item) {
			this.curDelItem = item
			this.showConfirm = true
		},
		deleteEdi(data) {
			if (data === "Yes") {
				let index = this.manipulatedEdi.indexOf(this.curDelItem)
				if (index > -1) {
					this.manipulatedEdi.splice(index, 1)
				}
			}
			this.showConfirm = false
		},
		close() {
			this.$store.state.ediFiles = this.$store.state.activeSG.channelsConfig.edi 
				? JSON.parse(JSON.stringify(this.$store.state.activeSG.channelsConfig.edi))
				: null
			this.$store.state.displayAddEdi = false
			this.$store.state.displayEditEDI = false
			this.initData()
		},
		async update() {
			let leSettingsOn = false
			Object.values(this.$store.state.activeSG.supplierLegalEntities).forEach(element => {
				if (element.channelsConfig !== undefined && Object.keys(element.channelsConfig).length > 0) leSettingsOn = true
			})
			if(leSettingsOn && this.ediBool !== (this.$store.state.activeSG.channelsConfig.edi !== undefined)){
				this.channelChanged = true
				setTimeout(() => {
					this.channelChanged = false
				}, 5000)
			}
			if (!this.formData.edi) {
				this.formData.edi = {ediChannelProviders: {}}
			}
			if (this.manipulatedEdi.length > 0) {
				this.formData.edi.ediChannelProviders = {}
				for (let item in this.manipulatedEdi) {
					this.formData.edi.ediChannelProviders[
						this.manipulatedEdi[item].name
						] = {
						provider: this.manipulatedEdi[item].provider,
						resendAllowedBySupplier:
						this.manipulatedEdi[item].resendAllowedBySupplier,
						fallbackToBW: this.manipulatedEdi[item].fallbackToBW,
						embedToBW: this.manipulatedEdi[item].embedToBW,
						receiverRegNrCheck: this.manipulatedEdi[item].receiverRegNrCheck,

					}
				}
				this.formData.edi.channelProviders = null
			}
			if (!this.ediBool || this.manipulatedEdi.length === 0) {
				this.formData.edi = null
			}

			let payload = {
				sgId: this.$store.state.activeSG.supplierGroupId,
				edit: "edi-settings",
				body: this.formData,
			}
			await this.$store.dispatch("EditSupplierGroup", payload)
			if (this.$store.state.responseStatus === 200) {
				this.$store.commit("toggleAlert", {
					title: "Edit SupplierGroup",
					text: "EDI successfully edited",
				})
			}
			this.close()
		}
	}
}
</script>

<style scoped>
.edi-item {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto;
	grid-column-gap: 20px;
}

.receiving-provider {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
}

.receiving-provider .provider-name, .receiving-provider .provider-dropdown {
	grid-column: 1/2;
	grid-row: 1/2;
}

.receiving-provider .provider-name {
	width: 0px;
	z-index: 50;
	align-self: center;
	margin-left: 10px;
}

</style>