<template>
	<pop-up-window
		:show="this.$store.state.displayAddExtractionTemplate"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Add Extraction template</template>
		<template #content>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-1/4">
					<p class="error" v-if="templateErrors">
						Name, Legal entity and at least one extraction field has to be filled in
					</p>
					<Field
						id="addExtractionName"
						title="Name:"
						type="text"
						v-model="formData.name"
						@click=clearErrors
					/>
					<div class="one-line-grid mt-2">
						<p class="text-lg font-bold mr-2">General template info:</p>
						<div>
							<button class="btn py-0 shadow-md mb-2"
									@click="toggleDisplayInfo('displayInfo')"
							>
								{{ displayText }}
							</button>
						</div>
					</div>
					<div v-if="displayInfo" class="ml-4">
						<FileUpload
							@update-value="updateFileValue"
							@delete-value="deleteFileValue"
							:data="formData.pdfFile"
							:delSuffix="true"
							id="editPdfTemplateUploadPreview"
							title="Upload .pdf template preview:"
							accept=".pdf"
						/>
						<Field
							id="addExtractionLocation"
							title="Location:"
							type="text"
							v-model="formData.location"
						/>
						<div>
							<p class="font-bold form-label mb-0"> Legal entity: </p>
							<Dropdown
								class="mt-2"
								:data="legalEntities"
								@update-value="updateLegalEntity($event)"
							/>
						</div>
						<div class="mt-4 move-address-location">
							<p class="font-bold form-label mb-0"> Move address location: </p>
							<ToggleSlide
								@update-value="updateMoveAddressLocation"
								class="w-min"
								id="editMoveAddressLocation"
								:bool="formData.moveAddressLocation"
							/>
						</div>
						<div class="mt-4 move-address-location">
							<label class="font-bold form-label mb-0">
								Use system date when no date was found:
							</label>
							<ToggleSlide
								@update-value="updateUseSystemDate"
								class="w-min"
								:bool="formData.useSystemDate"
							/>
						</div>
						<div class="mt-2">
							<p class="font-bold form-label mb-0">Paper format: </p>
							<Dropdown
								class="mt-2"
								:data="paperFormatOptions"
								@update-value="updatePaperFormat($event)"
								:selected=formData.paperFormat
							/>
						</div>
					</div>

					<div>
						<div class="button-container mt-2">
							<p class="text-lg font-bold mr-2">Extraction Fields:</p>
							<div>
								<button
									v-if="displayToggleFieldButton"
									@click="toggleExtractionField('displayExtractionField')"
									class="btn py-0 shadow-md mb-2"
								>
									{{ toggleExtractionFieldText }}
								</button>
								<button
									@click="displayAddExtractionField"
									class="btn bg-theme-1 py-0 text-white shadow-md mb-2"
								>
									Add
								</button>
								<AddExtractionField
									@add-field="addField"
									@cancel="showExtractionFields = false"
									:extractionDataType="extractionDataType"
									:fieldTypeData="manipulatedFields"
									:show-add-extraction-field="showExtractionFields"
								/>
							</div>
						</div>
						<div v-if="displayExtractionField">
							<Accordion
								:trigger="triggered"
								@click="giveDraggableColor(index)"
								:class="{ 'bg-primary-4': selectedField === index, 'edit-item': selectedField === index,  'edit-mode' : editMode}"
								class="mb-3 border border-gray-300 p-1 box ml-4 accordion-header"
								:key="item + index"
								v-for="(item, index) in formData.areas"
							>
								<template v-slot:title>
									<span class="font-semibold">{{ index }}</span>
									<span v-if="!editMode" class="edit-mode" @click="toggleEditMode(index)">
										<font-awesome-icon icon="pencil"/>
									</span>
									<span v-if="editMode" class="edit-mode" @click="toggleEditMode(index)">X</span>
								</template>
								<template v-slot:content>
									<div class="p-3 flex flex-col w-full">
										<div v-if="item.field === 'DUMMY'" class="mb-3">
											<label class="font-bold form-label">Field type:</label>
											<Dropdown
												:data="manipulatedFields"
												:key="item.field"
												:selected="item.field"
												@update-value="updateDummyFieldValue($event,item)"
											/>
										</div>
										<div class="flex">
											<Field
												id="viewExtractionXCoord"
												title="X-Coord:"
												:numberIntervals="0.1"
												type="number"
												v-model="item.x"
											/>
											<Field
												id="viewExtractionYCoord"
												title="Y-Coord:"
												:numberIntervals="0.1"
												type="number"
												v-model="item.y"
											/>
										</div>
										<div class="flex">
											<Field
												id="viewExtractionWidth"
												title="Width:"
												type="number"
												v-model="item.width"
											/>
											<Field
												id="viewExtractionHeight"
												title="Height:"
												type="number"
												v-model="item.height"
											/>
										</div>

										<div v-if="item.navigationText">
											<div class="mb-3">
												<Field
													class=""
													id="addExtractionRegex"
													type="string"
													title="Navigation text:"
													v-model="item.navigationText"
												/>
											</div>
										</div>

										<div v-if="item.defaultValue">
											<div class="mb-3">
												<Field
													class=""
													id="addDefaultValue"
													type="string"
													title="Default value:"
													v-model="item.defaultValue"
												/>
											</div>
										</div>

										<div v-if="item.dateFormatter">
											<div class="mb-3">
												<Field
													class=""
													id="addDateFormatter"
													type="string"
													title="Date formatter:"
													v-model="item.dateFormatter"
												/>
											</div>
										</div>

										<div class="mb-3" v-if="item.bigDecimalFormatter">
											<label class="font-bold form-label" v-if="item.bigDecimalFormatter">Big
												decimal
												formatter (both has to be filled in or empty):</label>
											<div class="ml-4">
												<label class="font-bold form-label">Grouping separator:</label>
												<Dropdown
													@update-value="updateBigDecimalFormatter($event, item, 'groupingSeparator')"
													:data="separatorOptions"
													:key="1"
													:selected="item.bigDecimalFormatter.groupingSeparator"
												/>
											</div>
											<div class="ml-4 mt-2">
												<label class="font-bold form-label">Decimal separator:</label>
												<Dropdown
													@update-value="updateBigDecimalFormatter($event, item, 'decimalSeparator')"
													:data="separatorOptions"
													:key="2"
													:selected="item.bigDecimalFormatter.decimalSeparator"
												/>
											</div>
										</div>

										<div class="mb-3" v-if="item.additionalRules">
											<label class="font-bold form-label" v-if="item.additionalRules">Additional
												rules:
											</label>
											<div
												v-if="item.additionalRules.offset"
												class="ml-4">
												<label class="font-bold form-label">Offset:</label>
												<Dropdown
													@update-value="updateAdditionalRules($event, item, 'offset')"
													:data="offsetOptions"
													:key="3"
													:selected="item.additionalRules.offset"
												/>
											</div>
										</div>

										<div class="flex flex-row justify-end">
											<button
												@click="showConfirmDialog(index)"
												class="btn bg-theme-1 text-white shadow-md mr-2"
											>
												Delete field
											</button>
										</div>
									</div>
								</template>
							</Accordion>
						</div>
						<div class="table-field-container" v-if="formData.extractionTableInfo">
							<div class="clear-table-info">
								<button
									@click="clearTableFields"
									v-if="displayClearButton"
									class="btn bg-theme-1 py-0 text-white shadow-md mb-2"
								>
									Clear table info
								</button>
							</div>
							<div class="one-line-grid">
								<p class="text-lg font-bold mr-2">General table info:</p>
								<div>
									<button
										class="btn py-0 shadow-md mb-2"
										@click="toggleDisplayInfo('displayInfoTable')"
									>
										{{ displayTextTable }}
									</button>
								</div>
							</div>
							<div
								class="ml-4"
								v-if="displayInfoTable"
								@click="setErrors('tableGeneralFields', false)"
							>
								<p class="error" v-if="errors.tableGeneralFields">
									Please, fill in all required fields and at least one extraction table field
								</p>
								<Field
									title="Table headers* (separate by comma):"
									type="text"
									v-model="formData.extractionTableInfo.tableHeaders"
								/>
								<Field
									title="Table cutoff* (separate by comma): "
									type="text"
									v-model="formData.extractionTableInfo.tableCutoff"
								/>
								<Field
									title="Max description lines* (only values >=1):"
									:numberIntervals="1"
									type="number"
									v-model="formData.extractionTableInfo.maxDescriptionLines"
								/>
								<div class="">
									<p class="font-bold form-label mb-0 mt-2"> Present in all rows*: </p>
									<Dropdown
										@update-value="updatePresentInAllRows($event)"
										:data="documentFieldOptions"

										:selected="formData.extractionTableInfo.presentInAllRows"
									/>
								</div>
							</div>

							<div class="button-container mt-2">
								<p class="text-lg font-bold mr-2">Extraction Table Fields:</p>
								<div>
									<button
										v-if="displayToggleTableFieldButton"
										@click="toggleExtractionField('displayExtractionTableField')"
										class="btn py-0 shadow-md mb-2"
									>
										{{ toggleExtractionTableFieldText }}
									</button>
									<button
										@click="displayAddExtractionTableField"
										class="btn bg-theme-1 py-0 text-white shadow-md mb-2"
									>
										Add
									</button>
									<AddExtractionField
										@add-field="addTableFields"
										@cancel="showExtractionTable = false"
										:extractionDataType="extractionDataType"
										:fieldTypeData="manipulatedTableFields"
										:show-add-extraction-field="showExtractionTable"
									/>
								</div>
							</div>

							<div v-if="formData.extractionTableInfo && displayExtractionTableField">
								<div>
									<Accordion
										:trigger="triggered"
										@click="giveDraggableColor(index)"
										:class="{ 'bg-primary-4': selectedField === index, 'edit-item': selectedField === index,  'edit-mode' : editMode }"
										class="mb-3 border border-gray-300 p-1 box ml-4 accordion-header"
										:key="item + index"
										v-for="(item, index) in formData.extractionTableInfo.tableColumns"
									>
										<template v-slot:title>
											<span class="font-semibold">{{ index }}</span>
											<span v-if="!editMode" class="edit-mode" @click="toggleEditMode(index)">
                                                <font-awesome-icon icon="pencil"/>
											</span>
											<span v-if="editMode" class="edit-mode" @click="toggleEditMode(index)">
												X
											</span>
										</template>
										<template v-slot:content>
											<div class="flex">
												<Field
													title="X-Coord:"
													:numberIntervals="0.1"
													type="number"
													v-model="item.x"
												/>
												<Field
													title="Y-Coord:"
													:numberIntervals="0.1"
													type="number"
													v-model="item.y"
												/>
											</div>
											<div class="flex">
												<Field
													title="Width:"
													type="number"
													v-model="item.width"
												/>
												<Field
													title="Height:"
													type="number"
													v-model="item.height"
												/>
											</div>
											<div>
												<Field
													title="Default value:"
													type="text"
													v-model="item.defaultValue"
												/>
												<div class="mt-2">
													<label class="font-bold form-label" v-if="item.bigDecimalFormatter">Big
														decimal
														formatter (both has to be filled in or empty):
													</label>
													<div class="ml-4 mt-2">
														<div class="ml-4">
															<label class="font-bold form-label">Grouping
																separator:</label>
															<Dropdown
																@update-value="updateBigDecimalFormatter($event, item, 'groupingSeparator')"
																:data="separatorOptions"
																:key="1"
																:selected="item.bigDecimalFormatter.groupingSeparator"
															/>
														</div>
														<div class="ml-4 mt-2">
															<label class="font-bold form-label">Decimal
																separator:</label>
															<Dropdown
																@update-value="updateBigDecimalFormatter($event, item, 'decimalSeparator')"
																:data="separatorOptions"
																:key="3"
																:selected="item.bigDecimalFormatter.decimalSeparator"
															/>
														</div>
													</div>
													<div class="flex flex-row justify-end">
														<button
															@click="showConfirmDialog(index, 'field')"
															class="btn bg-theme-1 text-white shadow-md mr-2 mt-2"
														>
															Delete field
														</button>
													</div>
												</div>
											</div>
										</template>
									</Accordion>
								</div>
							</div>

						</div>

						<div class="table-field-container">
							<div v-if="formData.summaryTableInfo">
								<div class="clear-table-info">
									<button
										@click="clearTableFieldsSummary"
										v-if="displayClearButtonSummary"
										class="btn bg-theme-1 py-0 text-white shadow-md mb-2"
									>
										Clear summary table info
									</button>
								</div>
								<div class="one-line-grid">
									<p class="text-lg font-bold mr-2">General summary info:</p>
									<div>
										<button class="btn py-0 shadow-md mb-2"
												@click="toggleDisplayInfo('displayInfoSummaryTable')"
										>
											{{ displayTextSummaryTable }}
										</button>
									</div>
								</div>
								<div
									v-if="displayInfoSummaryTable"
									class="ml-4"
									@click="setErrors('summaryTableGeneralFields', false)"
								>
									<p class="error" v-if="errors.summaryTableGeneralFields">
										Please, fill in all required fields and at least one extraction table field
									</p>
									<Field
										title="Table headers* (separate by comma):"
										type="text"
										v-model="formData.summaryTableInfo.tableHeaders"
									/>
									<Field
										title="Table cutoff* (separate by comma):"
										type="text"
										v-model="formData.summaryTableInfo.tableCutoff"
									/>
									<Field
										title="Max description lines* (only values >=1):"
										:numberIntervals="1"
										type="number"
										v-model="formData.summaryTableInfo.maxDescriptionLines"
									/>
									<div class="">
										<p class="font-bold form-label mb-0 mt-2"> Present in all rows*: </p>
										<Dropdown
											@update-value="updatePresentInAllRowsSummary($event)"
											:data="documentSummaryFieldOptions"
											:selected="formData.summaryTableInfo.presentInAllRows"
										/>
									</div>

									<div>
										<label class="font-bold form-label mt-2">Offset:</label>
										<Dropdown
											@update-value="updateOffsetSummary($event)"
											:data="offsetOptions"
											:selected="formData.summaryTableInfo.offset"
										/>
									</div>
								</div>
							</div>
							<div class="button-container mt-2">
								<p class="text-lg font-bold mr-2">Summary Fields:</p>
								<div>
									<button
										v-if="displayToggleSummaryTableFieldButton"
										@click="toggleExtractionField('displayExtractionSummaryTableField')"
										class="btn py-0 shadow-md mb-2"
									>
										{{ toggleExtractionSummaryTableFieldText }}
									</button>
									<button
										@click="displayAddExtractionSummaryTableField"
										class="btn bg-theme-1 py-0 text-white shadow-md mb-2"
									>
										Add
									</button>
									<AddExtractionField
										@add-field="addSummaryTableFields"
										@cancel="showSummaryFields = false"
										:extractionDataType="extractionDataType"
										:fieldTypeData="manipulatedSummaryTableFields"
										:show-add-extraction-field="showSummaryFields"
									/>
								</div>
							</div>
							<div v-if="formData.summaryTableInfo && displayExtractionSummaryTableField">
								<div>
									<Accordion
										:trigger="triggered"
										@click="giveDraggableColor(index)"
										:class="{ 'bg-primary-4': selectedField === index, 'edit-item': selectedField === index,  'edit-mode' : editMode}"
										class="mb-3 border border-gray-300 p-1 box ml-4 accordion-header"
										:key="item + index"
										v-for="(item, index) in formData.summaryTableInfo.tableColumns"
									>
										<template v-slot:title>
											<span class="font-semibold">{{ index }}</span>

											<span
												v-if="!editMode"
												class="edit-mode"
												@click="toggleEditMode(index)"
											>
                                                <font-awesome-icon icon="pencil"/>
                                            </span>
											<span
												v-if="editMode"
												class="edit-mode"
												@click="toggleEditMode(index)"
											>
												X
                                            </span>
										</template>
										<template v-slot:content>
											<div class="flex">
												<Field
													title="X-Coord:"
													:numberIntervals="0.1"
													type="number"
													v-model="item.x"
												/>
												<Field
													title="Y-Coord:"
													:numberIntervals="0.1"
													type="number"
													v-model="item.y"
												/>
											</div>
											<div class="flex">
												<Field
													title="Width:"
													type="number"
													v-model="item.width"
												/>
												<Field
													title="Height:"
													type="number"
													v-model="item.height"
												/>
											</div>
											<div>
												<Field
													title="Default value:"
													type="text"
													v-model="item.defaultValue"
												/>

												<div class="mt-2">
													<label class="font-bold form-label" v-if="item.bigDecimalFormatter">Big
														decimal
														formatter (both has to be filled in or empty):
													</label>

													<div class="ml-4 mt-2">
														<div class="ml-4">
															<label class="font-bold form-label">Grouping
																separator:</label>
															<Dropdown
																@update-value="updateBigDecimalFormatter($event, item, 'groupingSeparator')"
																:data="separatorOptions"
																:key="1"
																:selected=" item.bigDecimalFormatter.groupingSeparator"
															/>
														</div>
														<div class="ml-4 mt-2">
															<label class="font-bold form-label">Decimal
																separator:</label>
															<Dropdown
																@update-value="updateBigDecimalFormatter($event, item, 'decimalSeparator')"
																:data="separatorOptions"
																:key="3"
																:selected="item.bigDecimalFormatter.decimalSeparator"
															/>
														</div>

													</div>
													<div class="flex flex-row justify-end">
														<button
															@click="showConfirmDialog(index, 'field')"
															class="btn bg-theme-1 text-white shadow-md mr-2 mt-2"
														>
															Delete field
														</button>
													</div>
												</div>
											</div>
										</template>
									</Accordion>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex flex-col w-3/4">
					<div class="mb-3 flex justify-center">
						<label
							for="editExtractionDragState"
							class="font-bold form-label mr-3 mb-0">
							Drag Disabled
						</label>
						<ToggleSlide
							@update-value="changeDragState"
							class="w-min"
							id="editExtractionDragState"
							:bool="dragStateBool"
						/>
						<label
							for="editExtractionDragState"
							class="font-bold form-label mx-3 mb-0">
							Enabled</label>
						<label
							for="editExtractionCursorState"
							class="font-bold form-label mr-3 mb-0">
							Move
						</label>
						<ToggleSlide
							@update-value="changeCursorState"
							class="w-min"
							id="editExtractionCursorState"
							:bool="cursorStateBool"
						/>
						<label
							for="editExtractionCursorState"
							class="font-bold form-label ml-3 mb-0">
							Scale
						</label>
					</div>
					<div v-if="!formData.pdfFile" class="no-pdf">
						<p>No pdf yet</p>
					</div>
					<div
						v-if="formData.pdfFile"
						ref="boundary"
						class="mx-auto relative border-4"
						@mousedown="handleMouseDown"
						@mousemove="handleMouseMove"
						@mouseup="handleMouseUp"
					>
						<div
							:key="item + item.field + index"
							v-for="(item, index) in formData.areas"
							:ref="index"
							:class="{ 'border-primary-4': selectedField === index,
                                         'cursor-move': !cursorStateBool,
                                         'cursor-resize': cursorStateBool,
                                          }"
							@mouseenter="selectDraggable(index)"
							class="makeSquare cursor-move absolute border z-20"
							:style="{
                                    bottom: item.y + '%',
                                     left: item.x + '%',
                                     width: item.width + '%',
                                    height: item.height + '%',}"
						>
						</div>
						<div v-if="formData.extractionTableInfo">
							<div
								:key="item + item.field + index"
								v-for="(item, index) in formData.extractionTableInfo.tableColumns"
								:ref="index"
								:class="{  'border-primary-4': selectedField === index,
                                              'cursor-move': !cursorStateBool,
                                              'cursor-resize': cursorStateBool,
                                  }"
								@mouseenter="selectDraggable(index)"

								class="makeSquare cursor-move absolute border z-20"
								:style="{ bottom: item.y + '%',
                                          left: item.x + '%',
                                          width: item.width + '%',
                                          height: item.height + '%',
                               }"
							>
							</div>
						</div>

						<div v-if="formData.summaryTableInfo">
							<div
								:key="item + item.field + index"
								v-for="(item, index) in formData.summaryTableInfo.tableColumns"
								:ref="index"
								:class="{  'border-primary-4': selectedField === index,
                                              'cursor-move': !cursorStateBool,
                                              'cursor-resize': cursorStateBool,
                                  }"
								@mouseenter="selectDraggable(index)"
								class="makeSquare cursor-move absolute border z-20"
								:style="{ bottom: item.y + '%',
                                          left: item.x + '%',
                                          width: item.width + '%',
                                          height: item.height + '%',
                               }"
							>
							</div>
						</div>
						<div ref="pdfContainer">
							<VuePdf
								class="w-full"
								:scale="1.25"
								:src="formData.pdfFile"
								:page="1"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="create">Create</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
	<ConfirmDialog @close="deleteField" :show="showConfirm"/>
</template>

<script>
import PopUpWindow from "../PopUp.vue"
import Field from "../Field.vue"
import Accordion from "../Accordion.vue"
import AddExtractionField from "../AddSupplierGroup/AddExtractionField.vue"
import ConfirmDialog from "../ConfirmDialog.vue"
import ToggleSlide from "../ToggleSlide.vue"
import Dropdown from "../Dropdown.vue"
import FileUpload from "../FileUpload.vue"
import {VuePdf} from "vue3-pdfjs"

export default {
	name: "AddExtractionTemplate",
	components: {
		VuePdf,
		PopUpWindow,
		ToggleSlide,
		ConfirmDialog,
		Field,
		Dropdown,
		Accordion,
		AddExtractionField,
		FileUpload,
	},
	props: {
		fieldTypeData: Array,
		extractionDataType: Array,
	},
	data() {
		return {
			displayExtractionSummaryTableField: true,
			manipulatedSummaryTableFields: [{id: 0, name: "SELECT FIELD"}],
			displayInfoSummaryTable: true,

			displayExtractionField: true,
			displayExtractionTableField: true,
			editMode: false,
			displayInfo: true,
			displayInfoTable: true,

			manipulatedTableFields: [{id: 0, name: "SELECT FIELD"}],
			documentFieldOptions: this.$store.state.tableFieldTypeData,
			errors: {
				tableGeneralFields: false,
				summaryTableGeneralFields: false,
			},
			manipulatedFields: [...this.fieldTypeData],
			showConfirm: false,
			cursorStateBool: false,
			dragStateBool: false,
			currentDelItem: {},
			selectedField: "",
			isDragging: false,
			triggered: false,
			formData: {
				name: "",
				location: '',
				legalEntityId: '',
				areas: {},
				moveAddressLocation: false,
				paperFormat: 'A4',

				extractionTableInfo: {
					tableHeaders: '',
					tableCutoff: '',
					maxDescriptionLines: 1,
					presentInAllRows: this.$store.state.tableFieldTypeData[0].name,
					tableColumns: {}
				},
				summaryTableInfo: {
					tableHeaders: '',
					tableCutoff: '',
					presentInAllRows: this.$store.state.tableSummaryFieldTypeData[0].name,
					offset: "No offset",
					tableColumns: {}
				}
			},
			test: {
				startX: 0,
				startY: 0,
				stopX: 0,
				stopY: 0,
			},
			supplierGroups: this.$store.state.supplierGroups,
			extractionTemplates: this.$store.state.extractionTemplates,
			newName: '',
			templateErrors: false,

			paperFormatOptions: [
				{id: 0, name: "A4"},
				{id: 1, name: "US_LETTER"},
			],
			separatorOptions: [
				{id: 0, name: "COMMA"},
				{id: 1, name: "DOT"},
				{id: 2, name: "SPACE"},
				{id: 3, name: "No separator"},
			],
			offsetOptions: [
				{id: 0, name: "No offset"},
				{id: 1, name: "-2"},
				{id: 2, name: "-1"},
				{id: 3, name: "1"},
				{id: 4, name: "2"},
			],
			documentSummaryFieldOptions: [{
				id: 8,
				name: "SELECT FIELD"
			}, ...this.$store.state.tableSummaryFieldTypeData],

			showExtractionFields: false,
			showExtractionTable: false,
			showSummaryFields: false
		}
	},
	mounted() {
		this.clearTempFile()
	},
	computed: {
		toggleExtractionSummaryTableFieldText() {
			if (!this.displayExtractionSummaryTableField) {
				return "Show"
			}
			return "Hide"
		},
		displayTextSummaryTable() {
			if (!this.displayInfoSummaryTable) {
				return "Show"
			}
			return "Hide"
		},
		displayClearButtonSummary() {
			return !!this.formData.summaryTableInfo
		},
		displayToggleTableFieldButton() {
			return !!Object.keys(this.formData.extractionTableInfo.tableColumns).length
		},
		displayToggleSummaryTableFieldButton() {
			return !!Object.keys(this.formData.summaryTableInfo.tableColumns).length
		},
		displayToggleFieldButton() {
			return !!Object.keys(this.formData.areas).length
		},
		toggleExtractionFieldText() {
			if (!this.displayExtractionField) {
				return "Show"
			}
			return "Hide"
		},
		toggleExtractionTableFieldText() {
			if (!this.displayExtractionTableField) {
				return "Show"
			}
			return "Hide"
		},
		displayClearButton() {
			return !!(Object.keys(this.formData.extractionTableInfo.tableColumns).length
				|| this.formData.extractionTableInfo.tableHeaders !== ''
				|| this.formData.extractionTableInfo.tableCutoff !== ''
				|| this.formData.extractionTableInfo.maxDescriptionLines !== 1
				|| this.formData.extractionTableInfo.presentInAllRows !== this.$store.state.tableFieldTypeData[0].name)
		},
		displayTextTable() {
			if (!this.displayInfoTable) {
				return "Show"
			}
			return "Hide"
		},
		displayText() {
			if (!this.displayInfo) {
				return "Show"
			}
			return "Hide"
		},
		selectedLe() {
			if (this.formData.legalEntityId) {
				return this.$store.state.activeSG.supplierLegalEntities[this.formData.legalEntityId].internalName ? this.$store.state.activeSG.supplierLegalEntities[this.formData.legalEntityId].internalName : this.$store.state.activeSG.supplierLegalEntities[this.formData.legalEntityId].name
			}
			return ''
		},
		templateNames() {
			let names = [{id: 0, name: "Select template"}]
			if (this.$store.state.extractionTemplates.length) {
				let index = 1
				this.$store.state.extractionTemplates.forEach(template => {
					let tempItem = {
						id: index,
						name: template.name
					}
					names.push(tempItem)
					index = index + 1
				})
			}
			return names
		},
		legalEntities() {
			let options = [{
				id: 0,
				legalEntityId: '',
				name: "Select legal entity"
			}]
			let legalEntities = this.$store.state.activeSG.supplierLegalEntities
			let index = 1
			for (const le in legalEntities) {
				index = index + 1
				let tempItem = {
					id: index,
					legalEntityId: le,
					name: legalEntities[le].internalName ? legalEntities[le].internalName : legalEntities[le].name
				}
				options.push(tempItem)
			}
			return options
		}
	},
	'this.$store.state.extractionTemplates': {
		deep: true,
		handler() {
			this.formData = this.$store.state.extractionTemplates[0]
		}
	},
	methods: {
		addSummaryTableFields(data) {
			this.tableExtractionField = true
			this.selectedField = data.field

			let tableField = JSON.parse(JSON.stringify(data))

			if (!this.formData.summaryTableInfo) this.formData.summaryTableInfo = {}
			if (!this.formData.summaryTableInfo.tableColumns) this.formData.summaryTableInfo.tableColumns = {}

			this.formData.summaryTableInfo.tableColumns[tableField.field] = {
				x: parseFloat(tableField.x),
				y: parseFloat(tableField.y),
				width: parseFloat(tableField.width),
				height: parseFloat(tableField.height),
				defaultValue: tableField.defaultValue,
				bigDecimalFormatter: tableField.bigDecimalFormatter,

			}
			this.showSummaryFields = false
		},
		displayAddExtractionSummaryTableField() {
			this.manipulatedSummaryTableFields = JSON.parse(JSON.stringify(this.$store.state.tableSummaryFieldTypeData))

			if (this.formData.summaryTableInfo?.tableColumns) {
				for (let item in this.formData.summaryTableInfo.tableColumns) {
					const idx = this.manipulatedSummaryTableFields.findIndex(
						(itm) => itm.name === item
					)
					this.manipulatedSummaryTableFields.splice(idx, 1)
				}
				if (this.manipulatedSummaryTableFields.length === 0) {
					this.manipulatedSummaryTableFields = [{id: 0, name: "No items"}]
				}
			}
			this.showSummaryFields = true
		},
		updateOffsetSummary(offset) {
			this.formData.summaryTableInfo.offset = this.offsetOptions[offset].name
		},
		updatePresentInAllRowsSummary(field) {
			this.formData.summaryTableInfo.presentInAllRows = this.documentSummaryFieldOptions[field].name
		},
		clearTableFieldsSummary() {
			this.formData.summaryTableInfo = {
				tableHeaders: '',
				tableCutoff: '',
				presentInAllRows: this.$store.state.tableSummaryFieldTypeData[0].name,
				offset: "No offset",
				tableColumns: {}
			}
		},
		toggleExtractionField(field) {
			this[field] = !this[field]
		},
		toggleEditMode(field) {
			this.selectedField = field
			if (this.selectedField === field) {
				this.editMode = !this.editMode
			}
		},
		clearTableFields() {
			this.formData.extractionTableInfo = {
				tableHeaders: '',
				tableCutoff: '',
				maxDescriptionLines: 1,
				presentInAllRows: this.$store.state.tableFieldTypeData[0].name,
				tableColumns: {}
			}
		},
		toggleDisplayInfo(field) {
			this[field] = !this[field]
		},
		updateBigDecimalFormatterTable(separator, item) {
			if (item.bigDecimalFormatter === undefined) {
				item.bigDecimalFormatter = {
					decimalSeparator: 'No separator'
				}
			}
			item.bigDecimalFormatter.decimalSeparator = this.separatorOptions[separator].name
		},
		displayAddExtractionTableField() {
			this.manipulatedTableFields = JSON.parse(JSON.stringify(this.$store.state.tableFieldTypeData))
			if (this.formData.extractionTableInfo && this.formData.extractionTableInfo.tableColumns) {
				for (let item in this.formData.extractionTableInfo.tableColumns) {
					const idx = this.manipulatedTableFields.findIndex((itm) => itm.name === item)
					this.manipulatedTableFields.splice(idx, 1)
				}
				if (this.manipulatedTableFields.length === 0) {
					this.manipulatedTableFields = [{id: 0, name: "No items"}]
				}
			}
			this.showExtractionTable = true
		},
		addTableFields(data) {
			this.tableExtractionField = true
			this.selectedField = data.field

			let tableField = JSON.parse(JSON.stringify(data))

			if (!this.formData.extractionTableInfo) {
				this.formData.extractionTableInfo = {}
			}
			if (!this.formData.extractionTableInfo.tableColumns) {
				this.formData.extractionTableInfo.tableColumns = {}
			}
			this.formData.extractionTableInfo.tableColumns[tableField.field] = {
				x: parseFloat(tableField.x),
				y: parseFloat(tableField.y),
				width: parseFloat(tableField.width),
				height: parseFloat(tableField.height),
				defaultValue: tableField.defaultValue,
				bigDecimalFormatter: tableField.bigDecimalFormatter,
			}
			this.showExtractionTable = false
		},
		updatePresentInAllRows(field) {
			this.formData.extractionTableInfo.presentInAllRows = this.documentFieldOptions[field].name
		},
		setErrors(field, value) {
			this.errors[field] = value
		},
		updateAdditionalRules(offset, item, field) {
			item.additionalRules[field] = this.offsetOptions[offset].name
		},
		updateBigDecimalFormatter(separator, item, field) {
			item.bigDecimalFormatter[field] = this.separatorOptions[separator].name
		},
		updatePaperFormat(paperFormat) {
			this.formData.paperFormat = this.paperFormatOptions[paperFormat].name
		},
		updateMoveAddressLocation(data) {
			this.formData.moveAddressLocation = data
		},
		updateUseSystemDate(data){
			this.formData.useSystemDate = data
		},
		clearTempFile() {
			this.$store.state.tempFile = ''
		},
		async updateFileValue() {
			this.formData.pdfFile = this.$store.state.tempFile
		},
		deleteFileValue() {
			delete this.formData.pdfFile
		},
		setInitialTemplate() {
			if (this.$store.state.extractionTemplates.length) {
				this.formData = this.$store.state.extractionTemplates[0]
			}
		},
		setInitialExtractionTemplate() {
			if (this.$store.state.extractionTemplates.length) {
				this.formData = this.$store.state.extractionTemplates[0]
			}
		},
		changeCursorState(data) {
			this.cursorStateBool = data
		},
		changeDragState(data) {
			this.dragStateBool = data
		},
		updateTemplateName(data) {
			let selectedTemplate = this.templateNames.find(el => el.id === data)
			this.formData = this.$store.state.extractionTemplates.find(el => el.name === selectedTemplate.name)
		},
		updateLegalEntity(data) {
			this.clearErrors()
			this.formData.legalEntityId = this.legalEntities[data].legalEntityId
		},
		updateDummyFieldValue(data, item) {
			let found = this.formData.areas[item.field]
			found.field = this.manipulatedFields[data].name
			if (item.field !== "DUMMY") {
				this.formData.areas[item.field] = {
					x: item.x,
					y: item.y,
					width: item.width,
					height: item.height,

					navigationText: item.navigationText,
					defaultValue: item.defaultValue,
					dateFormatter: item.dateFormatter,
					bigDecimalFormatter: item.bigDecimalFormatter,
					additionalRules: item.additionalRules,
				}
				delete this.formData.areas.DUMMY
			}
		},
		addField(data) {
			this.selectedField = data.field;
			let newVal = JSON.parse(JSON.stringify(data));
			this.formData.areas[newVal.field] = {
				x: parseFloat(newVal.x),
				y: parseFloat(newVal.y),
				width: parseInt(newVal.width, 10),
				height: parseInt(newVal.height, 10),

				navigationText: newVal.navigationText,
				defaultValue: newVal.defaultValue,
				dateFormatter: newVal.dateFormatter,
				bigDecimalFormatter: newVal.bigDecimalFormatter,
				additionalRules: newVal.additionalRules
			}
			this.showExtractionFields = false
		},
		deleteField(data) {
			if (data === "Yes" && this.deleteItem === 'field') {
				if (this.currentDelItem.includes("TABLE")) {
					if (this.currentDelItem.includes("SUMMARY")) {
						delete this.formData.summaryTableInfo.tableColumns[this.currentDelItem]
					} else {
						delete this.formData.extractionTableInfo.tableColumns[this.currentDelItem]
					}
				} else delete this.formData.areas[this.currentDelItem]
				this.triggered = !this.triggered
			}
			if (data === "Yes" && this.deleteItem === 'template') {
				this.deleteExtractionTemplate()
			}
			this.showConfirm = false
		},
		showConfirmDialog(item) {
			this.currentDelItem = item
			this.showConfirm = true
		},
		displayAddExtractionField() {
			this.clearErrors()
			this.manipulatedFields = [...this.fieldTypeData]

			for (let item in this.formData.areas) {
				const idx = this.manipulatedFields.findIndex((itm) => itm.name === item)
				this.manipulatedFields.splice(idx, 1)
			}
			this.showExtractionFields = true
		},
		selectDraggable(field) {
			if (!this.isDragging && !this.editMode) {
				this.giveDraggableColor(field)
			}
		},
		giveDraggableColor(field) {
			this.selectedField = field
		},
		clamp(num, lower = 0, upper) {
			return num < lower ? lower : num > upper ? upper : num
		},
		clampInsideBoundary(x, y) {
			let found
			if (this.selectedField.includes("TABLE")) {
				if (this.selectedField.includes("SUMMARY")) {
					found = this.formData.summaryTableInfo.tableColumns[this.selectedField]
				} else found = this.formData.extractionTableInfo.tableColumns[this.selectedField]
			} else found = this.formData.areas[this.selectedField]

			const boundaryPos = this.$refs.pdfContainer.getBoundingClientRect()
			this.$store.state.qrBoundary.width = boundaryPos.width
			this.$store.state.qrBoundary.height = boundaryPos.height

			let heightBoundaryPercentage,
				widthBoundaryPercentage,
				maxWidth,
				maxHeight

			heightBoundaryPercentage = 100 - (this.$refs[this.selectedField][0].clientHeight / boundaryPos.height) * 100
			widthBoundaryPercentage = 100 - found.width
			maxWidth = boundaryPos.width - found.width
			maxHeight = boundaryPos.height - this.$refs[this.selectedField][0].clientHeight

			let cx = (this.clamp(x - boundaryPos.x, 0, maxWidth) / maxWidth) * 100
			let cy = ((maxHeight - this.clamp(y - boundaryPos.y, 0, maxHeight)) / maxHeight) * 100

			if (!this.cursorStateBool) {
				cx = cx > widthBoundaryPercentage ? widthBoundaryPercentage : cx
				cy = cy > heightBoundaryPercentage ? heightBoundaryPercentage : cy
				found.x = Math.round(cx)
				found.y = Math.round(cy)
			} else {
				found.width = cx - found.x
				found.height = cy - found.y
			}
		},
		findPositionInsideBound(e) {
			let x, y
			x = e.clientX - this.$refs[this.selectedField][0].clientWidth / 2
			y = e.clientY - this.$refs[this.selectedField][0].clientHeight / 2
			return this.clampInsideBoundary(x, y)
		},
		handleMouseDown(e) {
			const boundaryPos = this.$refs.pdfContainer.getBoundingClientRect()
			if (this.$refs[this.selectedField] ? e.target === this.$refs[this.selectedField][0] : false) {
				this.isDragging = true
				this.test.startX = null
				this.test.startY = null
			} else {
				this.test.startX = e.pageX - boundaryPos.left
				this.test.startY = e.pageY - boundaryPos.top
			}
		},
		handleMouseMove(e) {
			if (this.isDragging) this.findPositionInsideBound(e)
		},
		handleMouseUp(e) {
			this.isDragging = false
			if (this.test.startX) {
				const boundaryPos = this.$refs.pdfContainer.getBoundingClientRect()
				this.test.stopX = e.pageX - boundaryPos.left
				this.test.stopY = e.pageY - boundaryPos.top
				this.getSelectionCoords()
			}
		},
		getSelectionCoords() {
			const boundaryPos = this.$refs.pdfContainer.getBoundingClientRect()
			let c1x = (this.clamp(this.test.startX, 0, boundaryPos.width) / boundaryPos.width) * 100
			let c2x = (this.clamp(this.test.stopX, 0, boundaryPos.width) / boundaryPos.width) * 100
			let c1y = ((boundaryPos.height - this.clamp(this.test.startY - window.scrollY, 0, boundaryPos.height)) / boundaryPos.height) * 100
			let c2y = ((boundaryPos.height - this.clamp(this.test.stopY - window.scrollY, 0, boundaryPos.height)) / boundaryPos.height) * 100
			let width = c1x > c2x ? c1x - c2x : c2x - c1x
			let height = c1y > c2y ? c1y - c2y : c2y - c1y
			let x = c1x > c2x ? c2x : c1x
			let y = c1y > c2y ? c2y : c1y
			if (width !== 0 && height !== 0 && this.dragStateBool) {
				if (this.selectedField.includes("TABLE")) {
					if (this.selectedField.includes("SUMMARY")) {
						this.formData.summaryTableInfo.tableColumns[this.fieldTableTypeData[0].name] = {
							field: this.fieldTableTypeData[0].name,
							x: x,
							y: y,
							width: width,
							height: height,
							defaultValue: this.fieldTableTypeData[0].defaultValue,
							bigDecimalFormatter: this.fieldTypeData[0].bigDecimalFormatter,
						}
					} else {
						this.formData.extractionTableInfo.tableColumns[this.fieldTableTypeData[0].name] = {
							field: this.fieldTableTypeData[0].name,
							x: x,
							y: y,
							width: width,
							height: height,
							defaultValue: this.fieldTableTypeData[0].defaultValue,
							bigDecimalFormatter: this.fieldTypeData[0].bigDecimalFormatter,
						}
					}
				} else {
					this.formData.areas[this.fieldTypeData[0].name] = {
						field: this.fieldTypeData[0].name,
						x: x,
						y: y,
						width: width,
						height: height,

						moveAddressLocation: this.extractionDataType[0].moveAddressLocation,
						navigationText: this.extractionDataType[0].navigationText,
						defaultValue: this.extractionDataType[0].defaultValue,
						dateFormatter: this.extractionDataType[0].dateFormatter,
						bigDecimalFormatter: this.extractionDataType[0].bigDecimalFormatter,
						additionalRules: this.extractionDataType[0].additionalRules,
					}
				}
			}
			this.manipulatedFields = [...this.fieldTypeData]
			for (let item of this.formData.areas) {
				const idx = this.manipulatedFields.findIndex(
					(itm) => itm.name === item.field
				);
				this.manipulatedFields.splice(idx, 1)
			}
		},
		close() {
			this.displayInfo = true
			this.displayInfoTable = true
			this.formData = {
				name: "",
				legalEntityId: '',
				moveAddressLocation: false,
				useSystemDate: false,
				paperFormat: "A4",
				areas: {},
				pdfFile: '',
				extractionTableInfo: {
					tableHeaders: '',
					tableCutoff: '',
					maxDescriptionLines: 1,
					presentInAllRows: this.$store.state.tableFieldTypeData[0].name,
					tableColumns: {}
				},
				summaryTableInfo: {
					tableHeaders: '',
					tableCutoff: '',
					presentInAllRows: this.$store.state.tableSummaryFieldTypeData[0].name,
					offset: "No offset",
					tableColumns: {}
				}
			}
			this.templateErrors = false
			this.setErrors('tableGeneralFields', false)

			this.$store.state.displayAddExtractionTemplate = false
			this.$store.state.tempFile = ''
		},
		objectIsEmpty(obj) {
			return Object.keys(obj).length === 0
		},
		async create() {
			if (this.objectIsEmpty(this.formData.areas) || this.formData.name === '' || this.formData.legalEntityId === '') {
				this.displayInfo = true
				this.templateErrors = true
				return
			}

			let requestData = this.formData
			for (const area in this.formData.areas) {
				this.formData.areas[area].x = parseFloat(this.formData.areas[area].x)
				this.formData.areas[area].y = parseFloat(this.formData.areas[area].y)
				this.formData.areas[area].width = parseFloat(this.formData.areas[area].width)
				this.formData.areas[area].height = parseFloat(this.formData.areas[area].height)

				if (this.formData.areas[area].navigationText !== undefined && this.formData.areas[area].navigationText === '') {
					delete this.formData.areas[area].navigationText
				} else {
					if (this.formData.areas[area].navigationText !== undefined) {
						this.formData.areas[area].navigationText = this.formData.areas[area].navigationText.split(",");
					}
				}
				if (this.formData.areas[area].defaultValue !== undefined && this.formData.areas[area].defaultValue === '') {
					delete this.formData.areas[area].defaultValue
				}
				if (this.formData.areas[area].dateFormatter !== undefined && this.formData.areas[area].dateFormatter === '') {
					delete this.formData.areas[area].dateFormatter
				}

				if (this.formData.areas[area].bigDecimalFormatter !== undefined && this.formData.areas[area].bigDecimalFormatter.groupingSeparator === 'No separator'
					&& this.formData.areas[area].bigDecimalFormatter.decimalSeparator === 'No separator') {
					delete this.formData.areas[area].bigDecimalFormatter;
				}

				if (this.formData.areas[area].bigDecimalFormatter !== undefined) {

					if ((this.formData.areas[area].bigDecimalFormatter.groupingSeparator === 'No separator' && this.formData.areas[area].bigDecimalFormatter.decimalSeparator !== 'No separator')
						|| (this.formData.areas[area].bigDecimalFormatter.groupingSeparator === '' && this.formData.areas[area].bigDecimalFormatter.decimalSeparator !== 'No separator')
						|| (this.formData.areas[area].bigDecimalFormatter.groupingSeparator !== 'No separator' && this.formData.areas[area].bigDecimalFormatter.decimalSeparator === '')) {

						let errorText = "Big decimal formatter fields have to be either empty both  or filled in both. Please check " + area;
						this.$store.commit('setErrorAlertText', errorText)
						this.$store.commit('displayErrorAlert', true)
						return
					}
				}
				if (this.formData.areas[area].additionalRules !== undefined && this.formData.areas[area].additionalRules.offset !== undefined) {
					if (this.formData.areas[area].additionalRules.offset === 'No offset') {
						delete this.formData.areas[area].additionalRules
					} else {
						this.formData.areas[area].additionalRules.offset = parseInt(this.formData.areas[area].additionalRules.offset)
					}
				}
			}
			if (requestData.extractionTableInfo) {
				let tableFieldsLength = Object.keys(requestData.extractionTableInfo.tableColumns).length;
				if (tableFieldsLength > 0 &&
					(!requestData.extractionTableInfo.tableHeaders
						|| (requestData.extractionTableInfo.tableHeaders && requestData.extractionTableInfo.tableHeaders === '')
						|| !requestData.extractionTableInfo.tableCutoff
						|| (requestData.extractionTableInfo.tableCutoff && requestData.extractionTableInfo.tableCutoff === '')
						|| requestData.extractionTableInfo.maxDescriptionLines === '0'
						|| requestData.extractionTableInfo.maxDescriptionLines === ''
						|| requestData.extractionTableInfo.presentInAllRows === 'SELECT FIELD'
					)
				) {
					this.displayInfoTable = true
					this.setErrors('tableGeneralFields', true)
					return
				}
				if (tableFieldsLength === 0
					&& (requestData.extractionTableInfo.tableHeaders !== ''
						|| requestData.extractionTableInfo.tableCutoff !== ''
						|| requestData.extractionTableInfo.presentInAllRows !== this.$store.state.tableFieldTypeData[0].name
					)
				) {
					this.displayInfoTable = true

					this.setErrors('tableGeneralFields', true)
					return
				}

				if (tableFieldsLength === 0
					&& (requestData.extractionTableInfo.tableHeaders === '')
					&& (requestData.extractionTableInfo.tableCutoff === '')
				) {
					delete requestData.extractionTableInfo
				} else {
					for (let column in requestData.extractionTableInfo.tableColumns) {
						requestData.extractionTableInfo.tableColumns[column].height = parseFloat((requestData.extractionTableInfo.tableColumns[column].height));
						requestData.extractionTableInfo.tableColumns[column].width = parseFloat((requestData.extractionTableInfo.tableColumns[column].width));
						requestData.extractionTableInfo.tableColumns[column].x = parseFloat(requestData.extractionTableInfo.tableColumns[column].x);
						requestData.extractionTableInfo.tableColumns[column].y = parseFloat(requestData.extractionTableInfo.tableColumns[column].y);

						if (requestData.extractionTableInfo.tableColumns[column].bigDecimalFormatter.decimalSeparator === "No separator") {
							delete requestData.extractionTableInfo.tableColumns[column].bigDecimalFormatter
						}

						if (requestData.extractionTableInfo.tableHeaders.includes(',')) {
							requestData.extractionTableInfo.tableHeaders = requestData.extractionTableInfo.tableHeaders.split(',')
						} else {
							requestData.extractionTableInfo.tableHeaders = [requestData.extractionTableInfo.tableHeaders]
						}

						if (requestData.extractionTableInfo.tableCutoff.includes(',')) {
							requestData.extractionTableInfo.tableCutoff = requestData.extractionTableInfo.tableCutoff.split(',')
						} else {
							requestData.extractionTableInfo.tableCutoff = [requestData.extractionTableInfo.tableCutoff]
						}

						requestData.extractionTableInfo.maxDescriptionLines = parseInt(requestData.extractionTableInfo.maxDescriptionLines);
					}
				}
			}
			if (requestData.summaryTableInfo) {
				let tableFieldsLength = Object.keys(requestData.summaryTableInfo.tableColumns).length;

				if (tableFieldsLength === 0) {
					if (requestData.summaryTableInfo.tableHeaders === '' && requestData.summaryTableInfo.tableCutoff === '') {
						delete requestData.summaryTableInfo
					} else {
						this.displayInfoSummaryTable = true
						this.setErrors('summaryTableGeneralFields', true)
						return
					}
				} else {
					if (requestData.summaryTableInfo.tableHeaders === ''
						|| requestData.summaryTableInfo.tableCutoff === ''
						|| requestData.summaryTableInfo.maxDescriptionLines === '0'
						|| requestData.summaryTableInfo.maxDescriptionLines === ''
					) {
						this.displayInfoSummaryTable = true;
						this.setErrors('summaryTableGeneralFields', true)
						return
					} else {
						for (let column in requestData.summaryTableInfo.tableColumns) {

							requestData.summaryTableInfo.tableColumns[column].height = parseFloat((requestData.summaryTableInfo.tableColumns[column].height));
							requestData.summaryTableInfo.tableColumns[column].width = parseFloat((requestData.summaryTableInfo.tableColumns[column].width));
							requestData.summaryTableInfo.tableColumns[column].x = parseFloat(requestData.summaryTableInfo.tableColumns[column].x);
							requestData.summaryTableInfo.tableColumns[column].y = parseFloat(requestData.summaryTableInfo.tableColumns[column].y);

							if (requestData.summaryTableInfo.tableColumns[column].bigDecimalFormatter.decimalSeparator === "No separator") {
								delete requestData.summaryTableInfo.tableColumns[column].bigDecimalFormatter
							}
							if (typeof requestData.summaryTableInfo.tableHeaders === "string") {
								requestData.summaryTableInfo.tableHeaders = requestData.summaryTableInfo.tableHeaders.split(',')
							}
							if (typeof requestData.summaryTableInfo.tableCutoff === "string") {
								requestData.summaryTableInfo.tableCutoff = requestData.summaryTableInfo.tableCutoff.split(',')
							}

							requestData.summaryTableInfo.maxDescriptionLines = parseInt(requestData.summaryTableInfo.maxDescriptionLines)

							if (requestData.summaryTableInfo.offset === "SELECT FIELD") {
								delete requestData.summaryTableInfo.offset
							} else {
								requestData.summaryTableInfo.offset = parseInt(requestData.summaryTableInfo.offset)
							}
						}
					}
				}
			}
			this.$store.dispatch('createExtractionTemplate', {
				token: this.$root.token,
				requestData: requestData,
				supplierGroupId: this.$store.state.activeSG.supplierGroupId
			})
			this.close()
		},
		clearErrors() {
			this.templateErrors = false
		}
	},
};
</script>

<style scoped>
.cursor-resize {
	cursor: nesw-resize;
}

.no-pdf {
	text-align: center;
	margin-top: 40px;
}

.move-address-location {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 170px auto;
}

.one-line-grid {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 70% 30%;
}

.one-line-grid p:nth-child(2) {
	justify-self: end;
}

.button-container {
	display: grid;
	grid-template-columns: 65% 35%;
	grid-template-rows: 100%;
}

.button-container > div {
	justify-self: end;
}

.clear-table-info {
	display: grid;
	grid-template-columns:100%;
	grid-template-rows: 100%;
	justify-items: end;
}

.one-line-grid > div {
	justify-self: end;
}

.table-field-container {
	border-top: 1px solid grey;
	padding-top: 6px;
}

.edit-item.edit-mode {
	position: fixed;
	top: 15%;
	left: 20%;
	z-index: 600;
	min-width: 450px;
}

.edit-mode {
	position: relative;
}
</style>