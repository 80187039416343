<template>
	<div
		:class="{ 'brightness-95': this.$store.state.displayEditBranding }"
		class="box shadow-none col-span-6"
	>
		<div
			class="
        text-left
        lg:text-left
        p-5
        border-t border-gray-200
        dark:border-dark-5
      "
		>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-1/2 mr-12">
					<!--MainColor-->
					<div class="mb-3">
						<label for="mainColor" class="font-bold form-label"
						>Main Color:*</label
						>
						<div class="grid-cols-3 gap-2">
							<div class="input-group">
								<div
									id="mainColor"
									class="input-group-text"
									:style="{
                    'background-color':
                      supplierGroup.channelsConfig.docImage.configPortal
                        .mainColor,
                  }"
								></div>
								<input
									disabled
									type="text"
									class="form-control"
									:value="
                    supplierGroup.channelsConfig.docImage.configPortal.mainColor
                  "
								/>
							</div>
						</div>
					</div>
					<Field
						:disabled="true"
						type="text"
						:modelValue="supplierGroup.channelsConfig.docImage.configPortal.maxReportDays "
						title="Maximum Report Days:" id="viewMaximumReportDays"/>
					<Field
						:disabled="true" type="text"
						:modelValue="contactEmail"
						title="Contact email:" id="viewContactEmail"/>
					<div class="toggle-container mt-2 mb-2">
						<label for="hidePasswordReset" class="font-bold form-label mb-0 mr-2">
							Hide password reset:
						</label>

						<div id="hidePasswordReset">
							<ToggleSlide
								:disabled=true
								class="w-min"
								renewBool=true

								:bool="hidePasswordReset"
							/>
						</div>
					</div>
					<div class="toggle-container mt-2 mb-2">
						<label for="generalPost" class="font-bold form-label mb-0 mr-2">
							NymDrop:
						</label>

						<div id="generalPost">
							<ToggleSlide
								:disabled=true
								class="w-min"
								renewBool=true

								:bool="generalPost"
							/>
						</div>
					</div>
					<div class="toggle-container mt-2 mb-2">
						<label for="viewBilling" class="font-bold form-label mb-0 mr-2">
							Billing:
						</label>

						<div id="viewBilling">
							<ToggleSlide
								:disabled=true
								class="w-min"
								renewBool=true

								:bool="billing"
							/>
						</div>
					</div>
					<div class="toggle-container mt-2 mb-2">
						<label for="viewPeppol" class="font-bold form-label mb-0 mr-2">
							Peppol:
						</label>

						<div id="viewPeppol">
							<ToggleSlide
								:disabled=true
								class="w-min"
								renewBool=true

								:bool="peppol"
							/>
						</div>
					</div>

					<div class="mb-3">
						<p class="font-bold form-label mr-2">External portal links:</p>
						<div v-if="supplierGroup.channelsConfig.docImage.configPortal.externalPortalLinks">
							<div
								:key="item + index"
								v-for="(item, index) of Object.keys(supplierGroup.channelsConfig.docImage.configPortal
                        .externalPortalLinks)"
								class="flex flex-row input-group w-full mb-2"
							>
								<div class="input-group-text">
									{{ item }}
								</div>
								<input
									disabled type="text" class="form-control"
									:value="supplierGroup.channelsConfig.docImage.configPortal.externalPortalLinks[item].link"/>
								<input
									disabled type="text" class="form-control"
									:value="supplierGroup.channelsConfig.docImage.configPortal.externalPortalLinks[item].text"/>
							</div>
						</div>
						<p v-else>No external portal links</p>
					</div>
				</div>
				<div class="flex flex-col w-1/2">
					<!--Banner-->
					<div class="w-full mb-2">
						<label class="font-bold form-label">Banner:</label>
						<div class="input-group">
							<input
								disabled
								type="text"
								class="form-control w-full"
								style="width: 90%"
								:value="supplierGroup.channelsConfig.docImage.configPortal.banner"
							/>
							<div
								class="font-bold cursor-pointer input-group-text"
								@click="download(supplierGroup.channelsConfig.docImage.configPortal.banner)"
								style="cursor:pointer; padding: 7px 9px 2px 9px"
							>
								<span class="material-icons" style="font-size: 20px">file_download</span>
							</div>
						</div>
					</div>
					<img
						class="w-1/2 max-w-max"
						:src="supplierGroup.channelsConfig.docImage.configPortal.banner"
					/>
					<!--Logo-->
					<div class="w-full mb-2">
						<label class="font-bold form-label">Logo:</label>
						<div class="input-group">
							<input
								disabled
								type="text"
								class="form-control w-full"
								style="width: 90%"
								:value="supplierGroup.channelsConfig.docImage.configPortal.logo"
							/>
							<div
								class="font-bold cursor-pointer input-group-text"
								@click="download(supplierGroup.channelsConfig.docImage.configPortal.logo)"
								style="cursor:pointer; padding: 7px 9px 2px 9px"
							>
								<span class="material-icons" style="font-size: 20px">file_download</span>
							</div>
						</div>
					</div>
					<img
						class="w-1/4 mt-2 max-w-max"
						:src="supplierGroup.channelsConfig.docImage.configPortal.logo"
					/>
					<!--Favicon-->
					<Field
						:disabled="true" id="viewBrandingFavicon" type="text" title="Favicon:"
						:modelValue="supplierGroup.channelsConfig.docImage.configPortal.favicon"/>
					<img
						class="w-1/12"
						:src="supplierGroup.channelsConfig.docImage.configPortal.favicon"
					/>
					<label for="viewSearchableDocTypes" class="font-bold form-label">
						Searchable doc types:*
					</label>
					<DropdownSelectAll
						v-if="renderComponent"
						id="viewSearchableDocTypes"
						:data="docTypes"
						:selected=searchableDocTypes
						:disabled="true"

					/>
					<label for="viewDisplatableDocTypes" class="font-bold form-label">
						Displayable doc types:
					</label>
					<DropdownSelectAll
						v-if="renderComponent"
						id="viewDisplatableDocTypes"
						:data="docTypes"
						:selected=displayableDocTypes
						:disabled="true"

					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Field from "../Field.vue"
import DropdownSelectAll from "../DropdownSelectAll.vue";
import ToggleSlide from "../ToggleSlide.vue";

export default {
	name: "ViewBranding",
	components: {
		Field,
		DropdownSelectAll,
		ToggleSlide
	},
	watch: {
		"$store.state.activeSG": {
			handler(data) {
				this.supplierGroup = data;
				this.forceRerender();
			},
			deep: true,
		},
	},

	data() {
		return {
			supplierGroup: this.$store.state.activeSG,
			renderComponent: true,
			docTypes: this.$store.state.docTypes
		};
	},

	computed: {
		hidePasswordReset() {

			if (this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
				&& this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.hidePasswordReset
			) {
				return this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.hidePasswordReset
			}
			return false
		},
		generalPost() {
			if (this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
				&& this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost
			) {
				return this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost
			}
			return false
		},
		billing() {
			if (this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
				&& this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.billing
			) {
				return this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.billing
			}
			return false
		},
		peppol() {
			if (this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
				&& this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.peppol
			) {
				return this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.peppol
			}
			return false
		},
		searchableDocTypes() {
			if (this.$store.state.activeSG.channelsConfig
				&& this.$store.state.activeSG.channelsConfig.docImage
				&& this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
				&& this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.searchableDocTypes) {
				return this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.searchableDocTypes
			}
			return []
		},
		displayableDocTypes() {
			if (this.$store.state.activeSG.channelsConfig
				&& this.$store.state.activeSG.channelsConfig.docImage
				&& this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig
				&& this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.displayableDocTypes) {
				return this.$store.state.activeSG.channelsConfig.docImage.configPortal.additionalPortalConfig.displayableDocTypes
			}
			return []
		},

		contactEmail() {
			if (this.supplierGroup.channelsConfig.docImage.configPortal
				&& this.supplierGroup.channelsConfig.docImage.configPortal.additionalPortalConfig
				&& this.supplierGroup.channelsConfig.docImage.configPortal.additionalPortalConfig.contactEmail) {
				return this.supplierGroup.channelsConfig.docImage.configPortal.additionalPortalConfig.contactEmail
			}
			return ''
		}

	},
	methods: {
		forceRerender() {
			// Removing my-component from the DOM
			this.renderComponent = false;

			this.$nextTick(() => {
				// Adding the component back in
				this.renderComponent = true;
			});
		},
		download(url) {
			window.open(url)
		},
	}
};
</script>

<style scoped>
.toggle-container {
	display: grid;
	grid-template-columns: 160px auto;
	grid-template-rows: 100%;
	grid-auto-flow: row;
}
</style>
