<template>
  <pop-up-window
    :show="this.$store.state.displayAddLeBankAccount"
    :showClose="false"
    @close="close"
    class="doc-statuses-popup"
    fixed
  >
    <template #header>Add Bank Account</template>
    <template #content>
      <div class="flex flex-row">
        <div class="flex flex-col mr-12 w-1/2">
          <Field
            id="addBankAccountIndex"
            :hasError="indexInvalid"
            title="Index: *"
            @input="validate"
            v-model="index"
            type="number"
          />
          <span v-if="indexInvalid" class="text-primary-3">Index is required</span>
          <Field
            id="addBankAccountBankname"
            :hasError="banknameInvalid"
            title="Bankname: *"
            @input="validate"
            v-model="bankname"
            type="text"
          />
          <span v-if="banknameInvalid" class="text-primary-3">Bank is required</span>
        </div>
        <div class="flex flex-col w-1/2">
          <Field
            id="addBankAccountIban"
            :hasError="ibanInvalid"
            title="IBAN: *"
            @input="validate"
            v-model="iban"
            placeholder="NL20INGB0001234567"
            type="text"
          />
          <span v-if="ibanInvalid" class="text-primary-3">IBAN isn't valid</span>
          <Field
            id="addBankAccountBic"
            :hasError="bicInvalid"
            title="BIC: *"
            placeholder="OPSKATWW"
            @input="validate"
            v-model="bic"
            type="text"
          />
          <span v-if="bicInvalid" class="text-primary-3">BIC is required</span>
        </div>
      </div>
      <div class="grid grid-rows-1 justify-items-end">
        <div>
          <button class="btn shadow-md mr-2" @click="add">Add</button>
          <button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
        </div>
      </div>
    </template>
  </pop-up-window>
</template>

<script>
import PopUpWindow from "../PopUp.vue";
import Field from "../Field.vue";
export default {
  name: "AddEdi",
  components: {
    PopUpWindow,
    Field,
  },
  data() {
    return {
      indexInvalid: false,
      banknameInvalid: false,
      ibanInvalid: false,
      bicInvalid: false,
        index: null,
        bankname: "",
        iban: "",
        bic: "",
    };
  },
  emits:['add-bankAccount'],
  methods: {
    close() {
      this.initData()
      this.$store.state.displayAddLeBankAccount = false;
    },
    initData(){
      this.indexInvalid = false;
      this.banknameInvalid = false;
      this.ibanInvalid = false;
      this.bicInvalid = false;
      this.index = null
      this.bankname = ""
      this.iban = ""
      this.bic = ""
    },
    add() {
      let hasErrors = this.validate();
      if (!hasErrors) {
        let payload = {
          indexNumber: this.index,
          bankName: this.bankname,
          iban: this.iban,
          bic: this.bic
        }
        this.$emit("add-bankAccount", payload);
        this.close();
      }
    },
    validate() {
      let hasErrors = false;
      this.indexInvalid = false;
      this.banknameInvalid = false;
      this.ibanInvalid = false;
      this.bicInvalid = false;
      if (!this.index) {
        hasErrors = true;
        this.indexInvalid = true;
      }
      if (this.bankname === "") {
        hasErrors = true;
        this.banknameInvalid = true;
      }
      if (this.iban === "") {
        hasErrors = true;
        this.ibanInvalid = true;
      }
      if (!this.$store.state.ibanRegex.test(this.iban)) {
        hasErrors = true;
        this.ibanInvalid = true;
      }
      if (this.bic === "") {
        hasErrors = true;
        this.bicInvalid = true;
      }
      return hasErrors;
    },
  },
};
</script>

<style scoped>
</style>
