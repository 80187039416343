<template>
	<div
		:class="{ 'brightness-95': this.$store.state.displayEditInsertsTemplate }"
		class="box shadow-none col-span-6 min-height-600"
	>
		<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
			<div class="button-container">
				<button
					class="btn shadow-md bg-theme-1 text-white mr-2"
					@click="addNewOverlayTemplate">
					Add new template
				</button>
			</div>
			<div class="flex flex-row flex-nowrap" v-if="insertTemplatesOfSG.templates">
				<div v-if="insertTemplatesOfSG.templates.inserts" class="flex flex-col w-full">
					<Accordion
						:key="item + index"
						:add-is-open="this.$store.state.displayAddInsertsTemplate || this.$store.state.displayEditInsertsTemplate"
						v-for="(item, index) in insertTemplatesOfSG.templates.inserts"
						class="mb-5 border border-gray-300 p-1 box"
					>
						<template v-slot:title>
							<span class="font-semibold text-xl">{{ item.name }}</span>
						</template>
						<template v-slot:content>
							<div class="p-3 grid grid-cols-2">
								<div class="mr-2">
									<div>
										<div class="mb-2">
											<label for="viewResendDocType" class="font-bold">DocType:*</label>
										</div>
										<DropdownSelectAll
											v-if="renderComponent"
											id="viewResendDocType"
											:data="docTypes"
											:selected=item.docTypes
											:disabled=true
										/>
									</div>
								</div>
								<div class="ml-2">
									<div class="flex">
										<p class="font-bold form-label">Insert file(s):*</p>
										<span v-if="copyDescription" class="ml-2 font-bold text-theme-1">Copied</span>
									</div>

									<div
										:key="item + item[itm] + index"
										v-for="(itm, index) in Object.keys(item.location)"
										class="flex flex-row mb-1"
									>
										<div class="flex flex-col w-2/3">
											<div class="flex flex-row input-group w-full">
												<div :id="'insertView' + itm" class="input-group-text">
													{{ itm }}
												</div>
												<input
													disabled
													type="text"
													class="form-control"
													:value="item.location[itm].fileName"
												/>
												<div class="input-group-text" @click="download(item.location[itm].url)" style="cursor: pointer; padding-bottom: 3px">
													<span class="material-icons" style="font-size: 20px">file_download</span>
												</div>
											</div>
										</div>
										<div class="flex flex-col w-1/3">
											<div class="input-group mb-1 w-full">
												<input
													disabled
													type="text"
													class="form-control"
													:value="item.location[itm].description"
												/>
												<div
													@click="copy(item.location[itm].description)"
													class="font-bold cursor-pointer input-group-text"
												>
													<font-awesome-icon icon="copy"/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div v-if="!(this.$store.state.displayAddInsertsTemplate || this.$store.state.displayEditInsertsTemplate)">
									<toggle-options-insert-template
										:incoming-archive-config="item.archivedDocumentOptions"
										:incoming-add-to-print="item.addToPrint"
										:incoming-merge="item.archivedDocumentOptions === undefined ? false : item.archivedDocumentOptions.merge"
										:disabled="true"
									></toggle-options-insert-template>
								</div>
							</div>
						</template>
					</Accordion>
				</div>
				<p v-else>No insert templates yet</p>
			</div>
			<p v-else>No insert templates yet</p>
		</div>
	</div>
</template>
<script>
import Accordion from "../../Accordion.vue";
import DropdownSelectAll from "../../DropdownSelectAll.vue";
import ToggleOptionsInsertTemplate from "@/components/templates/inserts/toggleOptionsInsertTemplate.vue";
import languages from "@/langCodes";

export default {
	name: "ViewInserts",
	components: {
		ToggleOptionsInsertTemplate,
		Accordion,
		DropdownSelectAll
	},
	props: {
		isFor: String,
	},
	watch: {
		"$store.state.activeSG": {
			handler(data) {
				if (this.isFor === "supplierGroup") {
					this.insertTemplatesOfSG = data;
				}
				this.forceRerender()
			},
			deep: true,
		},
		"$store.state.activeLE": {
			handler(data) {
				if (this.isFor === "legalEntity") {
					this.insertTemplatesOfSG = data;
				}
				this.forceRerender();
			},
			deep: true,
		},
	},
	data() {
		return {
			insertTemplatesOfSG: [],
			copyDescription: false,
			renderComponent: true,
			docTypes: this.$store.state.docTypes,

			formData: {
				inserts: [],
			},
		}
	},
	mounted() {
		this.initData()
	},
	methods: {
		langCodes() {
			return languages
		},
		download(pdfPath){
			window.open(pdfPath)
		},
		addNewOverlayTemplate() {
			this.$store.state.displayAddInsertsTemplate = true
		},
		addTemplate(data) {
			this.initData()
			if (!this.formData.inserts) {
				this.formData.inserts = []
			}

			this.formData.inserts.push(data)
		},

		forceRerender() {
			// Removing my-component from the DOM
			this.renderComponent = false

			this.$nextTick(() => {
				// Adding the component back in
				this.renderComponent = true
			})
		},
		copy(data) {
			navigator.clipboard.writeText(data)
			this.copyDescription = true
			setTimeout(() => {
				this.copyDescription = false
			}, 750);
		},
		archivedDocumentOptions(item) {
			return !!item.archivedDocumentOptions;
		},
		initData() {
			if (this.isFor === "supplierGroup") {
				this.insertTemplatesOfSG = this.$store.state.activeSG;
			}
			if (this.isFor === "legalEntity") {
				this.insertTemplatesOfSG = this.$store.state.activeLE;
			}
		},
	},
	computed: {}
}
</script>

<style scoped>
.button-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	justify-items: end;
	margin-bottom: 10px;
}
</style>