<template>
	<div
		:key="activeItem"
		:class="{'brightness-95': this.$store.state.displayEditQRTemplate, 'min-height-600' : calculateMinHeight, 'min-height-60vw' : !calculateMinHeight}"
		class="box shadow-none col-span-6"
	>
		<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
			<div class="button-container">
				<button class="btn shadow-md bg-theme-1 text-white mr-2" @click="addNewTemplate">
					Add new template
				</button>
			</div>
			<div v-if="showTemplates">
				<label for="viewQrTemplateName" class="font-bold form-label">
					Select QR template:
				</label>
				<Dropdown
					id="viewQrTemplateName"
					@update-value="updateSelectedName"
					:class="{ 'border border-primary-3': selectedOverlayInvalid }"
					class="w-1/2 pr-6"
					:data="qrTemplatesNames"
					:selected="initialSelectedTemplateName"
				/>
				<br>
				<div class="flex flex-row flex-nowrap">
					<div class="flex flex-col w-1/2 mr-12">
						<!--QR Overlay-->
						<div class="mb-3">
							<label for="qrTemplateOverlayMode" class="font-bold form-label">
								QR overlay mode:*
							</label>
							<Dropdown
								id="viewQrTemplateOverlayMode"
								:data="qrOverlayData1"
								:key="activeTemplate.overlayMode"
								:selected="activeTemplate.overlayMode"
								:disabled="true"
							/>
						</div>

						<div class="mb-3" v-if="activeTemplate.offset">
							<label for="qrTemplateOffset" class="font-bold form-label">
								QR offset:*
							</label>
							<Dropdown
								id="viewQrTemplateOffset"
								:data="qrOffsetData"
								:key="activeTemplate.offset"
								:selected="activeTemplate.offset"
								:disabled="true"
							/>
						</div>

						<label for="viewQrTemplateDocTypes" class="font-bold form-label">
							QR doc types:*
						</label>

						<DropdownSelectAll
							id="viewQrTemplateDocTypes"
							:data="qrDocTypes"
							:key="activeTemplate.docTypes"
							:selected="activeTemplate.docTypes"
							:disabled="true"
							:noSelectAll="true"
						/>

						<label for="viewQrTemplateReason" class="font-bold form-label">
							QR template reason:*
						</label>

						<Dropdown
							id="viewQrTemplateReason"
							:disabled="true"
							class="w-1/2"
							:data="qrTemplatesReason"
							:selected="activeTemplate.reason"
						/>

						<!--Width X/Y Coords-->
						<div>
							<div class="label-with-mark">
								<div class="color-mark bg-red"></div>
								<h2 class="label-style mt-2">QR position*:</h2>
							</div>
							<div class="flex flex-row  ml-6 mt-2">
								<Field
									id="qrTemplateWidth"
									title="Width:"
									type="text"
									:modelValue="activeTemplate.width"
									:disabled="true"
								/>
								<Field
									id="qrTemplateXCoord"
									title="X-coordinate:"
									type="text"
									:modelValue="activeTemplate.xCoordinate"
									:disabled="true"
								/>
								<Field
									id="qrTemplateYCoord"
									title="Y-coordinate:"
									type="text"
									:modelValue="activeTemplate.yCoordinate"
									:disabled="true"
								/>
							</div>
						</div>
						<div v-show="activeTemplate.documentParameter">
							<div class="mb-3 flex">
								<div class="label-with-mark">
									<div class="color-mark bg-blue"></div>
									<h2 class="label-style mt-2 mr-2">Add link:</h2>
								</div>

								<ToggleSlide
									class="w-min"
									id="viewAddLink"
									:key="addLink"
									:bool="addLink"
									:disabled="true"
								/>
							</div>
							<div v-if="addLink" class="ml-6">
								<div class="grid grid-cols-3">
									<Field
										id="viewLinkTemplateXCoord"
										type="number"
										title="X-coordinate: *"
										v-model="activeTemplate.documentParameter.xCoordinate"
										:numberIntervals="0.5"
										:disabled=true
									/>
									<Field
										id="viewLinkTemplateYCoord"
										type="number"
										title="Y-coordinate: *"
										v-model="activeTemplate.documentParameter.yCoordinate"
										:numberIntervals="0.5"
										:disabled=true
									/>
									<Field
										id="viewLinkTemplateFontSize"
										type="number"
										title="Font size*: "
										v-model="activeTemplate.documentParameter.fontSize"
										:numberIntervals="1"
										:disabled=true
									/>
								</div>

								<div v-if="addLink">
									<Dropdown
										id="viewFieldLink"
										class=""
										:data="linkField"
										:selected="activeTemplate.documentParameter.field"
										:disabled=true
									/>
									<div class="mt-2">
										<Field
											:modelValue="activeTemplate.documentParameter.font.fileName"
											id="viewFontFile"
											title="Uploaded font file*:"
											:disabled="true"
										/>
									</div>
								</div>
							</div>
						</div>
						<div v-if="activeTemplate.background">
							<div class="label-with-mark">
								<div class="color-mark bg-green"></div>
								<h2 class="label-style mt-2 mr-2">QR images:</h2>
							</div>
							<div class="flex flex-row ml-6 mt-2">
								<div class="flex flex-col">
									<Field
										id="qrTemplateImagesWidth"
										type="number"
										title="Width: *"
										v-model.number="activeTemplate.background.width"
										:disabled=true
									/>
								</div>
								<Field
									id="qrTemplateImagesXCoord"
									type="number"
									title="X-coordinate: *"
									v-model="activeTemplate.background.xCoordinate"
									:disabled=true
								/>
								<Field
									id="qrTemplateImagesYCoord"
									type="number"
									title="Y-coordinate: *"
									v-model="activeTemplate.background.yCoordinate"
									:disabled=true
								/>
							</div>
							<div class="ml-6 mr-2">
								<div
									:key="index + item"
									v-for="(item, index) in Object.keys(activeTemplate.background.location)"
									class="flex flex-row"
								>
									<div class="input-group mb-1 w-full">
										<div class="input-group-text">
											{{ item }}
										</div>
										<input
											type="text"
											class="form-control"
											v-model="activeTemplate.background.location[item].fileName"
											disabled=true
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="flex flex-col w-1/2">
						<div ref="boundary" class="full-size mx-auto relative border-4">
							<div
								ref="draggable"
								class="makeSquare pointer-events-none cursor-move absolute bg-primary-3 z-30"
								:style="{ bottom: activeTemplate.yCoordinate + '%',
                                  left: activeTemplate.xCoordinate + '%',
                                  width: activeTemplate.width + '%' }"
							>
							</div>
							<div
								v-if="addLink"
								ref="draggableLink"
								class="cursor-move absolute bg-blue z-30 grid-div"
								:style="{bottom: activeTemplate.documentParameter.yCoordinate + '%',
								left: activeTemplate.documentParameter.xCoordinate + '%',
								width: linkWidth + '%',
								height: linkHeight + '%'}">
							</div>
							<div
								v-if=activeTemplate.background
								ref="draggable"
								class="qRImage pointer-events-none cursor-move absolute green z-10"
								:style="{ bottom: activeTemplate.background.yCoordinate + '%',
								left: activeTemplate.background.xCoordinate + '%',
								width: activeTemplate.background.width + '%',
								height: activeTemplate.background.height + '%',
								'background-image': 'url(' + qrBGImgUrl + ')'}">
							</div>

							<div ref="pdfContainer" v-if="!activeTemplate.file.endsWith('/proxy/')">
								<VuePdf
									class="w-full"
									:src="activeTemplate.file"
									:page="1"
								/>
								<button class="btn py-0 shadow-md" style="z-index: 50; position: absolute; right: 6px; top: 5px; padding: 0; background-color: #e2e8f0 " @click="download">
									<span class="material-icons" style="font-size: 20px">file_download</span>
								</button>
							</div>
						</div>
					</div>
				</div>
				<p v-if="!showTemplates">No QR templates yet</p>
			</div>
			<p v-if="!showTemplates">No QR templates yet</p>
		</div>
		<AddQrTemplate :isFor="isFor"></AddQrTemplate>
	</div>
</template>
<script>
import Field from "../../Field.vue"
import Dropdown from "../../Dropdown.vue"
import DropdownSelectAll from "../../DropdownSelectAll.vue"
import AddQrTemplate from "./AddQrTemplate.vue"
import ToggleSlide from "../../ToggleSlide.vue"
import {VuePdf} from "vue3-pdfjs"

export default {
	name: "ViewQrTemplate",
	components: {VuePdf, AddQrTemplate, Field, Dropdown, DropdownSelectAll, ToggleSlide},
	props: {
		isFor: String,
		qrOverlayData: Array,
	},
	data() {
		return {
			displayQR: false,
			qrTemplatesReason: [...this.$store.state.templateReason, {id: 4, name: "No template reason"}],
			qrDocTypes: this.$store.state.docTypes,
			qrOffsetData: this.$store.state.offsetData,
			qrOverlayData1: this.$store.state.overlayData,
			linkField: this.$store.state.linkField,
			isLegalEntity: this.isFor === "legalEntity",
			supplierGroup: this.$store.state.activeSG,
			activeItem: {},
			isDragging: false,
			initialSelectedTemplateName: '',
			activeTemplate: {},
			addLink: false,
			linkWidth: 8,
			qrBGImgUrl: '',
		}
	},
	mounted() {
		this.initData()
	},
	computed: {
		linkHeight() {
			//  1px = 2.54 cm / 96 = 0.0264cm
			//   the height of linkLine this.activeTemplate.documentParameter.font * 0.0264cm
			//the height of linkLine relatively to page height  ((this.activeTemplate.documentParameter.fontSize * 0.0264cm)/29.7)*100

			return ((this.activeTemplate.documentParameter.fontSize * 0.0264) / 29.7) * 100
		},
		calculateMinHeight() {
			return !this.$store.state.displayAddQrTemplate
		},
		qrTemplates() {
			let allTemplates = []
			if (this.activeItem.templates && this.activeItem.templates.qrTemplates) {
				allTemplates = this.activeItem.templates.qrTemplates
				allTemplates.forEach(template => {
					if (template.docTypes === undefined) {
						template.docTypes = []
					}
					if (template.reason === undefined) {
						template.reason = 'No template reason'
					}
				})
				return allTemplates
			}
			if (this.activeItem.templates && this.activeItem.templates.qr) {
				allTemplates = [this.activeItem.templates.qr]
				allTemplates.forEach(template => {
					if (template.docTypes === undefined) {
						template.docTypes = []
					}
					if (template.reason === undefined) {
						template.reason = 'No template reason'
					}
				})
				return allTemplates
			}

			return []
		},
		showTemplates() {
			return this.qrTemplates.length !== 0
		},
		qrTemplatesNames() {
			let templateNames = []
			if (this.activeItem.templates && this.activeItem.templates.qrTemplates) {
				this.activeItem.templates.qrTemplates.forEach((template, index) => {
					templateNames.push(
						{id: index, name: template.name}
					)
				})
				return templateNames
			}
			if (this.activeItem.templates && this.activeItem.templates.qr) {
				templateNames.push(
					{id: 0, name: this.activeItem.templates.qr.name}
				)
				return templateNames
			}
			return [{id: 0, name: "no templates yet"}]
		},
	},
	watch: {
		"$store.state.activeSG": {
			handler(data) {
				if (this.isFor === 'supplierGroup') {
					this.activeItem = data
					this.initData()
				}
			},
			deep: true,
		},
		"$store.state.activeLE": {
			handler(data) {
				if (this.isFor === "legalEntity") {
					this.activeItem = data
					this.initData()
				}
			},
			deep: true,
		}
	},
	methods: {
		addNewTemplate() {
			this.$store.state.displayAddQrTemplate = true
		},
		download(){
			window.open(this.activeTemplate.file)
		},
		updateSelectedName(templateId) {
			let selectedTemplateName = ''
			if (typeof templateId  === "object"){
				templateId = templateId[0]
			}
			this.qrTemplatesNames.forEach(item => {
				if (item.id.toString() === templateId.toString()) {
					selectedTemplateName = item.name
				}
			})
			if (selectedTemplateName === '') {
				selectedTemplateName = this.qrTemplatesNames[0].name
			}
			this.$store.commit("setSelectedQrTemplateName", selectedTemplateName)

			let foundTemplate = this.qrTemplates.find(template => (template.name === selectedTemplateName))
			this.activeTemplate = JSON.parse(JSON.stringify(foundTemplate))

			if (this.activeTemplate.background) {
				this.qrBGImgUrl = Object.values(this.activeTemplate.background.location)[0].url

			}
			this.initialSelectedTemplateName = selectedTemplateName
			this.addLink = !!this.activeTemplate.documentParameter
		},
		setActiveTemplate(template) {
			this.activeTemplate = template
			this.addLink = this.activeTemplate.documentParameter !== undefined

			if (this.activeTemplate.reason === '' || this.activeTemplate.reason === undefined) {
				this.activeTemplate.reason = "No template reason"
			}
			if (this.activeTemplate.background) {
				this.qrBGImgUrl = Object.values(this.activeTemplate.background.location)[0].url
			}
		},
		setSelectedTemplateName(name) {
			this.initialSelectedTemplateName = name
		},
		initData() {
			if (this.isFor === "supplierGroup") {
				this.activeItem = this.$store.state.activeSG
			}
			if (this.isFor === "legalEntity") {
				this.activeItem = this.$store.state.activeLE
			}
			if (this.qrTemplates.length) {
				if (this.activeItem.templates && this.activeItem.templates.qrTemplates) {

					this.setSelectedTemplateName(this.qrTemplates[0].name)
					this.setActiveTemplate(this.activeItem.templates.qrTemplates[0])
				}
				if (this.activeItem.templates && this.activeItem.templates.qr) {
					this.setSelectedTemplateName(this.qrTemplates[0].name)
					this.setActiveTemplate(this.activeItem.templates.qr)
				}
			}
			this.addLink = !!this.activeTemplate.documentParameter
		},
		clamp(num, lower = 0, upper) {
			return num < lower ? lower : num > upper ? upper : num
		}
	}
}
</script>

<style scoped>
.makeSquare:after {
	content: "";
	display: block;
	padding-bottom: 100%;
}

.green {
	opacity: 0.7;
}

.min-height-60vw {
	min-height: 60vw;
}

.button-container {
	display: grid;
	justify-items: end;
	width: 100%;
}

.label-style {
	font-weight: 700;
}

.color-mark {
	width: 30px;
}

.label-with-mark {
	display: grid;
	grid-template-columns: 20px auto;
	grid-template-rows: 100%;
	grid-auto-flow: row;
}

.label-with-mark > div {
	width: 15px;
	height: 15px;
	align-self: center;
}

.bg-red {
	background-color: red;
}

.bg-blue {
	background-color: #a76edb;
}

.bg-green {
	background-color: rgb(145, 199, 20);
}

.grid-div {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	grid-auto-flow: row;
	justify-self: start;
	align-self: start;
}

.check-link-text > span, .check-link-text > .check-link-input {
	grid-column: 1/2;
	grid-row: 1/2;
}

.check-link-text > .check-link-input {
	z-index: 100;
}

.check-link-text > span {
	opacity: 0;
	z-index: 2;
	justify-self: start;
}

.qRImage {
	background-repeat: no-repeat;
	background-position: top left;
	background-size: contain;
	background-color: #91c714;
}

.full-size {
	width: 100%;
	height: auto;
}
</style>