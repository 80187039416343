<template>
	<pop-up-window
		:show="this.$store.state.displayAddResendTemplate"
		:showClose="false"
		:positionFixed="true"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Add resend template</template>
		<template #content>
			<div class="form-container">
				<div>
					<div>
						<Field
							title="Resend template name:*"
							v-model="formData.name"
							type="text"
							@click="setError('name', false)"
						/>
						<div>
                          <span v-if="errors.name" class="text-primary-3">
                               Please, enter the template name
                          </span>
						</div>
					</div>
					<div>
						<Field
							title="Resend template version:"
							v-model="formData.version"
							type="text"
						/>
					</div>
				</div>
				<div>
					<div>
						<div class="mb-05">
							<label for="addResendDocType">DocType:*</label>
						</div>
						<DropdownSelectAll
							id="addResendDocType"
							@update-value="updateDocTypes"
							:data="docTypes"
						/>
						<div>
							<span v-if="errors.docTypes" class="text-primary-3">
								Please, select at least one doc type
							</span>
						</div>
					</div>
					<div>
						<label for="resendTemplateOverlayMode" class="font-bold form-label">
							Overlay mode:*
						</label>
						<Dropdown
							id="resendTemplateOverlayMode"
							@update-value="updateSelectedOverlay"
							:data="overlayData"
							:selected="formData.overlayMode"
						/>
					</div>
					<div>
						<div class="flex mb-1 mt-2">
							<p class="font-bold form-label my-0">PDF file:*</p>
							<button
								@click="this.showDialogAddFile = true"
								class="btn bg-theme-1 py-0 text-white shadow-md ml-2"
							>
								Add
							</button>
						</div>
						<div>
							<span v-if="errors.location" class="text-primary-3">
								Please, select at least one PDF file
							</span>
						</div>
					</div>
					<div
						:key="index + item"
						v-for="(item, index) in formData.location"
						class="flex flex-col"
					>
						<div class="flex flex-col">
							<FileUpload
								:isLegalEntity="isLegalEntity"
								:class=index
								@update-value="changeFileLocation($event, item, index, 'location')"
								@delete-value="deleteFile($event, item, index, 'location')"
								accept=".pdf"
								:prefix="true"
								:prefixData="index"
								:delSuffix="true"
								:data="item.fileName"
								:id="  'templatesEdit' + item.fileName"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md margin-right" @click="create">Create</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>

	<add-file-to-template
		title-dialog="Add resend template PDF"
		:isLegalEntity="isLegalEntity"
		@add-file="addFile($event, 'location')"
		@cancel-add-file="showDialogAddFile = false"
		:langCode="langCodes"
		:show-dialog="showDialogAddFile"
	/>
</template>

<script>
import PopUpWindow from "../../PopUp.vue";
import langCodes from "../../../langCodes";
import Field from "../../Field.vue";
import DropdownSelectAll from "../../DropdownSelectAll.vue";
import Dropdown from "../../Dropdown.vue";
import FileUpload from "../../FileUpload.vue";
import AddFileToTemplate from "@/components/templates/AddFileToTemplate.vue";

export default {
	name: "AddResendTemplate",
	components: {
		AddFileToTemplate,
		PopUpWindow,
		Field,
		DropdownSelectAll,
		Dropdown,
		FileUpload
	},
	props: {
		isFor: String,
	},
	data() {
		return {
			langCodes: langCodes,
			isLegalEntity: this.isFor === "legalEntity",
			docTypes: this.$store.state.docTypes,
			overlayData: this.$store.state.overlayMode,
			formData: {
				name: '',
				version: '',
				docTypes: [],
				location: {},
				overlayMode: 'FIRST_PAGE_ONLY'
			},
			showDialogAddFile: false,

			activeItem: {},
			errors: {
				name: false,
				version: false,
				docTypes: false,
				location: false,
			}
		}
	},
	mounted() {
		this.initData();
	},
	methods: {
		deleteFile(event, item, index, field) {
			delete this.formData[field][index];
		},
		changeFileLocation(event, item, index, field) {
			setTimeout(() => {
				this.setError('location', false);

				this.formData[field][index].url = this.$store.state.uploadedFileUrl;
			}, 500)
			this.formData[field][index].fileName = event.files[0].name;
		},
		addFile(data, field) {
			this.setError(field, false);
			this.formData[field] = {
				...this.formData[field],
				...data,
			};
			this.showDialogAddFile = false
		},

		updateSelectedOverlay(data) {
			if (this.overlayData[data]) {
				this.formData.overlayMode = this.overlayData[data].name;
			}
		},
		updateDocTypes(docTypes) {
			this.setError('docTypes', false);

			this.errors.docTypes = false;

			let foundArr = [];
			for (let index of docTypes) {
				let found = this.docTypes[index].name;
				foundArr.push(found);
			}
			this.formData.docTypes = foundArr;
		},
		setError(field, value) {
			this.errors[field] = value;
		},
		initData() {
			if (this.isFor === "supplierGroup") {
				this.activeItem = this.$store.state.activeSG;
			}
			if (this.isFor === "legalEntity") {
				this.activeItem = this.$store.state.activeLE;
			}

			this.formData = {
				name: '',
				version: '',
				docTypes: [],
				overlayMode: 'FIRST_PAGE_ONLY',
				location: {}
			};
		},
		close() {
			this.$store.state.displayAddResendTemplate = false;
			this.initData();
		},
		async create() {
			if (this.isFor === "supplierGroup") {
				this.activeItem = this.$store.state.activeSG;
			}
			if (this.isFor === "legalEntity") {
				this.activeItem = this.$store.state.activeLE;
			}
			console.log("nieuwe request")
			console.log("formdata " + this.formData)
			if (this.formData.name === '') {
				this.setError('name', true);
				return
			}
			if (this.formData.docTypes.length === 0) {
				this.setError('docTypes', true);
				return
			}
			if (Object.keys(this.formData.location).length === 0) {
				this.setError('location', true);
				return
			}
			let requestData = {resend: []}

			console.log(this.activeItem.templates)
			console.log(this.activeItem.templates.resendTemplates)
			console.log(this.activeItem.templates && this.activeItem.templates.resendTemplates)

			if (this.activeItem.templates && this.activeItem.templates.resendTemplates) {
				requestData.resend = this.activeItem.templates.resendTemplates;
				requestData.resend.push(this.formData)
			} else {
				requestData.resend.push(this.formData)
			}

			console.log("requestData: " + requestData)

			let payload = {
				isFor: this.isFor,
				sgId: this.$store.state.activeSG.supplierGroupId,
				formData: requestData
			};

			if (this.isFor === "legalEntity") {
				payload.legalEntityNumber = this.activeItem.entityNumber;
			}

			await this.$store.dispatch("editResendTemplate", payload);
			if (this.$store.state.responseStatus === 200) {
				this.$store.commit("toggleAlert", {
					title: "Edit resend template",
					text: "Resend template successfully edited",
				});
			}
			this.close();
		}
	}
}
</script>

<style scoped>
.form-container {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	margin-top: 20px;
}

.form-container > div:first-child {
	margin-right: 20px;
}

.form-container > div:last-child {
	margin-left: 20px;
}

label {
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.margin-right {
	margin-right: 10px;
}

.mb-05 {
	margin-bottom: 0.5rem;
}
</style>
