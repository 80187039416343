<template>
	<pop-up-window
		:show="this.$store.state.displayEditChannelMaintenance"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit channel maintenance</template>
		<template #content>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-1/2 mr-12">
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">Modify channel:</label>
						<ToggleSlide
							@update-value="updateToggleData($event, 'modifyChannel')"
							class="w-min"
							:bool="formData.modifyChannel"
						/>
					</div>
					<div class="mb-3">
						<label class="font-bold form-label">Default channel: *</label>
						<Dropdown
							id="editDefaultChannel"
							@update-value="updateDefaultChannels"
							:data="defaultChannelOptions"
							:selected="formData.defaultChannel"
							:key="formData.defaultChannel"
						/>
						<span v-if="errors.defaultChannel" class="text-primary-3">Please, select default channel</span>
					</div>
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">Allow import:</label>
						<ToggleSlide
							@update-value="updateToggleData($event, 'allowImport')"
							class="w-min"
							:bool="formData.allowImport"
						/>
					</div>
					<div class="mb-3" v-if="formData.allowImport">
						<label class="font-bold form-label">Import method: </label>
						<Dropdown
							id="editImportMethod"
							@update-value="importMethod"
							:data="importMethodOptions"
							:selected="formData.importMethod"
							:key="formData.importMethod"
						/>
						<span v-if="errors.importMethod" class="text-primary-3">Please, select import method</span>
					</div>

				</div>
				<div class="flex flex-col w-1/2 mr-12">
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">Display on portal:</label>
						<ToggleSlide
							@update-value="updateToggleData($event, 'displayOnPortal')"
							class="w-min"
							:bool="formData.displayOnPortal"
						/>
					</div>
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">Edit on portal:</label>
						<ToggleSlide
							@update-value="updateToggleData($event, 'editOnPortal')"
							class="w-min"
							:bool="formData.editOnPortal"
						/>
					</div>
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">Generate onboarding access code:</label>
						<ToggleSlide
							@update-value="updateToggleData($event, 'generateOnboardingAccessCode')"
							class="w-min"
							:bool="formData.generateOnboardingAccessCode"
						/>
					</div>
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">QR on email:</label>
						<ToggleSlide
							@update-value="updateToggleData($event, 'qrOnEmail')"
							class="w-min"
							:bool="formData.qrOnEmail"
						/>
					</div>
					<div class="mb-3 flex">
						<label class="font-bold form-label mb-0 mr-2">QR on print:</label>
						<ToggleSlide
							@update-value="updateToggleData($event, 'qrOnPrint')"
							class="w-min"
							:bool="formData.qrOnPrint"
						/>
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>

</template>s

<script>
import PopUpWindow from "../PopUp.vue";
import ToggleSlide from "../ToggleSlide.vue";
import Dropdown from "../Dropdown.vue";

export default {
	name: "EditChannelMaintenance",
	components: {
		PopUpWindow,
		ToggleSlide,
		Dropdown
	},
	mounted() {
		this.initData(this.$store.state.activeSG)
	},
	data() {
		return {
			formData: {
				allowImport: false,
				defaultChannel: '',
				displayOnPortal: false,
				editOnPortal: false,
				generateOnboardingAccessCode: false,
				qrOnEmail: false,
				qrOnPrint: false,
				importMethod: '',
				modifyChannel: false,
			},
			defaultChannelOptions: [
				{id: 0, name: ""},
				{id: 1, name: "ARCHIVE"},
				{id: 2, name: "PRINT"},
			],
			importMethodOptions: [
				{id: 0, name: ""},
				{id: 1, name: "MODIFY"},
				{id: 1, name: "OVERWRITE"},
			],
			errors: {
				defaultChannel: false,
				importMethod: false
			}
		}
	},
	computed: {
		activeSg() {
			return this.$store.state.activeSG
		}
	},
	watch: {
		activeSg() {
			this.initData();
		}
	},
	methods: {
		updateDefaultChannels(data) {
			this.errors.defaultChannel = false
			this.formData.defaultChannel = this.defaultChannelOptions[data].name
		},
		importMethod(data) {
			this.errors.importMethod = false
			this.formData.importMethod = this.importMethodOptions[data].name
		},
		updateToggleData(data, field) {
			this.formData[field] = data
		},
		initData() {
			if (this.$store.state.activeSG.channelsConfig.docImage.channelChoice?.modifyChannel !== undefined) {
				this.formData.modifyChannel = this.$store.state.activeSG.channelsConfig.docImage.channelChoice.modifyChannel
			}
			if (this.$store.state.activeSG.channelsConfig.docImage.channelChoice?.defaultChannel) {
				this.formData.defaultChannel = this.$store.state.activeSG.channelsConfig.docImage.channelChoice.defaultChannel
			}
			if (this.$store.state.activeSG.channelsConfig.docImage.channelChoice?.displayOnPortal !== undefined) {
				this.formData.displayOnPortal = this.$store.state.activeSG.channelsConfig.docImage.channelChoice.displayOnPortal
			} else this.formData.displayOnPortal = false

			if (this.$store.state.activeSG.channelsConfig.docImage.channelChoice?.editOnPortal !== undefined) {
				this.formData.editOnPortal = this.$store.state.activeSG.channelsConfig.docImage.channelChoice.editOnPortal
			} else this.formData.editOnPortal = false

			if (this.$store.state.activeSG.channelsConfig.docImage.channelChoice?.generateOnboardingAccessCode !== undefined) {
				this.formData.generateOnboardingAccessCode = this.$store.state.activeSG.channelsConfig.docImage.channelChoice.generateOnboardingAccessCode
			} else this.formData.generateOnboardingAccessCode = false

			if (this.$store.state.activeSG.channelsConfig.docImage.channelChoice?.qrOnEmail !== undefined) {
				this.formData.qrOnEmail = this.$store.state.activeSG.channelsConfig.docImage.channelChoice.qrOnEmail
			} else this.formData.qrOnEmail = false

			if (this.$store.state.activeSG.channelsConfig.docImage.channelChoice?.allowImport !== undefined) {
				this.formData.allowImport = this.$store.state.activeSG.channelsConfig.docImage.channelChoice.allowImport
			} else this.formData.allowImport = false

			if (this.$store.state.activeSG.channelsConfig.docImage.channelChoice?.importMethod) {
				this.formData.importMethod = this.$store.state.activeSG.channelsConfig.docImage.channelChoice.importMethod
			} else this.formData.importMethod = ""

			this.errors = {
				defaultChannel: false,
				importMethod: false
			}
		},
		update() {
			if (this.formData.defaultChannel === '') {
				this.errors.defaultChannel = true
				return
			}
			if (this.formData.allowImport) {
				if (this.formData.importMethod === '') {
					this.errors.importMethod = true
					return
				}
			} else {
				delete this.formData.importMethod
			}

			let payload = {
				supplierGroupId: this.$store.state.activeSG.supplierGroupId,
				formData: {channelChoice: this.formData}
			}

			this.$store.dispatch("EditChannelMaintenance", payload);
			this.close()
		},
		close() {
			this.$store.state.displayEditChannelMaintenance = false
		}
	}
}
</script>