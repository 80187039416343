<template>
  <div>
    <button
      @click="toggleAccordion"
      class="flex items-center space-x-3"
      :aria-expanded="isOpen"
    >

      <slot name="title" />
      <font-awesome-icon class="w-3 transition-all duration-200 transform"
        :class="{
          'rotate-180': isOpen,
          'rotate-90': !isOpen,
        }" icon="chevron-up" />
    </button>
    <div v-show="isOpen" >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  props:{
    trigger: Boolean,
    addIsOpen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch:{
    trigger(data){
      this.isOpen = false
    },
    addIsOpen(data) {
      if (data){
        this.isOpen = false
      }
    }
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>