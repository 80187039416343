<template>
    <div class="h-auto">
        <div v-if="show"  class="backdrop"></div>
        <transition name='dialog'>
            <dialog open v-if="show"
                    :class="{'absolute' : positionAbsolute,
                              'fixed' : positionFixed,
                              'brightness-95': blur,
                              'width10' : width10,
                              'width-20' : width20,
                              'top-0' : top,
                              'top-10' : top10,
                              'left-10' : left10,
                              'left-0' : left0,
                              'width-100'  :width100,
                              'width-60'  :width60,
                              'width-40'  :width40,
                              }"
					:style="{marginTop: variableTop}"
            >
                <header class="nymus-blue">

                        <div v-if="showClose" @click="close" class="close">X</div>
                        <div class="header">
                            <slot name="header"></slot>
                        </div>
                </header>
                <section class="sectionStyling">
                    <slot name="content"></slot>
                </section>
                <div class="dropdownSolution"></div>
            </dialog>
        </transition>
        
    </div>

</template>

<script>
export default {
    name: "PopUpWindow",
    props: {
        top: {
            type: Boolean,
            required: false,
            default: false,

        },
        top10: {
            type: Boolean,
            required: false,
            default: false,

        },
		variableTop: {
			type: String,
			required: false,
			default: ''
		},
        width100: {
            type: Boolean,
            required: false,
            default: false,

        },
        width60: {
            type: Boolean,
            required: false,
            default: false,

        },
        width40: {
            type: Boolean,
            required: false,
            default: false,

        },
        left10: {
            type: Boolean,
            required: false,
            default: false,

        },
        left0: {
            type: Boolean,
            required: false,
            default: false,

        },
      width20: {
        type: Boolean,
        required: false,
        default: false,
      },
        blur: Boolean,
        width10: {
            type: Boolean,
            required: false,
            default: false,
        },
        positionFixed: {
            type: Boolean,
            required: false,
            default: false
        },
        positionAbsolute: {
            type: Boolean,
            required: false,
            default: true
        },

        showClose: {
            type: Boolean,
            required: false,
            default: false
        },
        show: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
        additionalInfo: {
            type: String,
            required: false,
        },
        button1: {
            type: String,
            required: false
        },
        button2: {
            type: String,
            required: false
        },
    },
    emits: ['close'],
    methods: {
        close(){
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
.dropdownSolution{
    height: 200px;
    background-color: transparent;
}
.sectionStyling{
    background-color: white; 
    box-shadow: 0px 3px 20px #0000000b;
    overflow: visible;
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 10;
}
.absolute{
    position: absolute;
}
.fixed{
    position: fixed;
}
.makeBright{
    --tw-brightness: brightness(.95);
    filter: var(--tw-filter);
}

dialog {
    top: 25vh;
    left: 10%;
    width: fit-content;
    z-index: 100;
    border-radius: 0.375rem;
    padding: 0;
    margin: 0;
    background-color: transparent;
}

header {
    color: white;
    width: 100%;
    padding: 1rem;
}

header h2 {
    margin: 0;
}

.header {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    justify-items: center;
}

section {
    overflow:auto;
    padding: 1rem;
    border: 1px solid #d9d9d9;
}


.dialog-enter-active {
    transition: all 0.3s ease-out;
}

.dialog-leave-active {
    transition: all .3s ease-in;
}

.dialog-enter-from, .dialog-leave-to {
    transform: scale(0.8);
    opacity: 0;
}
.close{
    cursor: pointer;
    font-weight: bold;
    float: right;
}

@media (min-width: 768px) {
    dialog {
        left: 22.5%;
        top: 21%;
        width: 70%;
    }
    .width10{
        top: calc(50% - 5rem) !important;
        left: calc(58% - 5rem) !important;
        width: 10rem !important;

    }
}
.top-0{
    top: 0 !important;
}
.top-10{
    top: 10% !important;
}
.left-10{
    left: 10%;
}
.width-100{
    width: 100%;
}
.width-60{
    width: 60%;
    left: 30%
}
.width-40{
    width: 40%;
    left: 30%
}
.width-20{
  width: 20%;
  left: 40%
}
.left-0{
    left: 0;
}
</style>
