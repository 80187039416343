const mutations = {
    setSupplierGroups(state, data) {
        state.supplierGroups = data
    },
    setToken(state, data) {
        state.token = data
    },
    setRefreshToken(state, value){
        state.refreshToken = value
    },
    setSchedule(state, data) {
        state.schedule = data
    },
    setTempFile(state, data) {
        state.tempFile = data
    },
    toggleAlert(state, data) {
        state.alert = data
    },
    setCsvContent(state, data) {
        state.csvResponse = data
    },

    setActiveSg(state, data){
        state.activeSG = data

    },
    setActiveLe(state, data){
        state.activeLE = data

    },
    setSftpMountUploaded(state, payload){
        state.sftpMountFileUploaded[payload.type] = payload.value
    },
    setSmpParticipants(state, data){

        let identifiersList = []
        let index = 0;
        for (let identifier in data){

            let tempItem = {
                 id: index, name : identifier
            }
            identifiersList.push(tempItem)
            ++index;
        }
        state.identifiersList = identifiersList;
        let tableData = []

        for(let item in data){
            data[item].forEach(info =>{
                let tepmItem = {
                    identifier : item,
                    accessPointUrl : info.accessPointUrl,
                    detailUrl : info.detailUrl,
                    documentType : info.documentType,
                    documentIdentifier : info.documentIdentifier,
                    serviceDescription : info.serviceDescription
                }
                if (info.serviceDescription.length > 40){
                    let descriptionArray = info.serviceDescription.split(' ');
                    let middle = Math.floor(descriptionArray.length/2);
                    let str1 = '';
                    let str2 = '';
                    descriptionArray.forEach( (item, index) =>{
                        if (index <= middle){
                            str1 = str1 + item + " ";
                        } else {
                            str2 = str2 + item + " ";
                        }
                    });
             //       let description = "<div class='line'> <div class='line1'>" + str1 + "</div><div class='line2'>" + str2 + "</div></div>"
                    let description =  str1 + "<br>" + str2

                    tepmItem.serviceDescription = description;
                }
                tableData.push(tepmItem)
            })
        }


        state.smpParticipants = tableData;
        state.peppolDataRetrieved = true;
},
    setUpdatedSupplierGroup(state, data) {
        let found = Object.values(state.supplierGroups).find((item) => item.supplierGroupId === data.supplierGroupId)
        let index = state.supplierGroups.indexOf(found)
        state.supplierGroups[index] = data
        state.activeSG = data
    },
    updateSGPrintJobs(state, data) {
        if (state.activeSG.channelsConfig.print && state.activeSG.channelsConfig.print.printJobs) {
            state.activeSG.channelsConfig.print.printJobs = data;
        }
        if (!state.activeSG.channelsConfig.print) {
            state.activeSG.channelsConfig.print = {}
            state.activeSG.channelsConfig.print.printJobs = data;
        }

    },
    setUpdatedLegalEntity(state, payload) {
        let sg = Object.values(state.supplierGroups).find((item) => item.supplierGroupId === payload.sgId)
        let le = Object.values(sg.supplierLegalEntities).find((item) => item.entityNumber === payload.data.entityNumber)
        let index = state.supplierGroups.indexOf(sg)
        state.supplierGroups[index].supplierLegalEntities[payload.data.entityNumber] = payload.data
        state.activeLE = payload.data
        state.activeSG.supplierLegalEntities[le.entityNumber] = payload.data
    },
    setBucketName(state, value) {
        state.bucketName = value
    },
    setQrImageSize(state, value) {
        state.qrImageSize.width = value.width;
        state.qrImageSize.height = value.height;
    },
    displayErrorAlert(state, payload) {
        state.errorAlert.display = payload
    },
    displayErrorAlertText(state, payload) {
        state.errorAlert.text = payload
    },
    peppolIdentifiersDisplay(state, payload) {
        state.peppolIdentifiers.peppolInfoDisplay = payload
    },
    peppolIdentifiersResults(state, payload) {
        state.peppolIdentifiers[payload.field] = payload.value
    },
    setPeppolRetrievedData(state, payload) {
        state.peppolIdentifiers.peppolRetrievedData.peppolIdentifier = payload.identifier
        state.peppolIdentifiers.peppolRetrievedData.information = payload.information
    },

    peppolCreateDocumentDisplay(state, payload) {
        state.peppolIdentifiers.displayCreateDocument = payload
    },
    setLoader(state, payload) {
        state.displayLoader = payload;
    },
    setErrorAlertText(state, payload) {
        state.errorAlert.text = payload;
    },
    legalEntityCreated(state, data) {
        state.legalEntityCreated = data;
    },
    setUploadedFileUrl(state, payload) {
        state.uploadedFileUrl = payload
    },
    setOverviewTablePage(state, payload) {
        state.sgOverviewPageNumber = payload;
    },

    clearExtractionTemplate(state) {
        state.extractionTemplates = [];
    },
    updateExtractionTemplateIds(state, payload) {
        state.extractionTemplateIds = payload;
    },
    cancelEditExtraction(state, payload) {
        state.cancelEditExtraction = payload;
    },

    setUploadedFileName(state, payload) {
        state.tempFileName = payload;
    },
    setSelectedExtractionTemplateName(state, payload) {
        state.selectedExtractionTemplateName = payload
    },
    setSelectedQrTemplateName(state, payload) {
        state.selectedQrTemplateName = payload
    },
    setSelectedOverlayTemplateName(state, payload) {
        state.selectedOverlayTemplateName = payload
    },
    setSelectedPreIngestionTemplateName(state, payload) {
        state.selectedPreIngestionTemplateName = payload
    },
    setSelectedResendTemplateName(state, payload) {
        state.selectedResendTemplateName = payload
    },
    setSelectedMailTemplateName(state, payload) {
        state.selectedMailTemplateName = payload
    },
    overlayTemplateDeleted(state, payload) {

        state.overlayTemplateDeleted = payload
    },

    displayAddMailTemplate(state, payload) {
        state.displayAddMailTemplate = payload
    },
    peppolItemChanged(state, payload) {
        state.peppolIdentifiers.peppolItemChanged = payload
    },
    peppolDocumentCreated(state, payload) {
        state.peppolIdentifiers.peppolDocumentCreated = payload
    },
    peppolDocumentDeleted(state, payload) {
        state.peppolIdentifiers.peppolDocumentDeleted = payload
    },
    overviewTableDataUpdated(state, payload) {
        state.overviewTableDataUpdated = payload
    },
    setSgTableCurrentPage(state,payload){
        state.sgTableInitialPage = payload;
    },
    setIngestionStatus(state, payload){
        state.ingestionStatus = payload;
    },
    startArchive(state,payload){
        state.startArchive = payload;
    },
    fileUploaded(state, payload){
        state.fileUploaded = payload;
    },


}
export default mutations
