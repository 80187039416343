<template>
	<pop-up-window
		:show="this.$store.state.displayAddMailTemplate"
		:showClose="false"
		:top10="true"

		class="doc-statuses-popup add-new-overlay-template"
		fixed
	>
		<template #header>Add new mail template</template>
		<template #content>
			<div>
				<div class="flex flex-row flex-wrap">
					<div class="w-1/2 pr-8">
						<div>
							<Field
								title="Mail template name*"
								v-model="formData.name"
								type="text"
								@click="setError('name', false)"
							/>
							<span v-if="errors.name" class="text-primary-3">
								Please, enter the name
							</span>
						</div>
						<div>
							<div>
								<FileUpload
									:isLegalEntity="isLegalEntity"
									@update-value="changeBannerValue"
									accept="image/png, image/jpeg"
									id="bannerMailTemplate"
									title="Banner:  *"
									:data="formData.banner"
								/>
							</div>
							<span v-if="errors.banner" class="text-primary-3">Please, select a banner</span>
						</div>
					</div>

					<div class="w-1/2 pl-8">
						<div class="mb-3">
							<p class="font-bold form-label">Address information: *</p>
							<textarea
								@click="setError('address', false)"
								rows="8"
								class="form-control w-full"
								v-model="formData.address"
							/>
						</div>
						<span v-if="errors.address" class="text-primary-3">
							Please, provide address information
						</span>
					</div>
				</div>

				<div class="flex flex-row flex-wrap">
					<div class="pr-8 w-1/2">
						<p class="font-bold form-label">
							Mail doc types:*
						</p>
						<DropdownSelectAll
							id="overlayTemplateDocTypes"
							@update-value="updateSelectedDocTypes"
							:data="docTypes"
							:selected="formData.docTypes"
							:noSelectAll="true"
						/>
						<span v-if="errors.docTypes" class="text-primary-3">
							Please, select at least one docType
						</span>
					</div>
					<div class="pl-8 w-1/2">
						<Field
							id="disclaimer"
							type="text"
							title="Disclaimer email:"
							@click="setError('disclaimerEmail', false)"
							v-model="formData.disclaimerEmail"
						/>
						<span v-if="errors.disclaimerEmail" class="text-primary-3">
							Please, provide correct disclaimer email
						</span>
					</div>

					<div class="pr-8 w-1/2">
						<Field
							id="senderEmail"
							type="text"
							title="Sender email: *"
							v-model="formData.senderEmail"
							@click="setError('senderEmail', false)"

						/>
						<span v-if="errors.senderEmail" class="text-primary-3">
                                Please, provide correct sender email
                            </span>
					</div>
					<div class="pl-8 w-1/2">
						<div class="mb-3">
							<p class="font-bold form-label">Languages:</p>
							<DropdownSelectAll
								@update-value="updateSelectedLanguages"
								:data="lang"
								:selected="formData.emailLanguages"
								:disabled="false"
								:noSelectAll="true"
							/>
						</div>
					</div>

					<div class="pr-8 w-1/2">
						<Field
							id="fromName"
							title="Display name:"
							type="text"
							v-model="formData.fromName"
							:disabled="false"
						/>
					</div>
					<div class="mb-3 pl-8 w-1/2">
						<label for="editMailTemplateLanguages" class="font-bold form-label">
							Default Languages:
						</label>
						<Dropdown
							id="externalPortalLanguage"
							@update-value="updateSelectedDefaultLanguage"
							:data="defaultLanguageOptions"
							:selected="formData.defaultEmailLanguage"
						/>
					</div>

					<div class="pr-8 w-1/2">
						<Field
							id="replyEmail"
							title="Reply-to-email:"
							type="email"
							v-model="formData.replyEmail"
							:disabled="false"
							:has-error="errors.replyEmail"
						/>
						<span v-if="errors.replyEmail" class="text-primary-3">
							Invalid reply to email, please enter a correct email address
						</span>
					</div>
				</div>

				<div :class="{ 'pointer-events-none': showConfirm }" class="grid grid-rows-1 justify-items-end">
					<div>
						<button class="btn shadow-md mr-2" @click="add">Add</button>
						<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</template>
	</pop-up-window>

</template>

<script>
import PopUpWindow from "../../PopUp.vue";
import Field from "../../Field.vue";
import FileUpload from "../../FileUpload.vue";
import DropdownSelectAll from "../../DropdownSelectAll.vue";
import Dropdown from "@/components/Dropdown.vue";
import languages from "@/langCodes";

export default {
	name: "AddMailTemplate",
	components: {
		Dropdown,
		PopUpWindow,
		Field,
		FileUpload,
		DropdownSelectAll
	},
	props: {
		isFor: {
			type: String,
			required: false
		},
		lang: {
			type: Array,
			required: true
		}
	},
	computed: {
		defaultLanguageOptions() {
			let defaultLanguages = [...languages]
			if (defaultLanguages[0].name !== 'No default language') {
				defaultLanguages.unshift({name: 'No default language'})
			}
			return defaultLanguages
		}
	},
	data() {
		return {
			formData: {
				name: '',
				senderEmail: '',
				banner: '',
				emailLanguages: [],
				docTypes: [],
				disclaimerEmail: '',
				replyEmail: '',
				address: '',
				version: '1',
			},
			errors: {
				name: false,
				banner: false,
				disclaimerEmail: false,
				address: false,
				senderEmail: false,
				replyEmail: false
			},
			isLegalEntity: this.isFor === "legalEntity",

			docTypes: this.$store.state.docTypes,
		}
	},
	methods: {

		async add() {
			let errors = false
			if (this.formData.name === '') {
				this.setError('name', true);
				errors = true
			}
			let emailsInvalid = false;
			const re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

			let validEmail = re.test(this.formData.senderEmail);
			if (this.formData.senderEmail === '' || !validEmail) {
				emailsInvalid = true;
			}
			if (emailsInvalid) {
				this.setError('senderEmail', true);
				errors = true
			}
			if (this.formData.replyEmail.trim() !== '' && !this.$store.state.emailRegex.test(this.formData.replyEmail)) {
				this.setError('replyEmail', true);
				errors = true
			}

			if (this.formData.banner === '') {
				this.setError('banner', true);
				errors = true
			}

			if (this.formData.docTypes.length === 0) {
				this.setError('docTypes', true);
				errors = true
			}
			if (this.formData.disclaimerEmail !== '') {
				validEmail = re.test(this.formData.disclaimerEmail);
				if (!validEmail) {
					this.setError('disclaimerEmail', true);
					errors = true
				}
			}

			if (this.formData.address === '') {
				this.setError('address', true);
				errors = true
			}

			if (errors === true) return
			if (!this.formData.emailLanguages.length) {
				delete this.formData.emailLanguages;
			}

			if (this.formData.disclaimerEmail === '') {
				delete this.formData.disclaimerEmail;
			}

			if (this.formData.replyEmail === '') {
				delete this.formData.replyEmail;
			}

			if (this.formData.defaultEmailLanguage === 'No default language' || this.formData.defaultEmailLanguage === '') {
				delete this.formData.defaultEmailLanguage
			}

			let activeItem = {}
			if (this.isFor === 'supplierGroup') {
				activeItem = this.$store.state.activeSG
			} else {
				activeItem = this.$store.state.activeLE
			}

			let mailTemplatesToSend = []

			if (activeItem.templates && activeItem.templates.mails) {
				mailTemplatesToSend = activeItem.templates.mails;
			}
			mailTemplatesToSend.push(this.formData);

			if (this.isFor === "supplierGroup") {
				let payload = {
					sgId: this.$store.state.activeSG.supplierGroupId,
					edit: "mail-template",
					body: {mail: mailTemplatesToSend},
				};
				await this.$store.dispatch("EditSupplierGroup", payload);
				if (this.$store.state.responseStatus === 200) {
					this.$store.commit("toggleAlert", {
						title: "Edit Suppliergroup",
						text: "Mail template successfully edited",
					});
				}
			}
			if (this.isFor === "legalEntity") {
				let payload = {
					sgId: this.$store.state.activeSG.supplierGroupId,
					leId: this.$store.state.activeLE.entityNumber,
					edit: "mail-template",
					body: {mail: mailTemplatesToSend},
				};
				await this.$store.dispatch("EditLegalEntity", payload);
				if (this.$store.state.responseStatus === 200) {
					this.$store.commit("toggleAlert", {
						title: "Edit Legal entity",
						text: "Mail template successfully edited",
					});
				}
			}
			this.close()
		},
		close() {
			this.$store.commit('displayAddMailTemplate', false);
			this.formData = {
				name: '',
				senderEmail: '',
				banner: '',
				emailLanguages: [],
				defaultEmailLanguage: '',
				docTypes: [],
				disclaimerEmail: '',
				address: '',
				version: '1',
			};

			this.errors = {
				name: false,
				banner: false,
				disclaimerEmail: false,
				address: false,
				senderEmail: false
			};
		},
		updateSelectedDocTypes(data) {
			this.setError('docTypes', false);
			this.formData.docTypes = [];
			for (let item of data) {
				this.formData.docTypes.push(this.docTypes[item].name);
			}
		},
		updateSelectedLanguages(data) {
			this.formData.emailLanguages = []
			for (let item of data) {
				this.formData.emailLanguages.push(this.lang[item].name);
			}
		},
		updateSelectedDefaultLanguage(data) {
			if (typeof data === 'string') {
				this.formData.defaultEmailLanguage = this.defaultLanguageOptions[data].name
			} else this.formData.defaultEmailLanguage = this.defaultLanguageOptions[data[0]].name
		},
		setError(field, value) {
			this.errors[field] = value;
		},
		changeBannerValue(event) {
			this.formData.banner = event.value;
			this.setError('banner', false);
		},
	}
}
</script>
