<template>
    <div class="staging"
                 :class="{'showStaging' : isStaging}">
                STAGING
    </div>

</template>
<script>
export default {
    computed: {
        isStaging(){
            if (window.location.hostname.includes('localhost') || window.location.hostname.includes('staging') ){
                return true
            }
            return false
        },
    }
}
</script>
<style scoped>
.staging{
    position: relative;
    font-weight: bold;
    transform: rotate(-5deg);
    align-self: center;
    font-size: 20px;
    color: #D9D8E6;;
    border: 3px solid #D9D8E6;
    opacity: 0;
    padding: 4px 30px;
    width: 150px;

}
.showStaging {
    opacity: 1;
}
</style>
