<template>
	<div class="box shadow-none col-span-6 height-600">
		<div class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
			<div class="button-container">
				<button class="btn shadow-md bg-theme-1 text-white mr-2" @click="addNewTemplate">
					Add new template
				</button>
			</div>
			<div>
				<div v-if="displayTemplates">
					<div class="mb-3">
						<div>
							<div class="flex flex-col ">
								<label for="viewPreingestionTemplateName">Select pre-ingestion template:</label>
								<Dropdown
									id="viewPreingestionTemplateName"
									@update-value="updateSelectedName"
									class="w-1/2 pr-6"
									:data="templateNames"
									:selected="selectedTemplateName"
								/>
							</div>
							<div class="form-container">
								<div>
									<Field
										title="Pre-Ingestion template version:"
										v-model="activeTemplate.version"
										type="text"
										:disabled="true"
									/>
									<label for="viewPreingestionDocType">DocType:*</label>
									<DropdownSelectAll
										id="viewPreingestionDocType"
										:data="docTypes"
										:selected=activeTemplate.docTypes
										:disabled="true"
										v-if="renderComponent"
									/>
								</div>
								<div>
									<p class="font-bold form-label">Base file:*</p>
									<div
										:key="index + item"
										v-for="(item, index) in activeTemplate.baseFile"
										class="flex flex-col"
									>
										<FileUpload
											:uploadIconNotDisplay="true"
											:disabled="true"
											:isLegalEntity="isLegalEntity"
											accept=".pdf"
											:prefix="true"
											:prefixData="index"
											:delSuffix="false"
											:download-suffix="true"
											:data="activeTemplate.baseFile[index].fileName"
											@click="download(activeTemplate.baseFile[index].url)"
											:id=" index + 'overlay' + item + activeTemplate.baseFile[index].fileName"
										/>
									</div>
									<p class="font-bold form-label">Template JSON:*</p>
									<div
										:key="index + item"
										v-for="(item, index) in activeTemplate.templateJson"
										class="flex flex-col"
									>
										<FileUpload
											:uploadIconNotDisplay="true"
											:disabled="true"
											:isLegalEntity="isLegalEntity"
											accept=".pdf"
											:prefix="true"
											:prefixData="index"
											:delSuffix="false"
											:download-suffix="true"
											@click="download(activeTemplate.templateJson[index].url)"
											:data="activeTemplate.templateJson[index].fileName"
											:id=" index + 'overlay' + item + activeTemplate.templateJson[index].fileName"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="!displayTemplates">
					No templates yet
				</div>
			</div>
		</div>
		<AddPreingestionTemplate :isFor="isFor"/>
	</div>
</template>

<script>
import Dropdown from "../../Dropdown.vue";
import Field from "../../Field.vue";
import DropdownSelectAll from "../../DropdownSelectAll.vue";
import FileUpload from "../../FileUpload.vue";
import AddPreingestionTemplate from "./AddPreingestionTemplate.vue";

export default {
	name: "ViewPreIngestion",
	components: {
		Dropdown,
		Field,
		DropdownSelectAll,
		FileUpload,
		AddPreingestionTemplate
	},
	props: {
		isFor: String,
	},
	mounted() {
		this.initData();
	},
	data() {
		return {
			displaySelectedName: true,
			activeItem: {},
			activeTemplate: {},
			selectedTemplateName: '',
			preigestionTemplates: [],
			docTypes: this.$store.state.docTypes,
			isLegalEntity: this.isFor === "legalEntity",
			renderComponent: true,
			displayAddNewTemplate: false,
		}
	},
	computed: {
		activeSG() {
			return this.$store.state.activeSG;
		},
		activeLE() {
			return this.$store.state.activeLE;
		},
		displayTemplates() {
			return this.preigestionTemplates.length !== 0;
		},
		templateNames() {
			let templateNames = [];

			this.preigestionTemplates.forEach((template, index) => templateNames.push({id: index, name: template.name}))
			return templateNames;
		}
	},
	watch: {
		activeSG(newVal) {
			this.displaySelectedName = false;
			if (this.isFor === "supplierGroup" && newVal.templates && newVal.templates.textOverlayTemplates) {
				this.preigestionTemplates = newVal.templates.textOverlayTemplates;
				if (this.templateNames.length) {
					this.updateSelectedName(0)
				}
			} else {
				this.preigestionTemplates = []
				this.selectedTemplateName = ''
				this.activeTemplate = {}
			}
			setTimeout(() => {
				this.displaySelectedName = true;
			}, 3000)
			this.forceRerender();
		},
		activeLE(newVal) {
			if (this.isFor === "legalEntity" && newVal.templates && newVal.templates.textOverlayTemplates) {
				this.preigestionTemplates = newVal.templates.textOverlayTemplates;
				if (this.templateNames.length) {
					this.updateSelectedName(0)
				}
				this.forceRerender();
			} else {
				this.preigestionTemplates = [];
				this.selectedTemplateName = ''
				this.activeTemplate = {}
			}
			this.forceRerender();
		}
	},
	methods: {
		addNewTemplate() {
			this.$store.state.displayAddPreingestionTemplate = true;
		},
		download(pdfPath){
			window.open(pdfPath)
		},
		forceRerender() {
			// Removing my-component from the DOM
			this.renderComponent = false;

			this.$nextTick(() => {
				// Adding the component back in
				this.renderComponent = true;
			});
		},
		updateSelectedName(templateId) {
			if (typeof templateId === 'object') templateId = templateId[0]
			if (templateId < 0) templateId = 0
			let selectedName = this.templateNames.find(item => (item.id.toString() === templateId.toString())).name;
			this.selectedTemplateName = selectedName;
			this.$store.commit("setSelectedPreIngestionTemplateName", selectedName);
			this.activeTemplate = this.preigestionTemplates.find(template => (template.name === selectedName));
			this.forceRerender();
		},
		initData() {
			if (this.isFor === "supplierGroup" && this.$store.state.activeSG.templates && this.$store.state.activeSG.templates.textOverlayTemplates) {
				this.preigestionTemplates = this.$store.state.activeSG.templates.textOverlayTemplates;
			}
			if (this.isFor === "legalEntity" && this.$store.state.activeLE.templates && this.$store.state.activeLE.templates.textOverlayTemplates) {
				this.preigestionTemplates = this.$store.state.activeLE.templates.textOverlayTemplates;
			}

			if (this.templateNames.length) {
				this.selectedTemplateName = this.templateNames[0].name;
			}
			if (this.preigestionTemplates.length) {
				this.activeTemplate = this.preigestionTemplates[0];
			}
		}
	}
}
</script>

<style scoped>
.button-container {
	display: grid;
	justify-items: end;
}

.height-600 {
	min-height: 600px;
}

.form-container {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	margin-top: 20px;
}

.form-container > div:first-child {
	margin-right: 20px;
}

.form-container > div:last-child {
	margin-left: 20px;
}

label {
	font-weight: bold;
	margin-bottom: 0.5rem;
}
</style>