import qs from "qs"
import axios from "axios"

export default {
	postReport(context, payload) {
		let dataToSend = {
			client_id: window.environment.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken,
			body,
			{
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		)
			.then(res => {
				context.commit('setToken', res.data.access_token, {root: true})
				context.commit('setRefreshToken', res.data.refresh_token, {root: true})

				axios.request({
						url: "api/admin/supplier-groups/reports/" + payload.reportType,
						method: "post",
						baseURL: context.rootState.baseUrl,
						headers: {
							Authorization: `Bearer ${context.rootState.token}`,
						},
						data: {
							supplierGroupLegalEntities: payload.SG,
							from: payload.from,
							to: payload.to
						}
					})
					.then((response) => {
						context.rootState.responseStatus = 200
						context.commit('setCsvContent', response.data, {root: true})
					})
					.catch((exception) => {
						context.rootState.responseStatus = 400
						console.log("globalJobs-error: ", exception, {root: true})
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	}
}