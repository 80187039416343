<template>
    <TabulatorLegalEntity :token="token" :requestUrl="url"/>
</template>
<script>
import TabulatorLegalEntity from "../TabulatorLegalEntity.vue"
export default {
    name: 'ViewLegalEntity',
    components: {
        TabulatorLegalEntity
    },
    watch: {
    "$store.state.activeSG": {
      handler(data) {
        this.supplierGroup = data
      },
      deep: true,
    },
  },
    data(){
        return{
            url: this.$store.state.baseUrl + this.$store.state.legalEntityUrl,
            token: this.$store.state.token
        }
    }

}
</script>