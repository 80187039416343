<template>
	<pop-up-window
		:show="this.$store.state.displayEditExtraction"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit Extraction</template>
		<template #content>
			<div class="flex flex-row flex-nowrap template-info">
				<div class="flex flex-col w-1/4" v-if="this.$store.state.extractionTemplates.length">
					<Dropdown
						:data="templateNames"
						@update-value="updateTemplateName($event)"
						:selected=this.$store.state.selectedExtractionTemplateName
					/>
					<div v-if="this.formData.legalEntityId">
						<div class="one-line-grid mt-2">
							<p class="text-lg font-bold mr-2">General template info:</p>
							<div>
								<button class="btn py-0 shadow-md mb-2" @click="toggleDisplayInfo('displayInfo')">
									{{ displayText }}
								</button>
							</div>
						</div>
						<div v-if="displayInfo" class="ml-4">
							<FileUpload
								@update-value="updateFileValue"
								@delete-value="deleteFileValue"
								:data="formData.pdfFile"
								:delSuffix="true"
								id="editPdfTemplateUploadPreview"
								title="Upload .pdf template preview:"
								accept=".pdf"
								class="mt-2"
							/>
							<Field
								id="viewExtractionName"
								title="Name:"
								type="text"
								v-model="newName"
								class="new-name"
							/>
							<Field
								id="viewExtractionLocation"
								title="Location:"
								type="text"
								v-model="location"
								class="new-name"
							/>
							<div>
								<p class="font-bold form-label mb-0"> Legal entity: </p>
								<div>
									<Dropdown
										class="mt-2"
										:data="legalEntities"
										@update-value="updateLegalEntity($event)"
										:selected=selectedLe
									/>
								</div>
							</div>
							<div class="mt-4 move-address-location">
								<label class="font-bold form-label mb-0">
									Move address location:
								</label>
								<ToggleSlide
									@update-value="updateMoveAddressLocation"
									class="w-min"
									:bool="formData.moveAddressLocation"
								/>
							</div>
							<div class="mt-4 move-address-location">
								<label class="font-bold form-label mb-0" >
									Use system date when no date was found:
								</label>
								<ToggleSlide
									@update-value="updateUseSystemDate"
									class="w-min"
									:bool="formData.useSystemDate"
								/>
							</div>
							<div>
								<p class="font-bold form-label mb-0 mt-2"> Default country: </p>
								<div>
									<Dropdown
										class="mt-2"
										:data="defaultCountryOptions"
										@update-value="updateDefaultCountry($event)"
										:selected="formData.defaultCountry"
									/>
								</div>
							</div>
							<div class="mt-2">
								<p class="font-bold form-label mb-0">Paper format: </p>
								<div>
									<Dropdown
										class="mt-2"
										:data="paperFormatOptions"
										@update-value="updatePaperFormat($event)"
										:selected=formData.paperFormat
									/>
								</div>
							</div>
						</div>
						<div class="button-container mt-2">
							<p class="text-lg font-bold mr-2">Extraction Fields:</p>
							<div>
								<button
									@click="toggleExtractionField('displayExtractionField')"
									class="btn py-0 shadow-md mb-2"
								>
									{{ toggleExtractionFieldText }}
								</button>
								<button
									@click="displayAddExtractionField"
									class="btn bg-theme-1 py-0 text-white shadow-md mb-2"
								>
									Add
								</button>
								<AddExtractionField
									@add-field="addField"
									@cancel="showExtractionFields = false"
									:extractionDataType="extractionDataType"
									:fieldTypeData="manipulatedFields"
									:show-add-extraction-field="showExtractionFields"
								/>
							</div>
						</div>
						<div v-if="displayExtractionField">
							<Accordion
								:trigger="triggered"
								@click="giveDraggableColor(index)"
								:class="{ 'bg-primary-4': selectedField === index, 'edit-item': selectedField === index,  'edit-mode' : editMode }"
								class="mb-3 border border-gray-300 p-1 box ml-4 accordion-header"
								:key="item + index"
								v-for="(item, index) in formData.areas"
							>
								<template v-slot:title>
									<span class="font-semibold">{{ index }}</span>
									<span v-if="!editMode" class="edit-mode" @click="toggleEditMode(index)">
										<font-awesome-icon icon="pencil"/>
									</span>
									<span v-if="editMode" class="edit-mode" @click="toggleEditMode(index)">X</span>
								</template>
								<template v-slot:content>
									<div class="p-3 flex flex-col w-full">
										<div v-if="item.field === 'DUMMY'" class="mb-3">
											<label class="font-bold form-label">Field type:</label>
											<Dropdown
												:data="manipulatedFields"
												:key="item.field"
												:selected="item.field"
												@update-value="updateDummyFieldValue($event,item)"
											/>
										</div>
										<div class="flex">
											<Field
												id="viewExtractionXCoord"
												title="X-Coord:"
												:numberIntervals="0.1"
												type="number"
												v-model="item.x"
											/>
											<Field
												id="viewExtractionYCoord"
												title="Y-Coord:"
												:numberIntervals="0.1"
												type="number"
												v-model="item.y"
											/>
										</div>
										<div class="flex">
											<Field
												id="viewExtractionWidth"
												title="Width:"
												type="number"
												v-model="item.width"
											/>
											<Field
												id="viewExtractionHeight"
												title="Height:"
												type="number"
												v-model="item.height"
											/>
										</div>
										<div>
											<div class="mb-3">
												<Field
													id="editExtractionRegex"
													type="string"
													title="Navigation text (use comma to separate items):"
													v-model="item.navigationText"
												/>
											</div>
										</div>
										<div>
											<div class="mt-4 default-date">
												<p class="font-bold form-label mb-0">
													Use system date as default date:
												</p>
												<ToggleSlide
													@update-value="updateUseSystemDateAsDefaultDate($event, item)"
													class="w-min"
													:id="index"
													:bool="item.useSystemDateAsDefaultDate ? item.useSystemDateAsDefaultDate : false"
												/>
											</div>
											<div class="mb-3">
												<Field
													id="editDefaultValue"
													type="string"
													title="Default value:"
													v-model="item.defaultValue"
												/>
											</div>
										</div>
										<div>
											<div class="mb-3">
												<Field
													id="editDateFormatter"
													type="string"
													title="Date formatter:"
													v-model="item.dateFormatter"
												/>
											</div>
										</div>
										<div class="mb-3">
											<label class="font-bold form-label" v-if="item.bigDecimalFormatter">
												Big decimal formatter (both has to be filled in or empty):
											</label>
											<div class="ml-4">
												<label class="font-bold form-label">Grouping separator:</label>
												<Dropdown
													@update-value="updateBigDecimalFormatter($event, item, 'groupingSeparator')"
													:data="separatorOptions"
													:key="1"
													:selected="item.bigDecimalFormatter ? item.bigDecimalFormatter.groupingSeparator : 'No separator'"
												/>
											</div>
											<div class="ml-4 mt-2">
												<label class="font-bold form-label">Decimal separator:</label>
												<Dropdown
													@update-value="updateBigDecimalFormatter($event, item, 'decimalSeparator')"
													:data="separatorOptions"
													:key="2"
													:selected="item.bigDecimalFormatter ? item.bigDecimalFormatter.decimalSeparator : 'No separator'"
												/>
											</div>
										</div>
										<div class="mb-3">
											<label class="font-bold form-label">Additional rules:</label>
											<div class="ml-4">
												<label class="font-bold form-label">Offset:</label>
												<Dropdown
													@update-value="updateAdditionalRules($event, item, 'offset')"
													:data="offsetOptions"
													:key="3"
													:selected="item.additionalRules?.offset ? item.additionalRules.offset.toString() : 'No offset'"
												/>
											</div>
										</div>

										<div class="flex flex-row justify-end">
											<button
												@click="showConfirmDialog(index, 'field')"
												class="btn bg-theme-1 text-white shadow-md mr-2"
											>
												Delete field
											</button>
										</div>
									</div>
								</template>
							</Accordion>
						</div>

						<div class="table-field-container">
							<div v-if="formData.extractionTableInfo">
								<div class="clear-table-info">
									<button
										@click="clearTableFields"
										v-if="displayClearButton"
										class="btn bg-theme-1 py-0 text-white shadow-md mb-2"
									>
										Clear table info
									</button>
								</div>
								<div class="one-line-grid">
									<p class="text-lg font-bold mr-2">General table info:</p>
									<div>
										<button
											class="btn py-0 shadow-md mb-2"
											@click="toggleDisplayInfo('displayInfoTable')"
										>
											{{ displayTextTable }}
										</button>
									</div>
								</div>
								<div
									v-if="displayInfoTable" class="ml-4"
									@click="setErrors('tableGeneralFields', false)"
								>
									<p class="error" v-if="errors.tableGeneralFields">
										Please, fill in all required fields and at least one extraction table field
									</p>
									<Field
										title="Table headers* (separate by comma):"
										type="text"
										v-model="formData.extractionTableInfo.tableHeaders"
									/>
									<Field
										title="Table cutoff* (separate by comma):"
										type="text"
										v-model="formData.extractionTableInfo.tableCutoff"
									/>
									<Field
										title="Max description lines* (only values >=1):"
										:numberIntervals="1"
										type="number"
										v-model.number="formData.extractionTableInfo.maxDescriptionLines"
									/>
									<div class="">
										<p class="font-bold form-label mb-0 mt-2"> Present in all rows*: </p>
										<Dropdown
											@update-value="updatePresentInAllRows($event)"
											:data="documentFieldOptions"
											:selected="formData.extractionTableInfo.presentInAllRows ? formData.extractionTableInfo.presentInAllRows : documentFieldOptions[0].name"
										/>
									</div>
								</div>
							</div>
							<div class="button-container mt-2">
								<p class="text-lg font-bold mr-2">Extraction Table Fields:</p>
								<div>
									<button
										v-if="formData.extractionTableInfo"
										@click="toggleExtractionField('displayExtractionTableField')"
										class="btn py-0 shadow-md mb-2"
									>
										{{ toggleExtractionTableFieldText }}
									</button>
									<button
										@click="displayAddExtractionTableField"
										class="btn bg-theme-1 py-0 text-white shadow-md mb-2"
									>
										Add
									</button>
									<AddExtractionField
										@add-field="addTableFields"
										@cancel="showExtractionTable = false"
										:extractionDataType="extractionDataType"
										:fieldTypeData="manipulatedTableFields"
										:show-add-extraction-field="showExtractionTable"
									/>
								</div>
							</div>
							<div v-if="formData.extractionTableInfo && displayExtractionTableField">
								<div>
									<Accordion
										:trigger="triggered"
										@click="giveDraggableColor(index)"
										:class="{ 'bg-primary-4': selectedField === index, 'edit-item': selectedField === index,  'edit-mode' : editMode}"
										class="mb-3 border border-gray-300 p-1 box ml-4 accordion-header"
										:key="item + index"
										v-for="(item, index) in formData.extractionTableInfo.tableColumns"
									>
										<template v-slot:title>
											<span class="font-semibold">{{ index }}</span>
											<span v-if="!editMode" class="edit-mode" @click="toggleEditMode(index)">
												<font-awesome-icon icon="pencil"/>
                                            </span>
											<span v-if="editMode" class="edit-mode" @click="toggleEditMode(index)">
												X
											</span>
										</template>
										<template v-slot:content>
											<div class="flex">
												<Field
													title="X-Coord:"
													:numberIntervals="0.1"
													type="number"
													v-model="item.x"
												/>
												<Field
													title="Y-Coord:"
													:numberIntervals="0.1"
													type="number"
													v-model="item.y"
												/>
											</div>
											<div class="flex">
												<Field
													title="Width:"
													type="number"
													v-model="item.width"
												/>
												<Field
													title="Height:"
													type="number"
													v-model="item.height"
												/>
											</div>
											<div>
												<Field
													title="Default value:"
													type="text"
													v-model="item.defaultValue"
												/>

												<div class="mt-2">
													<label class="font-bold form-label" v-if="item.bigDecimalFormatter">
														Big decimal formatter (both has to be filled in or empty):
													</label>
													<div class="ml-4 mt-2">
														<div class="ml-4">
															<label class="font-bold form-label">
																Grouping separator:
															</label>
															<Dropdown
																@update-value="updateBigDecimalFormatter($event, item, 'groupingSeparator')"
																:data="separatorOptions"
																:key="1"
																:selected="item.bigDecimalFormatter ? item.bigDecimalFormatter.groupingSeparator : 'No separator'"
															/>
														</div>
														<div class="ml-4 mt-2">
															<label class="font-bold form-label">
																Decimal separator:
															</label>
															<Dropdown
																@update-value="updateBigDecimalFormatter($event, item, 'decimalSeparator')"
																:data="separatorOptions"
																:key="3"
																:selected="item.bigDecimalFormatter ? item.bigDecimalFormatter.decimalSeparator : 'No separator'"
															/>
														</div>
													</div>
												</div>
												<div class="flex flex-row justify-end">
													<button
														@click="showConfirmDialog(index, 'field')"
														class="btn bg-theme-1 text-white shadow-md mr-2 mt-2"
													>
														Delete field
													</button>
												</div>
											</div>
										</template>
									</Accordion>
								</div>
							</div>
						</div>
						<div class="table-field-container">
							<div v-if="formData.summaryTableInfo">
								<div class="clear-table-info">
									<button
										@click="clearTableFieldsSummary"
										v-if="displayClearButtonSummary"
										class="btn bg-theme-1 py-0 text-white shadow-md mb-2"
									>
										Clear summary table info
									</button>
								</div>
								<div class="one-line-grid">
									<p class="text-lg font-bold mr-2">General summary info:</p>
									<div>
										<button
											class="btn py-0 shadow-md mb-2"
											@click="toggleDisplayInfo('displayInfoSummaryTable')"
										>
											{{ displayTextSummaryTable }}
										</button>
									</div>
								</div>
								<div
									v-if="displayInfoSummaryTable"
									class="ml-4"
									@click="setErrors('summaryTableGeneralFields', false)"
								>
									<p class="error" v-if="errors.summaryTableGeneralFields">
										Please, fill in all required fields and at least one extraction table field
									</p>
									<Field
										title="Table headers* (separate by comma):"
										type="text"
										v-model="formData.summaryTableInfo.tableHeaders"
									/>
									<Field
										title="Table cutoff* (separate by comma):"
										type="text"
										v-model="formData.summaryTableInfo.tableCutoff"
									/>
									<Field
										title="Max description lines* (only values >=1):"
										:numberIntervals="1"
										type="number"
										v-model.number="formData.summaryTableInfo.maxDescriptionLines"
									/>
									<div class="">
										<p class="font-bold form-label mb-0 mt-2"> Present in all rows*: </p>
										<Dropdown
											@update-value="updatePresentInAllRowsSummary($event)"
											:data="documentSummaryFieldOptions"
											:selected="formData.summaryTableInfo.presentInAllRows ? formData.summaryTableInfo.presentInAllRows : documentSummaryFieldOptions[0].name"
										/>
									</div>
									<div>
										<label class="font-bold form-label mt-2">Offset:</label>
										<Dropdown
											@update-value="updateOffsetSummary($event)"
											:data="offsetOptions"
											:selected="formData.summaryTableInfo.offset? formData.summaryTableInfo.offset : 'No offset'"
										/>
									</div>
								</div>
							</div>
							<div class="button-container mt-2">
								<p class="text-lg font-bold mr-2">Summary Fields:</p>
								<div>
									<button
										v-if="formData.summaryTableInfo"
										@click="toggleExtractionField('displayExtractionSummaryTableField')"
										class="btn py-0 shadow-md mb-2"
									>
										{{ toggleExtractionSummaryTableFieldText }}
									</button>
									<button
										@click="displayAddExtractionSummaryTableField"
										class="btn bg-theme-1 py-0 text-white shadow-md mb-2"
									>
										Add
									</button>
									<AddExtractionField
										@add-field="addSummaryTableFields"
										@cancel="showSummaryFields = false"
										:extractionDataType="extractionDataType"
										:fieldTypeData="manipulatedSummaryTableFields"
										:show-add-extraction-field="showSummaryFields"
									/>
								</div>
							</div>
							<div v-if="formData.summaryTableInfo && displayExtractionSummaryTableField">
								<div>
									<Accordion
										:trigger="triggered"
										@click="giveDraggableColor(index)"
										:class="{ 'bg-primary-4': selectedField === index, 'edit-item': selectedField === index,  'edit-mode' : editMode}"
										class="mb-3 border border-gray-300 p-1 box ml-4 accordion-header"
										:key="item + index"
										v-for="(item, index) in formData.summaryTableInfo.tableColumns"
									>
										<template v-slot:title>
											<span class="font-semibold">{{ index }}</span>
											<span v-if="!editMode" class="edit-mode" @click="toggleEditMode(index)">
												<font-awesome-icon icon="pencil"/>
                                            </span>
											<span v-if="editMode" class="edit-mode" @click="toggleEditMode(index)">
												X
                                            </span>
										</template>
										<template v-slot:content>
											<div class="flex">
												<Field
													title="X-Coord:"
													:numberIntervals="0.1"
													type="number"
													v-model="item.x"
												/>
												<Field
													title="Y-Coord:"
													:numberIntervals="0.1"
													type="number"
													v-model="item.y"
												/>
											</div>
											<div class="flex">
												<Field
													title="Width:"
													type="number"
													v-model="item.width"
												/>
												<Field
													title="Height:"
													type="number"
													v-model="item.height"
												/>
											</div>
											<div>
												<Field
													title="Default value:"
													type="text"
													v-model="item.defaultValue"
												/>
												<div class="mt-2">
													<label class="font-bold form-label" v-if="item.bigDecimalFormatter">
														Big decimal formatter (both has to be filled in or empty):
													</label>
													<div class="ml-4">
														<label class="font-bold form-label">Grouping separator:</label>
														<Dropdown
															@update-value="updateBigDecimalFormatter($event, item, 'groupingSeparator')"
															:data="separatorOptions"
															:key="1"
															:selected="item.bigDecimalFormatter ? item.bigDecimalFormatter.groupingSeparator : 'No separator'"
														/>
													</div>
													<div class="ml-4 mt-2">
														<label class="font-bold form-label">Decimal separator:</label>
														<Dropdown
															@update-value="updateBigDecimalFormatter($event, item, 'decimalSeparator')"
															:data="separatorOptions"
															:key="3"
															:selected="item.bigDecimalFormatter ? item.bigDecimalFormatter.decimalSeparator : 'No separator'"
														/>
													</div>
												</div>
												<div class="flex flex-row justify-end">
													<button
														@click="showConfirmDialog(index, 'field')"
														class="btn bg-theme-1 text-white shadow-md mr-2 mt-2"
													>
														Delete field
													</button>
												</div>
											</div>
										</template>
									</Accordion>
								</div>
							</div>
						</div>
						<div class="button-outer mt-4">
							<button class="btn shadow-md text-white nymus-orange"
									@click="showConfirmDialog(null, 'template')">Delete template
							</button>
						</div>
					</div>
				</div>
				<div class="flex flex-col w-3/4">
					<div class="mb-3 flex justify-center">
						<label class="font-bold form-label mr-3 mb-0">
							Drag Disabled
						</label>
						<ToggleSlide
							@update-value="changeDragState"
							class="w-min"
							:bool="dragStateBool"
						/>
						<label class="font-bold form-label mx-3 mb-0">
							Enabled
						</label>
						<label class="font-bold form-label mr-3 mb-0">
							Move
						</label>
						<ToggleSlide
							@update-value="changeCursorState"
							class="w-min"
							:bool="cursorStateBool"
						/>
						<label for="editExtractionCursorState" class="font-bold form-label ml-3 mb-0">
							Scale
						</label>
					</div>
					<div class="no-pdf" v-if="choseTemplate">
						Please, select template
					</div>
					<div v-if="!formData.pdfFile && !choseTemplate" class="no-pdf">
						<p>No pdf yet</p>
					</div>
					<div
						v-if="formData.pdfFile"
						ref="boundary"
						class="mx-auto relative border-4"
						@mousedown="handleMouseDown"
						@mousemove="handleMouseMove"
						@mouseup="handleMouseUp"
					>
						<div
							:key="item + item.field + index"
							v-for="(item, index) in formData.areas"
							:ref="index"
							:class="{'border-primary-4': selectedField === index,
                                'cursor-move': !cursorStateBool,
                                'cursor-resize': cursorStateBool,
                            }"
							@mouseenter="selectDraggable(index)"
							class="makeSquare cursor-move absolute border z-20"
							:style="{ bottom: item.y + '%', left: item.x + '%', width: item.width + '%', height: item.height + '%'}"
						>
						</div>
						<div v-if="formData.extractionTableInfo">
							<div
								:key="item + item.field + index"
								v-for="(item, index) in formData.extractionTableInfo.tableColumns"
								:ref="index"
								:class="{
									'border-primary-4': selectedField === index,
									'cursor-move': !cursorStateBool,
									'cursor-resize': cursorStateBool,
                                }"
								@mouseenter="selectDraggable(index)"
								class="makeSquare cursor-move absolute border z-20"
								:style="{bottom: item.y + '%', left: item.x + '%', width: item.width + '%', height: item.height + '%'}"
							>
							</div>
						</div>
						<div v-if="formData.summaryTableInfo">
							<div
								:key="item + item.field + index"
								v-for="(item, index) in formData.summaryTableInfo.tableColumns"
								:ref="index"
								:class="{
									'border-primary-4': selectedField === index,
									'cursor-move': !cursorStateBool,
									'cursor-resize': cursorStateBool,
                                }"
								@mouseenter="selectDraggable(index)"
								class="makeSquare cursor-move absolute border z-20"
								:style="{
									bottom: item.y + '%',
									left: item.x + '%',
									width: item.width + '%',
									height: item.height + '%',
                               }"
							>
							</div>
						</div>
						<div ref="pdfContainer">
							<VuePdf
								class="w-full"
								:scale="1.25"
								:key="formData.pdfFile"
								:src="formData.pdfFile"
								:page="1"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
	<ConfirmDialog @close="deleteField" :show="showConfirm"/>
</template>

<script>
import PopUpWindow from "../PopUp.vue"
import Field from "../Field.vue"
import Accordion from "../Accordion.vue"
import ConfirmDialog from "../ConfirmDialog.vue"
import Dropdown from "../Dropdown.vue"
import FileUpload from "../FileUpload.vue"
import ToggleSlide from "../ToggleSlide.vue"
import AddExtractionField from "@/components/AddSupplierGroup/AddExtractionField.vue"
import {VuePdf} from "vue3-pdfjs"

export default {
	name: "EditExtraction",
	components: {
		VuePdf,
		AddExtractionField,
		PopUpWindow,
		ToggleSlide,
		ConfirmDialog,
		Field,
		Dropdown,
		Accordion,
		FileUpload,
	},
	props: {
		fieldTypeData: Array,
		extractionDataType: Array,
	},
	data() {
		return {
			displayExtractionField: true,
			displayExtractionTableField: true,
			displayExtractionSummaryTableField: true,

			editMode: false,
			errors: {
				tableGeneralFields: false,
				summaryTableGeneralFields: false,
			},
			displayInfoTable: false,
			displayInfoSummaryTable: false,
			displayInfo: false,
			fieldTableTypeData: this.$store.state.tableFieldTypeData,
			tableExtractionField: false,
			summaryTableExtractionField: false,
			manipulatedFields: [...this.fieldTypeData],
			manipulatedTableFields: [{id: 0, name: "SELECT FIELD"}],
			manipulatedSummaryTableFields: [{id: 0, name: "SELECT FIELD"}],
			showConfirm: false,
			cursorStateBool: false,
			dragStateBool: false,
			currentDelItem: {},
			selectedField: "",
			isDragging: false,
			triggered: false,
			formData: {},
			test: {
				startX: 0,
				startY: 0,
				stopX: 0,
				stopY: 0,
			},
			supplierGroups: this.$store.state.supplierGroups,
			extractionTemplates: this.$store.state.extractionTemplates,
			newName: '',
			location: '',
			deleteItem: '',
			paperFormatOptions: [
				{id: 0, name: "A4"},
				{id: 1, name: "US_LETTER"},
			],
			separatorOptions: [
				{id: 0, name: "COMMA"},
				{id: 1, name: "DOT"},
				{id: 2, name: "SPACE"},
				{id: 3, name: "No separator"},
			],
			offsetOptions: [
				{id: 0, name: "No offset"},
				{id: 1, name: "-2"},
				{id: 2, name: "-1"},
				{id: 3, name: "1"},
				{id: 4, name: "2"},
			],
			defaultCountryOptions: [
				{id: 0, name: "BE"},
				{id: 1, name: "FR"},
				{id: 2, name: "NL"},
				{id: 3, name: "No default country"},
			],
			documentFieldOptions: [{id: 8, name: "SELECT FIELD"}, ...this.$store.state.tableFieldTypeData],
			documentSummaryFieldOptions: [{
				id: 8,
				name: "SELECT FIELD"
			}, ...this.$store.state.tableSummaryFieldTypeData],

			showExtractionFields: false,
			showExtractionTable: false,
			showSummaryFields: false
		}
	},
	watch: {
		"$store.state.tempFile": {
			handler(newVal) {
				this.formData.pdfFile = newVal
			}
		},
	},
	computed: {
		toggleExtractionFieldText() {
			if (!this.displayExtractionField) {
				return "Show"
			}
			return "Hide"
		},
		toggleExtractionTableFieldText() {
			if (!this.displayExtractionTableField) {
				return "Show"
			}
			return "Hide"
		},
		toggleExtractionSummaryTableFieldText() {
			if (!this.displayExtractionSummaryTableField) {
				return "Show"
			}
			return "Hide"
		},
		displayText() {

			if (!this.displayInfo) {
				return "Show"
			}
			return "Hide"
		},
		displayTextTable() {

			if (!this.displayInfoTable) {
				return "Show"
			}
			return "Hide"
		},
		displayTextSummaryTable() {
			if (!this.displayInfoSummaryTable) {
				return "Show"
			}
			return "Hide"
		},
		choseTemplate() {
			return this.isEmpty(this.formData)
		},
		selectedLe() {
			if (this.formData.legalEntityId) {
				return this.$store.state.activeSG.supplierLegalEntities[this.formData.legalEntityId].internalName
					? this.$store.state.activeSG.supplierLegalEntities[this.formData.legalEntityId].internalName
					: this.$store.state.activeSG.supplierLegalEntities[this.formData.legalEntityId].name
			}
			return ''
		},
		templateNames() {
			let names = []
			if (this.$store.state.extractionTemplates.length) {
				let index = 0
				this.$store.state.extractionTemplates.forEach(template => {
					let tempItem = {
						id: index,
						name: template.name
					}
					names.push(tempItem)
					index++
				})
			}
			return names
		},
		legalEntities() {
			let options = []
			let legalEntities = this.$store.state.activeSG.supplierLegalEntities
			let index = 0
			for (const le in legalEntities) {
				index = index + 1
				let tempItem = {
					id: index,
					legalEntityId: le,
					name: legalEntities[le].internalName ? legalEntities[le].internalName : legalEntities[le].name
				}
				options.push(tempItem)
			}
			return options
		},
		displayClearButton() {
			return !!this.formData.extractionTableInfo
		},
		displayClearButtonSummary() {
			return !!this.formData.summaryTableInfo
		}
	},
	methods: {
		toggleExtractionField(field) {
			this[field] = !this[field]
		},
		clearTableFields() {
			delete this.formData.extractionTableInfo
		},
		clearTableFieldsSummary() {
			delete this.formData.summaryTableInfo
		},
		setErrors(field, value) {
			this.errors[field] = value
		},
		toggleDisplayInfo(field) {
			this[field] = !this[field]
		},
		updateUseSystemDateAsDefaultDate(data, item) {
			if (item.useSystemDateAsDefaultDate === undefined) {
				item.useSystemDateAsDefaultDate = data
			}
			item.useSystemDateAsDefaultDate = data
		},
		updatePresentInAllRows(field) {
			this.formData.extractionTableInfo.presentInAllRows = this.documentFieldOptions[field].name
		},
		updatePresentInAllRowsSummary(field) {
			this.formData.summaryTableInfo.presentInAllRows = this.documentSummaryFieldOptions[field].name
		},
		updateBigDecimalFormatterTable(separator, item) {
			if (item.bigDecimalFormatter === undefined) {
				item.bigDecimalFormatter = {
					groupingSeparator: 'No separator',
					decimalSeparator: 'No separator'
				}
			}
			item.bigDecimalFormatter.decimalSeparator = this.separatorOptions[separator].name
		},
		updateBigDecimalFormatter(separator, item, field) {
			if (item.bigDecimalFormatter === undefined) {
				item.bigDecimalFormatter = {
					groupingSeparator: 'No separator',
					decimalSeparator: 'No separator'
				}
			}
			item.bigDecimalFormatter[field] = this.separatorOptions[separator].name
		},

		updateAdditionalRules(offset, item, field) {
			if (typeof offset === 'object') offset = offset[0]
			if (offset < 0) return

			item.additionalRules = {offset: this.offsetOptions[offset]?.name}
			if (item.additionalRules?.offset === undefined) item.additionalRules = {offset: "No offset"}
		},
		updateOffsetSummary(offset) {
			this.formData.summaryTableInfo.offset = this.offsetOptions[offset].name
		},
		isEmpty(obj) {
			return Object.keys(obj).length === 0
		},
		async updateFileValue() {
			setTimeout(() => {
				this.formData.pdfFile = this.$store.state.tempFile
			}, 1500)
		},
		deleteFileValue() {
			delete this.formData.pdfFile
		},
		changeCursorState(data) {
			this.cursorStateBool = data
		},
		changeDragState(data) {
			this.dragStateBool = data
		},
		updateMoveAddressLocation(data) {
			this.formData.moveAddressLocation = data
		},
		updateUseSystemDate(data){
			this.formData.useSystemDate = data
		},
		updateTemplateName(data) {
			this.setErrors('tableGeneralFields', false)
			if (typeof data === 'object') data = data[0]
			const selectedTemplate = this.templateNames[data]
			this.formData = this.$store.state.extractionTemplates.find(el => el.name === selectedTemplate.name)

			if (this.formData.moveAddressLocation === undefined) this.formData.moveAddressLocation = false
			if (this.formData.useSystemDate === undefined) this.formData.useSystemDate = false

			if (this.formData.paperFormat === undefined) this.formData.paperFormat = "A4"

			if (this.formData.defaultCountry === undefined) this.formData.defaultCountry = "No default country"

			this.newName = this.formData.name
			if (this.formData.location) this.location = this.formData.location

			if(this.formData.extractionTableInfo?.tableColumns !== undefined){
				for(let tableColumn in this.formData.extractionTableInfo.tableColumns){
					if (this.formData.extractionTableInfo.tableColumns[tableColumn].bigDecimalFormatter === undefined){
						this.formData.extractionTableInfo.tableColumns[tableColumn].bigDecimalFormatter = {
							groupingSeparator: 'No separator',
							decimalSeparator: 'No separator'
						}
					}
				}
			}

		},
		updateLegalEntity(data) {
			this.formData.legalEntityId = this.legalEntities[data].legalEntityId
		},
		updatePaperFormat(paperFormat) {
			this.formData.paperFormat = this.paperFormatOptions[paperFormat].name
		},
		updateDefaultCountry(defaultCountry) {
			this.formData.defaultCountry = this.defaultCountryOptions[defaultCountry].name
		},
		updateDummyFieldValue(data, item) {
			let found = this.formData.areas[item.field]
			found.field = this.manipulatedFields[data].name
			if (item.field !== "DUMMY") {
				this.formData.areas[item.field] = {
					x: item.x,
					y: item.y,
					width: item.width,
					height: item.height,

					navigationText: item.navigationText,
					defaultValue: item.defaultValue,

					dateFormatter: item.dateFormatter,
					bigDecimalFormatter: item.bigDecimalFormatter ? item.bigDecimalFormatter : null,
					additionalRules: item.additionalRules ? item.additionalRules : null,
					useSystemDateAsDefaultDate: item.useSystemDateAsDefaultDate ? item.useSystemDateAsDefaultDate : false,
				}
				delete this.formData.areas.DUMMY
			}
		},
		addTableFields(data) {
			this.tableExtractionField = true
			this.selectedField = data.field

			let tableField = JSON.parse(JSON.stringify(data))

			if (!this.formData.extractionTableInfo) this.formData.extractionTableInfo = {}
			if (!this.formData.extractionTableInfo.tableColumns) this.formData.extractionTableInfo.tableColumns = {}
			this.formData.extractionTableInfo.tableColumns[tableField.field] = {
				x: parseFloat(tableField.x),
				y: parseFloat(tableField.y),
				width: parseFloat(tableField.width),
				height: parseFloat(tableField.height),
				defaultValue: tableField.defaultValue,
				bigDecimalFormatter: tableField.bigDecimalFormatter,
			}
		},
		addSummaryTableFields(data) {
			this.tableExtractionField = true
			this.selectedField = data.field

			let tableField = JSON.parse(JSON.stringify(data))

			if (!this.formData.summaryTableInfo) this.formData.summaryTableInfo = {}
			if (!this.formData.summaryTableInfo.tableColumns) this.formData.summaryTableInfo.tableColumns = {}
			this.formData.summaryTableInfo.tableColumns[tableField.field] = {
				x: parseFloat(tableField.x),
				y: parseFloat(tableField.y),
				width: parseFloat(tableField.width),
				height: parseFloat(tableField.height),
				defaultValue: tableField.defaultValue,
				bigDecimalFormatter: tableField.bigDecimalFormatter,
			}
			this.showSummaryFields = false
		},
		addField(data) {
			this.selectedField = data.field
			let newVal = JSON.parse(JSON.stringify(data))

			this.formData.areas[newVal.field] = {
				x: parseFloat(newVal.x),
				y: parseFloat(newVal.y),
				width: parseFloat(newVal.width),
				height: parseFloat(newVal.height),

				navigationText: newVal.navigationText,
				defaultValue: newVal.defaultValue,

				dateFormatter: newVal.dateFormatter,
				bigDecimalFormatter: newVal.bigDecimalFormatter,
				additionalRules: newVal.additionalRules,
				useSystemDateAsDefaultDate: newVal.useSystemDateAsDefaultDate,
			}
			this.showExtractionFields = false
		},
		deleteField(data) {
			if (data === "Yes" && this.deleteItem === 'field') {
				if (this.currentDelItem.includes("TABLE")) {
					if (this.currentDelItem.includes("SUMMARY")) {
						delete this.formData.summaryTableInfo.tableColumns[this.currentDelItem]
					} else {
						delete this.formData.extractionTableInfo.tableColumns[this.currentDelItem]
					}
				} else delete this.formData.areas[this.currentDelItem]
				this.triggered = !this.triggered
			}
			if (data === "Yes" && this.deleteItem === 'template') {
				this.deleteExtractionTemplate()
			}
			this.showConfirm = false
		},
		showConfirmDialog(item, delItem) {
			this.deleteItem = delItem
			if (delItem === 'field') {
				this.currentDelItem = item
			}
			this.showConfirm = true
		},
		displayAddExtractionField() {
			this.manipulatedFields = [...this.fieldTypeData]

			for (let item in this.formData.areas) {
				const idx = this.manipulatedFields.findIndex(itm => itm.name === item)
				this.manipulatedFields.splice(idx, 1)
			}
			this.showExtractionFields = true
		},
		displayAddExtractionTableField() {
			this.manipulatedTableFields = JSON.parse(JSON.stringify(this.$store.state.tableFieldTypeData))
			if (this.formData.extractionTableInfo && this.formData.extractionTableInfo.tableColumns) {
				for (let item in this.formData.extractionTableInfo.tableColumns) {
					const idx = this.manipulatedTableFields.findIndex((itm) => itm.name === item)
					this.manipulatedTableFields.splice(idx, 1)
				}
				if (this.manipulatedTableFields.length === 0) this.manipulatedTableFields = [{id: 0, name: "No items"}]
			}
			this.showExtractionTable = true
		},
		displayAddExtractionSummaryTableField() {
			this.manipulatedSummaryTableFields = JSON.parse(JSON.stringify(this.$store.state.tableSummaryFieldTypeData))
			if (this.formData.summaryTableInfo && this.formData.summaryTableInfo.tableColumns) {
				for (let item in this.formData.summaryTableInfo.tableColumns) {
					const idx = this.manipulatedSummaryTableFields.findIndex(itm => itm.name === item)
					this.manipulatedSummaryTableFields.splice(idx, 1)
				}
				if (this.manipulatedSummaryTableFields.length === 0) {
					this.manipulatedSummaryTableFields = [{id: 0, name: "No items"}]
				}
			}
			this.showSummaryFields = true
		},
		selectDraggable(field) {
			if (!this.isDragging && !this.editMode) this.giveDraggableColor(field)
		},
		giveDraggableColor(field) {
			this.selectedField = field
		},
		toggleEditMode(field) {
			this.selectedField = field
			if (this.selectedField === field) this.editMode = !this.editMode
		},
		clamp(num, lower = 0, upper) {
			return num < lower ? lower : num > upper ? upper : num
		},
		clampInsideBoundary(x, y) {
			let found
			if (this.selectedField.includes("TABLE")) {
				if (this.selectedField.includes("SUMMARY")) {
					found = this.formData.summaryTableInfo.tableColumns[this.selectedField]
				} else {
					found = this.formData.extractionTableInfo.tableColumns[this.selectedField]
				}
			} else found = this.formData.areas[this.selectedField]

			const boundaryPos = this.$refs.pdfContainer.getBoundingClientRect()

			this.$store.state.qrBoundary.width = boundaryPos.width
			this.$store.state.qrBoundary.height = boundaryPos.height

			let heightBoundaryPercentage, widthBoundaryPercentage, maxWidth, maxHeight

			heightBoundaryPercentage = 100 - (this.$refs[this.selectedField][0].clientHeight / boundaryPos.height) * 100
			widthBoundaryPercentage = 100 - found.width
			maxWidth = boundaryPos.width - found.width
			maxHeight = boundaryPos.height - this.$refs[this.selectedField][0].clientHeight

			let cx = (this.clamp(x - boundaryPos.x, 0, maxWidth) / maxWidth) * 100
			let cy = ((maxHeight - this.clamp(y - boundaryPos.y, 0, maxHeight)) / maxHeight) * 100

			if (!this.cursorStateBool) {
				cx = cx > widthBoundaryPercentage ? widthBoundaryPercentage : cx
				cy = cy > heightBoundaryPercentage ? heightBoundaryPercentage : cy
				found.x = Math.round(cx)
				found.y = Math.round(cy)
			} else {
				found.width = cx - found.x
				found.height = cy - found.y
			}
		},
		findPositionInsideBound(e) {
			let x, y
			x = e.clientX - this.$refs[this.selectedField][0].clientWidth / 2
			y = e.clientY - this.$refs[this.selectedField][0].clientHeight / 2
			return this.clampInsideBoundary(x, y)
		},
		handleMouseDown(e) {
			const boundaryPos = this.$refs.pdfContainer.getBoundingClientRect()
			if (this.$refs[this.selectedField] ? e.target === this.$refs[this.selectedField][0] : false) {
				this.isDragging = true
				this.test.startX = null
				this.test.startY = null
			} else {
				this.test.startX = e.pageX - boundaryPos.left
				this.test.startY = e.pageY - boundaryPos.top
			}
		},
		handleMouseMove(e) {
			if (this.isDragging) this.findPositionInsideBound(e)
		},
		handleMouseUp(e) {
			this.isDragging = false

			if (this.test.startX) {
				const boundaryPos = this.$refs.pdfContainer.getBoundingClientRect()
				this.test.stopX = e.pageX - boundaryPos.left
				this.test.stopY = e.pageY - boundaryPos.top
				this.getSelectionCoords()
			}
		},
		getSelectionCoords() {
			const boundaryPos = this.$refs.pdfContainer.getBoundingClientRect()
			let c1x = (this.clamp(this.test.startX, 0, boundaryPos.width) / boundaryPos.width) * 100
			let c2x = (this.clamp(this.test.stopX, 0, boundaryPos.width) / boundaryPos.width) * 100
			let c1y = ((boundaryPos.height - this.clamp(this.test.startY - window.scrollY, 0, boundaryPos.height)) / boundaryPos.height) * 100
			let c2y = ((boundaryPos.height - this.clamp(this.test.stopY - window.scrollY, 0, boundaryPos.height)) / boundaryPos.height) * 100
			let width = c1x > c2x ? c1x - c2x : c2x - c1x
			let height = c1y > c2y ? c1y - c2y : c2y - c1y
			let x = c1x > c2x ? c2x : c1x
			let y = c1y > c2y ? c2y : c1y
			if (width !== 0 && height !== 0 && this.dragStateBool) {
				if (this.selectedField.includes("TABLE")) {
					if (this.selectedField.includes("SUMMARY")) {
						this.formData.summaryTableInfo.tableColumns[this.fieldTableTypeData[0].name] = {
							field: this.fieldTableTypeData[0].name,
							x: x,
							y: y,
							width: width,
							height: height,
							defaultValue: this.fieldTableTypeData[0].defaultValue,
							bigDecimalFormatter: this.fieldTypeData[0].bigDecimalFormatter,
						}
					} else {
						this.formData.extractionTableInfo.tableColumns[this.fieldTableTypeData[0].name] = {
							field: this.fieldTableTypeData[0].name,
							x: x,
							y: y,
							width: width,
							height: height,
							defaultValue: this.fieldTableTypeData[0].defaultValue,
							bigDecimalFormatter: this.fieldTypeData[0].bigDecimalFormatter,
						}
					}
				} else {
					this.formData.areas[this.fieldTypeData[0].name] = {
						field: this.fieldTypeData[0].name,
						x: x,
						y: y,
						width: width,
						height: height,

						navigationText: this.fieldTypeData[0].navigationText,
						defaultValue: this.fieldTypeData[0].defaultValue,

						dateFormatter: this.fieldTypeData[0].dateFormatter,
						bigDecimalFormatter: this.fieldTypeData[0].bigDecimalFormatter,
						additionalRules: this.fieldTypeData[0].additionalRules,
						useSystemDateAsDefaultDate: this.fieldTypeData[0].useSystemDateAsDefaultDate,
					}
				}
			}
			this.manipulatedFields = [...this.fieldTypeData]
			for (let item of this.formData.areas) {
				const idx = this.manipulatedFields.findIndex((itm) => itm.name === item.field)
				this.manipulatedFields.splice(idx, 1)
			}
		},
		close() {
			this.setErrors('tableGeneralFields', false)
			this.setErrors('summaryTableGeneralFields', false)
			this.editMode = false
			this.displayInfoTable = false
			this.displayExtractionSummaryTableField = true
			this.displayInfoSummaryTable = false
			this.displayInfo = false

			this.newName = ''
			this.location = ''
			this.formData = {}
			this.$store.commit('cancelEditExtraction', true)
			this.$store.state.displayEditExtraction = false
			this.$store.state.tempFile = ''
		},
		async update() {
			if (this.formData.areas.length === 0) return

			let requestData = this.formData

			this.formData.name = this.newName
			this.formData.location = this.location

			for (const area in this.formData.areas) {
				this.formData.areas[area].x = parseFloat(this.formData.areas[area].x)
				this.formData.areas[area].y = parseFloat(this.formData.areas[area].y)
				this.formData.areas[area].width = parseFloat(this.formData.areas[area].width)
				this.formData.areas[area].height = parseFloat(this.formData.areas[area].height)
			}

			for (const field in this.formData.areas) {
				if (this.formData.areas[field].navigationText !== undefined) {
					if (this.formData.areas[field].navigationText === '') {
						delete this.formData.areas[field].navigationText
					} else {
						if (!Array.isArray(this.formData.areas[field].navigationText)) {
							this.formData.areas[field].navigationText = this.formData.areas[field].navigationText.split(",")
						}
					}
				}

				if (this.formData.areas[field].defaultValue !== undefined) {
					if (this.formData.areas[field].defaultValue === '') {
						delete this.formData.areas[field].defaultValue
					}
				}
				if (this.formData.areas[field].dateFormatter !== undefined) {
					if (this.formData.areas[field].dateFormatter === '') {
						delete this.formData.areas[field].dateFormatter
					}
				}
				if (this.formData.areas[field].bigDecimalFormatter !== undefined && this.formData.areas[field].bigDecimalFormatter.groupingSeparator === 'No separator'
					&& this.formData.areas[field].bigDecimalFormatter.decimalSeparator === 'No separator') {
					delete this.formData.areas[field].bigDecimalFormatter;
				}

				if (this.formData.areas[field].bigDecimalFormatter !== undefined) {
					if ((this.formData.areas[field].bigDecimalFormatter.groupingSeparator === 'No separator' && this.formData.areas[field].bigDecimalFormatter.decimalSeparator !== 'No separator')
						|| (this.formData.areas[field].bigDecimalFormatter.groupingSeparator === '' && this.formData.areas[field].bigDecimalFormatter.decimalSeparator !== 'No separator')
						|| (this.formData.areas[field].bigDecimalFormatter.groupingSeparator !== 'No separator' && this.formData.areas[field].bigDecimalFormatter.decimalSeparator === '')
					) {
						let errorText = "Big decimal formatter fields have to be either empty both  or filled in both. Please check " + field
						this.$store.commit('setErrorAlertText', errorText)
						this.$store.commit('displayErrorAlert', true)
						return
					}
				}
				if (this.formData.areas[field].additionalRules !== undefined && this.formData.areas[field].additionalRules.offset !== undefined) {
					if (this.formData.areas[field].additionalRules.offset === 'No offset') delete this.formData.areas[field].additionalRules
					else this.formData.areas[field].additionalRules.offset = parseInt(this.formData.areas[field].additionalRules.offset)
				}
			}
			if (this.formData.defaultCountry === "No default country") delete this.formData.defaultCountry

			if (requestData.extractionTableInfo) {
				let tableFieldsLength = Object.keys(requestData.extractionTableInfo.tableColumns).length
				if (tableFieldsLength > 0 &&
					(!requestData.extractionTableInfo.tableHeaders
						|| (requestData.extractionTableInfo.tableHeaders && requestData.extractionTableInfo.tableHeaders === '')
						|| !requestData.extractionTableInfo.tableCutoff
						|| (requestData.extractionTableInfo.tableCutoff && requestData.extractionTableInfo.tableCutoff === '')
						|| requestData.extractionTableInfo.maxDescriptionLines === '0'
						|| requestData.extractionTableInfo.maxDescriptionLines === ''
						|| requestData.extractionTableInfo.presentInAllRows === 'SELECT FIELD'
					)
				) {
					this.displayInfoTable = true
					this.setErrors('tableGeneralFields', true)
					return
				}

				if (tableFieldsLength === 0
					&& (!requestData.extractionTableInfo.tableHeaders || requestData.extractionTableInfo.tableHeaders && !requestData.extractionTableInfo.tableHeaders.length)
					&& (!requestData.extractionTableInfo.tableCutoff || requestData.extractionTableInfo.tableCutoff && !requestData.extractionTableInfo.tableCutoff.length)
					&& (requestData.extractionTableInfo.maxDescriptionLines === 0 || requestData.extractionTableInfo.maxDescriptionLines === '')
					&& (requestData.extractionTableInfo.presentInAllRows === 'SELECT FIELD')
				) {
					delete requestData.extractionTableInfo
				} else {
					for (let column in requestData.extractionTableInfo.tableColumns) {
						requestData.extractionTableInfo.tableColumns[column].height = parseFloat((requestData.extractionTableInfo.tableColumns[column].height))
						requestData.extractionTableInfo.tableColumns[column].width = parseFloat((requestData.extractionTableInfo.tableColumns[column].width))
						requestData.extractionTableInfo.tableColumns[column].x = parseFloat(requestData.extractionTableInfo.tableColumns[column].x)
						requestData.extractionTableInfo.tableColumns[column].y = parseFloat(requestData.extractionTableInfo.tableColumns[column].y)

						if (requestData.extractionTableInfo.tableColumns[column].bigDecimalFormatter.decimalSeparator === "No separator"
							&& requestData.extractionTableInfo.tableColumns[column].bigDecimalFormatter.groupingSeparator === "No separator") {
							delete requestData.extractionTableInfo.tableColumns[column].bigDecimalFormatter
						}

						if (typeof requestData.extractionTableInfo.tableHeaders === "string") {
							requestData.extractionTableInfo.tableHeaders = requestData.extractionTableInfo.tableHeaders.split(',')
						}
						if (typeof requestData.extractionTableInfo.tableCutoff === "string") {
							requestData.extractionTableInfo.tableCutoff = requestData.extractionTableInfo.tableCutoff.split(',')
						}
						requestData.extractionTableInfo.maxDescriptionLines = parseInt(requestData.extractionTableInfo.maxDescriptionLines)
					}
				}
			}

			if (requestData.summaryTableInfo) {
				let tableFieldsLength = Object.keys(requestData.summaryTableInfo.tableColumns).length

				if (tableFieldsLength === 0) {
					if ((requestData.summaryTableInfo.tableHeaders === '')
						&& (requestData.summaryTableInfo.tableCutoff === '')) {
						delete requestData.summaryTableInfo
					} else {
						this.displayInfoSummaryTable = true;
						this.setErrors('summaryTableGeneralFields', true)
						return
					}
				} else {
					if (requestData.summaryTableInfo.tableHeaders === ''
						|| requestData.summaryTableInfo.tableCutoff === ''
						|| requestData.summaryTableInfo.maxDescriptionLines === '0'
						|| requestData.summaryTableInfo.maxDescriptionLines === ''
					) {
						this.displayInfoSummaryTable = true
						this.setErrors('summaryTableGeneralFields', true)
						return
					} else {
						for (let column in requestData.summaryTableInfo.tableColumns) {
							requestData.summaryTableInfo.tableColumns[column].height = parseFloat((requestData.summaryTableInfo.tableColumns[column].height))
							requestData.summaryTableInfo.tableColumns[column].width = parseFloat((requestData.summaryTableInfo.tableColumns[column].width))
							requestData.summaryTableInfo.tableColumns[column].x = parseFloat(requestData.summaryTableInfo.tableColumns[column].x)
							requestData.summaryTableInfo.tableColumns[column].y = parseFloat(requestData.summaryTableInfo.tableColumns[column].y)
							if (requestData.summaryTableInfo.tableColumns[column].bigDecimalFormatter.decimalSeparator === "No separator"
								&& requestData.summaryTableInfo.tableColumns[column].bigDecimalFormatter.groupingSeparator === "No separator"
							) {
								delete requestData.summaryTableInfo.tableColumns[column].bigDecimalFormatter
							}

							if (typeof requestData.summaryTableInfo.tableHeaders === "string") {
								requestData.summaryTableInfo.tableHeaders = requestData.summaryTableInfo.tableHeaders.split(',')
							}
							if (typeof requestData.summaryTableInfo.tableCutoff === "string") {
								requestData.summaryTableInfo.tableCutoff = requestData.summaryTableInfo.tableCutoff.split(',')
							}

							requestData.summaryTableInfo.maxDescriptionLines = parseInt(requestData.summaryTableInfo.maxDescriptionLines);

							if (requestData.summaryTableInfo.offset === "SELECT FIELD") {
								delete requestData.summaryTableInfo.offset
							} else {
								requestData.summaryTableInfo.offset = parseInt(requestData.summaryTableInfo.offset)
							}
						}
					}
				}
			}

			await this.$store.dispatch('modifyExtractionTemplate', {
				token: this.$root.token,
				requestData: requestData,
				supplierGroupId: this.$store.state.activeSG.supplierGroupId
			})
			this.close()
		},
		deleteExtractionTemplate() {
			this.$store.dispatch('deleteExtractionTemplate', {
				token: this.$root.token,
				templateId: this.formData.id,
				supplierGroupId: this.$store.state.activeSG.supplierGroupId
			})
			this.close()
		}
	}
}
</script>

<style scoped>
.cursor-resize {
	cursor: nesw-resize;
}

.button-outer {
	display: grid;
	justify-items: end;
}

.template-info .new-name {
	margin: 10px 0;
}

.no-pdf {
	text-align: center;
	margin-top: 40px;
}

.move-address-location {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 170px auto;
}

.default-date {
	padding-right: 20px;
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: 90% 10%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
}

.one-line > div {
	width: 100%;
}

.one-line-grid {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 70% 30%;
}

.one-line-grid p:nth-child(2) {
	justify-self: end;
}

.button-container {
	display: grid;
	grid-template-columns: 65% 35%;
	grid-template-rows: 100%;
}

.button-container > div {
	justify-self: end;
}

.clear-table-info {
	display: grid;
	grid-template-columns:100%;
	grid-template-rows: 100%;
	justify-items: end;
}

.table-field-container {
	border-top: 1px solid grey;
	padding-top: 6px;
}

.one-line-grid > div {
	justify-self: end;
}

.edit-item.edit-mode {
	position: fixed;
	top: 15%;
	left: 20%;
	z-index: 600;
	min-width: 450px;
}

.edit-mode {
	position: relative;
}
</style>