<template>
  <div
    :class="{'hidden': trigger}"
    class="mt-1 fixed w-full flex justify-center z-50"
  >
    <div
      class="border-t-4 border-theme-30 bg-theme-29 rounded-b text-teal-900  px-4 py-3 mb-3 shadow-md"
      role="alert"
    >
      <div class="flex">
        <div class="py-1">
          <svg
            class="fill-current h-6 w-6 text-theme-30 mr-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
            />
          </svg>
        </div>
        <div>
          <p class="font-bold">{{ data.title }}</p>
          <p class="text-sm">{{ data.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    computed:{
    alertInfo(){
      return this.$store.state.alert
    }
  },
  watch: {
    alertInfo(data){
      this.alert(data)
    }
  },
  data(){
    return{
      trigger: true,
      data: {}
    }
  },
  methods:{
    alert(data){
      this.data = data
      this.trigger = false
      setTimeout(() => {
        this.trigger = true
      },1000)
    }
  },
}
</script>
