import qs from "qs"
import axios from "axios"
const env = window.environment

export default {
	async uploadFile(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		await axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(async res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			await axios.request({
				url: "api/admin/supplier-groups/upload/files",
				method: "post",
				baseURL: context.rootState.baseUrl,
				data: payload,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
			})
				.then((response) => {
					context.commit('fileUploaded', true, {root: true})
					context.rootState.responseStatus = response.status
					context.rootState.responseStatus = 200
					context.rootState.uploadedFileUrl = response.data.data[0]
				})
				.catch((exception) => {
					context.rootState.responseStatus = 400
					console.error("uploadFile-error: ", exception)
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	uploadFileSftpMount(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			let url = context.rootState.baseUrl + context.rootState.API.uploadFile
			return axios.post(url, payload.file, {
					headers: {
						'Authorization': "bearer " + context.rootState.token,
					}
				}
			).then(() => context.commit("setSftpMountUploaded", {type: payload.type, value: true}, {root: true}))
		}).catch(() => {
			context.commit('displayErrorAlert', true, {root: true})
			context.commit('displayErrorAlertText', "Session is expired", {root: true})
		})
	},
	async getProxyFile(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		await axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(async res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})

			await axios.request({
				url: "api/admin/supplier-groups/" + payload.supplierGroup + "/branding/" + payload.file,
				method: "get",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
			})
				.then((response) => {
					context.commit('setTempFile', response.data.data, {root: true})
					context.rootState.responseStatus = 200
				})
				.catch((exception) => {
					context.rootState.responseStatus = 400
					console.error("getProxyFile-error: ", exception)
				})
		}).catch(() => {
			context.commit('displayErrorAlert', true, {root: true})
			context.commit('displayErrorAlertText', "Session is expired", {root: true})
		})
	},
}