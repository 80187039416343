import axios from "axios"

const qs = require('qs')
const env = window.environment
const actions = {
	updateToken(context) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.state.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.state.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		)
			.then(res => {
				context.commit('setToken', res.data.access_token)
				context.commit('setRefreshToken', res.data.refresh_token)
			})
			.catch(() => {
				context.commit('displayErrorAlert', true)
				context.commit('displayErrorAlertText', "Session is expired")
			})
	},
	getCurrentUser(context) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.state.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.state.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token)
			context.commit('setRefreshToken', res.data.refresh_token)
			axios.request({
				url: "api/users/user/current",
				method: "get",
				baseURL: context.state.baseUrl,
				headers: {
					Authorization: `Bearer ${context.state.token}`,
				},
			})
				.then((response) => {
					context.state.currentUser = response.data.data
					context.state.responseStatus = 200
				})
				.catch((exception) => {
					context.state.responseStatus = 400
					console.error("fetch-CurrentUser-error: ", exception)
				})
		}).catch(() => {
			context.commit('displayErrorAlert', true)
			context.commit('displayErrorAlertText', "Session is expired")
		})
	},
	async setFavIcon(context) {
		await axios.get(context.state.baseUrl + context.state.API.getFavIcon, {
			headers: {
				Authorization: `Bearer ${context.rootState.token}`
			},
		}).then(response => {
			document.getElementById('favicon').setAttribute('href', response.data)
		})
	},
	reprocessXml(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.state.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.state.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token)
			context.commit('setRefreshToken', res.data.refresh_token)

			let url = context.state.baseUrl + context.state.API.reprocessXml
			return axios.post(url, payload, {
					headers: {
						'Authorization': "bearer " + context.state.token,
					}
				}
			).then(() => {
				context.commit("toggleAlert", {
					title: "Reprocess XML",
					text: "XML successfully reprocessed",
				})
			}).catch(() => context.commit('displayErrorAlert', true))
		}).catch(() => {
			context.commit('displayErrorAlert', true)
			context.commit('displayErrorAlertText', "Session is expired")
		})
	}
}
export default actions
