<template>
	<pop-up-window
		:show="showDialog"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>{{ titleDialog }}</template>
		<template #content>
			<div class="file-container">
				<div :class="showDescription? 'flex-item' : ''">
					<div style="margin-bottom: 10px">
						<label class="font-bold form-label">Language:</label>
						<Dropdown
							@update-value="updateSelectedLanguage"
							:selected="selectedLang"
							:data="langCode"
						/>
					</div>
					<FileUpload :isLegalEntity="isLegalEntity"
								@update-value="changeFileValue"
								:accept="fileType"
								id="addOverlayFileUpload"
								title="Choose File: *"
								:invalid="fileInvalid"
								:data="fileType.includes('image') ? imageValue : selectedFile"/>
					<span v-if="fileInvalid" class="text-primary-3">Select an Overlay file</span>
				</div>

				<div v-if="showDescription" class="flex-item">
					<label class="font-bold form-label">
						Description:
					</label>
					<input
						class="form-control"
						style="height: 40px"
						v-model.trim="description"
					/>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn  shadow-md mr-2 " @click="add">
						Add
					</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">
						Cancel
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../PopUp.vue";
import FileUpload from "../FileUpload.vue"
import Dropdown from "../Dropdown.vue";

export default {
	components: {
		PopUpWindow,
		FileUpload,
		Dropdown,
	},
	props: {
		fileType: {
			type: String,
			required: false,
			default: '.pdf'
		},
		position: {
			type: Object,
			required: false,
			default: null
		},
		showDescription: {
			type: Boolean,
			required: false,
			default: false
		},
		titleDialog: String,
		showDialog: Boolean,
		langCode: Array,
		isLegalEntity: Boolean,
	},
	data() {
		return {
			selectedFile: '',
			imageValue: '', // if file is an image
			file: "",
			selectedLang: "AA",
			fileUrl: "",
			fileInvalid: false,
			description: ""
		};
	},
	computed: {
		getFileResponse() {
			return this.$store.state.tempFile
		},
	},
	watch: {
		getFileResponse(data) {
			this.fileUrl = data
		},
	},
	emits: ['AddFile', 'cancelAddFile'],
	methods: {
		changeFileValue(event) {
			if (this.fileType.includes("image")) {
				this.imageValue = event.value;
				this.file = event.value
				const _URL = window.URL || window.webkitURL
				let file, img
				let that = this
				if ((file = event.files[0])) {
					img = new Image();
					img.onload = function () {
						that.width = this.width
						that.height = this.height
					};
					img.onerror = function () {
						alert("not a valid file: " + file.type)
					};
					img.src = _URL.createObjectURL(file)
				}
			} else {
				this.selectedFile = event.value
				this.file = event.files[0]
				this.validate()
			}
		},
		updateSelectedLanguage(data) {
			if (data) this.selectedLang = this.langCode[data].name
		},
		close() {
			this.fileInvalid = false
			this.file = ''
			this.selectedFile = ''
			this.selectedLang = "AA"
			this.imageValue = ''
			this.description = ''
			this.$emit("cancelAddFile")
		},
		async add() {
			let data
			let hasErrors
			if (this.fileType.includes("image")) {
				hasErrors = this.validateImage()
				data = {}
				if (hasErrors === false) {
					data[this.selectedLang] = {
						img: this.imageValue,
						width: this.width,
						height: this.height,
						url: this.$store.state.uploadedFileUrl
					}

					let width = 0;
					let height = 0;
					for (const item in data) {
						if ((data[item].width) && data[item].height) {
							if (this.position !== null && !this.position.width) {
								width = data[item].width
								height = data[item].height
								this.$store.commit('setQrImageSize', {
									width: width,
									height: height
								})
							}
						}
					}
					this.$emit("AddFile", data)
				}
			} else {
				hasErrors = this.validate()
				if (!hasErrors) {
					if (this.$store.state.responseStatus === 200) {
						data = {
							file: this.selectedFile,
							supplierGroup: this.$store.state.activeSG.supplierGroupId,
						}
						await this.$store.dispatch("file/getProxyFile", data)
					}
					let formData = {};
					if (this.fileUrl !== '') {
						if (this.showDescription){
							formData[this.selectedLang] = {
								fileName: this.selectedFile,
								url: this.fileUrl,
								description: this.description
							}
						}
						else{
							formData[this.selectedLang] = {
								fileName: this.selectedFile,
								url: this.fileUrl,
							}
						}

					}
					this.$emit("AddFile", formData)
				}
			}
			if (hasErrors === false) {
				this.fileInvalid = false
				this.file = ''
				this.selectedFile = ''
				this.selectedLang = "AA"
				this.imageValue = ''
				this.description = ''
			}
		},
		validate() {
			let hasErrors = false
			this.fileInvalid = false
			if (!this.file) {
				this.fileInvalid = true
				hasErrors = true
			}
			return hasErrors
		},
		validateImage() {
			let hasErrors = false
			this.fileInvalid = false
			if (this.imageValue === "") {
				this.fileInvalid = true
				hasErrors = true
			}
			return hasErrors
		}
	},
};
</script>

<style>
.file-container {
	display: flex;
	flex-flow: row wrap;
	flex-basis: auto;
	gap: 15px
}
.flex-item {
	flex: 1;
}
</style>