<template>
  <pop-up-window
    :show="this.$store.state.displayAddLegalEntity"
    :showClose="false"
    @close="close"
    fixed
  >
    <template #header>Create legal entity</template>
    <template #content>
      <div class="flex flex-row">
        <div class="flex flex-col mr-12 w-1/2">
          <Field
            id="addLeEntityNumber"
            :hasError="entityNumberInvalid"
            @input="validate"
            title="Entity number: *"
            v-model="formData.entityNumber"
            type="text"
          />
          <span v-if="entityNumberInvalid" class="text-primary-3">Entity Number is required and must be unique</span>
          <Field
            id="addLeName"
            :hasError="nameInvalid"
            title="Name: *"
            @input="validate"
            v-model="formData.name"
            type="text"
          />
          <span v-if="nameInvalid" class="text-primary-3">Name is required</span>
        </div>
      </div>
      <div class="grid grid-rows-1 justify-items-end">
        <div>
          <button class="btn shadow-md mr-2" @click="add">Create</button>
          <button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
        </div>
      </div>
    </template>
  </pop-up-window>
</template>

<script>
import PopUpWindow from "../PopUp.vue";
import Field from "../Field.vue";
export default {
  name: "AddLegalEntity",
  components: {
    PopUpWindow,
    Field,
  },
  data() {
    return {
      entityNumberInvalid: false,
      nameInvalid: false,
      formData: {
        entityNumber: "",
        name: "",
      }
    };
  },
  emits:['trigger'],
  methods: {
    close() {
      this.initData()
      this.$store.state.displayAddLegalEntity = false;
    },
    initData(){
      this.entityNumberInvalid = false
      this.nameInvalid = false
      this.formData = {
        entityNumber: "",
        name: "",
      }
    },
    async add() {
      let hasErrors = this.validate();
      if (!hasErrors) {
        let payload = {
          body : this.formData,
          sgId: this.$store.state.activeSG.supplierGroupId
        }
        await this.$store.dispatch('AddLegalEntity', payload)
        this.$emit('trigger')
        this.close();
      }
    },
    validate() {
      let hasErrors = false;
      this.entityNumberInvalid = false
      this.nameInvalid = false
      if (this.formData.entityNumber === "") {
        hasErrors = true;
        this.entityNumberInvalid = true;
      }
      if(this.formData.entityNumber !== ""){
          let found = Object.values(this.$store.state.activeSG.supplierLegalEntities).find((le) => le.entityNumber === this.formData.entityNumber)
          if(found){
            hasErrors = true
            this.entityNumberInvalid = true
          }
      }
      if (this.formData.name === "") {
        hasErrors = true;
        this.nameInvalid = true;
      }
      return hasErrors;
    },
  },
};
</script>

<style scoped>
</style>
