<template>
	<div
		:class="{ 'brightness-95': this.$store.state.displayEditTacTemplate }"
		class="box shadow-none col-span-6 min-height-600"
	>
		<div
			class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5"
		>
			<div class="button-container">
				<button
					class="btn shadow-md bg-theme-1 text-white mr-2"
					@click="addNewTemplate">
					Add new template
				</button>

			</div>
			<div class="flex flex-row flex-nowrap" v-if="activeItem.templates">
				<div v-if="activeItem.templates.terms" class="flex flex-col w-full">
					<Accordion
						:key="item + index"
						v-for="(item, index) in activeItem.templates.terms"
						class="mb-5 border border-gray-300 p-1 box"
					>
						<template v-slot:title>
							<span class="font-semibold text-xl">{{ item.name }}</span>
						</template>
						<template v-slot:content>
							<div class="p-3 grid grid-cols-2">
								<div class="mr-2">
									<div>
										<div class="mb-2">
											<label for="viewResendDocType" class="font-bold">DocType:*</label>
										</div>

										<DropdownSelectAll
											v-if="renderComponent"
											id="viewResendDocType"
											:data="docTypes"
											:selected=item.docTypes
											:disabled=true
										/>

									</div>
									<div class="mb-3 flex">
										<label class="font-bold form-label mr-3 mb-0">Display:</label>
										<ToggleSlide
											class="w-min"
											:bool="item.display"
											:disabled="true"
											:renew-bool="true"
										/>
									</div>
									<div class="mb-3 flex">
										<label class="font-bold form-label mr-3 mb-0">Merge to document:</label>
										<ToggleSlide
											class="w-min"
											:bool="item.mergeToBillingDocument"
											:disabled="true"
											:renew-bool="true"
										/>
									</div>

								</div>
								<div class="ml-2">
									<div class="flex">
										<p class="font-bold form-label">T&amp;C file(s):*</p>
										<span v-if="copyDescription" class="ml-2 font-bold text-theme-1">
											Copied
										</span>
									</div>

									<div
										:key="item + item[itm] + index"
										v-for="(itm, index) in Object.keys(item.location)"
										class="flex flex-row mb-1"
									>
										<div class="flex flex-col w-2/3">
											<div class="flex flex-row input-group w-full">
												<div :id="'tacView' + itm" class="input-group-text">
													{{ itm }}
												</div>
												<input
													disabled
													type="text"
													class="form-control"
													:value="item.location[itm].fileName"
												/>
												<div class="input-group-text" @click="download(item.location[itm].url)" style="cursor: pointer; padding-bottom: 3px">
													<span class="material-icons" style="font-size: 20px">file_download</span>
												</div>
											</div>
										</div>
										<div class="flex flex-col w-1/3">
											<div class="input-group mb-1 w-full">
												<input
													disabled
													type="text"
													class="form-control"
													:value="item.location[itm].description"
												/>
												<div
													@click="copy(item.location[itm].description)"
													class="font-bold cursor-pointer input-group-text"
												>
													<font-awesome-icon icon="copy"/>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</template>
					</Accordion>
				</div>
				<p v-else>No terms and condition templates yet</p>
			</div>
			<p v-else>No terms and condition templates yet</p>
		</div>
	</div>
	<add-tac-template
		:is-legal-entity="isFor === 'legalEntity'"
		:show-dialog="showAddDialog"
		@cancel-template="showAddDialog = false"
		@add-template="showAddDialog = false"
	></add-tac-template>
</template>
<script>
import Accordion from "../../Accordion.vue"
import ToggleSlide from "../../ToggleSlide.vue"
import DropdownSelectAll from "../../DropdownSelectAll.vue"
import AddTacTemplate from "@/components/templates/t&c/AddTacTemplate.vue"

export default {
	name: "ViewTac",
	components: {
		AddTacTemplate,
		Accordion,
		ToggleSlide,
		DropdownSelectAll
	},
	props: {
		isFor: String,
	},

	watch: {
		"$store.state.activeSG": {
			handler(data) {
				if (this.isFor === "supplierGroup") {
					this.activeItem = data;
				}
				this.forceRerender()
			},
			deep: true,
		},
		"$store.state.activeLE": {
			handler(data) {
				if (this.isFor === "legalEntity") {
					this.activeItem = data;
				}
				this.forceRerender();
			},
			deep: true,
		},
	},
	data() {
		return {
			activeItem: {},
			copyDescription: false,
			renderComponent: true,
			docTypes: this.$store.state.docTypes,
			showAddDialog: false
		};
	},
	mounted() {
		this.initData();
	},
	methods: {
		addNewTemplate() {
			this.showAddDialog = true;
		},
		download(pdfPath){
			window.open(pdfPath)
		},
		forceRerender() {
			// Removing my-component from the DOM
			this.renderComponent = false;

			this.$nextTick(() => {
				// Adding the component back in
				this.renderComponent = true;
			});
		},
		copy(data) {
			navigator.clipboard.writeText(data);
			this.copyDescription = true;
			setTimeout(() => {
				this.copyDescription = false;
			}, 750);
		},
		initData() {
			if (this.isFor === "supplierGroup") {
				this.activeItem = this.$store.state.activeSG;
			}
			if (this.isFor === "legalEntity") {
				this.activeItem = this.$store.state.activeLE;
			}
		}
	}
}
</script>

<style scoped>
.button-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	justify-items: end;
	margin-bottom: 10px;
}
</style>