<template>
    <pop-up-window
          :show="this.$store.state.displayEditPass"
          :showClose="false"
          @close="close"
          class="doc-statuses-popup "
          fixed
    >
        <template #header>Edit Password</template>
        <template #content>
            <div class="flex flex-row flex-nowrap">
                <!--sFTP-->
                <div class="flex flex-col w-1/2 mr-12">
                    
                  <label class="font-bold" for="editFtpPassword"
                    >FTP password:</label
                  >
                  <div class="input-group mb-3">

                    <input
                      id="editFtpPassword"
                      type="text"
                      class="form-control"
                      @input="validate"
                      :class="{'border border-primary-3':ftpPasswordInvalid}"
                      v-model="ftpPassword"
                    />
                    <div
                      @click="generateFtp"
                      class="font-bold cursor-pointer input-group-text"
                    >
                      <font-awesome-icon icon="gears" />
                    </div>
                  </div>
                  <span v-if="ftpPasswordInvalid" class="text-primary-3">FTP password cannot be empty</span>
                </div>
            </div>
           
            <div class="grid grid-rows-1 justify-items-end">
                <div>
                    <button class="btn shadow-md mr-2" @click="showConfirmation = true">Update</button>
                    <button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </pop-up-window>
    <ConfirmDialog
    :show="showConfirmation"
    @close="closeConfirmation"
  />
</template>

<script>
import PopUpWindow from "../PopUp.vue";
import ConfirmDialog from "../ConfirmDialog.vue";

export default {
    name: "EditIngestion",
    components: {
        PopUpWindow,
        ConfirmDialog,
    },
    props: {
        ingestionData: Array,
    },
    computed: {
        activeSG(){
            return this.$store.state.activeSG
        },
    },
    watch: {
        activeSG(){
            this.initData()
        },
    },

    data() {
        return {
            showConfirmation: false,
            supplierGroup: this.$store.state.activeSG,
            ftpPassword: this.$store.state.activeSG.sender.sFTP.ftp_password,
            ftpPasswordInvalid: false,
            allCapsAlpha: [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"],
            allLowerAlpha: [..."abcdefghijklmnopqrstuvwxyz"],
            allUniqueChars: [..."@%~!#$()_+-[]{}:?"],
            allNumbers: [..."0123456789"],
        };
    },
    mounted() {

    },
    methods: {
        initData() {
            this.ftpPassword = this.$store.state.activeSG.sender.sFTP.ftp_password
            this.ftpPasswordInvalid = false;
        },
        generateFtp() {
            const allBase = [
                ...this.allCapsAlpha,
                ...this.allLowerAlpha,
                ...this.allUniqueChars,
                ...this.allNumbers,
            ];
            this.ftpPassword = [...Array(12)]
                  .map((i) => allBase[(Math.random() * allBase.length) | 0])
                  .join("");
        },
        close() {
            this.initData();
            this.$store.state.displayEditPass = false;
        },
        async closeConfirmation(result) {
            this.showConfirmation = false;

            if (result === "Yes") {
            // Perform password update logic here
            let hasErrors = this.validate();
            
            if (!hasErrors) {
                let payloadFTP = {
                sgId: this.supplierGroup.supplierGroupId,
                body: { newPassword: this.ftpPassword },
                };

                await this.$store.dispatch("EditFtpPassword", payloadFTP);

                if (this.$store.state.responseStatus === 200) {
                this.$store.commit("toggleAlert", {
                    title: "Edit Password",
                    text: "FTP Password successfully updated",
                });
                }

                this.close(); // Close the popup after successful update
            }
            }
        },
        validate() {
            this.ftpPasswordInvalid = false;
            let hasErrors = false;
            if (this.ftpPassword === "") {
                this.ftpPasswordInvalid = true
                hasErrors = true
            }

            return hasErrors;
        },
    },
};
</script>

<style scoped>
</style>
