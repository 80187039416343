<template>
	<pop-up-window
		:show="showDialog"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Add terms &amp; conditions template</template>
		<template #content>
			<div class="flex flex-row flex-nowrap">
				<div class="flex flex-col w-full">
					<div class="p-3 grid grid-cols-2 ">
						<div class="mr-2">
							<div class="mb-3">
								<label class="font-bold form-label">Name: *</label>
								<input
									id="addTacName"
									v-model="name"
									:class="{'border border-primary-3': nameInvalid}"
									type="text"
									class="form-control w-full"
									@focusout="name.trim() === '' ? nameInvalid = true: nameInvalid = false"
								/>
								<span v-if="nameInvalid" class="text-primary-3">Name is required</span>
							</div>

							<div>
								<div class="mb-2">
									<label class="font-bold">DocType:*</label>
								</div>
								<DropdownSelectAll
									v-if="renderComponent"
									id="resendDocType"
									@update-value="updateDocTypes"
									:data="docTypeOptions"
								/>
								<div>
									<span v-if="docTypesError" class="text-primary-3">
										Please, select at least one docType
									</span>
								</div>
							</div>
							<div class="mb-3 flex">
								<label class="font-bold form-label mr-3 mb-0">Display:</label>
								<ToggleSlide
									@update-value="updateBoolDisplay"
									class="w-min"
									:bool="boolDisplay"
								/>
							</div>
							<div class="mb-3 flex">
								<label class="font-bold form-label mr-3 mb-0">
									Merge to document:
								</label>
								<ToggleSlide
									@update-value="updateBoolMerge"
									class="w-min"
									:bool="boolMerge"
								/>
							</div>
						</div>

						<div class="ml-2">
							<div class="flex mb-1">
								<p class="font-bold form-label my-0">T&amp;C file(s):*</p>
								<button @click="displayAddTacFile"
										class="btn bg-theme-1 py-0 text-white shadow-md ml-2">
									Upload
								</button>
							</div>
							<div
								:key="index"
								v-for="(item, index) in Object.keys(locations)"
								class="flex flex-row mb-1"
							>
								<div class="flex flex-col w-2/3">
									<FileUpload
										:isLegalEntity="isLegalEntity"
										@update-value="changePdfLocation($event, item)"
										@delete-value="deleteFile($event, item)"
										accept=".pdf"
										:prefix="true"
										:prefixData="item"
										:delSuffix="true"
										:data="locations[item].fileName"
										:id="'templatesAdd' + item + locations[item].fileName"
									/>
								</div>
								<div class="flex flex-col w-1/3">
									<div class="input-group mb-1 w-full">
										<input
											type="text"
											class="form-control"
											:value="locations[item].description"
										/>
									</div>
								</div>
							</div>
							<span v-if="locationsInvalid" class="text-primary-3">At least 1 file is required</span>
						</div>
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn  shadow-md mr-2 " @click="add">
						Add Template
					</button>
					<button class="btn  shadow-md mr-2 nymus-orange color-white" @click="close">
						Cancel
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
	<add-file-to-template
		title-dialog="Add t&c text"
		:show-dialog="displayAddFile"
		:show-description="true"
		:isLegalEntity="this.isLegalEntity"
		@add-file="addFile"
		@cancel-add-file="locationsInvalid = false; this.displayAddFile = false"
		:langCode="langCode"
	></add-file-to-template>
</template>
<script>
import PopUpWindow from "../../PopUp.vue"
import FileUpload from "../../FileUpload.vue"
import ToggleSlide from "../../ToggleSlide.vue"
import DropdownSelectAll from "../../DropdownSelectAll.vue"
import AddFileToTemplate from "@/components/templates/AddFileToTemplate.vue"
import langCodes from "@/langCodes";
import updateTemplate from "@/mixins/updateTemplates";

export default {
	name: "AddTacTemplate",
	components: {
		AddFileToTemplate,
		PopUpWindow,
		ToggleSlide,
		FileUpload,
		DropdownSelectAll
	},
	props: {
		isLegalEntity: Boolean,
		showDialog: Boolean
	},
	data() {
		return {
			name: '',
			docTypes: [],
			locations: [],
			selectedLang: "",
			fileUrl: "",
			boolDisplay: false,
			boolMerge: false,
			locationsInvalid: false,
			nameInvalid: false,
			docTypesError: false,
			docTypeOptions: this.$store.state.docTypes,
			renderComponent: true,
			displayAddFile: false,
			langCode: langCodes,
			supplierGroup: this.$store.state.activeSG
		}
	},
	computed: {
		getFileResponse() {
			return this.$store.state.tempFile
		},
		getAddedFileResponse() {
			return this.$store.state.addedTemplateTacFile
		},
	},
	watch: {
		getFileResponse(data) {
			this.fileUrl = data
		},
		getAddedFileResponse(data) {
			this.addFile(data)
		},
		"$store.state.activeSG": {
			deep: true,
			handler(){
				this.supplierGroup = this.$store.state.activeSG
			}
		}
	},
	emits: ['add-template', 'cancel-template'],
	methods: {
		updateDocTypes(docTypes) {
			let foundArr = []
			for (let index of docTypes) {
				let found = this.docTypeOptions[index].name
				foundArr.push(found)
			}
			this.docTypes = foundArr
			this.docTypesError = false
		},
		initData() {
			this.name = ''
			this.docTypes = []
			this.locations = []
			this.selectedLang = ""
			this.fileUrl = ""
			this.boolPayment = false
			this.boolPrivacy = false
			this.boolDisplay = false
			this.boolMerge = false
			this.locationsInvalid = false
			this.nameInvalid = false
			this.docTypesError = false
		},
		deleteFile(event, item) {
			delete this.locations[item]
		},
		addFile(data) {
			this.locations = {
				...this.locations,
				...data,
			}
			this.displayAddFile = false
		},
		async addToBucket(file) {
			if ((await this.$store.state.responseStatus) === 200) {
				let data = {
					file: file.name,
					supplierGroup: this.supplierGroup.supplierGroupId,
				}
				await this.$store.dispatch("file/getProxyFile", data)
			}
		},
		async changePdfLocation(event, item) {
			await this.addToBucket(event.files[0])
			this.locations[item].fileName = event.value
			this.locations[item].url = this.fileUrl
		},
		displayAddTacFile() {
			this.currentBools = []
			this.currentBools.push(this.boolPayment, this.boolPrivacy, this.boolDisplay, this.boolMerge)
			this.$store.state.parentTacFile = "Template"
			this.displayAddFile = true
		},
		updateBoolDisplay(data) {
			this.boolDisplay = data
		},
		updateBoolMerge(data) {
			this.boolMerge = data
		},
		close() {
			this.initData()
			this.nameInvalid = false
			this.locationsInvalid = false
			this.$emit("cancel-template")
		},
		async add() {
			let hasErrors = this.validate()
			let terms = {terms: []}

			terms.terms = this.supplierGroup.templates.terms

			if (this.isLegalEntity){
				terms.terms = this.supplierGroup.supplierLegalEntities[this.$store.state.activeLE.entityNumber].templates?.terms
			}
			if (!hasErrors) {
				if (terms.terms === undefined)terms.terms = []

				let data = {
					name: this.name,
					display: this.boolDisplay,
					mergeToBillingDocument: this.boolMerge,
					location: this.locations,
					docTypes: this.docTypes,
					version: 'v1'
				}

				terms.terms.push(data)
				await updateTemplate(terms, this.isLegalEntity ? 'legalEntity': 'supplierGroup', 'terms-template', 'Terms and condition')
				this.$emit("add-template", terms)
				this.close()
			}
		},
		validate() {
			this.locationsInvalid = false
			this.nameInvalid = false
			let hasErrors = false
			if (Object.values(this.locations).length === 0) {
				this.locationsInvalid = true
				hasErrors = true
			}
			if (this.name === '') {
				hasErrors = true
				this.nameInvalid = true
			}
			if (this.docTypes.length === 0) {
				hasErrors = true
				this.docTypesError = true
			}
			return hasErrors
		}
	}
}
</script>