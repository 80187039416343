import qs from "qs";
import axios from "axios";

const env = window.environment
export default {
	startGlobalJobs(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken,
			body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
				context.commit('setToken', res.data.access_token, {root: true})
				context.commit('setRefreshToken', res.data.refresh_token, {root: true})
				axios.request({
						url: "api/scheduler/jobs/global/start/" + payload,
						method: "post",
						baseURL: context.rootState.baseUrl,
						headers: {
							Authorization: `Bearer ${context.rootState.token}`,
						},
					})
					.then(() => {
						context.rootState.responseStatus = 200;
						context.dispatch("fetchSchedule", null)
					})
					.catch((exception) => {
						context.rootState.responseStatus = 400
						console.error("globalJobs-error: ", exception)
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	stopGlobalJobs(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken,
			body,
			{
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true});
			context.commit('setRefreshToken', res.data.refresh_token, {root: true});

			axios.request({
				url: "api/scheduler/jobs/global/stop/" + payload,
				method: "post",
				baseURL: context.rootState.baseUrl,
				headers: {
					Authorization: `Bearer ${context.rootState.token}`,
				},
			})
				.then(() => {
					context.rootState.responseStatus = 200
					context.dispatch("fetchSchedule", null)
				})
				.catch((exception) => {
					context.rootState.responseStatus = 400
					console.log("globalJobs-error: ", exception)
				})
		})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	toggleSgJob(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken,
			body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		)
			.then(res => {
				context.commit('setToken', res.data.access_token, {root: true})
				context.commit('setRefreshToken', res.data.refresh_token, {root: true})

				let url = context.rootState.baseUrl + 'api/scheduler/'
				if (payload.status) url = url + 'stop/' + payload.sgId + '/type/' + payload.reportType
				else url = url + 'start/' + payload.sgId + '/type/' + payload.reportType

				return axios.get(url, {
						headers: {
							'Authorization': "bearer " + context.rootState.token,
						}
					}
				)
					.then(() => {
						context.dispatch("fetchSchedule", null);
					})
					.catch(() => {
						context.commit('displayErrorAlert', true, {root: true})
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},

	printSpeosSchedule(context) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken,
			body,
			{
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
				context.commit('setToken', res.data.access_token, {root: true});
				context.commit('setRefreshToken', res.data.refresh_token, {root: true});

				axios.request({
						url: "api/printing/process",
						method: "post",
						baseURL: context.rootState.baseUrl,
						headers: {
							Authorization: `Bearer ${context.rootState.token}`,
						},
					})
					.then(() => {
						//NOTHING || All actions take place in backend
						context.rootState.responseStatus = 200
					})
					.catch((exception) => {
						context.rootState.responseStatus = 400
						console.error("printSpeosSchedule-error: ", exception)
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	printSpeosFeedback(context) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken,
			body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
				context.commit('setToken', res.data.access_token, {root: true});
				context.commit('setRefreshToken', res.data.refresh_token, {root: true});

				axios.request({
						url: "api/admin/speos/feedback",
						method: "post",
						baseURL: context.rootState.baseUrl,
						headers: {
							Authorization: `Bearer ${context.rootState.token}`,
						},
					})
					.then(() => {
						//NOTHING || All actions take place in backend
						context.rootState.responseStatus = 200
					})
					.catch((exception) => {
						context.rootState.responseStatus = 400
						console.error("printSpeosFeedback-error: ", exception)
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	easypostFeedback(context){
		axios.post(context.rootState.baseUrl + "api/printing/easypost-process-feedback", {}, {
			headers: {
				Authorization: `Bearer ${context.rootState.token}`,
			},
		}).catch((exception) => {
			console.error("easypostFeedback-error: ", exception)
		})
	},
	printSpeosFeedbackReprocess(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken,
			body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
				context.commit('setToken', res.data.access_token, {root: true});
				context.commit('setRefreshToken', res.data.refresh_token, {root: true});

				axios.request({
						url: "api/admin/speos/feedback/reprocess",
						method: "post",
						baseURL: context.rootState.baseUrl,
						headers: {
							Authorization: `Bearer ${context.rootState.token}`,
						},
						data: payload.body
					})
					.then(() => {
						//NOTHING || All actions take place in backend
						context.rootState.responseStatus = 200
					})
					.catch((exception) => {
						context.rootState.responseStatus = 400
						console.error("printSpeosFeedbackReprocess-error: ", exception)
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},

	startQuickActionIngestion(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken,
			body,
			{
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
				context.commit('setToken', res.data.access_token, {root: true});
				context.commit('setRefreshToken', res.data.refresh_token, {root: true});
				axios.request({
						url: "api/ingestion/process/" + payload,
						method: "get",
						baseURL: context.rootState.baseUrl,
						headers: {
							Authorization: `Bearer ${context.rootState.token}`,
						},
					})
					.then(() => {
						//NOTHING || All actions take place in backend
						context.rootState.responseStatus = 200
					})
					.catch((exception) => {
						context.rootState.responseStatus = 400
						console.error("startQAIngestion-error: ", exception)
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	startQuickActionPrinting(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken,
			body,
			{
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
				context.commit('setToken', res.data.access_token, {root: true});
				context.commit('setRefreshToken', res.data.refresh_token, {root: true});
				axios.request({
						url: "api/printing/" + payload + "/prepare",
						method: "get",
						baseURL: context.rootState.baseUrl,
						headers: {
							Authorization: `Bearer ${context.rootState.token}`,
						},
					})
					.then(() => {
						//NOTHING || All actions take place in backend
						context.rootState.responseStatus = 200
					})
					.catch((exception) => {
						context.rootState.responseStatus = 400
						console.error("startQAIngestion-error: ", exception)
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	startIngestionSchedule(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken,
			body,
			{
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
				context.commit('setToken', res.data.access_token);
				context.commit('setRefreshToken', res.data.refresh_token, {root: true});
				axios.request({
						url: "api/scheduler/start/" + payload + "/type/INGESTION",
						method: "get",
						baseURL: context.rootState.baseUrl,
						headers: {
							Authorization: `Bearer ${context.rootState.token}`,
						},
					})
					.then(() => {
						context.rootState.responseStatus = 200
						context.dispatch('fetchSchedule', null)

						context.commit("setIngestionStatus" , {type : "start", sg : payload}, {root: true})
					})
					.catch((exception) => {
						context.rootState.responseStatus = 400
						console.error("fetchSchedule-error: ", exception)
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	stopIngestionSchedule(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		axios.post(context.rootState.API.keycloakToken,
			body,
			{
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
				context.commit('setToken', res.data.access_token, {root: true});
				context.commit('setRefreshToken', res.data.refresh_token, {root: true});
				axios.request({
						url: "api/scheduler/stop/" + payload + "/type/INGESTION",
						method: "get",
						baseURL: context.rootState.baseUrl,
						headers: {
							Authorization: `Bearer ${context.rootState.token}`,
						},
					})
					.then(() => {
						context.rootState.responseStatus = 200
						context.dispatch('fetchSchedule', null)
						context.commit("setIngestionStatus" , {type : "stop", sg : payload}, {root: true})
					})
					.catch((exception) => {
						context.rootState.responseStatus = 400
						console.log("fetchSchedule-error: ", exception);
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	stopPrintSchedule(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken,
			body,
			{
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
				context.commit('setToken', res.data.access_token, {root: true})
				context.commit('setRefreshToken', res.data.refresh_token, {root: true})

				axios.request({
						url: context.rootState.baseUrl + "api/scheduler/stop/" + payload + "/type/PRINT",
						method: "get",
						baseURL: context.baseUrl,
						headers: {
							Authorization: `Bearer ${context.rootState.token}`,
						},
					})
					.then(() => {
						context.responseStatus = 200
						context.dispatch('fetchSchedule', null)
						context.commit('overviewTableDataUpdated', true, {root: true})
					})
					.catch((exception) => {
						context.responseStatus = 400
						console.error("fetchSchedule-error: ", exception);
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},

	triggerReport(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken,
			body,
			{
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
				context.commit('setToken', res.data.access_token, {root: true});
				context.commit('setRefreshToken', res.data.refresh_token, {root: true});

				axios.request({
						url: "api/admin/supplier-groups/" + payload.sgId + "/reports/" + payload.type + "/trigger",
						method: "post",
						baseURL: context.rootState.baseUrl,
						headers: {
							Authorization: `Bearer ${context.rootState.token}`,
						},
					})
					.then((response) => {
						//NOTHING || All actions take place in backend
						context.rootState.responseStatus = 200
					})
					.catch((exception) => {
						context.rootState.responseStatus = 400
						console.error("triggerReport-error: ", exception)
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},

	twikeyMandateSchedule(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken,
			body,
			{
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
				context.commit('setToken', res.data.access_token, {root: true});
				context.commit('setRefreshToken', res.data.refresh_token, {root: true});

				axios.request({
						url: "api/admin/mandates-feed",
						method: payload,
						baseURL: context.rootState.baseUrl,
						headers: {
							Authorization: `Bearer ${context.rootState.token}`,
						},
					})
					.then(() => {
						context.rootState.responseStatus = 200
						//NOTHING || All actions take place in backend
					})
					.catch((exception) => {
						context.rootState.responseStatus = 400
						console.error("twikeyMandate-error: ", exception);
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	twikeyTransactionSchedule(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken,
			body,
			{
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
				context.commit('setToken', res.data.access_token, {root: true});
				context.commit('setRefreshToken', res.data.refresh_token, {root: true});

				axios.request({
						url: "api/admin/transactions-feed",
						method: payload,
						baseURL: context.rootState.baseUrl,
						headers: {
							Authorization: `Bearer ${context.rootState.token}`,
						},
					})
					.then(() => {
						context.rootState.responseStatus = 200
						//NOTHING || All actions take place in backend
					})
					.catch((exception) => {
						context.rootState.responseStatus = 400
						console.error("twikeyTransaction-error: ", exception);
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	twikeyInvoiceFeedPolling(context, payload) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken,
			body,
			{
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
				context.commit('setToken', res.data.access_token, {root: true});
				context.commit('setRefreshToken', res.data.refresh_token, {root: true});
				axios.request({
						url: "api/admin/invoices-feed",
						method: payload,
						baseURL: context.rootState.baseUrl,
						headers: {
							Authorization: `Bearer ${context.rootState.token}`,
						},
					})
					.then(() => {
						context.rootState.responseStatus = 200
						//NOTHING || All actions take place in backend
					})
					.catch((exception) => {
						context.rootState.responseStatus = 400
						console.error("twikeyMandate-error: ", exception);
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},

	
	fetchSchedule(context) {
		let dataToSend = {
			client_id: env.data.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend);
		axios.post(context.rootState.API.keycloakToken,
			body,
			{
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		)
			.then(res => {
				context.commit('setToken', res.data.access_token, {root: true})
				context.commit('setRefreshToken', res.data.refresh_token, {root:true})
				axios.request({
						url: context.rootState.schedulerUrl,
						method: "get",
						baseURL: context.rootState.baseUrl,
						headers: {
							Authorization: `Bearer ${context.rootState.token}`,
						},
					})
					.then((response) => {
						const res = response.data.data
						context.rootState.responseStatus = 200
						context.commit("overviewTableDataUpdated", true, {root: true})
						context.commit('setSchedule', res, {root: true});
					})
					.catch((exception) => {
						context.rootState.responseStatus = 400
						console.error("fetchSchedule-error: ", exception);
					})
			})
			.catch(() => {
				context.commit('displayErrorAlert', true, {root: true})
				context.commit('displayErrorAlertText', "Session is expired", {root: true})
			})
	},
	pauseApiIngestion(context, payload){
		const url = context.rootState.baseUrl + context.rootState.API.pauseApiIngestion.replace(':supplierGroupId', payload.supplierGroupId)
		axios.post(url, {pauseIngestion: payload.pauseIngestion}, {
			headers: {
				Authorization: `Bearer ${context.rootState.token}`
			}
		}).then(response => {
			const indexSupplierGroup = context.rootState.supplierGroups.findIndex(s => s.supplierGroupId === payload.supplierGroupId)
			context.rootState.supplierGroups[indexSupplierGroup].apiConfig.pauseIngestion = response.data.data
			context.rootState.pauseApiIngestionChanged = {
				value: response.data.data,
				sg: payload.supplierGroupId
			}
		})
			.catch(err => {
				context.rootState.responseStatus = 400
				console.error("pauseApiIngestion: " + err)
			})
	},
	async pauseAllApiIngestion(context, payload) {
		const url = context.rootState.baseUrl + context.rootState.API.pauseAllApiIngestion
		await axios.post(url, payload, {
			headers: {
				Authorization: `Bearer ${context.rootState.token}`
			}
		}).then(resp => console.log(resp))
			.catch(err => {
				context.rootState.responseStatus = 400
				console.error("pauseAllApiIngestion: " + err)
				context.commit('displayErrorAlert', true, {root: true})
			})
	}
}