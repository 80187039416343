<template>
	<pop-up-window
		:show="
      this.$store.state.displayEditReporting &&
      !this.$store.state.displayAddReceiverMail"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit Reporting</template>
		<template #content>
			<div class="">
				<div class=" mr-12">
					<div class="mb-3 flex">
						<label class="font-bold text-lg form-label mb-0 mr-2">
							Reporting:
						</label>
						<ToggleSlide
							class="w-min"
							:key="ftpBool"
							:bool="ftpBool"
							@update-value="changeFtpBoolValue"
						/>
					</div>
					<div class="ml-6" v-if="ftpBool">
						<Accordion
							:key="item"
							v-for="item in types"
							:class="{ 'border-primary-3': ftpFoldersInvalid[item] }"
							class="mb-5 border p-1 box border-gray-300"
						>
							<template v-slot:title>
								<span class="font-semibold text-lg">{{ tabName(item) }}</span>
							</template>
							<template v-slot:content>
								<div class="m-3 reporting-item">
									<div>
										<div>
											<Field
												v-if="formData.reportingConfig.ftpFolders"
												:id="'editReportingFtpFolder' + item"
												title="FTP folder: "
												type="text"
												placeholder="/path/to"
												:hasError="ftpFoldersInvalid[item]"
												@input="validate"
												v-model="formData.reportingConfig.ftpFolders[item]"
											/>
											<span v-if="ftpFoldersInvalid[item]" class="text-primary-3">
												folder path format isn't correct
											</span>
										</div>

										<div class="mb-3">
											<p class="font-bold form-label mr-2">Receiver mails:</p>
											<button
												@click="displayAddReceiverMail(item)"
												class="btn bg-theme-1 py-0 text-white shadow-md ml-2"
											>
												Add
											</button>
											<div v-if="formData.reportingConfig.receiverEmails">
												<div v-if="formData.reportingConfig.receiverEmails[item]">
													<div
														:key="'edit' + item + itm + index"
														v-for="(itm, index) of Object.keys( formData.reportingConfig.receiverEmails[item])"
														class="flex flex-row input-group w-full mb-2"
													>
														<div class="input-group-text">
															{{ formData.reportingConfig.receiverEmails[item][itm] }}
														</div>
														<input
															type="text"
															:name="itm"
															class="form-control"
															@change=" updateReceiverEmail($event.target.name,  $event.target.value, item)"
															:value="itm"
														/>
														<div
															@click="deleteReceiverEmail(item, itm)"
															class="font-bold cursor-pointer input-group-text"
														>
															<font-awesome-icon icon="trash-can"/>
														</div>
													</div>
												</div>
											</div>

											<p v-if="receiverEmailsInvalid[item]" class="text-primary-3">
												ftpFolder or receiverMail must have a value
											</p>
										</div>
									</div>
									<div class="mb-3 flex" v-if="isFor === 'supplierGroup'">
										<label class="font-bold form-label mb-0 mr-2">
											Send as Xlsx:
										</label>
										<ToggleSlide
											class="w-min"
											:key="formData.reportingConfig.generateXlsx[item]"
											:bool="formData.reportingConfig.generateXlsx[item]"
											@update-value="changeGenXlsx($event, item)"
										/>
									</div>
								</div>
							</template>
						</Accordion>
					</div>
					<div class="mb-3 flex">
						<p class="font-bold text-lg form-label mb-0 mr-2">
							Admin e-mails:
						</p>

						<ToggleSlide
							class="w-min"
							id="editReportingAdminEmails"
							:key="adminEmailsBool"
							:bool="adminEmailsBool"
							@update-value="changeAdminEmailsBoolValue"
						/>
					</div>

					<div v-if="adminEmailsBool">
						<div>
							<button
								@click="displayAddReceiverMail('adminEmails')"
								class="btn bg-theme-1 py-0 text-white shadow-md ml-2"
							>
								Add
							</button>
						</div>

						<p class="font-bold form-label mr-2"></p>

						<div v-if="this.formData.reportingConfig.adminEmails" class="ml-2 w-2/4">
							<div
								v-for="(item, index) of Object.keys(this.formData.reportingConfig.adminEmails)"
								:key="'editAdminEmail' + index"
								class="flex flex-row input-group w-full mb-2"
							>
								<div class="input-group-text">
									{{ this.formData.reportingConfig.adminEmails[item] }}
								</div>
								<input
									type="text"
									:name="item"
									class="form-control"
									@change=" updateReceiverEmail($event.target.name,  $event.target.value, 'adminEmails')"
									:value="item"
								/>
								<div
									@click="deleteReceiverEmail('adminEmails', item)"
									class="font-bold cursor-pointer input-group-text"
								>
									<font-awesome-icon icon="trash-can"/>
								</div>

							</div>
						</div>
					</div>
				</div>
				<div class="flex flex-col w-1/2"></div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="update">Update</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
	<AddReceiverMail @add-receiverMail="addReceiverMail" :languages="languages"/>
</template>

<script>
import PopUpWindow from "../PopUp.vue";
import Field from "../Field.vue";
import AddReceiverMail from "../AddSupplierGroup/AddReceiverMail.vue";
import Accordion from "../Accordion.vue";
import ToggleSlide from "../ToggleSlide.vue";

export default {
	name: "EditReporting",
	components: {
		PopUpWindow,
		AddReceiverMail,
		Field,
		Accordion,
		ToggleSlide,
	},
	props: {
		languages: Array,
		isFor: String,
	},
	data() {
		return {
			types: [
				"MAIL_ERRORS",
				"CHANNEL_DETAIL",
				"CHANNEL_ARCHIVED_DETAIL",
				"DOCUMENTS_HELD",
				"TRANSACTIONS",
				"MANDATES_OVERVIEW",
				"MANDATES_CHOICES",
				"CHANNEL_CHOICE",
				"POST_DROP",
				"SG_SPECIFIC_ERRORS",
				"UBL_STATUS_REPORT",
			],
			currentType: "",
			ftpBool: false,
			adminEmailsBool: false,
			formData: {
				reportingConfig: {
					ftpFolders: {},
					receiverEmails: {},
					generateXlsx: {},
					adminEmails: {}
				},
			},
			ftpFoldersInvalid: {},
			receiverEmailsInvalid: {},
		};
	},

	mounted() {
		this.setFormData();
		this.initData();
	},

	computed: {
		activeSG() {
			return this.$store.state.activeSG
		},
		activeLE() {
			return this.$store.state.activeLE
		},
	},
	watch: {
		activeSG() {
			this.initData()
		},
		activeLE() {
			this.initData()
		}
	},
	methods: {
		tabName(item) {
			let name = item;
			if (item === 'SG_SPECIFIC_ERRORS') {
				name = "GENERAL_REPORT"
			}
			return name
		},
		setFormData() {
			this.types.forEach(type => {
				this.formData.reportingConfig.ftpFolders[type] = "";
				this.formData.reportingConfig.receiverEmails[type] = null;
				this.formData.reportingConfig.generateXlsx[type] = false;
			})
		},
		initData() {
			if (this.formData.reportingConfig !== null) {
				this.types.forEach((type) => {
					if (this.formData.reportingConfig.ftpFolders) {
						this.formData.reportingConfig.ftpFolders[type] = "";
					} else {
						this.formData.reportingConfig.ftpFolders = {}
						this.formData.reportingConfig.ftpFolders[type] = "";
					}
					if (this.formData.reportingConfig.receiverEmails) {
						this.formData.reportingConfig.receiverEmails[type] = null;
					} else {
						this.formData.reportingConfig.receiverEmails = {};
						this.formData.reportingConfig.receiverEmails[type] = null;
					}
					if (this.formData.reportingConfig.generateXlsx) {
						this.formData.reportingConfig.generateXlsx[type] = false;
					} else {
						this.formData.reportingConfig.generateXlsx = {};
						this.formData.reportingConfig.generateXlsx[type] = false;
					}
				});
				this.formData.reportingConfig.adminEmails = {};
			} else {

				this.formData.reportingConfig = {
					ftpFolders: {},
					receiverEmails: {},
					generateXlsx: {},
					adminEmails: {}
				};
				this.types.forEach((type) => {
					if (this.formData.reportingConfig.ftpFolders) {
						this.formData.reportingConfig.ftpFolders[type] = "";
					} else {
						this.formData.reportingConfig.ftpFolders = {};
						this.formData.reportingConfig.ftpFolders[type] = "";
					}
					if (this.formData.reportingConfig.receiverEmails) {
						this.formData.reportingConfig.receiverEmails[type] = null;
					} else {
						this.formData.reportingConfig.receiverEmails = {}
						this.formData.reportingConfig.receiverEmails[type] = null;
					}
					if (this.formData.reportingConfig.generateXlsx) {
						this.formData.reportingConfig.generateXlsx[type] = false;
					} else {
						this.formData.reportingConfig.generateXlsx = {};
						this.formData.reportingConfig.generateXlsx[type] = false;
					}
				})
			}
			if (this.isFor === "supplierGroup") {
				this.ftpBool = !!(this.$store.state.activeSG.reportingConfig
					&& this.$store.state.activeSG.reportingConfig.receiverEmails);

				this.adminEmailsBool = !!(this.$store.state.activeSG.reportingConfig
					&& this.$store.state.activeSG.reportingConfig.adminEmails);

				if (this.formData.reportingConfig.adminEmails) {
					this.formData.reportingConfig.adminEmails = {}
					if (this.$store.state.activeSG.reportingConfig
						&& this.$store.state.activeSG.reportingConfig.adminEmails
					) {
						this.formData.reportingConfig.adminEmails = JSON.parse(JSON.stringify(this.$store.state.activeSG.reportingConfig.adminEmails))
					}
				} else this.formData.reportingConfig.adminEmails = {}

				this.types.forEach((type) => {
					{
						if (this.formData.reportingConfig.ftpFolders) {
							this.formData.reportingConfig.ftpFolders[type] = ''
							if (this.$store.state.activeSG.reportingConfig
								&& this.$store.state.activeSG.reportingConfig.ftpFolders
								&& this.$store.state.activeSG.reportingConfig.ftpFolders[type]) {
								this.formData.reportingConfig.ftpFolders[type] = JSON.parse(JSON.stringify(this.$store.state.activeSG.reportingConfig.ftpFolders[type]))
							}
						} else {
							this.formData.reportingConfig.ftpFolders = {}
							this.formData.reportingConfig.ftpFolders[type] = ''
						}
					}

					if (this.formData.reportingConfig.receiverEmails) {
						this.formData.reportingConfig.receiverEmails[type] = null
						if (this.$store.state.activeSG.reportingConfig
							&& this.$store.state.activeSG.reportingConfig.receiverEmails
							&& this.$store.state.activeSG.reportingConfig.receiverEmails[type]
						) {
							this.formData.reportingConfig.receiverEmails[type] = JSON.parse(JSON.stringify(this.$store.state.activeSG.reportingConfig.receiverEmails[type]))
						}
					} else {
						this.formData.reportingConfig.receiverEmails = {}
						this.formData.reportingConfig.receiverEmails[type] = null
					}

					if (this.$store.state.activeSG.reportingConfig
						&& this.$store.state.activeSG.reportingConfig.generateXlsx
						&& this.$store.state.activeSG.reportingConfig.generateXlsx[type]
					) {
						this.formData.reportingConfig.generateXlsx[type] = this.$store.state.activeSG.reportingConfig.generateXlsx[type]
					} else {
						this.formData.reportingConfig.generateXlsx[type] = false
					}
				})
			}

			if (this.isFor === "legalEntity") {

				this.ftpBool = !!(this.$store.state.activeLE.legalEntityConfig
					&& this.$store.state.activeLE.legalEntityConfig.reportingConfig
					&& this.$store.state.activeLE.legalEntityConfig.reportingConfig.receiverEmails);

				this.adminEmailsBool = !!(this.$store.state.activeLE.legalEntityConfig
					&& this.$store.state.activeLE.legalEntityConfig.reportingConfig
					&& this.$store.state.activeLE.legalEntityConfig.reportingConfig.adminEmails);

				if (this.formData.reportingConfig) {
					if (this.$store.state.activeLE.legalEntityConfig
						&& this.$store.state.activeLE.legalEntityConfig.reportingConfig
						&& this.$store.state.activeLE.legalEntityConfig.reportingConfig.adminEmails
					) {
						this.formData.reportingConfig.adminEmails = JSON.parse(JSON.stringify(this.$store.state.activeLE.legalEntityConfig.reportingConfig.adminEmails))
					} else this.formData.reportingConfig.adminEmails = {}
				} else {
					this.formData.reportingConfig.adminEmails = {}
				}

				this.types.forEach((type) => {
					if (this.formData.reportingConfig.ftpFolders) {
						this.formData.reportingConfig.ftpFolders[type] = '';
						if (this.$store.state.activeLE.legalEntityConfig
							&& this.$store.state.activeLE.legalEntityConfig.reportingConfig
							&& this.$store.state.activeLE.legalEntityConfig.reportingConfig.ftpFolders
							&& this.$store.state.activeLE.legalEntityConfig.reportingConfig.ftpFolders[type]
						) {
							this.formData.reportingConfig.ftpFolders[type] = JSON.parse(JSON.stringify(this.$store.state.activeLE.legalEntityConfig.reportingConfig.ftpFolders[type]))
						}
					} else {
						this.formData.reportingConfig.ftpFolders = {}
						this.formData.reportingConfig.ftpFolders[type] = "";
					}

					if (this.formData.reportingConfig.receiverEmails) {
						this.formData.reportingConfig.receiverEmails[type] = null;
						if (this.$store.state.activeLE.legalEntityConfig
							&& this.$store.state.activeLE.legalEntityConfig.reportingConfig
							&& this.$store.state.activeLE.legalEntityConfig.reportingConfig.receiverEmails
							&& this.$store.state.activeLE.legalEntityConfig.reportingConfig.receiverEmails[type]
						) {
							this.formData.reportingConfig.receiverEmails[type] = JSON.parse(JSON.stringify(this.$store.state.activeLE.legalEntityConfig.reportingConfig.receiverEmails[type]))
						}
					} else {
						this.formData.reportingConfig.receiverEmails = {};
						this.formData.reportingConfig.receiverEmails[type] = null;
					}

					this.formData.reportingConfig.generateXlsx[type] = false;

					if (this.$store.state.activeLE.legalEntityConfig
						&& this.$store.state.activeLE.legalEntityConfig.reportingConfig
						&& this.$store.state.activeLE.legalEntityConfig.reportingConfig.generateXlsx
						&& this.$store.state.activeLE.legalEntityConfig.reportingConfig.generateXlsx[type]

					) {
						this.formData.reportingConfig.generateXlsx[type] = JSON.parse(JSON.stringify(this.$store.state.activeLE.legalEntityConfig.reportingConfig.generateXlsx[type]))
					}
				});
			}
		},
		displayAddReceiverMail(item) {
			this.currentType = item;
			this.$store.state.displayAddReceiverMail = true;
		},
		addReceiverMail(data) {
			if (this.currentType === 'adminEmails') {
				if (!this.formData.reportingConfig[this.currentType]) {
					this.formData.reportingConfig[this.currentType] = {};
				}
				this.formData.reportingConfig[this.currentType][data.email] = data.language;
			} else {

				if (!this.formData.reportingConfig.receiverEmails[this.currentType]) {
					this.formData.reportingConfig.receiverEmails[this.currentType] = {};
				}
				this.formData.reportingConfig.receiverEmails[this.currentType][data.email] = data.language;
			}

			this.validate();
		},
		deleteReceiverEmail(type, item) {
			if (type === "adminEmails") {
				delete this.formData.reportingConfig[type][item];
			} else {
				delete this.formData.reportingConfig.receiverEmails[type][item];
			}
		},
		changeFtpBoolValue(data) {
			this.ftpBool = data;
		},

		changeAdminEmailsBoolValue(data) {
			this.adminEmailsBool = data;
		},
		changeGenXlsx(data, type) {
			this.formData.reportingConfig.generateXlsx[type] = data;
		},
		updateReceiverEmail(name, item, type) {
			if (type === 'adminEmails') {
				this.formData.reportingConfig[type][item] = this.formData.reportingConfig[type][name];
				delete this.formData.reportingConfig[type][name];
			} else {
				this.formData.reportingConfig.receiverEmails[type][item] = this.formData.reportingConfig.receiverEmails[type][name];
				delete this.formData.reportingConfig.receiverEmails[type][name];
			}
		},
		close() {
			this.initData();
			this.$store.state.displayEditReporting = false;
		},
		async update() {
			let hasErrors = this.validate();
			if (!hasErrors) {
				if (!this.ftpBool) {
					this.formData.reportingConfig.generateXlsx = null;
					this.formData.reportingConfig.receiverEmails = null;
					this.formData.reportingConfig.ftpFolders = null;
				}
				if (!this.adminEmailsBool || (Object.keys(this.formData.reportingConfig.adminEmails).length === 0)) {
					this.formData.reportingConfig.adminEmails = null;

				}
				if (this.formData.reportingConfig) {
					for (let type of this.types) {
						if (this.formData.reportingConfig.ftpFolders) {
							if (this.formData.reportingConfig.ftpFolders[type] === '') {
								delete this.formData.reportingConfig.ftpFolders[type]
								if (Object.keys(this.formData.reportingConfig.ftpFolders).length === 0) {
									delete this.formData.reportingConfig.ftpFolders
								}
							}
						}

						if (this.formData.reportingConfig.receiverEmails) {
							if (this.formData.reportingConfig.receiverEmails[type] ? Object.keys(this.formData.reportingConfig.receiverEmails[type]).length === 0 : false || !this.formData.reportingConfig.receiverEmails[type]) {
								delete this.formData.reportingConfig.receiverEmails[type]
								if (Object.keys(this.formData.reportingConfig.receiverEmails).length === 0) {
									delete this.formData.reportingConfig.receiverEmails
								}
							}
						} else delete this.formData.reportingConfig.receiverEmails
					}
				}

				if (this.isFor === "supplierGroup") {
					let payload = {
						sgId: this.$store.state.activeSG.supplierGroupId,
						edit: "report-settings",
						body: this.formData,
					};

					console.log(payload)
					await this.$store.dispatch("EditSupplierGroup", payload);
					if (this.$store.state.responseStatus === 200) {
						this.$store.commit("toggleAlert", {
							title: "Edit SupplierGroup",
							text: "General successfully edited",
						});
					}
				}
				if (this.isFor === "legalEntity") {
					let payload = {
						sgId: this.$store.state.activeSG.supplierGroupId,
						leId: this.$store.state.activeLE.entityNumber,
						edit: "report-settings",
						body: this.formData,
					};
					await this.$store.dispatch("EditLegalEntity", payload);
					if (this.$store.state.responseStatus === 200) {
						this.$store.commit("toggleAlert", {
							title: "Edit Legal entity",
							text: "Reporting successfully edited",
						});
					}
				}
			}
		},
		validate() {
			let hasErrors = false;
			this.ftpFoldersInvalid = {};
			this.receiverEmailsInvalid = {};
			for (let type of this.types) {
				if (this.formData.reportingConfig.ftpFolders) {
					if (this.formData.reportingConfig.ftpFolders[type]) {
						if (
							this.formData.reportingConfig.ftpFolders[type].charAt(0) !== "/" ||
							this.formData.reportingConfig.ftpFolders[type].charAt(
								this.formData.reportingConfig.ftpFolders[type].length - 1
							) === "/"
						) {
							hasErrors = true;
							this.ftpFoldersInvalid[type] = true;
						}
					}
				}

				//TODO
				//check if ftpFolder or emails are filled

			}
			return hasErrors;
		},
	},
};
</script>

<style scoped>
.reporting-item {
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: 50% 50%;
	grid-column-gap: 20px;
	align-items: start;
}
</style>