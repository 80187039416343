<template>
	<pop-up-window
		:show="showAddExtractionField"
		:showClose="false"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Add extraction field</template>
		<template #content>
			<div>
				<div class="form-container">
					<div class="mr-4">
						<div class="mb-3">
							<label class="font-bold form-label">Field type:</label>
							<Dropdown
								@update-value="changeSelectedField"
								:data="fieldTypeData"
								:key="formData.field"
								:selected="formData.field"
							/>
						</div>
						<div class="flex">
							<Field
								id="viewExtractionXCoord"
								title="X-Coord:"
								type="number"
								v-model="formData.x"
							/>
							<Field
								id="viewExtractionYCoord"
								title="Y-Coord:"
								type="number"
								v-model="formData.y"
							/>
							<Field
								id="viewExtractionWidth"
								title="Width:"
								type="number"
								v-model="formData.width"
							/>
							<Field
								id="viewExtractionHeight"
								title="Height:"
								type="number"
								v-model="formData.height"
							/>
						</div>

						<div class="mb-3">
							<Field
								class=""
								id="addNavigationText"
								type="string"
								title="Navigation text (use comma to separate items):"
								v-model="formData.navigationText"
							/>
						</div>
						<div class="mt-3 default-date">
							<p class="font-bold form-label mb-0">Use system date as default date: </p>
							<ToggleSlide
								@update-value="updateUseSystemDateAsDefaultDate"
								class="w-min"
								id="addDefaultData"
								:bool="formData.useSystemDateAsDefaultDate"
							/>
						</div>
						<div class="mb-3">
							<Field
								id="addDefaultValue"
								type="string"
								title="Default value:"
								v-model="formData.defaultValue"
							/>
						</div>
					</div>
					<div class="ml-4">
						<div class="mb-3">
							<Field
								id="addDateFormatter"
								type="string"
								title="Date formatter:"
								v-model="formData.dateFormatter"
							/>
						</div>
						<div class="mb-3">
							<label class="font-bold form-label">Big decimal formatter:</label>
							<div class="ml-4">
								<label class="font-bold form-label">Grouping separator:</label>
								<Dropdown
									@update-value="updateBigDecimalFormatter($event, 'groupingSeparator')"
									:data="separatorOptions"
									:key="1"
									:selected="formData.bigDecimalFormatter.groupingSeparator"
								/>
							</div>
							<div class="ml-4 mt-2">
								<label class="font-bold form-label">Decimal separator:</label>
								<Dropdown
									@update-value="updateBigDecimalFormatter($event, 'decimalSeparator')"
									:data="separatorOptions"
									:key="2"
									:selected="formData.bigDecimalFormatter.decimalSeparator"
								/>
							</div>
						</div>
						<div class="mb-3">
							<p class="font-bold form-label">Additional rules:</p>
							<div class="ml-4">
								<p class="font-bold form-label">Offset:</p>
								<Dropdown
									@update-value="updateAdditionalRules($event, 'offset')"
									:data="offsetOptions"
									:key="3"
									:selected="formData.additionalRules.offset"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-end">
				<div>
					<button class="btn shadow-md mr-2" @click="add">Add</button>
					<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../PopUp.vue"
import Field from "../Field.vue"
import Dropdown from "../Dropdown.vue"
import ToggleSlide from "../ToggleSlide.vue"


export default {
	name: "AddExtractionField",
	components: {
		PopUpWindow,
		Field,
		Dropdown,
		ToggleSlide,
	},
	props: {
		showAddExtractionField: Boolean,
		fieldTypeData: Array,
		extractionDataType: Array
	},
	data() {
		return {
			formData: {
				field: this.fieldTypeData[0].name,
				x: 0,
				y: 0,
				width: 10,
				height: 10,

				navigationText: '',
				defaultValue: '',
				useSystemDateAsDefaultDate: false,
				dateFormatter: '',
				bigDecimalFormatter: {
					groupingSeparator: "No separator",
					decimalSeparator: "No separator"
				},
				additionalRules: {
					offset: 'No offset'
				}
			},
			separatorOptions: [
				{id: 0, name: "No separator"},
				{id: 1, name: "DOT"},
				{id: 2, name: "SPACE"},
				{id: 3, name: "COMMA"},
			],
			offsetOptions: [
				{id: 0, name: "No offset"},
				{id: 1, name: "-2"},
				{id: 2, name: "-1"},
				{id: 3, name: "1"},
				{id: 4, name: "2"},
			]
		}
	},
	emits: ['add-field', 'cancel'],
	methods: {
		updateUseSystemDateAsDefaultDate(data) {
			this.formData.useSystemDateAsDefaultDate = data
		},
		updateAdditionalRules(offset, field) {
			this.formData.additionalRules[field] = this.offsetOptions[offset].name
		},
		updateBigDecimalFormatter(separator, field) {
			this.formData.bigDecimalFormatter[field] = this.separatorOptions[separator].name
		},
		changeSelectedField(data) {
			if (data[0] !== -1) {
				this.formData.field = this.fieldTypeData[data].name
			} else {
				this.formData.field = this.fieldTypeData[0].name
			}
		},
		close() {
			this.$store.state.displayAddExtractionField = false;
			this.formData = {
				field: this.fieldTypeData[0].name,
				x: 0,
				y: 0,
				width: 10,
				height: 10,

				navigationText: '',
				defaultValue: '',
				useSystemDateAsDefaultDate: false,
				dateFormatter: '',
				bigDecimalFormatter: {
					groupingSeparator: "No separator",
					decimalSeparator: "No separator"
				},
				additionalRules: {
					offset: 'No offset'
				}
			}
			this.$emit('cancel')
		},
		add() {
			this.$emit('add-field', this.formData)
			this.close()
		},
		validate() {
		},
	},
}
</script>

<style scoped>
.form-container {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 100%;
	padding: 20px;
}

.default-date {
	padding-right: 20px;
	margin-bottom: 10px;
	display: grid;
	grid-template-columns:40% 10%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
}
</style>