<template>
	<div v-if="displayComponent" class="box shadow-none col-span-6 height-600 height-100">
		<div
			class="text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
			<div class="button-container">
				<button class="btn shadow-md bg-theme-1 text-white mr-2" @click="addNewTemplate">
					Add new template
				</button>
			</div>
			<div v-if="displayTemplates">
				<div class="mb-3 ">
					<div class="flex flex-col px-2">
						<label for="resendTemplateName">Select resend template:</label>
						<Dropdown
							id="resendTemplateName"
							@update-value="updateSelectedName"
							class="w-1/2 pr-2"
							:data="templateNames"
							:selected="initialTemplate"
						/>
					</div>
					<div class="form-container mt-2">
						<div class="px-2">
							<div>
								<Field
									title="Resend template version*:"
									v-model="activeTemplate.version"
									type="text"
									:disabled="true"
								/>
							</div>
							<div>
								<label for="viewResendDocType" class="font-bold ">DocType:*</label>
								<DropdownSelectAll
									v-if="renderComponent"
									id="viewResendDocType"
									:data="docTypes"
									:selected=activeTemplate.docTypes
									:disabled="true"
									class="my-1"
								/>
							</div>
						</div>
						<div class="px-2">
							<div>
								<label for="viewOverlayMode" class="font-bold form-label">Overlay mode:*</label>
								<Dropdown
									id="viewOverlayMode"
									:data="overlayData"
									:selected="activeTemplate.overlayMode"
									:disabled="true"
								/>
							</div>
							<div>
								<p class="font-bold my-2">PDF file:*</p>
								<div
									:key="index + item"
									v-for="(item, index) in activeTemplate.location"
									class="flex flex-col"
								>
									<FileUpload
										:uploadIconNotDisplay="true"
										:disabled="true"
										:isLegalEntity="isLegalEntity"
										accept=".pdf"
										:prefix="true"
										:prefixData="index"
										:delSuffix="false"
										:download-suffix="true"
										@click="download(activeTemplate.location[index].url)"
										:data="activeTemplate.location[index].fileName"
										:id=" index + 'selectTemplate' + item + activeTemplate.location[index].fileName"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="!displayTemplates">
				No templates yet
			</div>
		</div>
	</div>
	<AddResendTemplate :isFor="isFor"/>
</template>

<script>
import Dropdown from "../../Dropdown.vue";
import Field from "../../Field.vue";
import DropdownSelectAll from "../../DropdownSelectAll.vue";
import FileUpload from "../../FileUpload.vue";
import AddResendTemplate from "./AddResendTemplate.vue";

export default {
	name: "ViewResendTemplate",
	components: {
		Dropdown,
		Field,
		DropdownSelectAll,
		FileUpload,
		AddResendTemplate
	},
	props: {
		isFor: String,
	},
	data() {
		return {
			displaySelectedName: true,
			activeItem: {},
			activeTemplate: {},
			initialTemplate: '',
			resendTemplates: [],
			docTypes: this.$store.state.docTypes,
			isLegalEntity: this.isFor === "legalEntity",
			renderComponent: true,
			displayAddNewTemplate: false,
			overlayData: this.$store.state.overlayMode,
			templateNames: [],
			displayComponent: true,
		}
	},
	mounted() {
		this.initData();
	},
	computed: {
		activeSG() {
			return this.$store.state.activeSG;
		},
		activeLE() {
			return this.$store.state.activeLE;
		},
		displayTemplates() {
			return this.resendTemplates.length !== 0;
		},
	},
	watch: {
		activeSG(newVal) {
			this.displayComponent = false;
			if (this.isFor === "supplierGroup" && newVal.templates && newVal.templates.resendTemplates) {
				this.resendTemplates = newVal.templates.resendTemplates;
			}
			if (this.templateNames.length) {
				this.initialTemplate = this.templateNames[0].name;
			}
			if (this.resendTemplates.length) {
				this.activeTemplate = this.resendTemplates[0];
			}

			this.templateNamesOptions();
			this.forceRerender();
			setTimeout(() => {
				this.displayComponent = true;
			}, 200)

		},
		activeLE(newVal) {
			this.displayComponent = false;
			if (this.isFor === "legalEntity" && newVal.templates && newVal.templates.resendTemplates) {
				this.resendTemplates = newVal.templates.resendTemplates;
			}
			if (this.templateNames.length) {
				this.initialTemplate = this.templateNames[0].name;
			}
			if (this.resendTemplates.length) {
				this.activeTemplate = this.resendTemplates[0];
			}
			this.templateNamesOptions();
			this.forceRerender();
			setTimeout(() => {
				this.displayComponent = true;
			}, 200)
		}
	},
	methods: {
		templateNamesOptions() {
			let templateNames = [];
			this.resendTemplates.forEach((template, index) => {
				templateNames.push(
					{id: index, name: template.name}
				)
			});
			this.templateNames = templateNames;
		},
		download(pdfPath) {
			window.open(pdfPath)
		},
		addNewTemplate() {
			this.$store.state.displayAddResendTemplate = true;
		},
		updateSelectedName(templateId) {
			if (typeof templateId === 'object') templateId = templateId[0]
			if (templateId < 0) templateId = 0

			if (this.templateNames !== undefined) {
				let selectedName = this.templateNames.find(item => (item.id.toString() === templateId.toString())).name;
				this.activeTemplate = this.resendTemplates.find(template => (template.name === selectedName));
				this.initialTemplate = selectedName
				this.$store.commit("setSelectedResendTemplateName", selectedName);
				this.forceRerender();
			}
		},
		forceRerender() {
			// Removing my-component from the DOM
			this.renderComponent = false;

			this.$nextTick(() => {
				// Adding the component back in
				this.renderComponent = true;
			});
		},
		initData() {
			if (this.isFor === "supplierGroup" && this.$store.state.activeSG.templates && this.$store.state.activeSG.templates.resendTemplates) {
				this.resendTemplates = this.$store.state.activeSG.templates.resendTemplates;
			}
			if (this.isFor === "legalEntity" && this.$store.state.activeLE.templates && this.$store.state.activeLE.templates.resendTemplates) {
				this.resendTemplates = this.$store.state.activeLE.templates.resendTemplates;
			}
			this.templateNamesOptions();

			if (this.templateNames.length) {
				this.initialTemplate = this.templateNames[0].name;
			}
			if (this.resendTemplates.length) {
				this.activeTemplate = this.resendTemplates[0];
			}
		}
	}
}
</script>

<style scoped>
.height-100 {
	min-height: 700px;
}

.form-container {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 100%;
}

.button-container {
	display: grid;
	justify-items: end;
}

label {
	font-weight: bold;
	margin-bottom: 0.5rem;
}
</style>