<template>
	<pop-up-window
		:show="this.$store.state.displayEditOverlayTemplate && !this.$store.state.displayAddOverlayFile"
		:showClose="false"
		:blur="showConfirm"
		@close="close"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit Overlay Template</template>
		<template #content>
			<div v-if="showTemplateContent">
				<div class="">
					<div :class="{'pointer-events-none': showConfirm}" v-if="formData.overlay" class="flex flex-col">
						<div class="flex flex-col w-1/2 pr-2">
							<label for="overlayTemplateName" class="font-bold form-label">
								Select overlay template:
							</label>
							<Dropdown
								id="overlayTemplateName"
								@update-value="updateSelectedName"
								:class="{ 'border border-primary-3': selectedOverlayInvalid }"
								class=""
								:data="overlayTemplatesNames"
								:selected="this.$store.state.selectedOverlayTemplateName"
							/>
						</div>

						<div v-show="showTemplate && this.activeOverlayTemplate" class="flex">
							<div class="flex flex-col w-1/2 mb-3 mr-2">
								<label for="overlayTemplateOverlayMode" class="font-bold form-label mt-2">
									Overlay mode:*
								</label>
								<Dropdown
									id="overlayTemplateOverlayMode"
									@update-value="updateSelectedOverlay"
									:class="{ 'border border-primary-3': selectedOverlayInvalid }"
									class=""
									:data="overlayOverlayData"
									:selected="activeOverlayTemplate.overlayMode"
								/>
								<span v-if="selectedOverlayInvalid" class="text-primary-3">
                                    Select an overlay mode
                                </span>
								<label for="overlayTemplateDocTypes" class="font-bold form-label  mt-2">
									Overlay doc types:*
								</label>
								<DropdownSelectAll
									v-if="renderComponent"
									id="overlayTemplateDocTypes"
									:class="{ 'border border-primary-3': selectedOverlayInvalid }"
									@update-value="updateSelectedDocTypes"
									:data="overlayDocTypes"
									:selected=activeOverlayTemplate.docTypes
								/>
								<span v-if="docTypesError" class="text-primary-3">
									Select at least one doc type
								</span>
							</div>
							<div class="flex flex-col w-1/2 mb-3 ml-2">
								<div class="flex mb-1 mt-2">
									<p class="font-bold form-label my-0">Overlay file:*</p>
									<button @click="displayAddFile"
											class="btn bg-theme-1 py-0 text-white shadow-md ml-2">
										Add
									</button>
								</div>
								<div
									:key="index + item"
									v-for="(item, index) in activeOverlayTemplate.location"
									class="flex flex-col"
								>
									<FileUpload
										:isLegalEntity="isLegalEntity"
										@update-value="updateFile($event, index)"
										@delete-value="deleteFile($event, index)"
										accept=".pdf"
										:prefix="true"
										:prefixData="index"
										:invalid="overlayFileInvalid"
										:delSuffix="true"
										:data="activeOverlayTemplate.location[index].fileName"
										:id="index + 'overlay' + item + activeOverlayTemplate.location[index].fileName"
									/>
								</div>

								<span v-if="overlayFileInvalid" class="text-primary-3">
									At least 1 Overlay file required
								</span>
								<label for="overlayTemplateOverlayMode" class="font-bold form-label">
									Overlay position:
								</label>
								<Dropdown
									id="editOverlayPosition"
									@update-value="updateSelectedOverlayPosition"
									class=""
									:data="overlayPositionData"
									:selected="activeOverlayTemplate.overlayPosition"
								/>
							</div>
						</div>
					</div>
				</div>

				<div :class="{'pointer-events-none': showConfirm}" class="grid grid-rows-1 justify-items-end">
					<div>
						<button class="btn shadow-md mr-2"
								@click="update"
								v-show="showTemplate && this.activeOverlayTemplate"
						>
							Update
						</button>
						<button v-if="templateExist && showTemplate && this.activeOverlayTemplate"
								class="btn shadow-md bg-theme-1 text-white mr-2"
								@click="showConfirmDialog"
						>
							Delete
						</button>
						<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
					</div>
				</div>
			</div>
			<div v-if="!showTemplateContent">
				No template yet
				<div class="grid grid-rows-1 justify-items-end">
					<div>
						<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
					</div>
				</div>
			</div>
		</template>
	</pop-up-window>
	<ConfirmDialog @close="delTemp" :show="showConfirm"/>
	<add-file-to-template
		title-dialog="Edit Overlay text"
		:isLegalEntity="isLegalEntity"
		@add-file="addFile"
		@cancel-add-file="showDialogAddFile = false"
		:langCode="langCodes"
		:show-dialog="showDialogAddFile"
	>
	</add-file-to-template>
</template>

<script>
import PopUpWindow from "../../PopUp.vue";
import FileUpload from "../../FileUpload.vue";
import Dropdown from "../../Dropdown.vue";
import ConfirmDialog from "../../ConfirmDialog.vue"
import DropdownSelectAll from "../../DropdownSelectAll.vue";
import AddFileToTemplate from "@/components/templates/AddFileToTemplate.vue";

export default {
	name: "EditOverlayTemplate",
	components: {
		AddFileToTemplate,
		PopUpWindow,
		FileUpload,
		Dropdown,
		ConfirmDialog,
		DropdownSelectAll,
	},
	props: {
		overlayOverlayData: Array,
		langCodes: Array,
		isFor: String,
	},
	data() {
		return {
			displaySelectedAll: false,
			showDialogAddFile: false,
			renderComponent: true,

			showTemplate: false,
			showConfirm: false,
			isLegalEntity: this.isFor === "legalEntity",
			activeItem: {},
			tempFile: "",
			formData: {
				overlay: {},
			},
			selectedOverlayInvalid: false,
			overlayFileInvalid: false,
			docTypesError: false,
			activeOverlayTemplate: {},
			overlayDocTypes: this.$store.state.docTypes,
			overlayTemplatesNames: [
				{id: 0, name: "no templates yes"},
			],
			overlayPositionData: [
				{id: 0, name: "BACKGROUND"},
				{id: 1, name: "FOREGROUND"},
			],

			selectedTemplate: 0,
			selectedTemplateName: ''

		};
	},
	watch: {
		"$store.state.activeSG": {
			handler() {
				if (this.isFor === 'supplierGroup') {
					this.activeItem = this.$store.state.activeSG;
					this.setFormDataOverlay();
				}
			},
			deep: true,
		},
		"$store.state.activeLE": {
			handler() {
				if (this.isFor === "legalEntity") {
					this.activeItem = JSON.parse(JSON.stringify(this.$store.state.activeLE));
					this.setFormDataOverlay();
				}
			},
			deep: true,
		},
	},
	computed: {
		showTemplateContent() {
			if (this.isFor === "legalEntity") {
				if (this.$store.state.activeLE.templates && this.$store.state.activeLE.templates.overlayTemplates === undefined) return false
			}
			if (this.isFor === "supplierGroup") {
				if (this.$store.state.activeSG.templates && this.$store.state.activeSG.templates.overlayTemplates === undefined) return false
			}
			return true
		},
		templateExist() {
			this.setTemplateNames();
			if (this.isFor === "legalEntity") {
				return this.$store.state.activeLE.templates
					? !!this.$store.state.activeLE.templates.overlayTemplates
					: false
			}
			return this.$store.state.activeSG.templates
				? !!this.$store.state.activeSG.templates.overlayTemplates
				: false
		},
	},
	mounted() {
		this.initData();
	},
	methods: {
		setFormDataOverlay() {
			this.formData.overlay = {};

			if (this.activeItem.templates && this.activeItem.templates.overlayTemplates) {
				this.formData.overlay = JSON.parse(JSON.stringify(this.activeItem.templates.overlayTemplates))
			}
			if (this.activeItem.templates && this.activeItem.templates.overlay) {
				this.formData.overlay = JSON.parse(JSON.stringify(this.activeItem.templates.overlay))
			}
		},
		forceRerender() {
			// Removing my-component from the DOM
			this.renderComponent = false;

			this.$nextTick(() => {
				// Adding the component back in
				this.renderComponent = true;
			});
		},
		setTemplateNames() {
			let templates = []
			if (this.isFor === "legalEntity") {
				if (this.$store.state.activeLE.templates && this.$store.state.activeLE.templates.overlayTemplates) {
					templates = this.$store.state.activeLE.templates.overlayTemplates
				}
				if (this.$store.state.activeLE.templates && this.$store.state.activeLE.templates.overlay) {
					templates = [this.$store.state.activeLE.templates.overlay]
				}
			} else {
				if (this.$store.state.activeSG.templates && this.$store.state.activeSG.templates.overlayTemplates) {
					templates = this.$store.state.activeSG.templates.overlayTemplates;
				}
				if (this.$store.state.activeSG.templates && this.$store.state.activeSG.templates.overlay) {
					templates = [this.$store.state.activeSG.templates.overlay];
				}
			}

			let names = [];
			templates.forEach((template, index) => {
				names.push(
					{
						id: index,
						name: template.name
					}
				)
			})
			this.overlayTemplatesNames = names;

		},
		initData() {
			this.activeOverlayTemplate = {};
			this.showTemplate = false;
			this.tempFile = "";
			this.isLegalEntity = this.isFor === "legalEntity";
			this.showConfirm = false;
			if (this.isFor === "supplierGroup") {
				this.activeItem = JSON.parse(JSON.stringify(this.$store.state.activeSG));
			}
			if (this.isFor === "legalEntity") {
				this.activeItem = JSON.parse(JSON.stringify(this.$store.state.activeLE));
			}
			if (!this.formData.overlay) {
				this.formData.overlay = {}
			}
			this.selectedOverlayInvalid = false;
			this.overlayFileInvalid = false;

			this.setFormDataOverlay();
		},
		updateSelectedName(data) {
			if (typeof data === 'object'){
				data = data[0]
			}

			this.selectedTemplateName = this.overlayTemplatesNames.find((item) => (item.id.toString() === data.toString())).name;

			this.showTemplate = true;
			this.displaySelectedAll = false;

			let foundTemplate = {};
			if (this.activeItem.templates && this.activeItem.templates.overlayTemplates) {

				this.activeItem.templates.overlayTemplates.forEach(template => {
					if (template.name === this.selectedTemplateName) {
						foundTemplate = template;
					}
				})
			}

			if (this.activeItem.templates && this.activeItem.templates.overlay) {
				foundTemplate = this.activeItem.templates.overlay
			}

			if (foundTemplate.docTypes === undefined) {
				foundTemplate.docTypes = []
			}
			this.activeOverlayTemplate = foundTemplate;
			this.found = foundTemplate;
			this.forceRerender();
		},
		updateSelectedOverlay(data) {
			if (this.overlayOverlayData[data]) {
				this.activeOverlayTemplate.overlayMode = this.overlayOverlayData[data].name;
			}
		},
		updateSelectedOverlayPosition(data) {
			this.activeOverlayTemplate.overlayPosition = this.overlayPositionData[data].name;
		},
		updateSelectedDocTypes(data) {
			let foundArr = [];
			for (let index of data) {
				let found = this.overlayDocTypes[index].name;
				foundArr.push(found);
			}
			this.activeOverlayTemplate.docTypes = foundArr;
			this.docTypesError = false
		},

		displayAddFile() {
			this.showDialogAddFile = true;
		},
		addFile(data) {
			this.activeOverlayTemplate.location = {
				...this.activeOverlayTemplate.location,
				...data,
			};
			this.showDialogAddFile = false
			this.validate()
		},
		updateFile(event, data) {
			this.activeOverlayTemplate.location[data].fileName = event.value;
			this.activeOverlayTemplate.location[data].url = this.tempFile;
			this.validate();
		},
		deleteFile(event, data) {
			delete this.activeOverlayTemplate.location[data];
		},
		close() {
			this.initData();
			this.$store.state.displayAddOverlayFile = false;
			this.$store.state.displayEditOverlayTemplate = false;
		},
		async update() {
			let hasErrors = this.validate();

			if (!hasErrors) {
				let foundIndex = -1;
				this.formData.overlay.forEach((template, index) => {
					if (template.name === this.selectedTemplateName) {
						foundIndex = index
					}
				});

				this.formData.overlay[foundIndex] = this.activeOverlayTemplate;

				if (this.isFor === "supplierGroup") {
					let payload = {
						sgId: this.$store.state.activeSG.supplierGroupId,
						edit: "overlay-template",
						body: this.formData,
					};
					await this.$store.dispatch("EditSupplierGroup", payload);
					if (this.$store.state.responseStatus === 200) {
						this.$store.commit("toggleAlert", {
							title: "Edit Supplier group",
							text: "Overlay template successfully edited",
						});
					}
				}

				if (this.isFor === "legalEntity") {

					let payload = {
						sgId: this.$store.state.activeSG.supplierGroupId,
						leId: this.$store.state.activeLE.entityNumber,
						edit: "overlay-template",
						body: this.formData,
					};
					await this.$store.dispatch("EditLegalEntity", payload);
					if (this.$store.state.responseStatus === 200) {
						this.$store.commit("toggleAlert", {
							title: "Edit Legal entity",
							text: "Overlay template successfully edited",
						});
					}
				}
				this.$store.state.displayAddOverlayFile = false;
			}
		},
		showConfirmDialog() {
			this.showConfirm = true
		},
		async delTemp(data) {
			if (data === "Yes") {
				let deleteIndex = null;
				this.formData.overlay.forEach((template, index) => {
					if (template.name === this.selectedTemplateName) {
						deleteIndex = index
					}
				});
				if (deleteIndex > -1) { // only splice array when item is found
					this.$store.commit("overlayTemplateDeleted", true)
					this.formData.overlay.splice(deleteIndex, 1); // 2nd parameter means remove one item only
				}

				if (this.formData.overlay.length === 0) {
					this.formData.overlay = null;
				}

				if (this.isFor === "supplierGroup") {
					let payload = {
						sgId: this.$store.state.activeSG.supplierGroupId,
						edit: "overlay-template",
						body: this.formData,
					};
					await this.$store.dispatch("EditSupplierGroup", payload);
				}
				if (this.isFor === "legalEntity") {
					let payload = {
						sgId: this.$store.state.activeSG.supplierGroupId,
						leId: this.$store.state.activeLE.entityNumber,
						edit: "overlay-template",
						body: this.formData,
					};
					await this.$store.dispatch("EditLegalEntity", payload);
				}
			}
			this.showConfirm = false
		},
		validate() {
			this.selectedOverlayInvalid = false;
			this.overlayFileInvalid = false;
			this.docTypesError = false;
			let hasErrors = false;
			if (this.activeOverlayTemplate.docTypes.length === 0) {
				hasErrors = true;
				this.docTypesError = true;
			}
			if (this.activeOverlayTemplate.overlayMode === "") {
				this.selectedOverlayInvalid = true;
				hasErrors = true;
			}
			if (Object.keys(this.activeOverlayTemplate.location).length === 0) {
				this.overlayFileInvalid = true;
				hasErrors = true;
			}
			return hasErrors;
		},
	},
};
</script>
