<template>
	<pop-up-window
		:show="this.$store.state.displayEditPreIngestion"
		class="doc-statuses-popup"
		fixed
	>
		<template #header>Edit pre-ingestion</template>
		<template #content>
			<div v-if="showComponent">
				<div v-if="showTemplate">
					<div class="mb-3">
						<div class="flex flex-col mr-12">
							<label for="preIngestionTemplateName">Select pre-ingestion template:</label>
							<Dropdown
								id="preIngestionTemplateName"
								@update-value="updateSelectedName"
								class="w-1/2"
								:data="preIngestionTemplateNames"
								:selected="this.$store.state.selectedPreIngestionTemplateName"
							/>
						</div>
						<div class="form-container" v-show="showActiveTemplate">
							<div>
								<Field
									id="templateVersion"
									title="Pre-Ingestion template version:"
									v-model="activeTemplate.version"
									type="text"
								/>
								<label for="preIngestionDocType">DocType:*</label>
								<DropdownSelectAll
									v-if="renderComponent"
									id="preIngestionDocType"
									@update-value="updateDocTypes"
									:data="preIngestionDocTypes"
									:selected=activeTemplate.docTypes
								/>
								<div>
                                    <span v-if="errors.docTypes" class="text-primary-3">
										Please, select at least one doc type
									</span>
								</div>
							</div>
							<div>
								<div class="flex mb-1">
									<p class="font-bold form-label my-0">Base file:*</p>
									<button
										@click="showDialogAddFile = true"
										class="btn bg-theme-1 py-0 text-white shadow-md ml-2"
									>
										Add
									</button>
									<add-file-to-template
										title-dialog="Add pre-ingestion base file"
										:isLegalEntity="isLegalEntity"
										@add-file="addFile"
										@cancel-add-file="showDialogAddFile = false"
										:langCode="langCodes"
										:show-dialog="showDialogAddFile"
									/>
								</div>
								<div>
                                    <span v-if="errors.baseFile" class="text-primary-3">
										Please, select at least one base file
									</span>
								</div>
								<div
									:key="index + item"
									v-for="(item, index) in activeTemplate.baseFile"
									class="flex flex-col"
								>
									<div class="flex flex-col">
										<FileUpload
											:isLegalEntity="isLegalEntity"
											:class=index
											@update-value="changeFileLocation($event, item, index, 'baseFile')"
											@delete-value="deleteFile($event, item, index)"
											accept=".pdf"
											:prefix="true"
											:prefixData="index"
											:delSuffix="true"
											:data="item.fileName"
											:id="  'templatesEdit' + item.fileName"
										/>
									</div>
								</div>

								<div class="flex mb-1">
									<p class="font-bold form-label my-0">Base template JSON:*</p>
									<button
										@click="showDialogAddJsonFile = true"
										class="btn bg-theme-1 py-0 text-white shadow-md ml-2"
									>
										Add
									</button>
									<add-file-to-template
										title-dialog="Add template JSON"
										:isLegalEntity="isLegalEntity"
										@add-file="addJsonFile"
										@cancel-add-file="showDialogAddJsonFile = false"
										:langCode="langCodes"
										:show-dialog="showDialogAddJsonFile"
										file-type=".json"
									/>
								</div>
								<div>
                                    <span v-if="errors.templateJson" class="text-primary-3">
                                        Please, select at least one template JSON
                                    </span>
								</div>
								<div
									:key="index + item"
									v-for="(item, index) in activeTemplate.templateJson"
									class="flex flex-col">
									<FileUpload
										:isLegalEntity="isLegalEntity"
										:class=index
										@update-value="changeFileLocation($event, item, index, 'templateJson')"
										@delete-value="deleteJsonFile($event, item, index)"
										accept=".json"
										:prefix="true"
										:prefixData="index"
										:delSuffix="true"
										:data="item.fileName"
										:id="  'templatesEdit' + item.fileName"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-if="!showTemplate">
					No template yet
				</div>

				<div class="grid grid-rows-1 justify-items-end">
					<div>
						<button class="btn shadow-md mr-2" @click="update(false)" v-show="showActiveTemplate">Update
						</button>
						<button
							v-if="showActiveTemplate"
							class="btn shadow-md bg-theme-1 text-white mr-2"
							@click="showConfirmDialog"
						>
							Delete
						</button>
						<button class="btn shadow-md mr-2 nymus-orange color-white" @click="close">Cancel</button>
					</div>
				</div>
			</div>
		</template>
	</pop-up-window>
	<ConfirmDialog @close="delTemp" :show="showConfirm"/>
</template>

<script>
import PopUpWindow from "../../PopUp.vue";
import Dropdown from "../../Dropdown.vue";
import Field from "../../Field.vue";
import DropdownSelectAll from "../../DropdownSelectAll.vue";
import FileUpload from "../../FileUpload.vue";
import langCodes from '../../../langCodes.js';
import ConfirmDialog from "../../ConfirmDialog.vue";
import AddFileToTemplate from "@/components/templates/AddFileToTemplate.vue";


export default {
	name: "EditPreIngestion",
	components: {
		AddFileToTemplate,
		PopUpWindow,
		Dropdown,
		Field,
		DropdownSelectAll,
		FileUpload,
		ConfirmDialog
	},
	props: {
		isFor: {
			type: String,
			required: true,
		}
	},
	data() {
		return {
			showComponent: false,
			activeItem: {},
			initialTemplate: '',
			selectedTemplateName: '',
			activeTemplate: {},
			renderComponent: true,
			preIngestionDocTypes: this.$store.state.docTypes,
			isLegalEntity: this.isFor === "legalEntity",
			langCodes: langCodes,
			errors: {
				docTypes: false,
				baseFile: false,
				templateJson: false
			},
			showConfirm: false,
			showDialogAddFile: false,
			showDialogAddJsonFile: false
		}
	},
	mounted() {
		this.initData();
	},
	computed: {
		activeSG() {
			return this.$store.state.activeSG;
		},

		activeLE() {
			return this.$store.state.activeLE;
		},
		showActiveTemplate() {
			return Object.keys(this.activeTemplate).length !== 0;
		},
		showTemplate() {
			if ((this.activeItem.templates && this.activeItem.templates.textOverlayTemplates && this.activeItem.templates.textOverlayTemplates.length !== 0)) {
				return true;
			}
			if ((this.activeItem.templates && this.activeItem.templates.textOverlayTemplates && this.activeItem.templates.textOverlayTemplates.length === 0)) {
				return false;
			}
			return false
		},
		preIngestionTemplates() {
			let templates = []
			if (this.activeItem.templates && this.activeItem.templates.textOverlayTemplates) {
				templates = this.activeItem.templates.textOverlayTemplates;
			}
			return templates;
		},
		preIngestionTemplateNames() {
			let names = [];
			if (this.activeItem.templates && this.activeItem.templates.textOverlayTemplates) {
				this.activeItem.templates.textOverlayTemplates.forEach((template, index) => {
					names.push({id: index, name: template.name})
				})
			}
			return names;
		},
	},
	watch: {
		activeSG(newVal) {
			this.activeItem = newVal;
		},

		activeLE(newVal) {
			this.activeItem = newVal;
		}
	},
	methods: {
		showConfirmDialog() {
			this.showConfirm = true;
		},
		changeFileLocation(event, item, index, field) {
			setTimeout(() => {
				this.activeTemplate[field][index].url = this.$store.state.uploadedFileUrl;
			}, 500)
			this.activeTemplate[field][index].fileName = event.files[0].name;
		},
		updateFile(event, data, field) {
			this.errors[field] = false;
			this.activeTemplate[field][data].fileName = event.value;
			setTimeout(() => {
				this.activeTemplate[field][data].url = this.$store.state.uploadedFileUrl;
			}, 500)
			this.activeTemplate[field][data].url = this.$store.state.uploadedFileUrl;
		},
		deleteFile(event, item, index) {

			delete this.activeTemplate.baseFile[index];
		},
		deleteJsonFile(event, item, index) {
			delete this.activeTemplate.templateJson[index];
		},
		addFile(data) {
			this.errors.baseFile = false;
			this.activeTemplate.baseFile = {
				...this.activeTemplate.baseFile,
				...data,
			};
			this.showDialogAddFile = false
		},
		addJsonFile(data) {
			this.errors.templateJson = false;
			this.activeTemplate.templateJson = {
				...this.activeTemplate.templateJson,
				...data,
			};
			this.showDialogAddJsonFile = false
		},
		forceRerender() {
			// Removing my-component from the DOM
			this.renderComponent = false;

			this.$nextTick(() => {
				// Adding the component back in
				this.renderComponent = true;
			});
		},
		updateDocTypes(docTypes) {
			this.errors.docTypes = false;

			let foundArr = [];
			for (let index of docTypes) {
				let found = this.preIngestionDocTypes[index].name;
				foundArr.push(found);
			}
			this.activeTemplate.docTypes = foundArr;
		},
		updateSelectedName(templateId) {
			if (typeof templateId === 'object'){
				templateId = templateId[0]
			}
			this.selectedTemplateName = this.preIngestionTemplateNames.find(item => (item.id.toString() === templateId.toString())).name;

			if (this.selectedTemplateName !== "") {
				this.preIngestionTemplates.forEach(template => {
					if (template.name === this.selectedTemplateName) {
						this.activeTemplate = template
					}
				})
			} else {
				this.activeTemplate = {}
			}
			this.forceRerender();
		},
		initData() {
			if (this.isFor === "supplierGroup") {
				this.activeItem = this.$store.state.activeSG;
			}
			if (this.isFor === "legalEntity") {
				this.activeItem = this.$store.state.activeLE;
			}
			this.initialTemplate = ""
			this.showComponent = true;
		},
		close() {
			this.$store.state.displayEditPreIngestion = false;
			this.errors = {
				docTypes: false,
				baseFile: false,
				templateJson: false
			}
			this.activeTemplate = {}
		},
		delTemp(data) {
			if (data === "Yes") {
				this.update(true);
				this.showConfirm = false;
			} else {
				this.showConfirm = false;
			}
		},
		async update(deleteParam) {
			if (this.activeTemplate.docTypes.length === 0) {
				this.errors.docTypes = true;
				return
			}
			if (Object.keys(this.activeTemplate.baseFile).length === 0) {
				this.errors.baseFile = true;
				return
			}
			if (Object.keys(this.activeTemplate.templateJson).length === 0) {
				this.errors.templateJson = true;
				return
			}

			let formData = []
			if (this.isFor === "legalEntity" && this.activeItem.templates && this.activeItem.templates.textOverlayTemplates) {
				formData = this.$store.state.activeLE.templates.textOverlayTemplates
			}
			if (this.isFor === "supplierGroup" && this.activeItem.templates && this.activeItem.templates.textOverlayTemplates) {
				formData = this.activeItem.templates.textOverlayTemplates
			}

			let foundIndex;
			formData.forEach((item, index) => {
				if (item.name === this.activeTemplate.name) {
					foundIndex = index
				}
			})

			if (foundIndex >= 0) {
				if (!deleteParam) {
					formData[foundIndex] = this.activeTemplate;
				}
				if (deleteParam) {
					formData.splice(foundIndex, 1)
				}
			}

			let requestData = {textOverlay: formData}

			let payload = {
				isFor: this.isFor,
				sgId: this.activeItem.supplierGroupId,
				formData: requestData
			};

			if (this.isFor === "legalEntity") {
				payload.legalEntityNumber = this.activeItem.entityNumber;
				payload.sgId = this.$store.state.activeSG.supplierGroupId;
			}

			await this.$store.dispatch("editPreIngestionTemplate", payload);

			if (this.$store.state.responseStatus === 200) {
				this.$store.commit("toggleAlert", {
					title: "Edit Pre Ingestion template",
					text: "QR template successfully edited",
				});
			}
			this.close();
		}
	}
}
</script>

<style scoped>
.form-container {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	margin-top: 20px;
}

.form-container > div:first-child {
	margin-right: 20px;
}

.form-container > div:last-child {
	margin-left: 20px;
}

label {
	font-weight: bold;
	margin-bottom: 0.5rem;
}

</style>
