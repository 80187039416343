const languages = [{ name: 'AA' },
    { name: 'AB' },
    { name: 'ACE' },
    { name: 'ACH' },
    { name: 'ADA' },
    { name: 'ADY' },
    { name: 'AE' },
    { name: 'AF' },
    { name: 'AFA' },
    { name: 'AFH' },
    { name: 'AGQ' },
    { name: 'AIN' },
    { name: 'AK' },
    { name: 'AKK' },
    { name: 'ALE' },
    { name: 'ALG' },
    { name: 'ALT' },
    { name: 'AM' },
    { name: 'AN' },
    { name: 'ANG' },
    { name: 'ANP' },
    { name: 'APA' },
    { name: 'AR' },
    { name: 'ARC' },
    { name: 'ARN' },
    { name: 'ARP' },
    { name: 'ARS' },
    { name: 'ART' },
    { name: 'ARW' },
    { name: 'AS' },
    { name: 'ASA' },
    { name: 'AST' },
    { name: 'ATH' },
    { name: 'AUS' },
    { name: 'AV' },
    { name: 'AWA' },
    { name: 'AY' },
    { name: 'AZ' },
    { name: 'BA' },
    { name: 'BAD' },
    { name: 'BAI' },
    { name: 'BAL' },
    { name: 'BAN' },
    { name: 'BAS' },
    { name: 'BAT' },
    { name: 'BAX' },
    { name: 'BBJ' },
    { name: 'BE' },
    { name: 'BEJ' },
    { name: 'BEM' },
    { name: 'BER' },
    { name: 'BEZ' },
    { name: 'BFD' },
    { name: 'BG' },
    { name: 'BH' },
    { name: 'BHO' },
    { name: 'BI' },
    { name: 'BIK' },
    { name: 'BIN' },
    { name: 'BKM' },
    { name: 'BLA' },
    { name: 'BM' },
    { name: 'BN' },
    { name: 'BNT' },
    { name: 'BO' },
    { name: 'BR' },
    { name: 'BRA' },
    { name: 'BRX' },
    { name: 'BS' },
    { name: 'BSS' },
    { name: 'BTK' },
    { name: 'BUA' },
    { name: 'BUG' },
    { name: 'BUM' },
    { name: 'BYN' },
    { name: 'BYV' },
    { name: 'CA' },
    { name: 'CAD' },
    { name: 'CAI' },
    { name: 'CAR' },
    { name: 'CAU' },
    { name: 'CAY' },
    { name: 'CCH' },
    { name: 'CE' },
    { name: 'CEB' },
    { name: 'CEL' },
    { name: 'CGG' },
    { name: 'CH' },
    { name: 'CHB' },
    { name: 'CHG' },
    { name: 'CHK' },
    { name: 'CHM' },
    { name: 'CHN' },
    { name: 'CHO' },
    { name: 'CHP' },
    { name: 'CHR' },
    { name: 'CHY' },
    { name: 'CKB' },
    { name: 'CMC' },
    { name: 'CO' },
    { name: 'COP' },
    { name: 'CPE' },
    { name: 'CPF' },
    { name: 'CPP' },
    { name: 'CR' },
    { name: 'CRH' },
    { name: 'CRP' },
    { name: 'CS' },
    { name: 'CSB' },
    { name: 'CU' },
    { name: 'CUS' },
    { name: 'CV' },
    { name: 'CY' },
    { name: 'DA' },
    { name: 'DAK' },
    { name: 'DAR' },
    { name: 'DAV' },
    { name: 'DAY' },
    { name: 'DE' },
    { name: 'DEL' },
    { name: 'DEN' },
    { name: 'DGR' },
    { name: 'DIN' },
    { name: 'DJE' },
    { name: 'DOI' },
    { name: 'DRA' },
    { name: 'DSB' },
    { name: 'DUA' },
    { name: 'DUM' },
    { name: 'DV' },
    { name: 'DYO' },
    { name: 'DYU' },
    { name: 'DZ' },
    { name: 'DZG' },
    { name: 'EBU' },
    { name: 'EE' },
    { name: 'EFI' },
    { name: 'EGY' },
    { name: 'EKA' },
    { name: 'EL' },
    { name: 'ELX' },
    { name: 'EN' },
    { name: 'ENM' },
    { name: 'EO' },
    { name: 'ES' },
    { name: 'ET' },
    { name: 'EU' },
    { name: 'EWO' },
    { name: 'FA' },
    { name: 'FAN' },
    { name: 'FAT' },
    { name: 'FF' },
    { name: 'FI' },
    { name: 'FIL' },
    { name: 'FIU' },
    { name: 'FJ' },
    { name: 'FO' },
    { name: 'FON' },
    { name: 'FR' },
    { name: 'FRM' },
    { name: 'FRO' },
    { name: 'FRR' },
    { name: 'FRS' },
    { name: 'FUR' },
    { name: 'FY' },
    { name: 'GA' },
    { name: 'GAA' },
    { name: 'GAY' },
    { name: 'GBA' },
    { name: 'GD' },
    { name: 'GEM' },
    { name: 'GEZ' },
    { name: 'GIL' },
    { name: 'GL' },
    { name: 'GMH' },
    { name: 'GN' },
    { name: 'GOH' },
    { name: 'GON' },
    { name: 'GOR' },
    { name: 'GOT' },
    { name: 'GRB' },
    { name: 'GRC' },
    { name: 'GSW' },
    { name: 'GU' },
    { name: 'GUZ' },
    { name: 'GV' },
    { name: 'GWI' },
    { name: 'HA' },
    { name: 'HAI' },
    { name: 'HAW' },
    { name: 'HE' },
    { name: 'HI' },
    { name: 'HIL' },
    { name: 'HIM' },
    { name: 'HIT' },
    { name: 'HMN' },
    { name: 'HO' },
    { name: 'HR' },
    { name: 'HSB' },
    { name: 'HT' },
    { name: 'HU' },
    { name: 'HUP' },
    { name: 'HY' },
    { name: 'HZ' },
    { name: 'IA' },
    { name: 'IBA' },
    { name: 'IBB' },
    { name: 'ID' },
    { name: 'IE' },
    { name: 'IG' },
    { name: 'II' },
    { name: 'IJO' },
    { name: 'IK' },
    { name: 'ILO' },
    { name: 'INC' },
    { name: 'INE' },
    { name: 'INH' },
    { name: 'IO' },
    { name: 'IRA' },
    { name: 'IRO' },
    { name: 'IS' },
    { name: 'IT' },
    { name: 'IU' },
    { name: 'JA' },
    { name: 'JBO' },
    { name: 'JGO' },
    { name: 'JMC' },
    { name: 'JPR' },
    { name: 'JRB' },
    { name: 'JV' },
    { name: 'KA' },
    { name: 'KAA' },
    { name: 'KAB' },
    { name: 'KAC' },
    { name: 'KAJ' },
    { name: 'KAM' },
    { name: 'KAR' },
    { name: 'KAW' },
    { name: 'KBD' },
    { name: 'KBL' },
    { name: 'KCG' },
    { name: 'KDE' },
    { name: 'KEA' },
    { name: 'KFO' },
    { name: 'KG' },
    { name: 'KHA' },
    { name: 'KHI' },
    { name: 'KHO' },
    { name: 'KHQ' },
    { name: 'KI' },
    { name: 'KJ' },
    { name: 'KK' },
    { name: 'KKJ' },
    { name: 'KL' },
    { name: 'KLN' },
    { name: 'KM' },
    { name: 'KMB' },
    { name: 'KN' },
    { name: 'KO' },
    { name: 'KOK' },
    { name: 'KOS' },
    { name: 'KPE' },
    { name: 'KR' },
    { name: 'KRC' },
    { name: 'KRL' },
    { name: 'KRO' },
    { name: 'KRU' },
    { name: 'KS' },
    { name: 'KSB' },
    { name: 'KSF' },
    { name: 'KSH' },
    { name: 'KU' },
    { name: 'KUM' },
    { name: 'KUT' },
    { name: 'KV' },
    { name: 'KW' },
    { name: 'KY' },
    { name: 'LA' },
    { name: 'LAD' },
    { name: 'LAG' },
    { name: 'LAH' },
    { name: 'LAM' },
    { name: 'LB' },
    { name: 'LEZ' },
    { name: 'LG' },
    { name: 'LI' },
    { name: 'LKT' },
    { name: 'LN' },
    { name: 'LO' },
    { name: 'LOL' },
    { name: 'LOZ' },
    { name: 'LT' },
    { name: 'LU' },
    { name: 'LUA' },
    { name: 'LUI' },
    { name: 'LUN' },
    { name: 'LUO' },
    { name: 'LUS' },
    { name: 'LUY' },
    { name: 'LV' },
    { name: 'MAD' },
    { name: 'MAF' },
    { name: 'MAG' },
    { name: 'MAI' },
    { name: 'MAK' },
    { name: 'MAN' },
    { name: 'MAP' },
    { name: 'MAS' },
    { name: 'MDE' },
    { name: 'MDF' },
    { name: 'MDR' },
    { name: 'MEN' },
    { name: 'MER' },
    { name: 'MFE' },
    { name: 'MG' },
    { name: 'MGA' },
    { name: 'MGH' },
    { name: 'MGO' },
    { name: 'MH' },
    { name: 'MI' },
    { name: 'MIC' },
    { name: 'MIN' },
    { name: 'MIS' },
    { name: 'MK' },
    { name: 'MKH' },
    { name: 'ML' },
    { name: 'MN' },
    { name: 'MNC' },
    { name: 'MNI' },
    { name: 'MNO' },
    { name: 'MO' },
    { name: 'MOH' },
    { name: 'MOS' },
    { name: 'MR' },
    { name: 'MS' },
    { name: 'MT' },
    { name: 'MUA' },
    { name: 'MUL' },
    { name: 'MUN' },
    { name: 'MUS' },
    { name: 'MWL' },
    { name: 'MWR' },
    { name: 'MY' },
    { name: 'MYE' },
    { name: 'MYN' },
    { name: 'MYV' },
    { name: 'NA' },
    { name: 'NAH' },
    { name: 'NAI' },
    { name: 'NAP' },
    { name: 'NAQ' },
    { name: 'NB' },
    { name: 'ND' },
    { name: 'NDS' },
    { name: 'NE' },
    { name: 'NEW' },
    { name: 'NG' },
    { name: 'NIA' },
    { name: 'NIC' },
    { name: 'NIU' },
    { name: 'NL' },
    { name: 'NMG' },
    { name: 'NN' },
    { name: 'NNH' },
    { name: 'NO' },
    { name: 'NOG' },
    { name: 'NON' },
    { name: 'NQO' },
    { name: 'NR' },
    { name: 'NSO' },
    { name: 'NUB' },
    { name: 'NUS' },
    { name: 'NV' },
    { name: 'NWC' },
    { name: 'NY' },
    { name: 'NYM' },
    { name: 'NYN' },
    { name: 'NYO' },
    { name: 'NZI' },
    { name: 'OC' },
    { name: 'OJ' },
    { name: 'OM' },
    { name: 'OR' },
    { name: 'OS' },
    { name: 'OSA' },
    { name: 'OTA' },
    { name: 'OTO' },
    { name: 'PA' },
    { name: 'PAA' },
    { name: 'PAG' },
    { name: 'PAL' },
    { name: 'PAM' },
    { name: 'PAP' },
    { name: 'PAU' },
    { name: 'PEO' },
    { name: 'PHI' },
    { name: 'PHN' },
    { name: 'PI' },
    { name: 'PL' },
    { name: 'PON' },
    { name: 'PRA' },
    { name: 'PRO' },
    { name: 'PS' },
    { name: 'PT' },
    { name: 'QU' },
    { name: 'RAJ' },
    { name: 'RAP' },
    { name: 'RAR' },
    { name: 'RM' },
    { name: 'RN' },
    { name: 'RO' },
    { name: 'ROA' },
    { name: 'ROF' },
    { name: 'ROM' },
    { name: 'RU' },
    { name: 'RUP' },
    { name: 'RW' },
    { name: 'RWK' },
    { name: 'SA' },
    { name: 'SAD' },
    { name: 'SAH' },
    { name: 'SAI' },
    { name: 'SAL' },
    { name: 'SAM' },
    { name: 'SAQ' },
    { name: 'SAS' },
    { name: 'SAT' },
    { name: 'SBA' },
    { name: 'SBP' },
    { name: 'SC' },
    { name: 'SCN' },
    { name: 'SCO' },
    { name: 'SD' },
    { name: 'SE' },
    { name: 'SEE' },
    { name: 'SEH' },
    { name: 'SEL' },
    { name: 'SEM' },
    { name: 'SES' },
    { name: 'SG' },
    { name: 'SGA' },
    { name: 'SGN' },
    { name: 'SHI' },
    { name: 'SHN' },
    { name: 'SHU' },
    { name: 'SI' },
    { name: 'SID' },
    { name: 'SIO' },
    { name: 'SIT' },
    { name: 'SK' },
    { name: 'SL' },
    { name: 'SLA' },
    { name: 'SM' },
    { name: 'SMA' },
    { name: 'SMI' },
    { name: 'SMJ' },
    { name: 'SMN' },
    { name: 'SMS' },
    { name: 'SN' },
    { name: 'SNK' },
    { name: 'SO' },
    { name: 'SOG' },
    { name: 'SON' },
    { name: 'SQ' },
    { name: 'SR' },
    { name: 'SRN' },
    { name: 'SRR' },
    { name: 'SS' },
    { name: 'SSA' },
    { name: 'SSY' },
    { name: 'ST' },
    { name: 'SU' },
    { name: 'SUK' },
    { name: 'SUS' },
    { name: 'SUX' },
    { name: 'SV' },
    { name: 'SW' },
    { name: 'SWB' },
    { name: 'SWC' },
    { name: 'SYC' },
    { name: 'SYR' },
    { name: 'TA' },
    { name: 'TAI' },
    { name: 'TE' },
    { name: 'TEM' },
    { name: 'TEO' },
    { name: 'TER' },
    { name: 'TET' },
    { name: 'TG' },
    { name: 'TH' },
    { name: 'TI' },
    { name: 'TIG' },
    { name: 'TIV' },
    { name: 'TK' },
    { name: 'TKL' },
    { name: 'TL' },
    { name: 'TLH' },
    { name: 'TLI' },
    { name: 'TMH' },
    { name: 'TN' },
    { name: 'TO' },
    { name: 'TOG' },
    { name: 'TPI' },
    { name: 'TR' },
    { name: 'TRV' },
    { name: 'TS' },
    { name: 'TSI' },
    { name: 'TT' },
    { name: 'TUM' },
    { name: 'TUP' },
    { name: 'TUT' },
    { name: 'TVL' },
    { name: 'TW' },
    { name: 'TWQ' },
    { name: 'TY' },
    { name: 'TYV' },
    { name: 'TZM' },
    { name: 'UDM' },
    { name: 'UG' },
    { name: 'UGA' },
    { name: 'UK' },
    { name: 'UMB' },
    { name: 'UND' },
    { name: 'UR' },
    { name: 'UZ' },
    { name: 'VAI' },
    { name: 'VE' },
    { name: 'VI' },
    { name: 'VO' },
    { name: 'VOT' },
    { name: 'VUN' },
    { name: 'WA' },
    { name: 'WAE' },
    { name: 'WAK' },
    { name: 'WAL' },
    { name: 'WAR' },
    { name: 'WAS' },
    { name: 'WEN' },
    { name: 'WO' },
    { name: 'XAL' },
    { name: 'XH' },
    { name: 'XOG' },
    { name: 'YAO' },
    { name: 'YAP' },
    { name: 'YAV' },
    { name: 'YBB' },
    { name: 'YI' },
    { name: 'YO' },
    { name: 'YPK' },
    { name: 'YUE' },
    { name: 'ZA' },
    { name: 'ZAP' },
    { name: 'ZBL' },
    { name: 'ZEN' },
    { name: 'ZH' },
    { name: 'ZND' },
    { name: 'ZU' },
    { name: 'ZUN' },
    { name: 'ZXX' },
    { name: 'ZZA' },]
export default languages